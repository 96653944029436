/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import moment, { Moment } from 'moment';
import Exam from 'models/Exam';
import { setSelectedExam } from 'actions/Exam/setSelectExam';
import { getAllTraining } from 'actions';
import { UserEnum } from 'constants/types/User';

export const useExamHandler = (endDivRef: React.RefObject<HTMLDivElement>) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isExamDateOpen, setIsExamDateOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [date, setDate] = useState<Moment | null>(moment());
  const [hasDate, setHasDate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useDispatch();
  const { allFormatedTrainings } = useSelector(
    (state: RootState) => state.training,
  );

  const formattedTrainingSuggestions: { label: string; value: string }[] = [];

  allFormatedTrainings?.forEach(training => {
    formattedTrainingSuggestions.push({
      label: `${training.type}: ${training.title}`,
      value: `${training.type}: ${training.title}`,
    });
  });

  const setselectedDraft = (exam?: Exam) => {
    dispatch(setSelectedExam(exam));
  };

  const toggleCalendarBar = () => setIsCalendarOpen(!isCalendarOpen);

  useEffect(() => {
    dispatch(getAllTraining());
    return () => {
      dispatch({ type: UserEnum.RESET_USERS_EXAM });
    };
  }, []);

  useEffect(() => {
    if (isCalendarOpen)
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsUserOpen(false);
  }, [isCalendarOpen]);

  useEffect(() => {
    if (isExamDateOpen)
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isExamDateOpen]);

  useEffect(() => {
    if (isUserOpen) endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsCalendarOpen(false);
    setIsExamDateOpen(false);
  }, [isUserOpen]);

  const toggleSideBar = (value: Moment | null): void => {
    setIsExamDateOpen(true);
    setDate(moment(value?.toDate().setHours(0, 0, 0, 0)));
  };
  const toggleUserBar = () => setIsUserOpen(!isUserOpen);

  const closeSessionBar = () => setIsExamDateOpen(false);

  return {
    toggleUserBar,
    isUserOpen,
    toggleCalendarBar,
    toggleSideBar,
    isCalendarOpen,
    date,
    isSaved,
    hasDate,
    isExamDateOpen,
    setIsSaved,
    setHasDate,
    setIsExamDateOpen,
    setselectedDraft,
    formattedTrainingSuggestions,
    closeSessionBar,
  };
};
