import { getFinishedModules } from './getFinishedModule';
import { getSemesterModules } from './getSemesterModules';
import { addModuleToCertificate } from './addModuleToCertificate';
import { deleteModule } from './deleteModule';
import { updateModule } from './updateModule';
import { updateCycleModule } from './updateCycleModule';

export default {
  getFinishedModules,
  getSemesterModules,
  addModuleToCertificate,
  updateModule,
  updateCycleModule,
  deleteModule,
};
