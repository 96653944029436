import { InjectedFormProps, reduxForm } from 'redux-form';
import InputField from 'components/InputField';
import * as React from 'react';
import Validators from 'helpers/formValidator';
import BreakType from 'models/BreakType';

const AddBreakTypeForm: React.FC<InjectedFormProps<BreakType, {}, string>> = ({
  handleSubmit,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <InputField name="title" label="Titre" validate={[Validators.required]} />
      <InputField
        name="provider"
        label="Fournisseur"
        validate={[Validators.required]}
      />
      <InputField
        name="unitCost"
        label="Coût unitaire"
        validate={[Validators.required, Validators.integer]}
      />
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<BreakType>({ form: 'AddBreakTypeFormForm' })(
  AddBreakTypeForm,
);
