/* eslint-disable react/no-array-index-key */
import { IClassroomDaysData } from 'models/Stats';
import React from 'react';
import StatCard from './StatCard';

const DaysPerClassroomDataDisplay = React.memo(
  ({
    title,
    value,
    className,
  }: {
    title: string;
    value: number;
    className?: string;
  }) => (
    <div className={className}>
      <strong>{`${title} : `}</strong>
      {`${value} jour${value > 1 ? 's' : ''}`}
    </div>
  ),
);

export type DaysPerClassroomProps = {
  data: IClassroomDaysData[];
  exportCSVButton: React.ReactNode;
};

const DaysPerClassroom: React.FC<DaysPerClassroomProps> = ({
  data,
  exportCSVButton,
}) => {
  return (
    <StatCard exportCSVButton={exportCSVButton} title="Jours par salle">
      {data.map((el, i) => (
        <DaysPerClassroomDataDisplay
          className={i > 0 ? 'mt-2' : ''}
          key={`${el.classroom}-${i}`}
          title={el.classroom}
          value={el.days}
        />
      ))}
    </StatCard>
  );
};

export default DaysPerClassroom;
