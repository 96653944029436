import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { getThemes, getCategories, getSessions } from 'actions';
import { getBreaks } from 'actions/Break';
import Certificate from 'models/Certificate';
import { change, formValueSelector } from 'redux-form';
import { IProgramFormDays, ICertifProgramReturn } from 'models/IProgramForm';
import Semester from 'models/Semester';

import moment from 'moment';
export const useCertificate = (
  initialValues: Partial<Certificate> | Partial<Semester>,
  certificateProgram?: ICertifProgramReturn,
) => {
  const dispatch = useDispatch();
  const { categoryList, themeList, newCertificate } = useSelector(
    (state: RootState) => state.certificate,
  );
  const { newSemester } = useSelector((state: RootState) => state.semester);
  useEffect(() => {
    dispatch(getThemes());
    dispatch(getCategories());
  }, []);

  const { modules } = useSelector((state: RootState) => state.modules);
  const { semesterModuleList } = useSelector(
    (state: RootState) => state.cycleModule,
  );
  useEffect(() => {
    dispatch(getSessions());
    dispatch(getBreaks());
  }, []);

  let formattedModules;

  if (modules) {
    formattedModules = modules?.filter(item => {
      return (
        (initialValues?.id && item.certificateId === initialValues?.id) ||
        (newCertificate?.id && item.certificateId === newCertificate?.id)
      );
    });
  } else {
    formattedModules = semesterModuleList?.filter(item => {
      return (
        (initialValues?.id && item.semesterId === initialValues?.id) ||
        (newSemester?.id && item.semesterId === newSemester?.id)
      );
    });
  }

  const selector = formValueSelector('addCertifProgramForm');

  const durations: Record<
    string,
    IProgramFormDays
  > = useSelector((state: RootState) => selector(state, 'programDays'));

  useEffect(() => {
    if (!durations) return;
    dispatch(
      change(
        'addCertifProgramForm',
        'totalTheoreticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.theoreticalDuration),
          0,
        ),
      ),
    );
    dispatch(
      change(
        'addCertifProgramForm',
        'totalPracticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.practicalDuration),
          0,
        ),
      ),
    );
  }, [durations]);

  const { BreakList } = useSelector((state: RootState) => state.breaks);
  const formattedBreaks = BreakList.filter(
    item => newCertificate && item.certificateId === newCertificate.id,
  ).map(el => ({
    id: el.id,
    type: el.breakType.title,
    date: moment(el.dateTime).format('L'),
    time: moment(el.dateTime).format('h:mm A'),
  }));

  const formattedSemesterBreaks = BreakList.filter(
    item => newSemester && item.semesterId === newSemester?.id,
  ).map(el => ({
    id: el.id,
    type: el.breakType.title,
    date: moment(el.dateTime).format('L'),
    time: moment(el.dateTime).format('h:mm A'),
  }));

  const formattedProgramValues = {
    methodology: certificateProgram?.program.methodology,
    pedGoals: certificateProgram?.program.pedGoals,
    trGoals: certificateProgram?.program.trGoals,
  };

  return {
    formattedModules,
    formattedBreaks,
    formattedSemesterBreaks,
    themeList,
    categoryList,
    formattedProgramValues,
  };
};
