import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

const PrivateRoute: React.FC<{
  component: React.FC<unknown>;
  path?: string;
  exact?: boolean;
  isNotOrganizator?: number;
  isNotLogisitic?: number;
  isNotAdmin?: number;
}> = ({
  component,
  path,
  exact,
  isNotOrganizator,
  isNotLogisitic,
  isNotAdmin,
}) => {
  const { isLogged } = useSelector((state: RootState) => state.user);
  if (isNotAdmin === 10 && isLogged) return <Redirect to="/not-found" />;
  if (isNotLogisitic === 9 && isLogged) return <Redirect to="/not-found" />;
  if (isNotOrganizator === 8 && isLogged) return <Redirect to="/not-found" />;
  return isLogged ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};
export default PrivateRoute;
