/* eslint-disable max-lines */
import * as React from 'react';
import { Moment } from 'moment';
import {
  InjectedFormProps,
  reduxForm,
  change,
  reset as RFReset,
} from 'redux-form';
import SelectField from 'components/SelectField';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { SessionForm } from 'view-models/SessionViewModel';
import Validators from 'helpers/formValidator';
import { useDispatch, useSelector } from 'react-redux';
import { useSession } from 'hooks/useSession';
import TrainingSidebar from 'components/TrainingSidebar';
import Exam from 'models/Exam';
import { RootState } from 'reducers';
interface IProps {
  date: Moment | null;
  examId?: number;
  toggleSessionBar?: () => void;
  currentExam?: Exam;
}
const AddExamDate: React.FC<IProps &
  InjectedFormProps<SessionForm, IProps>> = ({
  date,
  examId,
  handleSubmit,
  reset,
  toggleSessionBar,
  currentExam,
}) => {
  const userList = useSelector((state: RootState) => state.exam.usersExam);
  const {
    formattedDataEndTime,
    formattedDataStartTime,
    formattedDataClassroomsNum,
    filtredDataClassrooms,
    setselectedClassroom,
    setstartTime,
    setendTime,
    endTime,
    selectedClassroom,
    startTime,
  } = useSession(date, examId, true, userList);
  const dispatch = useDispatch();

  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };

  const handleStartTime = (value: ISelectFieldOption): void => {
    setstartTime(value as ISelectFieldOption<Moment>);
    dispatch(change('TrainerSectionForm', 'to', undefined));
  };

  const handleEndTime = (value: ISelectFieldOption): void => {
    setendTime(value as ISelectFieldOption<Moment>);
    dispatch(change('TrainerSectionForm', 'classroom', undefined));
  };

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <TrainingSidebar
        header={`Ajouter séances, le ${date?.format('L')}`}
        className=" p-4"
        onClose={() => {
          toggleSessionBar?.();
        }}
      >
        <div
          className="d-flex flex-column"
          style={{ justifyContent: 'space-evenly', flex: 1 }}
        >
          <div className="my-4">
            {currentExam?.defaultUser && (
              <ul className="list-group list-group-flush bg-transparent">
                <h3>Surveillants sélectionnés </h3>
                {userList?.map(el => (
                  <li
                    key={el?.email}
                    className="list-group-item d-flex justify-content-between 
            align-items-center"
                  >
                    {`${el?.firstName} ${el?.lastName}`}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <SelectField
            options={formattedDataStartTime}
            name="from"
            isSearchable
            getSelectedValue={handleStartTime}
            placeholder="Commence à"
            label="De"
            validate={[Validators.required]}
          />
          <SelectField
            options={formattedDataEndTime}
            name="to"
            getSelectedValue={handleEndTime}
            isSearchable
            placeholder="jusqu'à"
            label="A"
            validate={[Validators.required]}
            isDisabled={startTime === undefined}
          />
          <SelectField
            options={filtredDataClassrooms}
            name="classroom"
            getSelectedValue={handleClassroom}
            isSearchable
            placeholder="Choisir par type"
            label="Salle"
            validate={[Validators.required]}
            isDisabled={endTime === undefined}
          />
          <SelectField
            options={formattedDataClassroomsNum}
            name="classroomId"
            isSearchable
            placeholder="Choisir par type"
            label="Numero de salle"
            validate={[Validators.required]}
            isDisabled={selectedClassroom === undefined}
          />
          <div className="row align-items-center">
            <div className="col-6 d-flex justify-content-center">
              <button type="submit" className="btn btn-sm-wide btn-primary">
                Ajouter
              </button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <button
                type="button"
                onClick={reset}
                className="btn btn-sm-wide btn-soft-primary"
              >
                Réinitialiser
              </button>
            </div>
          </div>
        </div>
      </TrainingSidebar>
    </form>
  );
};

export default reduxForm<SessionForm, IProps>({
  form: 'AddExamDateForm',
  onSubmitSuccess: (_, dispatch) => dispatch(RFReset('AddExamDateForm')),
})(AddExamDate);
