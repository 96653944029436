import IProgramForm, {
  ICertifProgram,
  ICertifProgramReturn,
  IProgramFormDays,
} from 'models/IProgramForm';

const handleCertifModuleValues = (
  values: ICertifProgram,
  moduleIds: number[],
): ICertifProgramReturn => {
  const { programDays } = values;

  const programDaysArry: IProgramFormDays[] = [];
  let pos;
  const programme: Partial<IProgramForm> = {
    title: values?.title,
    id: values?.id,
    target: values?.target,
    themeId: values?.themeId,
    trGoals: values?.trGoals,
    pedGoals: values?.pedGoals,
    methodology: values?.methodology,
    totalTheoreticalDuration: values?.totalTheoreticalDuration,
    totalPracticalDuration: values?.totalPracticalDuration,
    certificationId: values?.certificationId,
  };

  programDays?.forEach((pro: IProgramFormDays, index: number) => {
    const courseNumber = pro?.days?.substr(0, 1);
    pos = pro?.days?.indexOf('/');
    const duration = pro?.days?.substr((pos && pos + 2) || 0, 1);
    const restOfString = pro?.days?.slice(pos && pos + 1, pro?.days?.length);
    pos = restOfString?.indexOf('/');
    const days = restOfString?.substr((pos && pos + 2) || 0, 1);

    programDaysArry.push({
      title: pro?.module,
      content: pro?.content,
      methods: pro?.methods,
      duration,
      days,
      courseNumber,
      theoreticalDuration: pro?.theoreticalDuration,
      practicalDuration: pro?.practicalDuration,
      id: moduleIds[index],
    });
  });
  return { program: programme, programModules: programDaysArry };
};

export default handleCertifModuleValues;
