/* eslint-disable max-lines */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import Training from 'models/Training';
import { useCandidate } from 'hooks/useCandidate';
import { useHistory } from 'react-router';
import { downloadFile } from 'actions/Program';
import {
  fetchCandidateAttendanceList,
  saveCandidatePresence,
  setSelectedTraining,
} from 'actions';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import columns from 'containers/ViewCertificatesAboutSection/columns';
import Modal from 'components/Modal';
import CandidatePresenceTable from 'components/CandidatePresenceTable';
import Candidate from 'models/Candidate';
import { IFormattedSessionAttendance } from 'view-models/SessionViewModel';
import { CandInterfaceEnum } from 'constants/types/candidates';
import formatData from './formatData';

export interface IProps {
  training?: Training;
  downloadProgramFile: () => void;
}
const ViewTrainingAboutSection: React.FC<IProps> = ({ training }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<
    IFormattedSessionAttendance
  >();
  const { SessionList } = useSelector((state: RootState) => state.session);
  const { candidateWithAttendance } = useSelector(
    (state: RootState) => state.candidates,
  );

  const { downloadCondiadatesFile, currentCandidates } = useCandidate(
    'One Shot',
  );
  const formattedData = formatData(SessionList, training?.id);

  const togglePresence = () => setIsModalOpen(!isModalOpen);
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePresence = (session: IFormattedSessionAttendance) => {
    setSelectedSession(session);
    dispatch(
      fetchCandidateAttendanceList({
        sessionId: session.id,
        candidateList: currentCandidates,
      }),
    );
    togglePresence();
  };

  const cols = columns(
    downloadCondiadatesFile,
    training?.theme.title,
    false,
    false,
    handlePresence,
  );

  const handleEdit = () => {
    dispatch(setSelectedTraining(training));
    dispatch({ type: mecansimTypesEnum.TOGGLE_EDIT_ONE_SHOT });
    history.push('/addtraining');
  };
  const downloadProgramFile = () => {
    dispatch(downloadFile(training?.id));
  };
  const handleSavePresence = (selectedCandidate: Candidate[]) => {
    const absentCandidate = currentCandidates.filter(
      el =>
        !selectedCandidate.some(e => {
          return e.id === el.id;
        }),
    );
    selectedSession &&
      dispatch(
        saveCandidatePresence(
          selectedSession,
          selectedCandidate,
          absentCandidate,
        ),
      );
    togglePresence();
    dispatch({ type: CandInterfaceEnum.RESET_CANDIDATE_ATTENDANCE_LIST });
  };

  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <Modal
        title="Reporting présence"
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        className="modal-xl"
      >
        {currentCandidates?.length > 0 && (
          <CandidatePresenceTable
            candidates={candidateWithAttendance || currentCandidates}
            handleSavePresence={handleSavePresence}
          />
        )}
      </Modal>
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h4 className="card-title">Informations générales</h4>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <button
                  type="button"
                  onClick={handleEdit}
                  className="btn btn-sm btn-soft-primary"
                >
                  Modifier
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row form-group">
            <span className="col-auto h5 m-0 font-weight">Thème :</span>
            <span className="col-auto h5 m-0 font-weight">
              {training?.theme.title}
            </span>

            <div className="ml-auto" style={{ marginRight: '15px' }}>
              <button
                type="button"
                className="btn btn-sm btn-soft-dark"
                onClick={downloadProgramFile}
              >
                Télécharger fiche programme
              </button>
            </div>
          </div>
          <div className="row form-group">
            <span className="col-auto m-0 h5 font-weight">Catégorie :</span>
            <span className="col-auto h5 m-0 font-weight">
              {training?.category.title}
            </span>
          </div>
          <div className="row form-group">
            <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
              Séances :
            </span>
            <div className="col-9">
              {formattedData && (
                <DataTable
                  data={formattedData}
                  columns={cols}
                  noHeader
                  persistTableHead
                  subHeaderAlign="center"
                  expandOnRowClicked
                  highlightOnHover
                  noDataComponent="Aucun élément"
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTrainingAboutSection;
