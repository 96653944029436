import { authInterfaceEnum } from 'constants/types/Auth';
import { IReduxAction } from 'constants/types/IReduxAction';
import { AxiosError } from 'axios';
import UserViewModel from 'view-models/UserViewModel';
export interface UserState {
  error?: AxiosError | Error;
  isLogged: boolean;
  token?: { id: string; userId: number };
  currentUser?: UserViewModel;
  isVerified?: boolean;
}

const initialState: UserState = { isLogged: false, isVerified: false };

export default (
  state: UserState = initialState,
  action: IReduxAction,
): UserState => {
  const { type, error, payload } = action;
  switch (type) {
    case authInterfaceEnum.SIGNIN_SUCCESS:
      return {
        ...state,
        token: payload,
        isLogged: true,
        isVerified: true,
      };

    case authInterfaceEnum.SIGNIN_FAILURE:
      return {
        ...state,
        isLogged: false,
        isVerified: true,
        error,
      };

    case authInterfaceEnum.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLogged: true,
        isVerified: true,
        currentUser: payload,
      };

    case authInterfaceEnum.LOGGED_OUT:
      return { ...initialState, isVerified: true };

    case authInterfaceEnum.LOGGED_OUT_FAILED:
      return {
        ...state,
        isVerified: true,
        error,
      };

    default:
      return state;
  }
};
