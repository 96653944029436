import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectExamId, selectTrainingId } from 'actions';

export const DisplayData: React.FC<{
  title: string;
  value?: string | number;
  className?: string;
}> = ({ title, value, className }) => (
  <div className={`h6 m-0 font-weight-semi-bold ${className || ''}`}>
    {`${title} `}
    <span className="font-weight-normal">{value}</span>
  </div>
);

interface Props {
  data: {
    trainingId?: number;
    title?: string;
    theme?: string;
    category?: string;
    type: string;
    trainer: string;
    time: string;
    room: string;
    courseNumber: number;
    trainerId: number;
    fomationTypeTitle?: string;
    examId?: number;
    examSubject?: string;
  };
  color: string;
  selectable: boolean;
}

const BigCalendarCard: React.FC<Props> = ({ data, color, selectable }) => {
  const {
    trainer,
    time,
    room,
    courseNumber,
    theme,
    category,
    title,
    type,
    fomationTypeTitle,
    examId,
  } = data;
  const dispatch = useDispatch();
  const setTraining = (id: number) => {
    dispatch(selectTrainingId(id));
  };
  const setExam = (id: number) => {
    dispatch(selectExamId(id));
  };
  const routerDiff = () => {
    switch (data.type) {
      case 'One shot':
        return '/détailsformation';
      case 'Cycle':
        return '/détailsCycle';
      case 'Certification':
        return '/détailsCertification';
      case 'Exam':
        return '/détailsExamen';
      default:
        return '';
    }
  };
  return (
    <>
      <Link
        style={{
          pointerEvents: !selectable ? 'none' : undefined,
        }}
        to={selectable ? routerDiff() : '#'}
        onClick={() => {
          setTraining(data.trainingId as number);
          setExam(data.examId as number);
        }}
      >
        <div
          className="py-1 px-3 shadow rounded mr-3 transition-3d-hover text-body"
          style={{
            backgroundColor: `${data.type === 'Exam' ? '#EE922A' : color}`,
            minWidth: 450,
            height: 150,
            cursor: 'pointer',
          }}
          data-tip=""
          data-for={data.trainingId || examId}
        >
          <div className="d-flex justify-content-between mb-1">
            <span className="h5 m-0 font-weight-semi-bold">{title}</span>
            <span className="h6 m-0">{time}</span>
          </div>
          {data.type === 'Exam' ? (
            <DisplayData title="Evaluation" />
          ) : (
            <>
              <DisplayData
                title="Type de la formation:"
                value={type === 'One shot' ? 'Formation continue' : type}
              />
              {type !== 'One shot' && (
                <DisplayData
                  title={
                    type === 'Cycle'
                      ? 'Titre du cycle:'
                      : 'Titre de la certification:'
                  }
                  value={fomationTypeTitle}
                />
              )}
            </>
          )}
          {data.type === 'Exam' && (
            <DisplayData
              title="Formation Associée"
              value={`${data.examSubject}`}
            />
          )}
          <DisplayData title="Assuré par:" value={trainer} />
          <DisplayData title="Salle:" value={room} />
          <div className="d-flex justify-content-between mb-1">
            {data.type !== 'Exam' && (
              <>
                <DisplayData title="Nombre de Seances:" value={courseNumber} />
                <DisplayData title="Catégorie:" value={category} />
              </>
            )}
          </div>
        </div>
      </Link>
      <ReactTooltip
        id={(data?.trainingId || (examId as number))?.toString()}
        textColor={color}
        place="bottom"
      >
        {/* <DisplayData title="Catégorie" value={category} /> */}
        <DisplayData title="Thème:" value={theme} />
      </ReactTooltip>
    </>
  );
};

export default BigCalendarCard;
