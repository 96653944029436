import { useEffect, useState } from 'react';

export const useTrainingView = () => {
  const [isAboutOpen, setisAboutOpen] = useState(true);
  const [isCandidatOpen, setisCandidatOpen] = useState(false);
  const [isClientOpen, setisClientOpen] = useState(false);
  const [isInviteAllClientsOpen, setisInviteAllClientsOpen] = useState(false);
  const toggleCandidatSection = () => {
    setTimeout(() => {
      setisCandidatOpen(true);
    }, 30);
  };
  useEffect(() => {
    if (!isCandidatOpen) return;
    setisAboutOpen(false);
    setisClientOpen(false);
    setisInviteAllClientsOpen(false);
  }, [isCandidatOpen]);

  useEffect(() => {
    if (!isInviteAllClientsOpen) return;
    setisCandidatOpen(false);
    setisAboutOpen(false);
    setisClientOpen(false);
  }, [isInviteAllClientsOpen]);

  const toggleAboutSection = () => {
    setTimeout(() => {
      setisAboutOpen(true);
    }, 30);
  };
  useEffect(() => {
    if (!isAboutOpen) return;
    setisCandidatOpen(false);
    setisClientOpen(false);
    setisInviteAllClientsOpen(false);
  }, [isAboutOpen]);

  const toggleClientSection = () => {
    setTimeout(() => {
      setisClientOpen(true);
    }, 30);
  };
  const toggleInviteAllClients = () => {
    setTimeout(() => {
      setisInviteAllClientsOpen(true);
    }, 30);
  };
  useEffect(() => {
    if (!isClientOpen) return;
    setisCandidatOpen(false);
    setisAboutOpen(false);
    setisInviteAllClientsOpen(false);
  }, [isClientOpen]);

  return {
    toggleCandidatSection,
    toggleAboutSection,
    toggleClientSection,
    isClientOpen,
    isInviteAllClientsOpen,
    toggleInviteAllClients,
    isCandidatOpen,
    isAboutOpen,
  };
};
