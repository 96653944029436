import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import Category from 'models/Category';
import Semester from 'models/Semester';
import Module from 'models/Module';
import Theme from 'models/Theme';
import { SemesterEnum } from 'constants/types/semester';
import IImportedSemester from 'models/IimportedSemester';
import ISemesterDetails from 'models/ISemesterDetails';
export interface ICertifcateState {
  error?: AxiosError | Error;
  newSemester?: Semester;
  selectedDraft?: Semester;
  finishedSemester?: Semester;
  selectedSemesterId?: number | null;
  unfinishedSemesterList?: Semester[];
  finishedSemesterList?: Semester[];
  moduleData?: Module;
  themeList: Theme[];
  categoryList: Category[];
  semesterList?: IImportedSemester[];
  semesterDetails?: ISemesterDetails;
}

const INITIAL_STATE: ICertifcateState = {
  themeList: [],
  categoryList: [],
  selectedSemesterId: null,
};
export default (
  state = INITIAL_STATE,
  { type, payload, error }: IReduxAction,
): ICertifcateState => {
  switch (type) {
    case SemesterEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };
    case SemesterEnum.FETCH_SEMESTERS:
      return { ...state, semesterList: payload };

    case SemesterEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case SemesterEnum.RESET_SEMESTER:
      return { ...state, newSemester: undefined };
    case SemesterEnum.FETCH_UNFINISHED_SEMESTER_SUCCESS:
      return { ...state, unfinishedSemesterList: payload };
    case SemesterEnum.FETCH_CATEGORIES_FAILURE:
    case SemesterEnum.FETCH_SEMESTERS_FAILURE:
    case SemesterEnum.FETCH_THEMES_FAILURE:
    case SemesterEnum.FETCH_UNFINISHED_SEMESTER_FAILURE:
    case SemesterEnum.FETCH_FINISHED_SEMESTER_FAILURE:
    case SemesterEnum.GET_SEMESTER_FAILURE:
    case SemesterEnum.FETCH_MODULE_DATA_FAILURE:
      return { ...state, error };
    case SemesterEnum.ADD_SEMESTER_SUCCESS:
      return { ...state, newSemester: payload };
    case SemesterEnum.SET_SELECTED_SEMESTER_SUCCESS:
      return { ...state, selectedDraft: payload };
    case SemesterEnum.SELECT_SEMESTER_SUCCESS:
      return { ...state, selectedSemesterId: payload };
    case SemesterEnum.UNSELECT_SEMESTER_SUCCESS:
      return { ...state, selectedSemesterId: payload };
    case SemesterEnum.FETCH_FINISHED_SEMESTER_SUCCESS:
      return { ...state, finishedSemester: payload };
    case SemesterEnum.RESET_SEMESTER_LIST:
      return { ...state, semesterList: undefined };
    case SemesterEnum.FETCH_SEMESTER_DETAILS_SUCCESS:
      return { ...state, semesterDetails: payload };
    default:
      return state;
  }
};
