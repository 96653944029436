import { Link, useHistory } from 'react-router-dom';
import * as React from 'react';
import { abf } from 'styles';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'actions';
import { RootState } from 'reducers';

const StaticSideBarLinks: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const handleLogout = () => {
    dispatch(signOut());
    history.push('/login');
  };
  return (
    <>
      <div className="w-100 p-3 d-flex justify-content-center border-bottom">
        <img src={abf} alt="abf" className="abfpicture" />
      </div>

      <div className="leftsidebar ">
        <Link to="/formation" className=" btn  sidebartab">
          Calendrier des Formations
        </Link>
        <Link to="/MonthCalendar" className=" btn  sidebartab">
          Calendrier par mois
        </Link>
        {currentUser?.role !== 8 && (
          <Link to="/calendrier-salle" className=" btn  sidebartab">
            Calendrier des Salles
          </Link>
        )}
        <Link to="/addtraining" className=" btn   sidebartab">
          Ajouter Formation
        </Link>
        <Link to="/candidats" className="btn   sidebartab">
          Candidats
        </Link>
        <Link to="/formateur" className=" btn  sidebartab">
          Formateurs
        </Link>
        <Link to="/classrooms" className=" btn  sidebartab">
          Salles
        </Link>
        {currentUser?.role !== 8 && (
          <Link to="/booked-classrooms" className=" btn  sidebartab">
            Salles Réservées
          </Link>
        )}
        {currentUser?.role === 10 && (
          <Link to="/formations" className=" btn  sidebartab">
            Formations
          </Link>
        )}
        {currentUser?.role === 10 && (
          <Link to="/examens" className=" btn  sidebartab">
            Examens
          </Link>
        )}

        <Link to="/BreakTypes" className=" btn  sidebartab">
          Types de pause
        </Link>
        {currentUser?.role === 10 && (
          <Link to="/BreaksPerWeek" className=" btn  sidebartab">
            Pauses par semaine
          </Link>
        )}
        <Link to="/clients" className="btn  sidebartab">
          Clients
        </Link>
        {currentUser?.role === 10 && (
          <Link to="/users" className="btn  sidebartab">
            Utilisateurs
          </Link>
        )}
        {currentUser?.role === 10 && (
          <Link to="/statistique" className="btn  sidebartab">
            Statistiques
          </Link>
        )}
        {/* <Link to="/evaluation" className=" btn  sidebartab">
          Evaluation
        </Link>
        <Link to="/feedbacks" className=" btn  sidebartab">
          Feedbacks et notes
        </Link>
        <Link to="/documents" className="btn  sidebartab">
          Documents
        </Link> */}
        <button
          type="button"
          onClick={handleLogout}
          className="btn sidebartab w-100"
        >
          Se déconnecter
        </button>
      </div>
    </>
  );
};
export default StaticSideBarLinks;
