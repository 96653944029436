export enum ClassInterfaceEnum {
  FETCH_CLASSROOM_SUCCESS = 'FETCH_CLASSROOMS_SUCCESS',
  FETCH_CLASSROOM_FAILURE = 'FETCH_CLASSROOMS_FAILURE',
  FETCH_SESSION_CLASSROOM_SUCCESS = 'FETCH_SESSION_CLASSROOM_SUCCESS',
  FETCH_SESSION_CLASSROOM_FAILURE = 'FETCH_SESSION_CLASSROOM_FAILURE',
  SELECT_CLASSROOM = 'SELECT_CLASSROOM',
  ADD_CLASSROOM_SUCCESS = 'ADD_CLASSROOM_SUCCESS',
  ADD_CLASSROOM_FAILURE = 'ADD_CLASSROOM_FAILURE',
  DELETE_CLASSROOM_SUCCES = 'DELETE_CLASSROOM_SUCCES',
  DELETE_CLASSROOM_FAILURE = 'DELETE_CLASSROOM_FAILURE',
  FETCH_BOOCKED_CLASSROOM_SUCCESS = 'FETCH_BOOCKED_CLASSROOM_SUCCESS',
  FETCH_BOOCKED_CLASSROOM_FAILURE = 'FETCH_BOOCKED_CLASSROOM_FAILURE',
}
