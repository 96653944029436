import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { UserEnum } from 'constants/types/User';
import { errorHandler } from 'helpers/errorHandler';

export const fetchCertificateUserList = (
  certificateId: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `/Certificates/${certificateId}/defaultUser`,
    );

    dispatch({
      type: UserEnum.ADD_USERS_TO_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UserEnum.ADD_USERS_TO_CERTIFICATE_FAILURE, error });
  }
  stopLoading()(dispatch);
};
