import { mecansimTypesEnum } from 'constants/types/mecanism';
import { IReduxAction } from 'constants/types/IReduxAction';

const initialState: MecanismState = {
  isOneShotEdited: false,
  isCertificateEdited: false,
  isCycleEdited: false,
  isExamEdited: false,
};

export interface MecanismState {
  isOneShotEdited: boolean;
  isCertificateEdited: boolean;
  isCycleEdited: boolean;
  isExamEdited: boolean;
}

export default (
  state: MecanismState = initialState,
  action: IReduxAction,
): MecanismState => {
  const {
    isCertificateEdited,
    isCycleEdited,
    isOneShotEdited,
    isExamEdited,
  } = state;
  const { type } = action;
  switch (type) {
    case mecansimTypesEnum.TOGGLE_EDIT_ONE_SHOT:
      return {
        isCycleEdited: false,
        isCertificateEdited: false,
        isOneShotEdited: !isOneShotEdited,
        isExamEdited: false,
      };
    case mecansimTypesEnum.TOGGLE_EDIT_CERTIFICATE:
      return {
        isOneShotEdited: false,
        isCycleEdited: false,
        isCertificateEdited: !isCertificateEdited,
        isExamEdited: false,
      };
    case mecansimTypesEnum.TOGGLE_EDIT_CYCLE:
      return {
        isOneShotEdited: false,
        isCertificateEdited: false,
        isCycleEdited: !isCycleEdited,
        isExamEdited: false,
      };
    case mecansimTypesEnum.TOGGLE_EDIT_EXAM:
      return {
        isOneShotEdited: false,
        isCertificateEdited: false,
        isCycleEdited: false,
        isExamEdited: !isExamEdited,
      };
    case mecansimTypesEnum.RESET_ALL:
      return {
        isOneShotEdited: false,
        isCertificateEdited: false,
        isCycleEdited: false,
        isExamEdited: false,
      };
    default:
      return state;
  }
};
