import { TrainingEnum } from 'constants/types/Training';
import { AxiosError } from 'axios';
import Theme from 'models/Theme';
import Category from 'models/Category';
import { IReduxAction } from 'constants/types/IReduxAction';
import Training from 'models/Training';
import IProgramData from 'models/IProgramData';
import { IDefaultUserTraining } from 'models/DefaultUserTraining';
import { UserEnum } from 'constants/types/User';
import { IGroupedTrainings } from 'models/IGroupedTrainings';
import IProgramDetails from 'models/IProgramDetails';
import { IAccountingNotes } from 'models/IAccountingNotes';
export interface TrainingState {
  error?: AxiosError | Error;
  themeList: Theme[];
  categoryList: Category[];
  newTraining?: Training;
  selectedDraft?: Training;
  finishedTraining?: Training;
  selectedTrainingId?: number | null;
  unfinishedTrainingsList?: Training[];
  finishedTrainingsList?: Training[];
  programData?: IProgramData;
  userTrainings: IDefaultUserTraining[];
  allFormatedTrainings?: IGroupedTrainings[];
  trainingDetails?: IProgramDetails;
  accountingNotes?: IAccountingNotes;
}

const initialState: TrainingState = {
  themeList: [],
  categoryList: [],
  selectedTrainingId: null,
  userTrainings: [],
  allFormatedTrainings: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): TrainingState => {
  switch (type) {
    case TrainingEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };
    case TrainingEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case TrainingEnum.FETCH_UNFINISHED_TRAINING_SUCCESS:
      return { ...state, unfinishedTrainingsList: payload };
    case TrainingEnum.FETCH_THEMES_FAILURE:
    case TrainingEnum.FETCH_CATEGORIES_FAILURE:
    case TrainingEnum.FETCH_FINISHED_TRAINING_FAILURE:
    case TrainingEnum.FETCH_UNFINISHED_TRAINING_FAILURE:
    case TrainingEnum.FETCH_PROGRAM_DATA_FAILURE:
    case TrainingEnum.GET_TRAINING_FAILURE:
    case TrainingEnum.FETCH_ACCOUNTING_NOTES_FAILURE:
      return { ...state, accountingNotes: undefined, error };
    case TrainingEnum.ADD_TRAINING_SUCCESS:
      return { ...state, newTraining: payload, selectedDraft: payload };
    case TrainingEnum.SET_SELECTED_TRAINING_SUCCESS:
      return { ...state, selectedDraft: payload };
    case TrainingEnum.SELECT_TRAINING_SUCCESS:
      return { ...state, selectedTrainingId: payload };
    case TrainingEnum.UNSELECT_TRAINING_SUCCESS:
      return { ...state, selectedTrainingId: null };
    case TrainingEnum.FETCH_PROGRAM_DATA_SUCCESS:
      return { ...state, programData: payload };
    case TrainingEnum.GET_TRAINING_SUCCESS:
      return { ...state, finishedTraining: payload };
    case TrainingEnum.RESET_TRAINING:
      return { ...state, newTraining: undefined, selectedDraft: undefined };
    case TrainingEnum.FETCH_FINISHED_TRAINING_SUCCESS:
      return { ...state, finishedTrainingsList: payload };
    case TrainingEnum.FETCH_USER_TRAININGS_SUCCESS:
    case UserEnum.FETCH_USERS_TRAINING_SUCCESS:
      return { ...state, userTrainings: payload };
    case TrainingEnum.FETCH_ALL_TRAINING_SUCCESS:
      return { ...state, allFormatedTrainings: payload };
    case TrainingEnum.FETCH_TRAINING_DETAILS_SUCCESS:
      return { ...state, trainingDetails: payload };
    case TrainingEnum.FETCH_TRAINING_DETAILS_FAILURE:
      return { ...state, error };
    case TrainingEnum.FETCH_ACCOUNTING_NOTES_SUCCESS:
      return { ...state, accountingNotes: payload };

    default:
      return state;
  }
};
