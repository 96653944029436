
import { resetProgram } from 'actions';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import Certificate from 'models/Certificate';

export const setSelectedCertificate = (
  certificate?: Certificate,
): IThunkAction => (dispatch): void => {
  dispatch(resetProgram())
  dispatch({
    type: CertificateEnum.SET_SELECTED_CERTIFICATE_SUCCESS,
    payload: certificate,
  });
};
