import { InjectedFormProps, reduxForm } from 'redux-form';
import Bank from 'models/Bank';
import FieldArrayForm from 'components/FieldArrayForm';
import ImagePickerField from 'components/ImagePickerField';
import InputField from 'components/InputField';
import * as React from 'react';
import Validators from 'helpers/formValidator';

const AddBankContainer: React.FC<InjectedFormProps<Bank, {}, string>> = ({
  handleSubmit,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <InputField name="name" label="Nom" validate={[Validators.required]} />
      <div className="align-self-center">
        <ImagePickerField
          className="bg-primary "
          name="image"
          label="Logo"
          maxFiles={1}
        />
      </div>
      <FieldArrayForm />
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<Bank>({ form: 'addBankContainerForm' })(
  AddBankContainer,
);
