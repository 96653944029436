export enum ModuleEnum {
  GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS',
  GET_MODULE_FAILURE = 'GET_MODULE_FAILURE',
  ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS',
  ADD_MODULE_FAILURE = 'ADD_MODULE_FAILURE',
  DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS',
  DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE',
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS',
  FETCH_MODULES_FAILURE = 'FETCH_MODULES_FAILURE',
  PUBLISH_MODULE_SUCCESS = 'PUBLISH_MODULE_SUCCESS',
  PUBLISH_MODULE_FAILURE = 'PUBLISH_MODULE_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  FETCH_PROGRAM_DATA_SUCCESS = 'FETCH_PROGRAM_DATA_SUCCESS',
  FETCH_PROGRAM_DATA_FAILURE = 'FETCH_PROGRAM_DATA_FAILURE',
  FETCH_UNFINISHED_MODULE_SUCCESS = 'FETCH_UNFINISHED_MODULE_SUCCESS',
  FETCH_UNFINISHED_MODULE_FAILURE = 'FETCH_UNFINISHED_MODULE_FAILURE',
  RESET_MODULE = 'RESET_MODULE',
  SELECT_MODULE_SUCCESS = 'SELECT_MODULE_SUCCESS',
  UNSELECT_MODULE_SUCCESS = 'UNSELECT_MODULE_SUCCESS',
  SET_SELECTED_MODULE_SUCCESS = 'SET_SELECTED_MODULE_SUCCESS',
  FETCH_FINISHED_MODULE_SUCCESS = 'FETCH_FINISHED_MODULE_SUCCESS',
  FETCH_FINISHED_MODULE_FAILURE = 'FETCH_FINISHED_MODULE_FAILURE',
  FETCH_USER_MODULE_SUCCESS = 'FETCH_USER_MODULE_SUCCESS',
  IMPORT_CERTIFICATE_WITH_MODULES_SUCCES = 'IMPORT_CERTIFICATE_WITH_MODULES_SUCCES',
  UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS',
  UPDATE_MODULE_FAILURE = 'UPDATE_MODULE_FAILURE',
  FETCH_MODULES_SESSIONS_SUCCESS = 'FETCH_MODULES_SESSIONS_SUCCESS',
  FETCH_MODULES_SESSIONS_FAILURE = 'FETCH_MODULES_SESSIONS_FAILURE',
}
