import IProgramForm, { ProgramToAdd } from 'models/IProgramForm';
import ProgramDaysViewModel from 'view-models/PorgramDaysViewModel';
import moment from 'moment';

const formatProgram = (data: IProgramForm, id?: number): ProgramToAdd => {
  const programDays: ProgramDaysViewModel[] = Object.keys(data.programDays).map(
    el => ({
      date: moment(el, 'L').toDate(),
      ...data.programDays[el],
    }),
  );
  const formattedData = {
    program: { ...data, id: id || 0, programDays: undefined },
    programDays,
  };
  return formattedData;
};
export default formatProgram;
