import Theme from 'models/Theme';
import Category from 'models/Category';
import { IReduxAction } from 'constants/types/IReduxAction';
import { AxiosError } from 'axios';
import { IDefaultUserTraining } from 'models/DefaultUserTraining';
import IProgramData from 'models/IProgramData';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import IModule from 'models/IModule';

export interface CycleModuleState {
  error?: AxiosError | Error;
  themeList: Theme[];
  categoryList: Category[];
  newCycleModule?: IModule;
  selectedCycleModuleDraft?: IModule;
  finishedCycleModule?: IModule;
  selectedCycleModuleId?: number | null;
  unfinishedTrainingsList?: IModule[];
  finishedCycleModuleList?: IModule[];
  moduleWithSession?: IModule;
  semesterModuleList?: IModule[];
  programData?: IProgramData;
  userTrainings: IDefaultUserTraining[];
}

const initialState: CycleModuleState = {
  themeList: [],
  categoryList: [],
  selectedCycleModuleId: null,
  userTrainings: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): CycleModuleState => {
  switch (type) {
    case ModuleCycleEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };
    case ModuleCycleEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case ModuleCycleEnum.FETCH_UNFINISHED_CYCLE_MODULE_SUCCESS:
      return { ...state, unfinishedTrainingsList: payload };
    case ModuleCycleEnum.FETCH_THEMES_FAILURE:
    case ModuleCycleEnum.FETCH_CATEGORIES_FAILURE:
    case ModuleCycleEnum.FETCH_FINISHED_CYCLE_MODULE_FAILURE:
    case ModuleCycleEnum.FETCH_UNFINISHED_CYCLE_MODULE_FAILURE:
    case ModuleCycleEnum.FETCH_PROGRAM_DATA_FAILURE:
    case ModuleCycleEnum.GET_CYCLE_MODULE_FAILURE:
      return { ...state, error };
    case ModuleCycleEnum.ADD_CYCLE_MODULE_SUCCESS:
      return { ...state, newCycleModule: payload };
    case ModuleCycleEnum.SET_SELECTED_CYCLE_MODULE_SUCCESS:
      return { ...state, selectedCycleModuleDraft: payload };
    case ModuleCycleEnum.SELECT_CYCLE_MODULE_SUCCESS:
      return { ...state, selectedCycleModuleId: payload };
    case ModuleCycleEnum.UNSELECT_CYCLE_MODULE_SUCCESS:
      return { ...state, selectedCycleModuleId: null };
    case ModuleCycleEnum.FETCH_PROGRAM_DATA_SUCCESS:
      return { ...state, programData: payload };
    case ModuleCycleEnum.GET_CYCLE_MODULE_SUCCESS:
      return { ...state, finishedCycleModule: payload };
    case ModuleCycleEnum.RESET_CYCLE_MODULE:
      return { ...state, newCycleModule: undefined };
    case ModuleCycleEnum.FETCH_FINISHED_CYCLE_MODULE_SUCCESS:
      return { ...state, finishedCycleModuleList: payload };
    case ModuleCycleEnum.FETCH_USER_CYCLE_MODULE_SUCCESS:
      return { ...state, userTrainings: payload };
    case ModuleCycleEnum.FETCH_SEMESTER_MODULE_SUCCESS:
      return { ...state, semesterModuleList: payload };
    case ModuleCycleEnum.RESET_SEMESTER_MODULE_LIST:
      return { ...state, semesterModuleList: [] };
    case ModuleCycleEnum.FETCH_CYCLE_MODULES_SESSIONS_SUCCESS:
      return { ...state, moduleWithSession: payload };
    case ModuleCycleEnum.FECH_CYCLE_MODULES_SESSIONS_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
