import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import ISelectFieldOption from 'models/ISelectFieldOption';

const SIZE = 15;
const styles: {
  [key: string]: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >['style'];
} = {
  radio: {
    height: SIZE,
    width: SIZE,
    borderRadius: SIZE / 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    marginRight: 5,
  },
};

interface Props {
  selected?: boolean;
  onPress: (item?: string | ISelectFieldOption) => void;
  title?: string;
  item?: ISelectFieldOption;
}
const RadioButton: React.FC<Props> = ({
  selected = false,
  onPress,
  title,
  item,
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className="d-flex align-items-center"
      onClick={(): void => onPress(item || title)}
      onKeyDown={(): void => onPress(item || title)}
      style={{
        cursor: 'pointer',
      }}
    >
      <div className={`${selected ? 'bg-primary' : ''}`} style={styles.radio} />
      {title && <span>{title}</span>}
    </div>
  );
};

export default RadioButton;
