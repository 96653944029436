import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ProgramEnum } from 'constants/types/Program';
import { errorHandler } from 'helpers/errorHandler';
import fileDownload from 'js-file-download';
import { ICertifProgramReturn } from 'models/IProgramForm';
import { toast } from 'react-toastify';

export const getCertificateProgram = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `certificationPrograms/${id}?filter={"include" : ["programModules"]}`,
    );

    const program = {
      title: response.data.title,
      target: response.data.target,
      themeId: response.data.themeId,
      trGoals: response.data.trGoals,
      pedGoals: response.data.pedGoals,
      methodology: response.data.methodology,
      totalTheoreticalDuration: response.data.totalTheoreticalDuration,
      totalPracticalDuration: response.data.totalPracticalDuration,
      certificationId: response.data.certificationId,
      id: response.data.id,
    };

    const { programModules } = response.data;

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: { program, programModules },
    });
  } catch (error) {
    dispatch({ type: ProgramEnum.FETCH_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addCertifProgram = (
  data: ICertifProgramReturn,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post(
      '/CertificationPrograms/with-program-modules',
      data,
    );

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: response.data,
    });
    toast('le programme a éte sauvegardé avec succès');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProgramEnum.ADD_CERTIF_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addCertifProgramAndGeneratFile = (
  data: ICertifProgramReturn,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post(
      '/CertificationPrograms/createAndGenerateFile',
      data,
    );

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: response.data,
    });
    toast('le programme a éte sauvegardé avec succès');

    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    fileDownload(file.data, `${response.data.document}`);

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: response.data,
    });
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    dispatch({ type: ProgramEnum.ADD_CERTIF_PROGRAM_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const downloadCertifProgram = (
  data: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post(
      '/CertificationPrograms/downloadFile',
      data,
    );

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: response.data,
    });
    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    fileDownload(file.data, `${response.data.document}`);

    dispatch({
      type: ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS,
      payload: response.data,
    });
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    toast(error?.response?.data?.error?.message, { type: 'error' });
    dispatch({ type: ProgramEnum.ADD_CERTIF_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};
