/* eslint-disable max-lines */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { fetchUserTrainings, getSessions } from 'actions';
import randomcolor from 'randomcolor';
import moment, { Moment } from 'moment';
import DatePickerComponent from 'components/DatePickerComponent';
import BigCalendarCard from 'components/BigCalendarCard';
import _ from 'lodash';
import formatData from './formatData';

export interface CalendarDataType {
  list: {
    colorId: string;
    trainingId?: number;
    title?: string;
    theme?: string;
    type: string;
    category?: string;
    courseNumber: number;
    fomationTypeTile?: string;
    trainer: string;
    time: string;
    room: string;
    trainerId: number;
  }[];
  day: moment.Moment | null;
}
const Calendar: React.FC<{}> = () => {
  const dispatch = useDispatch();
  let data: CalendarDataType[] = [];
  const { SessionList, currentUser, userTrainings } = useSelector(
    (state: RootState) => ({
      SessionList: state.session.SessionList,
      currentUser: state.user.currentUser,
      userTrainings: state.training.userTrainings,
    }),
  );

  const colors = React.useMemo(() => {
    const trainings = _.uniq(
      SessionList.map(el => {
        if (el.trainingId) return `training-${el.trainingId}`;
        if (el.moduleId) return `module-${el.moduleId}`;
        if (el.cycleModuleId) return `cycle-${el.cycleModuleId}`;
        if (el.examId) return `exam-${el.examId}`;
        return null;
      }).filter(el => el) as string[],
    );

    const _colors = {} as Record<string, string>;
    trainings.forEach(training => {
      _colors[training] = randomcolor({
        luminosity: 'light',
        seed: training,
      });
    });

    return _colors;
  }, [SessionList]);
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(
    moment(),
  );
  const [formattedData, setformattedData] = React.useState<typeof data>();
  React.useEffect(() => {
    dispatch(getSessions());
    dispatch(fetchUserTrainings());
  }, [dispatch]);

  React.useEffect(() => {
    data = [];
    const startDay = selectedDate?.weekday(0);
    for (let i = 0; i < 7; i++) {
      data.push({
        list: formatData(SessionList, moment(startDay)?.add(i, 'day')),
        day: moment(startDay)?.add(i, 'day'),
      });
    }
    setformattedData(data);
  }, [selectedDate, SessionList]);
  const handleDateChange = (newDate: Moment | null): void => {
    if (newDate) setSelectedDate(newDate);
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="bg-primary mb-2">
        <div className="space-1 mx-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 mb-0 text-white">
                  Calendrier des formations
                </h1>
              </div>
            </div>
            <div className="col-4">
              <div
                className="d-none d-lg-block"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                }}
              >
                <DatePickerComponent
                  setDate={handleDateChange}
                  value={selectedDate}
                  step={7}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          overflowX: 'scroll',
          flex: 1,
        }}
      >
        {formattedData &&
          formattedData.map((el, index) => (
            <div
              className="row mx-0  flex-nowrap "
              style={{ minHeight: 110, flex: 1 }}
              key={index.toString() + el.day?.toString()}
            >
              <div className="col-1 mt-1 mb-1 d-flex flex-column justify-content-center ">
                <div
                  className="h6 text-center text-primary 
                      mx-auto font-weight-semi-bold mb-1"
                >
                  {el.day?.format('dddd')}
                </div>
                <div
                  className="h6 text-center text-primary 
                      mx-auto font-weight-semi-bold mb-1"
                >
                  {el.day?.format('L')}
                </div>
              </div>
              <>
                {el.list.map(session => (
                  <div
                    key={session.trainer + session.time}
                    className="pb-1 pt-1"
                  >
                    <BigCalendarCard
                      data={session}
                      color={colors[session.colorId]}
                      selectable={
                        currentUser?.role === 10 ||
                        userTrainings.findIndex(
                          userTraining =>
                            userTraining.trainingId === session.trainingId,
                        ) !== -1
                      }
                    />
                  </div>
                ))}
              </>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Calendar;
