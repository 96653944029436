export enum BreakTypeEnum {
  FETCH_BREAK_TYPE_SUCCESS = 'FETCH_BREAK_TYPE_SUCCESS',
  FETCH_BREAK_TYPE_FAILURE = 'FETCH_BREAK_TYPE_FAILURE',
  GET_BREAK_TYPE_SUCCESS = 'GET_BREAK_TYPE_SUCCESS',
  GET_BREAK_TYPE_FAILURE = 'GET_BREAK_TYPE_FAILURE',
  UPDATE_BREAK_TYPE_SUCCESS = 'UPDATE_BREAK_TYPE_SUCCESS',
  UPDATE_BREAK_TYPE_FAILURE = 'UPDATE_BREAK_TYPE_FAILURE',
  ADD_BREAK_SUCESS = 'ADD_BREAK_SUCESS',
  ADD_BREAK_FAILURE = 'ADD_BREAK_FAILURE',
  DELETE_BREAK_TYPES_SUCCESS = 'DELETE_BREAK_TYPES_SUCCESS',
  DELETE_BREAK_TYPES_FAILURE = 'DELETE_BREAK_TYPES_FAILURE',
}
