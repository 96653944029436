export enum BreakEnum {
  FETCH_BREAK_SUCCESS = 'FETCH_BREAK_SUCCESS',
  FETCH_BREAK_FAILURE = 'FETCH_BREAK_FAILURE',
  ADD_BREAK_SUCCESS = 'ADD_BREAK_SUCCESS',
  ADD_BREAK_FAILURE = 'ADD_BREAK_FAILURE',
  FETCH_SEARCHED_BREAKS_PER_WEEK_SUCCESS = 'FETCH_SEARCHED_BREAKS_PER_WEEK_SUCCESS',
  FETCH_SEARCHED_BREAKS_PER_WEEK_FAILURE = 'FETCH_SEARCHED_BREAKS_PER_WEEK_FAILURE',
  DELETE_BREAK_SUCCESS = 'DELETE_BREAK_SUCCESS',
  DELETE_BREAK_FAILURE = 'DELETE_BREAK_FAILURE',
  UPDATE_BREAK_SUCCESS = 'UPDATE_BREAK_SUCCESS',
  UPDATE_BREAK_FAILURE = 'UPDATE_BREAK_FAILURE',
  FETCH_BREAK_BY_ID_SUCCESS = 'FETCH_BREAK_BY_ID_SUCCESS',
  FETCH_BREAK_BY_ID_FAILURE = 'FETCH_BREAK_BY_ID_FAILURE',
}
