/* eslint-disable max-lines */
/* eslint-disable no-empty */
import { BankInterfaceEnum } from 'constants/types/Bank';
import { startLoading, stopLoading } from 'actions/Loading';
import { AxiosRequestConfig } from 'axios';
import Bank, { BankInvitationForm, BankToAdd } from 'models/Bank';
import api from 'api';
import buildFormData from 'helpers/getFromData';
import { IThunkAction } from 'constants/types/IThunkAction';
import { toast } from 'react-toastify';
import { errorHandler } from 'helpers/errorHandler';

export const getBank = (): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get('Banks?filter={"include":["contact"]}');
    dispatch({
      type: BankInterfaceEnum.FETCH_BANK_SUCCESS,
      payload: response.data,
    });
    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: BankInterfaceEnum.FETCH_BANK_FAILURE, error });
    errorHandler(error);
    stopLoading()(dispatch);
  }
};

export const deleteBank = (id: number): IThunkAction => async (
  dispatch,
  getState,
) => {
  try {
    startLoading()(dispatch);
    await api.delete(`Banks/${id}`);
    dispatch({
      type: BankInterfaceEnum.DELETE_BANK_SUCCESS,
    });
    getBank()(dispatch, getState, null);
    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: BankInterfaceEnum.DELETE_BANK_FAILURE, error });
    errorHandler(error);
    stopLoading()(dispatch);
  }
};

export const addBank = (newBank: Bank): IThunkAction => async (
  dispatch,
  getState,
) => {
  try {
    startLoading()(dispatch);
    const RequestBody = new FormData();
    buildFormData(RequestBody, newBank);

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    await api.post('Banks', RequestBody, config);
    dispatch({
      type: BankInterfaceEnum.ADD_BANK_SUCCESS,
    });
    getBank()(dispatch, getState, null);
    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: BankInterfaceEnum.ADD_BANK_FAILURE, error });
    errorHandler(error);
    stopLoading()(dispatch);
  }
};

export const UpdateBank = (bank: Bank): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let requestBody: FormData | object | any | HTMLFormElement;
    if (typeof bank.image === 'string') {
      requestBody = { ...bank, image: undefined };
    } else {
      requestBody = new FormData();
      buildFormData(requestBody, bank);
    }

    const config: AxiosRequestConfig = {
      headers: {
        // Authorization: state.auth.token.id,
        'Content-Type':
          typeof bank.image === 'string'
            ? 'application/json'
            : 'multipart/form-data',
      },
    };
    await api.patch(
      `/Banks/${bank.id}${typeof bank.image === 'string' ? '' : '/withimage'}`,
      requestBody,
      config,
    );
  } catch (error) {
    errorHandler(error);
  } finally {
    stopLoading()(dispatch);
  }
};

export const AddBankCsv = (bank: BankToAdd[]): IThunkAction => async (
  dispatch,
  getState,
) => {
  try {
    await api.post('/Banks/csv', bank);
    getBank()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BankInterfaceEnum.ADD_BANK_FAILURE, error });
  }
};

export const SendMails = (
  data: BankInvitationForm[],
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const RequestBody = new FormData();
    buildFormData(RequestBody, data);
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await api.post(
      'Banks/sendInvitaionMails',
      RequestBody,
      config,
    );
    toast(response.data);
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BankInterfaceEnum.SEND_MAILS_FAILURE, error });
    stopLoading()(dispatch);
  }
};

export const SendMailsToAllClients = (
  data: Partial<BankInvitationForm>,
): IThunkAction => async dispatch => {
  try {
    const response = await api.post('Banks/sendInvitationsToAllClients', data);
    toast(response.data);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BankInterfaceEnum.SEND_MAILS_FAILURE, error });
  }
};
