import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { BankToAdd } from 'models/Bank';

interface Props {
  onChange: (data: BankToAdd[]) => void;
}

const CsvBank: React.FC<Props> = ({ onChange }) => {
  const [data, setData] = useState<BankToAdd[]>([]);
  useEffect(() => {
    onChange(data);
  }, [data]);
  const formatData = (_data: Array<Array<string>>): void => {
    const newArray = _data.slice(1, _data.length - 1);
    const header = _data[0];
    const formattedData: BankToAdd[] = [];

    newArray.forEach((singleBank: Array<string>) => {
      let bank = {} as BankToAdd;
      singleBank.forEach((field: string, fieldId: number) => {
        header.forEach((singleHeader: string, headerId: number) => {
          if (singleHeader === 'name') {
            if (headerId === fieldId) {
              bank = { ...bank, [singleHeader]: field };
            }
          }
        });
      });

      formattedData.push(bank);
    });
    setData(formattedData);
  };

  return (
    <CSVReader
      cssClass="btn btn btn-soft-light"
      onFileLoaded={formatData}
      inputId="BankInput"
    />
  );
};

export default CsvBank;
