/* eslint-disable max-len */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from 'reducers';
import moment from 'moment';
import Exam from 'models/Exam';
import { useEffect } from "react";
import { getSessions } from 'actions';



export const useAddExamSection = (initialValues: Partial<Exam>) => {
    const dispatch = useDispatch();
    const { newExam } = useSelector(
        (state: RootState) => state.exam,
      );
    const { SessionList } = useSelector((state: RootState) => state.session);
    const { loading } = useSelector((state: RootState) => state.loading);
    const userList = useSelector((state: RootState)  => state.exam.usersExam)

    useEffect(() => {
      dispatch(getSessions());
    }, [userList]);

    const formattedSessions = SessionList?.filter(
        item =>
          (initialValues && item.examId === initialValues.id) ||
          (newExam && item.examId === newExam.id),
      ).map(el => ({
        list: {
          name: el.examId ? el.exam.defaultUser.map(superivor => `${superivor.firstName} ${superivor.lastName}`).toString()   : `${el.trainer.firstName} ${el.trainer.lastName}`,
          time: `de ${moment(el.from).format('h:mm A')} à ${moment(el.to).format(
            'h:mm A',
          )}`,
          room: `${el.classroom.type} ${
            el.classroom.name !== null ? el.classroom.name : ''
          } ${el.classroom.number}`,
          courseNumber: el.courseNumber,
          isExam: !!el.examId,
          sesssion  :el
        },
        day: moment(el.date).format('L'),
        id: el.id,
      }));

    return { 
        formattedSessions,
        SessionList,
        loading 
    }
}