import { Dispatch } from 'redux';
import { LoadingInterfaceTypesEnum } from 'constants/types/Loading';

export const startLoading = () => (dispatch: Dispatch): void => {
  dispatch({ type: LoadingInterfaceTypesEnum.LOADING_START });
};

export const stopLoading = () => (dispatch: Dispatch): void => {
  dispatch({ type: LoadingInterfaceTypesEnum.LOADING_FINISHED });
};
