import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { CycleEnum } from 'constants/types/cycle';
import { errorHandler } from 'helpers/errorHandler';
export const getAllCycles = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `cycles?filter={"include" : {"semester": ["cycleModule"]}}`,
    );
    dispatch({
      type: CycleEnum.FETCH_ALL_CYCLES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CycleEnum.FETCH_ALL_CYCLES_FAILURE, error });
  }
  stopLoading()(dispatch);
};
