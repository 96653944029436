/* eslint-disable max-lines */
// import { useOneShot } from 'hooks/useOneShot';
import AddTrainingOneShotSection from 'containers/AddTrainingOneShotSection';
import React, { RefObject, useEffect } from 'react';
import { useOneShotHandler } from 'hooks/useOneShotHandler';
import { useDispatch, useSelector } from 'react-redux';
import { addSession, addTraining, publishTraining } from 'actions';
import Training from 'models/Training';
import { useTrainingHandler } from 'hooks/useTrainingHandler';
import AddTrainingBreakSection from 'containers/AddTrainingBreakSection';
import AddTrainingCalendarSection from 'containers/AddTrainingCalendarSection';
import AddTrainingProgramSection from 'containers/AddTrainingProgramSection';
import AddTrainingSessionSection from 'containers/AddTrainingSessionSection';
import AddTrainingUserSection from 'containers/AddTrainingUserSection';
import formatSession from 'helpers/formatSession';
import { SessionForm, SessionToAdd } from 'view-models/SessionViewModel';
import { BreakForm } from 'view-models/BreakViewModel';
import formatBreak from 'helpers/formatBreak';
import { addBreaks } from 'actions/Break';
import { addProgram, addProgramAndGenerateFile } from 'actions/Program';
import formatProgram from 'helpers/formatProgram';
import IProgramForm from 'models/IProgramForm';
import { addUsersToTraining } from 'actions/User';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import { RootState } from 'reducers';

interface IProp {
  isOneShotOpen: boolean;
  endDivRef: RefObject<HTMLDivElement>;
  isOneShotEdited: boolean;
  toggleOneShot: () => void;
}

export const AddOneShotContainer: React.FC<IProp> = ({
  isOneShotOpen,
  endDivRef,
  isOneShotEdited,
  toggleOneShot,
}) => {
  // one Shot handler methods
  const {
    toggleBreakBar,
    toggleCalendarBar,
    toggleProgramBar,
    toggleUserBar,
    isSaved,
    setisSaved,
    isBreakOpen,
    isCalendarOpen,
    isProgramOpen,
    isSessionOpen,
    date,
    isWithFile,
    toggleSessionBar,
    isUserOpen,
    setIsWithFile,
    setIsSessionOpen,
    setIsProgramOpen,
    setIsBreakOpen,
  } = useOneShotHandler(endDivRef);

  const { setIsOneShotOpen } = useTrainingHandler(endDivRef);
  const { selectedDraft, newTraining } = useSelector(
    (state: RootState) => state.training,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: mecansimTypesEnum.RESET_ALL });
  }, []);

  useEffect(() => {
    if (isOneShotEdited) {
      toggleCalendarBar();
    }
  }, [isOneShotEdited]);

  const handleOneShot = (values: Training): void => {
    dispatch(addTraining(values));
    setisSaved(true);
  };

  const handlepublishTraining = (): void => {
    dispatch(publishTraining(selectedDraft?.id));
    setIsOneShotOpen(false);
  };

  const handleSession = (values: SessionForm): void => {
    const formattedData = formatSession(
      values,
      date,
      newTraining,
      selectedDraft,
    );

    dispatch(addSession(formattedData as SessionToAdd));
    setIsSessionOpen(false);
  };

  const handleBreak = (data: { breaks: BreakForm[] }): void => {
    const formattedData = formatBreak(data, newTraining, selectedDraft);
    dispatch(addBreaks(formattedData));
    setIsBreakOpen(false);
  };

  const handleProgram = (values: IProgramForm): void => {
    const id = newTraining?.id || selectedDraft?.id;

    if (isWithFile)
      dispatch(addProgramAndGenerateFile(formatProgram(values, id)));
    else dispatch(addProgram(formatProgram(values, id)));
    setIsProgramOpen(false);
  };

  const handleUsers = (values: { users: number[] }): void => {
    const trainingId = newTraining?.id || selectedDraft?.id;
    trainingId &&
      dispatch(addUsersToTraining({ users: values.users, trainingId }));
  };

  return (
    <>
      {isOneShotOpen && (
        <AddTrainingOneShotSection
          isSaved={isSaved || selectedDraft !== undefined}
          toggleCalendar={(): void => toggleCalendarBar()}
          toggleBreakBar={(): void => toggleBreakBar()}
          toggleProgramBar={(): void => toggleProgramBar()}
          toggleUserBar={(): void => toggleUserBar()}
          handlePublish={handlepublishTraining}
          toggleOneShot={toggleOneShot}
          onSubmit={handleOneShot}
          initialValues={selectedDraft}
          trainingId={newTraining?.id || selectedDraft?.id}
        />
      )}
      {isBreakOpen && (
        <AddTrainingBreakSection
          onSubmit={handleBreak}
          training={newTraining || selectedDraft}
          toggleBreakBar={() => toggleBreakBar()}
        />
      )}

      {isCalendarOpen && (
        <AddTrainingCalendarSection
          toggleSidebar={(value): void => toggleSessionBar(value)}
          toggleCalendarBar={() => toggleCalendarBar()}
        />
      )}
      {isProgramOpen && (
        <AddTrainingProgramSection
          trainingId={newTraining?.id || selectedDraft?.id}
          onSubmit={handleProgram}
          setIsWithFile={setIsWithFile}
          toggleProgramBar={toggleProgramBar}
        />
      )}
      {isSessionOpen && (
        <AddTrainingSessionSection
          date={date}
          trainingId={newTraining?.id || selectedDraft?.id}
          onSubmit={handleSession}
          toggleSessionBar={() => setIsSessionOpen(false)}
        />
      )}
      {isUserOpen && (
        <AddTrainingUserSection
          currentTraining={newTraining || selectedDraft}
          onSubmit={handleUsers}
          toggleUserBar={toggleUserBar}
        />
      )}
    </>
  );
};
