import IImportedCertificate from 'models/IImportedCertificate';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import Theme from 'models/Theme';
import Category from 'models/Category';
import columns from './columns';

interface Props {
  certifications: IImportedCertificate[];
  handleImport: (id?: number) => void;
  themeList: Theme[];
  categoryList: Category[];
}

const CertificationTable: React.FC<Props> = ({
  certifications,
  handleImport,
  themeList,
  categoryList,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedCertifs, setSortedCertifs] = React.useState<
    IImportedCertificate[]
  >([]);

  const formattedCertifs = certifications.map(certif => ({
    ...certif,
    theme: themeList.find(el => el.id === certif.themeId)?.title,
    category: categoryList.find(el => el.id === certif.categoryId)?.title,
  }));
  React.useEffect(() => {
    if (keyword.length === 0) {
      setSortedCertifs(formattedCertifs);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'theme', 'category'],
    };
    const fuse = new Fuse(formattedCertifs, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCertifs(results);
  }, [keyword, certifications]);

  const cols = columns(handleImport);
  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <DataTable
      data={sortedCertifs}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default CertificationTable;
