import { getBank } from 'actions';
import { getUsers } from 'actions/User';
import InputField from 'components/InputField';
import InputFileField from 'components/InputFileField';
import RenderInvitationArrayField from 'components/RenderInvitationArrayField';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { BankInvitationForm } from 'models/Bank';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

export interface IProps {
  trainingId?: number;
}

const ViewTrainingClientSection: React.FC<IProps &
  InjectedFormProps<BankInvitationForm[], IProps>> = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBank());
    dispatch(getUsers());
  }, []);
  const { UserList } = useSelector((state: RootState) => state.users);
  const emailUserOptions = UserList.map(({ firstName, lastName, email }) => ({
    label: `${firstName} ${lastName} : ${email}`,
    value: email,
  }));
  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h4 className="card-title">
                  Inviter les Banques Concernées par Cette Formation
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div>
              <SelectField
                name="defaultUserMail"
                label="séléctionner l'expéditeur"
                options={emailUserOptions}
              />
            </div>
            <FieldArray
              props={{}}
              name="banks"
              component={RenderInvitationArrayField}
            />
            <InputFileField name="file" className="btn btn-primary py-2 mb-2" />
            <InputField
              name="subject"
              label="Objet"
              validate={[Validators.required]}
            />
            <InputField
              name="text"
              label="Texte"
              type="textarea"
              validate={[Validators.required]}
            />
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary py-2">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default reduxForm<BankInvitationForm[], IProps>({
  form: 'InvitationForm',
  //   onSubmitSuccess: (_, dispatch) => dispatch(reset('InvitationForm')),
})(ViewTrainingClientSection);
