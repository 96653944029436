/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { deleteBank, getBank, AddBankCsv } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import Bank, { BankToAdd } from 'models/Bank';
import BankCard from 'components/BankCard';
import { BankInterfaceEnum } from 'constants/types/Bank';
import { RootState } from 'reducers';
import { Link } from 'react-router-dom';
import CsvBank from 'components/CsvBank';

import { API } from 'api';

const Classrooms: React.FC = () => {
  const dispatch = useDispatch();
  const { bankList } = useSelector((state: RootState) => state.banks);

  const handleDelete = (id: number): void => {
    dispatch(deleteBank(id));
  };

  const handleUpdate = (bank: Bank): void => {
    dispatch({ type: BankInterfaceEnum.SELECT_BANK, payload: bank });
  };

  const handleChange = (data: BankToAdd[]): void => {
    dispatch(AddBankCsv(data));
  };

  useEffect(() => {
    dispatch(getBank());
  }, []);

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Banques</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier, ajouter ou supprimer une banque
                </li>
              </ol>
            </div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <Link
                    to="/addbank"
                    type="button"
                    className="btn btn btn-soft-light"
                  >
                    Ajouter une banque
                  </Link>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <a
                    href={`${API}/api/containers/CSV/download/bank_template.csv`}
                    download
                    type="button"
                    className="btn btn-soft-light"
                  >
                    Télécharger modèle CSV
                  </a>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <CsvBank onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-body">
            <div className="row mx-n2 mb-3">
              {bankList.map(el => (
                <div key={el.id} className="col-sm-6 col-md-4 px-2 mb-3">
                  <BankCard
                    {...el}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Classrooms;
