import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import AllTrainingListTable from 'components/AllTrainingListTable';
import { RootState } from 'reducers';
import { getAllTraining } from 'actions';

const AllTrainingList: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllTraining());
  }, []);
  const { allFormatedTrainings } = useSelector(
    (state: RootState) => state.training,
  );

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Formations</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  liste des formations , cycle et certification
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des Formations</h5>
          </div>
          <div className="card-body">
            {allFormatedTrainings && (
              <AllTrainingListTable data={allFormatedTrainings} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTrainingList;
