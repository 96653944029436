export enum CandInterfaceEnum {
  GENERATE_CANDIDATES_FILE_SUCCESS = 'GENERATE_CANDIDATES_FILE_SUCCESS',
  GENERATE_CANDIDATES_FILE_FAILURE = 'GENERATE_CANDIDATES_FILE_FAILURE',
  FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS',
  FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS',
  FETCH_CANDIDATES_FAILURE = 'FETCH_CANDIDATES_FAILURE',
  FETCH_CANDIDATES_ATTENDANCE_SUCCESS = 'FETCH_CANDIDATES_ATTENDANCE_SUCCESS',
  FETCH_CANDIDATES_ATTENDANCE_FAILURE = 'FETCH_CANDIDATES_ATTENDANCE_FAILURE',
  SELECT_CANDIDATE = 'SELECT_CANDIDATE',
  ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS',
  ADD_CANDIDATE_FAILURE = 'ADD_CANDIDATE_FAILURE',
  DELETE_CANDIDATE_SUCCES = 'DELETE_CANDIDATE_SUCCES',
  DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE',
  ADD_CANDIDATE_TRAINING_SUCCESS = 'ADD_CANDIDATE_TRAINING_SUCCESS',
  ADD_CANDIDATE_TRAINING_FAILURE = 'ADD_CANDIDATE_TRAINING_FAILURE',
  DELETE_CANDIDATE_FROM_TRAINING_SUCCESS = 'DELETE_CANDIDATE_FROM_TRAINING_SUCCESS',
  DELETE_CANDIDATE_FROM_TRAINING_FAILURE = 'DELETE_CANDIDATE_FROM_TRAINING_FAILURE',
  ADD_CANDIDATE_CYCLE_SUCCESS = 'ADD_CANDIDATE_CYCLE_SUCCESS',
  ADD_CANDIDATE_CYCLE_FAILURE = 'ADD_CANDIDATE_CYCLE_FAILURE',
  ADD_CANDIDATE_CERTIFICATE_SUCCESS = 'ADD_CANDIDATE_CERTIFICATE_SUCCESS',
  ADD_CANDIDATE_CERTIFICATE_FAILURE = 'ADD_CANDIDATE_CERTIFICATE_FAILURE',
  DELETE_CANDIDATE_FROM_CYCLE_SUCCESS = 'DELETE_CANDIDATE_FROM_CYCLE_SUCCESS',
  DELETE_CANDIDATE_FROM_CERTIFICATE_SUCCESS = 'DELETE_CANDIDATE_FROM_CERTIFICATE_SUCCESS',
  DELETE_CANDIDATE_FROM_CYCLE_FAILURE = 'DELETE_CANDIDATE_FROM_CYCLE_FAILURE',
  DELETE_CANDIDATE_FROM_CERTIFICATE_FAILURE = 'DELETE_CANDIDATE_FROM_CERTIFICATE_FAILURE',
  SAVE_CANDIDATE_PRESENCE_SUCCESS = 'SAVE_CANDIDATE_PRESENCE_SUCCESS',
  SAVE_CANDIDATE_PRESENCE_FAILURE = 'SAVE_CANDIDATE_PRESENCE_FAILURE',
  RESET_CANDIDATE_ATTENDANCE_LIST = 'RESET_CANDIDATE_ATTENDANCE_LIST',
  ADD_CANDIDATE_EXAM_SUCCESS = 'ADD_CANDIDATE_EXAM_SUCCESS',
  ADD_CANDIDATE_EXAM_FAILURE = 'ADD_CANDIDATE_EXAM_FAILURE',
  DELETE_CANDIDATE_FROM_EXAM_SUCCESS = 'DELETE_CANDIDATE_FROM_EXAM_SUCCESS',
  DELETE_CANDIDATE_FROM_EXAM_FAILURE = 'DELETE_CANDIDATE_FROM_EXAM_FAILURE',
}
