import { useDispatch, useSelector } from 'react-redux';
import { getProgramData } from 'actions';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { change, formValueSelector } from 'redux-form';
import { IProgramFormDays } from 'models/IProgramForm';
import { getProgram, programExists } from 'actions/Program';

export const useProgram = (trainingId?: number) => {
  const dispatch = useDispatch();

  const getInitialData = async (id: number) => {
    await dispatch(getProgramData(id));
  };
  const getData = async (id: number) => {
    const exists = await dispatch(programExists(id));
    if (typeof exists === 'boolean' && exists) dispatch(getProgram(id));
  };

  const { programData } = useSelector((state: RootState) => state.training);
  const { importedProgram } = useSelector(
    (state: RootState) => state.importedProgram,
  );
  const {
    program: { Program },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (trainingId) {
      getInitialData(trainingId);
      getData(trainingId);
    }
  }, [trainingId]);

  const selector = formValueSelector('addProgramForm');
  const durations: Record<
    string,
    IProgramFormDays
  > = useSelector((state: RootState) => selector(state, 'programDays'));
  useEffect(() => {
    dispatch(change('addProgramForm', 'title', programData?.title));
    dispatch(
      change(
        'addProgramForm',
        'target',
        importedProgram?.target || Program?.target,
      ),
    );
  }, [programData, importedProgram, Program]);
  useEffect(() => {
    if (!durations) return;
    dispatch(
      change(
        'addProgramForm',
        'totalTheoreticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.theoreticalDuration),
          0,
        ),
      ),
    );
    dispatch(
      change(
        'addProgramForm',
        'totalPracticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.practicalDuration),
          0,
        ),
      ),
    );
  }, [durations]);
  return { programData };
};
