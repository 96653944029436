import api from 'api';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import { ROLES } from 'constants/roles';
import Validators from 'helpers/formValidator';
import * as React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import UserViewModel from 'view-models/UserViewModel';

const validateUsername = async ({
  username,
}: UserViewModel): Promise<string | undefined> => {
  if (!username) return undefined;
  const response = await api.get(
    `/defaultusers/count?where={"username":"${username}"}`,
  );
  if (response.data.count !== 0)
    // eslint-disable-next-line no-throw-literal
    throw { username: "Nom d'utilisateur existe déjà" };
  return undefined;
};

const AddUserContainer: React.FC<InjectedFormProps<UserViewModel>> = ({
  handleSubmit,
  initialValues,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <div className="row">
        <div className="col-lg-4">
          <InputField
            name="lastName"
            label="Nom"
            validate={[Validators.required]}
          />
        </div>
        <div className="col-lg-4">
          <InputField
            name="firstName"
            label="Prénom"
            validate={[Validators.required]}
          />
        </div>
        <div className="col-lg-4">
          <SelectField
            name="role"
            label="Rôle"
            options={ROLES}
            validate={[Validators.required]}
          />
        </div>
      </div>
      <div className="row">
        {!initialValues && (
          <div className="col-lg-6">
            <InputField
              name="username"
              label="Nom d'utilisateur"
              validate={[Validators.required]}
            />
          </div>
        )}
        <div className="col-lg-6">
          <InputField
            name="email"
            label="Email"
            validate={[Validators.required, Validators.email]}
          />
        </div>
      </div>
      {!initialValues && (
        <div className="row">
          <div className="col-lg-6">
            <InputField
              name="password"
              label="Mot de passe"
              type="password"
              validate={[Validators.required, Validators.min8]}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              name="passwordConfirm"
              label="Confirmer votre mot de passe"
              type="password"
              validate={[Validators.required, Validators.passwordConfirm]}
            />
          </div>
        </div>
      )}
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<UserViewModel>({
  form: 'addUserform',
  asyncValidate: validateUsername,
})(AddUserContainer);
