import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import { ProgramEnum } from 'constants/types/Program';
import { ICertifProgramReturn } from 'models/IProgramForm';
import { ProgramWithDays } from 'view-models/ProgramViewModel';

export interface ProgramState {
  error?: AxiosError | Error;
  Program?: ProgramWithDays;
  certifProgram?: ICertifProgramReturn;
  semesterProgram?: ICertifProgramReturn;
}

const initialState: ProgramState = {};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): ProgramState => {
  switch (type) {
    case ProgramEnum.FETCH_PROGRAM_SUCCESS:
      return { ...state, Program: payload };
    case ProgramEnum.FETCH_PROGRAM_FAILURE:
      return { ...state, error };
    case ProgramEnum.ADD_CERTIF_PROGRAM_SUCCESS:
      return { ...state, certifProgram: payload };
    case ProgramEnum.ADD_SEMESTER_PROGRAM_SUCCESS:
      return { ...state, semesterProgram: payload };
    case ProgramEnum.FETCH_SEMESTER_PROGRAM_SUCCESS:
      return { ...state, semesterProgram: payload };
    case ProgramEnum.ADD_CERTIF_PROGRAM_FAILURE:
    case ProgramEnum.ADD_SEMESTER_PROGRAM_FAILURE:
      return { ...state, error };
    case ProgramEnum.ADD_PROGRAM_SUCCESS:
      return { ...state, Program: payload };
    case ProgramEnum.RESET_PROGRAM:
      return {
        ...state,
        Program: undefined,
        certifProgram: undefined,
        semesterProgram: undefined,
      };

    default:
      return state;
  }
};
