import Legend from 'components/Legend';
import { IClassroomPercentages } from 'models/Stats';
import React, { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import StatCard from './StatCard';

const ClassroomsPieChart = ({
  ABF,
  other,
  exportCSVButton,
}: IClassroomPercentages) => {
  const pieChartData = useMemo<DataEntry[]>(
    () => [
      { title: 'ABF', value: ABF, color: '#247275' },
      { title: 'Autre', value: other, color: '#000000' },
    ],
    [ABF, other],
  );

  const legendData = useMemo(
    () => pieChartData.map(el => ({ color: el.color, label: el.title })),
    [pieChartData],
  );

  return (
    <StatCard title="Répartition des salles" exportCSVButton={exportCSVButton}>
      <div className="d-flex justify-content-center">
        <PieChart
          className="my-3 w-50"
          data={pieChartData}
          lineWidth={20}
          paddingAngle={18}
          rounded
          animate
          label={({ dataEntry: { percentage: p } }) => `${Math.round(p)} %`}
          labelStyle={index => ({
            fill: pieChartData[index].color,
            fontSize: '5px',
            fontFamily: 'Poppins',
          })}
          labelPosition={60}
        />
      </div>
      <Legend data={legendData} />
    </StatCard>
  );
};

export default ClassroomsPieChart;
