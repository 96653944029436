import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import Category from 'models/Category';
import Cycle from 'models/Cycle';
import Module from 'models/Module';
import Theme from 'models/Theme';
import DefaultUserCycle from 'models/DefaultUserCycle';
import { CycleEnum } from 'constants/types/cycle';
import { UserEnum } from 'constants/types/User';
import Semester from 'models/Semester';
export interface ICertifcateState {
  error?: AxiosError | Error;
  newCycle?: Cycle;
  selectedDraft?: Cycle;
  finishedCycle?: Cycle;
  selectedCycleId?: number | null;
  unfinishedCycleList?: Cycle[];
  finishedCycleList?: Cycle[];
  moduleData?: Module;
  themeList: Theme[];
  categoryList: Category[];
  userCycle?: DefaultUserCycle[];
  cycleSemesters?: Semester[];
  //   userCycle?: DefaultUserCycle[];
}

const INITIAL_STATE: ICertifcateState = {
  themeList: [],
  categoryList: [],
  selectedCycleId: null,
};
export default (
  state = INITIAL_STATE,
  { type, payload, error }: IReduxAction,
): ICertifcateState => {
  switch (type) {
    case CycleEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };
    case CycleEnum.FETCH_CYCLE_SEMESTERS:
      return { ...state, cycleSemesters: payload };
    case CycleEnum.RESET_CYCLE_SEMESTER:
      return { ...state, cycleSemesters: [] };
    case CycleEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case CycleEnum.FETCH_UNFINISHED_CYCLE_SUCCESS:
      return { ...state, unfinishedCycleList: payload };
    case CycleEnum.FETCH_CATEGORIES_FAILURE:
    case CycleEnum.FETCH_CYCLE_SEMESTERS_FAILURE:
    case CycleEnum.FETCH_THEMES_FAILURE:
    case CycleEnum.FETCH_UNFINISHED_CYCLE_FAILURE:
    case CycleEnum.FETCH_FINISHED_CYCLE_FAILURE:
    case CycleEnum.GET_CYCLE_FAILURE:
    case CycleEnum.FETCH_MODULE_DATA_FAILURE:
      return { ...state, error };
    case CycleEnum.ADD_CYCLE_SUCCESS:
      return { ...state, newCycle: payload };
    case CycleEnum.SET_SELECTED_CYCLE_SUCCESS:
      return { ...state, selectedDraft: payload, newCycle: payload };
    case CycleEnum.SELECT_CYCLE_SUCCESS:
      return { ...state, selectedCycleId: payload };
    case CycleEnum.UNSELECT_CYCLE_SUCCESS:
      return { ...state, selectedCycleId: payload };
    case CycleEnum.RESET_CYCLE:
      return { ...state, selectedDraft: undefined, newCycle: undefined };
    case CycleEnum.FETCH_FINISHED_CYCLE_SUCCESS:
      return { ...state, finishedCycleList: payload };
    case UserEnum.ADD_USERS_TO_CYCLE_SUCCESS:
      return { ...state, userCycle: payload };

    default:
      return state;
  }
};
