/* eslint-disable max-lines */
import { TrainerInterfaceEnum } from 'constants/types/trainer';
import { startLoading, stopLoading } from 'actions/Loading';
import { AxiosRequestConfig } from 'axios';
import Trainer, { TrainerToAdd } from 'models/Trainer';
import api from 'api';
import buildFormData from 'helpers/getFromData';
import { IThunkAction } from 'constants/types/IThunkAction';
import { Moment } from 'moment';
import { errorHandler } from 'helpers/errorHandler';

export const getTrainers = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      'Trainers/?filter={"where":{"isDisabled": false}}',
    );

    dispatch({
      type: TrainerInterfaceEnum.FETCH_TRAINER_SUCCESS,
      payload: response.data,
    });
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TrainerInterfaceEnum.FETCH_TRAINER_FAILURE, error });
    stopLoading()(dispatch);
  }
};

export const getFiltredSessions = (
  trainerId: number,
  date: Moment,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    const formattedDate = date.startOf('d').toISOString();
    startLoading()(dispatch);
    const response = await api.get(
      // eslint-disable-next-line max-len
      `Trainers/${trainerId}/session?filter={"where":{"date": "${formattedDate}"}}`,
    );

    dispatch({
      type: TrainerInterfaceEnum.FETCH_SESSION_TRAINER_SUCCESS,
      payload: response.data,
    });
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: TrainerInterfaceEnum.FETCH_SESSION_TRAINER_FAILURE,
      error,
    });
    stopLoading()(dispatch);
  }
};
export const disableTrainer = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.get(`/Trainers/disable?id=${id}`);
    getTrainers()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TrainerInterfaceEnum.ADD_TRAINER_FAILURE, error });
  }
};

export const selectTrainer = (trainer: Trainer): IThunkAction => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: TrainerInterfaceEnum.SELECT_TRAINER,
    payload: trainer,
  });
};

export const addTrainer = (trainer: TrainerToAdd): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const RequestBody: FormData = new FormData();
    buildFormData(RequestBody, trainer);
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await api.post('/Trainers', RequestBody, config);
    dispatch({
      type: TrainerInterfaceEnum.ADD_TRAINER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TrainerInterfaceEnum.ADD_TRAINER_FAILURE, error });
  }
};

export const UpdateTrainer = (
  id: number,
  body: TrainerToAdd,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    let RequestBody;
    let config: AxiosRequestConfig = {
      headers: api.defaults.headers,
    };
    let apiRoute = '';

    if (typeof body.CVUrl !== 'string') {
      RequestBody = new FormData();
      buildFormData(RequestBody, body);
      config = {
        headers: {
          ...api.defaults.headers,
          'Content-Type': 'multipart/form-data',
        },
      };
      apiRoute = `/Trainers/${id}/withimage`;
    } else {
      RequestBody = body;
      apiRoute = `/Trainers?id=${id}`;
    }
    await api.patch(apiRoute, RequestBody, config);
    getTrainers()(dispatch, getState, null);
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TrainerInterfaceEnum.ADD_TRAINER_FAILURE, error });
    stopLoading()(dispatch);
  }
};

export const AddTrainerCsv = (trainer: TrainerToAdd[]): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.post('/Trainers/csv', trainer);

    getTrainers()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TrainerInterfaceEnum.ADD_TRAINER_FAILURE, error });
  }
};

export const FetchTrainerSessions = (id: string): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.get(`Trainers/trainer-sessions/${id}`);
    dispatch({
      type: TrainerInterfaceEnum.FETCH_TRAINER_SESSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainerInterfaceEnum.FETCH_TRAINER_SESSIONS_FAILURE,
      error,
    });
  }
};
