import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { filterTrainingTrainers } from 'helpers/filterTrainers';
import _ from 'lodash';
interface Props {
  refToPrint: React.RefObject<HTMLDivElement>;
}

const ViewTrainingDetails: React.FC<Props> = ({ refToPrint }) => {
  const { trainingDetails } = useSelector((state: RootState) => state.training);
  const uniqDates = _.uniq(trainingDetails?.dates);

  return (
    <div
      ref={refToPrint}
      className="container space-1 space-top-lg-0 ml-2 mt-lg-n10 col-7"
    >
      <div className="card-body" style={{ margin: '0px' }}>
        <div className="card">
          <div
            className="card-header"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <h5
              className="card-header-title text-center"
              style={{ marginRight: 0 }}
            >
              {`Formation: ${
                trainingDetails?.title ? trainingDetails.title : ''
              }`}
            </h5>
          </div>
          <div className="card-body row">
            <div className="container col-9 space-1 d-flex flex-column ">
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Type de La Formation : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${trainingDetails?.type ? trainingDetails.type : ''}`}
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Nombre de Jours et Durée : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${
                      trainingDetails?.nmbDays ? trainingDetails.nmbDays : 0
                    } jours / ${
                      trainingDetails?.nmbHours ? trainingDetails.nmbHours : 0
                    } heures`}
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Dates Associées : `}</h5>
                </div>
                <div className="col-4">
                  <div className="row">
                    {uniqDates?.map((d, i) => {
                      return (
                        <p
                          key={i.toString() + d.toString()}
                          className="font-weight-light font-italic mr-2"
                        >
                          {` ${d}, `}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Formateurs : `}</h5>
                </div>
                <div className="col-4">
                  <div>
                    {trainingDetails &&
                      filterTrainingTrainers(trainingDetails)?.map((t, i) => {
                        return (
                          <p
                            key={i.toString() + t.toString()}
                            className="font-weight-light font-italic row"
                          >
                            {`${t.tr} (${t.hr} heures), `}
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewTrainingDetails;
