import React from 'react';
import { WrappedFieldProps } from 'redux-form';

export interface Props extends Partial<WrappedFieldProps> {
  title?: string;
  handleChange?: (file: FileList | null) => void;
}

const InputFile: React.FC<Props> = ({ input, handleChange }) => {
  return (
    <div>
      <input
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          if (input && event.target.files) input.onChange(event.target.files);
          if (handleChange) handleChange(event.target.files);
        }}
        type="file"
        multiple
      />
    </div>
  );
};

export default InputFile;
