/* eslint-disable max-lines */
import { ProgramToAdd } from 'models/IProgramForm';
import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { ProgramEnum } from 'constants/types/Program';
import { startLoading, stopLoading } from 'actions';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { ProgramWithDays } from 'view-models/ProgramViewModel';
import { errorHandler } from 'helpers/errorHandler';
import { AxiosError } from 'axios';

export const addProgram = (data: ProgramToAdd): IThunkAction => async (
  dispatch,
): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post('/programs/createAndGenerateFile', data);
    dispatch({
      type: ProgramEnum.ADD_PROGRAM_SUCCESS,
      payload: response.data,
    });
    toast('le programme a éte sauvegardé avec succès');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProgramEnum.ADD_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addProgramAndGenerateFile = (
  data: ProgramToAdd,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post('/programs/createAndGenerateFile', data);
    dispatch({
      type: ProgramEnum.ADD_PROGRAM_SUCCESS,
      payload: response.data,
    });
    toast('le programme a éte sauvegardé avec succès');

    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    FileDownload(file.data, `${response.data.document}`);
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    dispatch({ type: ProgramEnum.ADD_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const downloadFile = (data?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post('/programs/downloadFile', data);
    dispatch({
      type: ProgramEnum.ADD_PROGRAM_SUCCESS,
      payload: response.data,
    });
    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    FileDownload(file.data, `${response.data.document}`);
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    toast(error?.response?.data?.error?.message, { type: 'error' });
    dispatch({ type: ProgramEnum.ADD_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const getProgramFile = (title: string): IThunkAction => async (
  dispatch,
): Promise<void> => {
  startLoading()(dispatch);
  try {
    const file = await api.get(`containers/docs/download/${title}.docx`, {
      responseType: 'blob',
    });
    FileDownload(file.data, `${title}.docx`);
  } catch (error) {
    toast(error.message, { type: 'error' });
  }
  stopLoading()(dispatch);
};

export const getProgram = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `programs/${id}?filter={"include" : ["programDays"]}`,
    );

    dispatch({
      type: ProgramEnum.FETCH_PROGRAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ProgramEnum.FETCH_PROGRAM_FAILURE,
      error: error as AxiosError,
    });
  }
  stopLoading()(dispatch);
};

export const resetProgram = (): IThunkAction => (dispatch): void => {
  dispatch({
    type: ProgramEnum.RESET_PROGRAM,
  });
};

export const programExists = (id: number): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`programs/${id}/exists`);
    stopLoading()(dispatch);
    return response.data.exists;
  } catch (error) {
    stopLoading()(dispatch);
    return error;
  }
};

export const getAllProgramsWithDays = (): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `programs/?filter={"include" : ["programDays"]}`,
    );
    dispatch({
      type: ProgramEnum.FETCH_ALL_PROGRAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: ProgramEnum.FETCH_ALL_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const impProgramWithDays = (
  impProg?: ProgramWithDays,
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    dispatch({
      type: ProgramEnum.IMPORT_PROGRAM_SUCCESS,
      payload: impProg,
    });
  } catch (error) {
    dispatch({ type: ProgramEnum.IMPORT_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};
