import Category from 'models/Category';
import IImportedSemester from 'models/IimportedSemester';
import Theme from 'models/Theme';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import columns from './columns';

interface Props {
  Semesters: IImportedSemester[];
  handleImport: (id?: number) => void;
  themeList: Theme[];
  categoryList: Category[];
}

const SemestersTable: React.FC<Props> = ({
  Semesters,
  handleImport,
  themeList,
  categoryList,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedSemesters, setSortedSemesters] = React.useState<
    IImportedSemester[]
  >([]);
  const formattedSemesters = Semesters.map(semester => ({
    ...semester,
    theme: themeList.find(el => el.id === semester.themeId)?.title,
    category: categoryList.find(el => el.id === semester.categoryId)?.title,
  }));
  React.useEffect(() => {
    if (keyword.length === 0) {
      setSortedSemesters(formattedSemesters);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'theme', 'category'],
    };
    const fuse = new Fuse(formattedSemesters, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedSemesters(results);
  }, [keyword, Semesters]);
  const cols = columns(handleImport);
  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <DataTable
      data={sortedSemesters}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default SemestersTable;
