import ISelectFieldOption from 'models/ISelectFieldOption';
import { CSSProperties } from 'react';
import { Styles } from 'react-select';

const customStyles: Partial<Styles<ISelectFieldOption, boolean>> = {
  option: (provided, state) => {
    const focusedControlStyles: CSSProperties = {
      // label: 'option',
      backgroundColor: 'rgba(247, 148, 28, .3)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, 0.3)' },
      boxSizing: 'border-box',
    };
    const selectedControlStyles: CSSProperties = {
      // label: 'option',
      backgroundColor: 'rgba(247,148, 28, .7)',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, .7)' },
      boxSizing: 'border-box',
    };

    if (state.isSelected) return selectedControlStyles;
    if (state.isFocused) return focusedControlStyles;

    return provided;
  },
  control: (provided, state) => {
    const focusedControlStyles: CSSProperties = {
      // label: 'control',
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: 'rgba(247, 148, 28, .5)',
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 0 10px rgba(247, 148, 28, .1)',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 47,
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      // '&:hover': {
      //   borderColor: 'rgba(247, 148, 28, .5)',
      // },
      boxSizing: 'border-box',
    };

    return state.isFocused
      ? focusedControlStyles
      : { ...provided, minHeight: 47 };
  },
};

export default customStyles;
