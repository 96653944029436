/* eslint-disable max-len */
import SessionViewModel from 'view-models/SessionViewModel';
import moment from 'moment';

const formatData = (
  list: SessionViewModel[],
  currentuserId?: number,
  examId?: number,
) => {
  const filtred = list.filter(el => examId === el.examId);

  const formatted = filtred.map(el => ({
    id: el.id,
    trainer: el.examId
      ? el?.exam?.defaultUser
          .map(superivor => `${superivor.firstName} ${superivor.lastName}`)
          .toString()
      : `${el.trainer.firstName} ${el.trainer.lastName}`,
    date: moment(el.date).format('L'),
    from: moment(el.from).format('HH:mm'),
    to: moment(el.to).format('HH:mm'),
    room: `${el.classroom.type} ${
      el.classroom.name !== null ? el.classroom.name : ''
    } ${el.classroom.number}`,
    isDefaultUser: currentuserId === el.exam.defaultUser[0].id,
  }));

  return formatted;
};

export default formatData;
