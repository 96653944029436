import * as React from 'react';
import BreakListPerWeekViewModel from 'view-models/BreakPerWeekViewModel';
import moment from 'moment';
const columns = (
  handleEdit: (id: number | undefined) => void,
  handleDelete: (id?: number) => void,
) => {
  const data = [
    {
      name: 'Titre de formation',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Type de formation',
      selector: 'trainingType',
      sortable: true,
    },
    {
      name: 'nombre de personne inscrit',
      selector: 'totalCandidates',
      sortable: true,
    },
    {
      name: 'Type de pause',
      center: true,
      maxWidth: '8vm',
      cell: (row: BreakListPerWeekViewModel) => (
        <div>{row.breakType.title}</div>
      ),
    },
    {
      center: true,
      name: 'Heure',
      maxWidth: '8vm',
      cell: (row: BreakListPerWeekViewModel) => (
        <div>{moment(row.dateTime).format('HH:mm')}</div>
      ),
    },
    {
      center: true,
      name: 'Date',
      maxWidth: '8vm',
      cell: (row: BreakListPerWeekViewModel) => (
        <div>{moment(row.dateTime).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      center: true,
      name: 'Modifier',
      maxWidth: '8vw',
      cell: (row: BreakListPerWeekViewModel) => (
        <button
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
              align-items-center justify-content-center"
          onClick={(): void => handleEdit(row?.id)}
        >
          <i className="fa fa-edit" />
        </button>
      ),
    },
    {
      center: true,
      name: 'Supprimer',
      maxWidth: '8vw',
      cell: (row: BreakListPerWeekViewModel) => (
        <button
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
              align-items-center justify-content-center"
          onClick={(): void => handleDelete(row?.id)}
        >
          <i className="fa fa-trash" />
        </button>
      ),
    },
  ];
  return data;
};

export default columns;
