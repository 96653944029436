import { selectSemesterCard } from 'actions/Semester/selectSemesterCard';
import { selectSemesterProgram } from 'actions/SemesterProgram';
import Semester from 'models/Semester';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';

export interface ISemesterCard {
  semester: Semester;
  toggleSemester?: () => void;
  setisSaved?: (isSaved: boolean) => void;
  deleteSemester?: (id: number) => void;
}

const SemesterCard: React.FC<ISemesterCard> = ({
  semester,
  deleteSemester,
  toggleSemester,
  setisSaved,
}) => {
  const dispatch = useDispatch();
  const getSelectedSemester = (): void => {
    setisSaved?.(true);
    dispatch(selectSemesterCard({ semester }));
    dispatch(selectSemesterProgram(semester.id));
    toggleSemester?.();
  };
  const { themeList, categoryList } = useSelector(
    (state: RootState) => state.cycle,
  );

  const themeTitle = themeList.find(e => e.id === semester.themeId);
  const categoryTitle = categoryList.find(e => e.id === semester.categoryId);

  return (
    <div className="mt-1 mb-2 position-relative">
      <div
        role="button"
        key={semester.id}
        onClick={getSelectedSemester}
        onKeyPress={getSelectedSemester}
        tabIndex={semester.id}
        className="p-1 shadow rounded text-center mx-auto"
        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
      >
        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto mb-1 text-primary">
              Semester:
            </h3>
          </div>
          <div className="col-9">
            <h6 style={{ wordBreak: 'break-word' }}>{semester.title}</h6>
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">théme:</h3>
          </div>
          <div className="col-9">
            {themeTitle && (
              <h6
                style={{ wordBreak: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: themeTitle.title }}
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              Categorie:
            </h3>
          </div>
          <div className="col-9">
            {categoryTitle && (
              <h6
                style={{ wordBreak: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: categoryTitle.title }}
              />
            )}
          </div>
        </div>
      </div>
      {semester.id && deleteSemester && (
        <div
          className="position-absolute bottom-0 right-0"
          style={{ cursor: 'pointer' }}
        >
          <button
            type="button"
            className="btn btn-icon transition-3d-hover"
            onClick={(): void => deleteSemester(semester.id)}
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SemesterCard;
