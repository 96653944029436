import Legend from 'components/Legend';
import { IDaysPerTrainerData } from 'models/Stats';
import randomColor from 'randomcolor';
import React, { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import StatCard from './StatCard';

const SEED_MULTIPLIER = 10;

export type IDaysPerTrainerProps = {
  data: IDaysPerTrainerData[];
  exportCSVButton: React.ReactNode;
};

const DaysPerTrainer = ({ data, exportCSVButton }: IDaysPerTrainerProps) => {
  const pieChartData = useMemo<DataEntry[]>(
    () =>
      data.map(el => ({
        name: el.name,
        value: el.count,
        color: randomColor({
          seed: el.id * SEED_MULTIPLIER,
          hue: 'blueroyal',
          luminosity: 'dark',
        }),
      })),
    [data, SEED_MULTIPLIER],
  );

  const legendData = useMemo(
    () => pieChartData.map(el => ({ color: el.color, label: el.name })),
    [pieChartData],
  );
  return (
    <StatCard {...{ exportCSVButton }} title="Nombre de jours par formateur">
      <div className="d-flex justify-content-center">
        <PieChart
          className="my-3 w-50"
          data={pieChartData}
          lineWidth={20}
          paddingAngle={18}
          rounded
          animate
          label={({ dataEntry: { value } }) => value}
          labelStyle={index => ({
            fill: pieChartData[index].color,
            fontSize: '5px',
            fontFamily: 'Poppins',
          })}
          labelPosition={60}
        />
      </div>
      <Legend data={legendData} />
    </StatCard>
  );
};

export default DaysPerTrainer;
