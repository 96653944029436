import * as React from 'react';
import { InjectedFormProps, reduxForm, reset as RFReset } from 'redux-form';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { getUsers } from 'actions/User';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import TrainingSidebar from 'components/TrainingSidebar';
import { fetchCertificateUserList } from 'actions/Certificate/fetchCertificateUserList';
import Certificate from 'models/Certificate';
import { DeleteUserFromCertificate } from 'actions/Certificate/deleteUserFromCertificate';

interface IProps {
  currentCertificate?: Certificate;
  toggleUserBar?: () => void;
}

const AddCertificateUserSection: React.FC<IProps &
  InjectedFormProps<{ users: number[] }, IProps>> = ({
  toggleUserBar,
  handleSubmit,
  currentCertificate,
}) => {
  const dispatch = useDispatch();
  const { userCertificate } = useSelector(
    (state: RootState) => state.certificate,
  );
  const { UserList } = useSelector((state: RootState) => state.users);
  const { token } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleDelete = (id: number) => {
    currentCertificate &&
      dispatch(DeleteUserFromCertificate(id, currentCertificate.id));
  };

  useEffect(() => {
    dispatch(fetchCertificateUserList(currentCertificate?.id));
  }, []);

  const actUser = token?.userId;
  const currentUsers = userCertificate?.filter(el => el.id !== actUser);
  const filtredUsers = UserList?.filter(
    a => !userCertificate?.some(b => a.id === b.id),
  );
  const formattedData: ISelectFieldOption[] = filtredUsers.map(el => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));

  return (
    <div className="col-4 p-0">
      <TrainingSidebar
        header="Gérer les organisateurs"
        onClose={() => toggleUserBar?.()}
        className="p-4"
      >
        <div className="my-4">
          {currentUsers && (
            <ul className="list-group list-group-flush">
              {currentUsers.map(el => (
                <li
                  key={el.email}
                  className="list-group-item d-flex justify-content-between 
            align-items-center"
                >
                  {`${el.firstName} ${el.lastName}`}
                  <div
                    className="position-absolute right-0"
                    style={{ cursor: 'pointer' }}
                  >
                    <button
                      type="button"
                      className="btn btn-icon"
                      onClick={() => handleDelete(el.id)}
                    >
                      <i className="far fa-trash-alt" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <SelectField
            options={formattedData}
            name="users"
            placeholder="Choisir un utilisateur"
            validate={[Validators.required]}
            label="Ajouter organisateurs"
            isMulti
          />
          <button type="submit" className="btn btn-primary py-2 align-self-end">
            Enregistrer
          </button>
        </form>
      </TrainingSidebar>
    </div>
  );
};

export default reduxForm<{ users: number[] }, IProps>({
  form: 'addCertificateUserForm',
  onSubmitSuccess: (_, dispatch) => dispatch(RFReset('addCertificateUserForm')),
})(AddCertificateUserSection);
