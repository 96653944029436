import api from 'api';
import {
  getCycleSemesterModuleSessions,
  startLoading,
  stopLoading,
} from 'actions';
import { IThunkAction } from 'constants/types/IThunkAction';
import { SemesterEnum } from 'constants/types/semester';
import { CycleEnum } from 'constants/types/cycle';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { errorHandler } from 'helpers/errorHandler';

export const deleteSemester = (
  id: number,
  idCycle: number,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const res = await api.delete(`/Semesters/${id}/${idCycle}`);
    dispatch({
      type: CycleEnum.FETCH_CYCLE_SEMESTERS,
      payload: res.data,
    });
    dispatch({
      type: ModuleCycleEnum.RESET_SEMESTER_MODULE_LIST,
    });
    dispatch(getCycleSemesterModuleSessions(idCycle));
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SemesterEnum.DELETE_SEMESTER_FAILURE, error });
  }
  stopLoading()(dispatch);
};
