import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { errorHandler } from 'helpers/errorHandler';

export const getCycleModuleSessions = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `CycleModules/${id}?filter={"include" : [{"session" : ["classroom","trainer"]} ,
            {"semester" : [{"cycle" : ["candidate"]} , "theme" , "category"]}]}`,
    );
    dispatch({
      type: ModuleCycleEnum.FETCH_CYCLE_MODULES_SESSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleCycleEnum.FECH_CYCLE_MODULES_SESSIONS_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
