import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { getSessions } from 'actions/Sessions';
import { ModuleEnum } from 'constants/types/Module';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { SessionEnum } from 'constants/types/Session';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import { getSemesterModules } from './getSemesterModules';
import { getFinishedModules } from './getFinishedModule';

export const deleteModule = (id?: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/Modules/${id}/session`);
    dispatch(getSessions());
    await api.delete(`/Modules/${id}`);
    dispatch({
      type: ModuleEnum.DELETE_MODULE_SUCCESS,
    });
    dispatch({
      type: SessionEnum.RESET_NEW_SESSION,
    });

    getFinishedModules()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ModuleEnum.DELETE_MODULE_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const deleteCycleModule = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  const semesterId = getState().semester.newSemester?.id;
  if (!semesterId) return;
  try {
    startLoading()(dispatch);
    await api.delete(`/CycleModules/${id}/session`);
    await api.delete(`/CycleModules/${id}`);
    dispatch({
      type: ModuleCycleEnum.DELETE_CYCLE_MODULE_SUCCESS,
    });
    dispatch({
      type: SessionEnum.RESET_SESSION_MODULE,
    });
    getSemesterModules(semesterId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: ModuleCycleEnum.DELETE_CYCLE_MODULE_FAILURE, error });
  }
  stopLoading()(dispatch);
};
