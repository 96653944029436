import api from 'api';
import { ModuleEnum } from 'constants/types/Module';
import { IThunkAction } from 'constants/types/IThunkAction';
import { startLoading, stopLoading } from 'actions';
import { errorHandler } from 'helpers/errorHandler';
export const getCycleModules = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const cycleModules = await api.get('CycleModules');
    const modules = await api.get('modules');

    const data = [...cycleModules.data, ...modules.data];

    dispatch({
      type: ModuleEnum.FETCH_MODULES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ModuleEnum.FETCH_MODULES_FAILURE, error });
  }
  stopLoading()(dispatch);
};
