/* eslint-disable max-lines */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useCandidate } from 'hooks/useCandidate';
import { fetchCandidateAttendanceList, saveCandidatePresence } from 'actions';
import Exam from 'models/Exam';
import Candidate from 'models/Candidate';
import Modal from 'components/Modal';
import CandidatePresenceTable from 'components/CandidatePresenceTable';
import { CandInterfaceEnum } from 'constants/types/candidates';
import columns from 'containers/ViewCertificatesAboutSection/columns';
import { IFormattedSessionAttendance } from 'view-models/SessionViewModel';
import formatData from './formatData';

export interface IProps {
  exam: Exam;
}
const ViewExamAboutSection: React.FC<IProps> = ({ exam }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<
    IFormattedSessionAttendance
  >();
  const { SessionList } = useSelector((state: RootState) => state.session);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { candidateWithAttendance } = useSelector(
    (state: RootState) => state.candidates,
  );
  const { downloadCondiadatesFile, currentCandidates } = useCandidate('Exam');
  const formattedData = formatData(SessionList, currentUser?.id, exam?.id);

  const togglePresence = () => setIsModalOpen(!isModalOpen);
  const dispatch = useDispatch();

  const handlePresence = (session: IFormattedSessionAttendance) => {
    setSelectedSession(session);
    dispatch(
      fetchCandidateAttendanceList({
        sessionId: session.id,
        candidateList: currentCandidates,
      }),
    );
    togglePresence();
  };

  const cols = columns(
    downloadCondiadatesFile,
    exam?.title,
    false,
    true,
    handlePresence,
    exam.id,
    formattedData[0]?.isDefaultUser,
  );

  const handleSavePresence = (selectedCandidate: Candidate[]) => {
    const absentCandidate = currentCandidates.filter(
      el =>
        !selectedCandidate.some(e => {
          return e.id === el.id;
        }),
    );
    selectedSession &&
      dispatch(
        saveCandidatePresence(
          selectedSession,
          selectedCandidate,
          absentCandidate,
        ),
      );
    togglePresence();
    dispatch({ type: CandInterfaceEnum.RESET_CANDIDATE_ATTENDANCE_LIST });
  };

  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <Modal
        title="Reporting présence"
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        className="modal-xl"
      >
        {currentCandidates?.length > 0 && (
          <CandidatePresenceTable
            candidates={candidateWithAttendance || currentCandidates}
            handleSavePresence={handleSavePresence}
          />
        )}
      </Modal>
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h4 className="card-title">Informations générales</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row form-group">
            <span className="col-auto h5 m-0 font-weight">Titre :</span>
            <span className="col-auto h5 m-0 font-weight">{exam?.title}</span>
          </div>
          <div className="row form-group">
            <span className="col-auto m-0 h5 font-weight">Surveillant :</span>
            <span className="col-auto h5 m-0 font-weight">
              {exam?.defaultUser}
            </span>
          </div>
          <div className="row form-group">
            <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
              Séances :
            </span>
            <div className="col-9">
              {formattedData && (
                <DataTable
                  data={formattedData}
                  columns={cols}
                  noHeader
                  persistTableHead
                  subHeaderAlign="center"
                  expandOnRowClicked
                  highlightOnHover
                  noDataComponent="Aucun élément"
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewExamAboutSection;
