import * as React from 'react';

interface Props {
  data: {
    type: string;
    date: string;
    time: string;
    id: number;
  };
  deleteBreak: (id: number) => void;
}

const BreakCard: React.FC<Props> = ({ data, deleteBreak }) => {
  const { type, date, time, id } = data;

  return (
    <div className="mt-1 mb-2 position-relative">
      <div
        className="p-1 shadow rounded text-center mx-auto"
        style={{ backgroundColor: 'lightgrey' }}
      >
        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto mb-1 text-primary">Date:</h3>
          </div>
          <div className="col-9">
            <h6>{date}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">Heure:</h3>
          </div>
          <div className="col-9">
            <h6>{time}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">type:</h3>
          </div>
          <div className="col-9">
            <h6>{type}</h6>
          </div>
        </div>
      </div>

      <div
        className="position-absolute bottom-0 right-0"
        style={{ cursor: 'pointer' }}
      >
        <button
          type="button"
          className="btn btn-icon transition-3d-hover"
          onClick={(): void => deleteBreak(id)}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
    </div>
  );
};

export default BreakCard;
