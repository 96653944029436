import axios from 'axios';
import Dexie from 'dexie';

export const API =
  process.env.NODE_ENV === 'production'
    ? 'https://api-backoffice.abf.tn'
    : 'http://localhost:4123';

const api = axios.create({
  baseURL: `${API}/api`,
});
export const db = new Dexie('Auth');
db.version(1).stores({
  user: 'id',
});
export const resetAuthToken = (token: string | undefined) => {
  if (token) {
    api.defaults.headers.Authorization = token;
  } else {
    api.defaults.headers.Authorization = null;
  }
};
export default api;
