import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { useEffect } from 'react';
import RenderInvitationFields from 'components/RenderInvitationFields';

const RenderInvitationArrayField: React.FC<WrappedFieldArrayProps> = ({
  fields,
}) => {
  useEffect(() => {
    fields.push(null);
  }, []);

  return (
    <>
      {fields.map((el, index) => (
        <div className="row" key={el}>
          <RenderInvitationFields
            bankName={`${el}.bank`}
            contactsName={`${el}.contacts`}
          />
          <div className="col-auto d-flex justify-content-center px-0 m-auto pb-0">
            <button
              className="btn btn-block btn-primary btn-icon"
              onClick={(): void => fields.push(null)}
              type="button"
            >
              <i className="fas fa-plus" />
            </button>
          </div>
          <div className="col-auto d-flex justify-content-center px-0 m-auto pb-0">
            <button
              className="btn btn-block btn-danger btn-icon"
              onClick={(): void => fields.remove(index)}
              type="button"
            >
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default RenderInvitationArrayField;
