/* eslint-disable max-lines */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
// import RenderProgramDays from 'components/RenderProgramDays';
import { ICertifProgram, ICertifProgramReturn } from 'models/IProgramForm';
// import { useProgram } from 'hooks/useProgram';
import RichField from 'components/RichField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
// import Modal from 'components/Modal';
import { getAllProgramsWithDays } from 'actions/Program';
// import ProgramsTable from 'components/ProgramsTable';
import RenderCertifProgramDays from 'components/RenderCertifProgramDays';
import AutoCompleteField from 'components/AutoCompleteFiled';
import { useCertificate } from 'hooks/useCertificate';

interface IProps {
  setIsWithFile: (value: boolean) => void;
  certificateProgram?: ICertifProgramReturn;
}
const AddCertificateProgramSection: React.FC<IProps &
  InjectedFormProps<ICertifProgram, IProps>> = ({
  handleSubmit,
  setIsWithFile,
  initialValues,
  certificateProgram,
}) => {
  const {
    loading: { loading },
    certificate: { newCertificate, themeList },
    program: { certifProgram },
  } = useSelector((state: RootState) => state);

  const { formattedModules, formattedProgramValues } = useCertificate(
    initialValues,
    certificateProgram,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProgramsWithDays());
  }, []);

  useEffect(() => {
    dispatch(change('addCertifProgramForm', 'title', newCertificate?.title));
    dispatch(
      change('addCertifProgramForm', 'target', certifProgram?.program.target),
    );
  }, [newCertificate]);

  if (loading) return null;

  return (
    <form onSubmit={handleSubmit} className="col-12 p-0">
      <TrainingSidebar header="Fiche programme" className="p-4">
        <div className="row my-4">
          <div className="col-4">
            <InputField
              name="title"
              label="Titre"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-4">
            <AutoCompleteField
              importedValue={newCertificate?.themeId}
              className="orange badge-outline-primary"
              label="Thème"
              name="themeId"
              validate={[Validators.required]}
              suggestions={themeList}
            />
          </div>
          <div className="col-4">
            <InputField
              name="target"
              label="Cible"
              validate={[Validators.required]}
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6">
            <RichField
              name="trGoals"
              label="Objectifs formation"
              validate={[Validators.required]}
              pickedContent={formattedProgramValues?.trGoals}
            />
          </div>
          <div className="col-6">
            <RichField
              name="pedGoals"
              label="Objectifs pédagogiques"
              validate={[Validators.required]}
              // pickedContent={importedProgram?.pedGoals}
              pickedContent={formattedProgramValues?.pedGoals}
            />
          </div>
          {/* <div className="col-4">
            {programData?.trainer && <ProgramCard data={programData} />}
          </div> */}
        </div>

        {formattedModules && (
          <RenderCertifProgramDays data={formattedModules} />
        )}

        <div className="row my-4 align-items-center">
          <div className="col-8">
            <RichField
              name="methodology"
              label="Méthodologies et outils d'évaluation"
              validate={[Validators.required]}
              // pickedContent={importedProgram?.methodology}
              pickedContent={formattedProgramValues?.methodology}
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalTheoreticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalPracticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(true)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer et télécharger PDF
            </button>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(false)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<ICertifProgram, IProps>({
  form: 'addCertifProgramForm',
  // onSubmitSuccess: (_, dispatch) => dispatch(reset('addProgramForm')),
  enableReinitialize: true,
})(AddCertificateProgramSection);
