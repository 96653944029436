import moment from 'moment';

export const getMonthAndYear = (
  selectedMonth: string,
  selectedYear: string | undefined,
) => {
  moment.locale('fr');
  const month = selectedMonth || moment().format('MMMM');

  const year = selectedYear || moment().year();

  return { month, year };
};
