import { AxiosError } from 'axios';
import { CertificateEnum } from 'constants/types/Certificate';
import { IReduxAction } from 'constants/types/IReduxAction';
import Category from 'models/Category';
import Certificate from 'models/Certificate';
import Module from 'models/Module';
import Theme from 'models/Theme';
import { UserEnum } from 'constants/types/User';
import UserViewModel from 'view-models/UserViewModel';
import ICertificateDetails from 'models/ICertificateDetails';
export interface ICertifcateState {
  error?: AxiosError | Error;
  newCertificate?: Certificate;
  selectedDraft?: Certificate;
  finishedCertificate?: Certificate;
  selectedCertificateId?: number | null;
  unfinishedCertificateList?: Certificate[];
  finishedCertificateList?: Certificate[];
  moduleData?: Module;
  themeList: Theme[];
  categoryList: Category[];
  userCertificate?: UserViewModel[];
  certificateDetails?: ICertificateDetails;
}

const INITIAL_STATE: ICertifcateState = {
  themeList: [],
  categoryList: [],
  selectedCertificateId: null,
};
export default (
  state = INITIAL_STATE,
  { type, payload, error }: IReduxAction,
): ICertifcateState => {
  switch (type) {
    case CertificateEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };

    case CertificateEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case CertificateEnum.FETCH_UNFINISHED_CERTIFICATE_SUCCESS:
      return { ...state, unfinishedCertificateList: payload };
    case CertificateEnum.FETCH_CATEGORIES_FAILURE:
    case CertificateEnum.FETCH_THEMES_FAILURE:
    case CertificateEnum.FETCH_UNFINISHED_CERTIFICATE_FAILURE:
    case CertificateEnum.FETCH_FINISHED_CERTIFICATE_FAILURE:
    case CertificateEnum.GET_CERTIFICATE_FAILURE:
    case CertificateEnum.FETCH_MODULE_DATA_FAILURE:
      return { ...state, error };
    case CertificateEnum.ADD_CERTIFICATE_SUCCESS:
      return { ...state, newCertificate: payload };
    case CertificateEnum.SET_SELECTED_CERTIFICATE_SUCCESS:
      return { ...state, selectedDraft: payload, newCertificate: payload };
    case CertificateEnum.SELECT_CERTIFICATE_SUCCESS:
      return { ...state, selectedCertificateId: payload };
    case CertificateEnum.UNSELECT_CERTIFICATE_SUCCESS:
      return { ...state, selectedCertificateId: payload };
    case CertificateEnum.RESET_CERTIFICATE:
      return { ...state, selectedDraft: undefined, newCertificate: undefined };
    case CertificateEnum.FETCH_FINISHED_CERTIFICATE_SUCCESS:
      return { ...state, finishedCertificateList: payload };
    case UserEnum.ADD_USERS_TO_CERTIFICATE_SUCCESS:
      return { ...state, userCertificate: payload };
    case CertificateEnum.FETCH_CERTIFICATE_DETAILS_SUCCESS:
      return { ...state, certificateDetails: payload };
    case CertificateEnum.FETCH_CERTIFICATE_DETAILS_FAILURE:
    default:
      return state;
  }
};
