/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Candidate, { ICandidateWithAttendance } from 'models/Candidate';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import { useEffect } from 'react';

interface Props {
  candidates: ICandidateWithAttendance[] | Candidate[];
  handleSavePresence: (data: ICandidateWithAttendance[]) => void;
}

const CandidatePresenceTable: React.FC<Props> = ({
  candidates,
  handleSavePresence,
}) => {
  const [presentCandidate, setPresentCandidate] = React.useState<
    ICandidateWithAttendance[]
  >([]);
  const [keyword, setKeyword] = React.useState('');
  const [sortedCandidates, setSortedCandidates] = React.useState<
    ICandidateWithAttendance[] | Candidate[]
  >([]);
  const onSavePresence = () => {
    handleSavePresence(presentCandidate);
    setPresentCandidate([]);
  };

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedCandidates(candidates);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'lastName',
        'firstName',
        'phoneNumber',
        'email',
        'CIN',
        'bank.name',
        'job.title',
      ],
    };
    const fuse = new Fuse(candidates, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCandidates(results);
  }, [keyword, candidates]);
  const TabHeader = () => (
    <div className="row">
      <div className="col-6">
        <input
          className="form-control"
          placeholder="Recherche.."
          value={keyword}
          onChange={event => setKeyword(event.target.value)}
        />
      </div>
      <div className="col-6">
        <button
          type="button"
          onClick={onSavePresence}
          className="btn btn-soft-primary"
        >
          Enregister
        </button>
      </div>
    </div>
  );
  const columns = () => {
    const data = [
      {
        name: 'Nom',
        selector: 'lastName',
        sortable: true,
      },
      {
        name: 'Prénom',
        selector: 'firstName',
        sortable: true,
      },
      {
        name: 'Téléphone',
        selector: 'phoneNumber',
        sortable: true,
      },
      {
        name: 'E-mail',
        sortable: true,
        selector: 'email',
      },
      {
        name: 'CIN',
        selector: 'CIN',
        sortable: true,
      },
      {
        center: true,
        name: 'Etablissement',
        sortable: true,
        cell: (candidat: ICandidateWithAttendance) => candidat?.bank?.name,
      },
      {
        center: true,
        name: 'Poste',
        sortable: true,
        cell: (candidat: ICandidateWithAttendance) => candidat?.job?.title,
      },
    ];
    return data;
  };
  return (
    <DataTable
      data={sortedCandidates}
      columns={columns()}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      selectableRows
      selectableRowSelected={(row: ICandidateWithAttendance) =>
        row?.isPresent ? row?.isPresent : false}
      onSelectedRowsChange={values => setPresentCandidate(values.selectedRows)}
      selectableRowsHighlight
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default CandidatePresenceTable;
