import ImagePicker, { Props as ComponentProps } from 'components/ImagePicker';
import { Field, Validator } from 'redux-form';

import * as React from 'react';

interface Props extends ComponentProps {
  name: string;
  validate?: Validator[];
  className?: string;
}

const ImagePickerField: React.FC<Props> = ({
  name,
  validate,
  maxFiles,
  label,
  className,
  ...props
}) => {
  return (
    <Field
      name={name}
      id={name}
      component={ImagePicker}
      validate={validate}
      maxFiles={maxFiles}
      label={label}
      className={className}
      {...props}
    />
  );
};

export default ImagePickerField;
