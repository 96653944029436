import * as React from 'react';
import Validators from 'helpers/formValidator';
import InputField from 'components/InputField';
import { change, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import RichField from 'components/RichField';

interface IProps {
  day: string;
  uniqKey?: number;
  moduleTitle?: string;
  duration: string;
  seance?: number;
  content?: string;
  methods?: string;
}
const RenderCycleProgramDay: React.FC<IProps> = ({
  uniqKey,
  moduleTitle,
  seance,
  day,
  duration,
  content,
  methods,
}) => {
  const dispatch = useDispatch();
  const selector = formValueSelector('addCycleModuleProgramForm');
  const value = useSelector((state: RootState) =>
    selector(state, `programDays.${uniqKey}.theoreticalDuration`),
  );

  React.useEffect(() => {
    if (value > duration)
      dispatch(
        change(
          'addCycleModuleProgramForm',
          `programDays.${uniqKey}.theoreticalDuration`,
          duration.toString(),
        ),
      );
    dispatch(
      change(
        'addCycleModuleProgramForm',
        `programDays.${uniqKey}.days`,
        `${seance} séances/ ${duration} heures/ ${day} jours`,
      ),
    );
    dispatch(
      change(
        'addCycleModuleProgramForm',
        `programDays.${uniqKey}.module`,
        moduleTitle,
      ),
    );
  }, [value]);
  React.useEffect(() => {
    if (value && duration)
      dispatch(
        change(
          'addCycleModuleProgramForm',
          `programDays.${uniqKey}.practicalDuration`,
          (+duration - value).toString(),
        ),
      );
  }, [value]);

  return (
    <div className="row my-4" key={uniqKey}>
      <div className="col-sm">
        <InputField
          name={`programDays.${uniqKey}.days`}
          label="Jour"
          validate={[Validators.required]}
          disabled
        />
      </div>

      <div className="col-sm">
        <InputField
          name={`programDays.${uniqKey}.module`}
          label="module"
          validate={[Validators.required]}
          disabled
        />
      </div>

      <div className="col-sm">
        <RichField
          pickedContent={content}
          name={`programDays.${uniqKey}.content`}
          label="Contenus"
          validate={[Validators.required]}
        />
      </div>
      <div className="col-sm">
        <RichField
          pickedContent={methods}
          name={`programDays.${uniqKey}.methods`}
          label="Méthodes"
          validate={[Validators.required]}
        />
      </div>
      <div className="col-sm">
        <InputField
          name={`programDays.${uniqKey}.theoreticalDuration`}
          label="durée théorique"
          type="number"
          placeholder={duration?.toString()}
          validate={[Validators.required]}
          min={0}
          step={0.25}
        />
      </div>

      <div className="col-sm">
        <InputField
          name={`programDays.${uniqKey}.practicalDuration`}
          label="durée pratique"
          type="number"
          disabled
          validate={[Validators.required]}
        />
      </div>
    </div>
  );
};

export default RenderCycleProgramDay;
