import React from 'react';
import Candidate from 'models/Candidate';

const columns = (
  handleAddCandidate: (candidat: Candidate) => void,
  handleDeleteCandidate: (candidate: Candidate) => void,
  selectedIndex: number,
) => {
  const data = [
    {
      name: 'CIN',
      selector: 'CIN',
      maxWidth: '10vw',
    },
    {
      name: 'Nom',
      selector: 'firstName',
      sortable: true,
      maxWidth: '10vw',
    },
    {
      name: 'Prénom',
      selector: 'lastName',
      maxWidth: '10vw',
      sortable: true,
    },
    {
      name: 'Téléphone',
      maxWidth: '10vw',
      selector: 'phoneNumber',
    },
    {
      name: 'E-mail',
      selector: 'email',
      sortable: true,
    },
    {
      center: true,
      name: 'Etablissement',
      sortable: true,
      cell: (candidat: Candidate) => candidat?.bank?.name,
    },
    {
      center: true,
      name: 'Poste',
      sortable: true,
      cell: (candidat: Candidate) => candidat?.job?.title,
    },
    {
      center: true,
      name: '',
      cell: (candidate: Candidate) =>
        selectedIndex === 0 ? (
          <button
            className="btn btn-block btn-primary btn-icon"
            onClick={() => handleAddCandidate(candidate)}
            type="button"
          >
            <i className="fas fa-plus" />
          </button>
        ) : (
          <button
            className="btn btn-block btn-danger btn-icon"
            onClick={() => handleDeleteCandidate(candidate)}
            type="button"
          >
            <i className="fas fa-trash-alt" />
          </button>
        ),
    },
  ];
  return data;
};

export default columns;
