export enum ExamEnum {
  GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS',
  GET_EXAM_FAILURE = 'GET_EXAM_FAILURE',
  ADD_EXAM_SUCCESS = 'ADD_EXAM_SUCCESS',
  ADD_EXAM_FAILURE = 'ADD_EXAM_FAILURE',
  DELETE_EXAM_SUCCESS = 'DELETE_EXAM_SUCCESS',
  DELETE_EXAM_FAILURE = 'DELETE_EXAM_FAILURE',
  PUBLISH_EXAM_SUCCESS = 'PUBLISH_EXAM_SUCCESS',
  PUBLISH_EXAM_FAILURE = 'PUBLISH_EXAM_FAILURE',
  RESET_EXAM = 'RESET_EXAM',
  SELECT_EXAM_SUCCESS = 'SELECT_EXAM_SUCCESS',
  UNSELECT_EXAM_SUCCESS = 'UNSELECT_EXAM_SUCCESS',
  FETCH_FINISHED_EXAM_SUCCESS = 'FETCH_FINISHED_EXAM_SUCCESS',
  FETCH_FINISHED_EXAM_FAILURE = 'FETCH_FINISHED_EXAM_FAILURE',
  FETCH_USER_EXAM_SUCCESS = 'FETCH_USER_EXAM_SUCCESS',
  FETCH_ALL_EXAM_SUCCESS = 'FETCH_ALL_EXAM_SUCCESS',
  FETCH_ALL_EXAM_FAILURE = 'FETCH_ALL_EXAM_FAILURE',
  FETCH_ALL_CANDIDATS_EXAM = 'FETCH_ALL_CANDIDATS_EXAM',
  FETCH_UNFINISHED_EXAM_SUCCESS = 'FETCH_UNFINISHED_EXAM_SUCCESS',
  FETCH_UNFINISHED_EXAM_FAILURE = 'FETCH_UNFINISHED_EXAM_FAILURE',
  SET_SELECTED_EXAM_SUCCESS = 'SET_SELECTED_EXAM_SUCCESS',
  FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS',
  FETCH_EXAM_FAILURE = 'FETCH_EXAM_FAILURE',
  UPDATE_EXAM_NOTES = 'UPDATE_EXAM_NOTES',
  
}
