import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { BankInvitationForm } from 'models/Bank';
import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

const viewInviteAllClientsSection: React.FC<InjectedFormProps<
  Partial<BankInvitationForm>
>> = ({ handleSubmit }) => {
  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h4 className="card-title">
                  Inviter Tous les Clients et Contacts
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <InputField
              name="subject"
              label="Objet"
              validate={[Validators.required]}
            />
            <InputField
              name="text"
              label="Texte"
              type="textarea"
              validate={[Validators.required]}
            />
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary py-2">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default reduxForm<Partial<BankInvitationForm>>({
  form: 'AllClientsInvitationForm',
})(viewInviteAllClientsSection);
