import moment from 'moment';
import SessionViewModel from 'view-models/SessionViewModel';
import Classroom from 'models/Classroom';
import ISelectFieldOption from 'models/ISelectFieldOption';

export const getActiveRooms = (
  tab: SessionViewModel[],
  from: ISelectFieldOption,
  to: ISelectFieldOption,
): Classroom[] => {
  const start = moment(from.value as Date).format('HH:mm');
  const end = moment(to.value as Date).format('HH:mm');
  const result = tab
    .filter(
      el =>
        (start >= moment(el.from).format('HH:mm') &&
          start < moment(el.to).format('HH:mm')) ||
        (end > moment(el.from).format('HH:mm') &&
          end <= moment(el.to).format('HH:mm')) ||
        (moment(el.from).format('HH:mm') >= start &&
          moment(el.from).format('HH:mm') < end) ||
        (moment(el.to).format('HH:mm') > start &&
          moment(el.to).format('HH:mm') <= end),
    )
    .map(item => item.classroom);
  return [...new Map(result.map(el => [el.id, el])).values()];
};
