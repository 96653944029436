import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { IGroupedTrainings } from 'models/IGroupedTrainings';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { updateTrainingRegulation } from 'actions';
import { NativeSelect } from '@material-ui/core';
import columns from './columns';

interface Props {
  data: IGroupedTrainings[];
}

const AllTrainingListTable: React.FC<Props> = ({ data }) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedTrainings, setSortedTrainings] = useState<IGroupedTrainings[]>(
    [],
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedTrainings(data);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'title',
        'createdAt',
        'type',
        'isFinished',
        'isSetteled',
        'isTrainersSettled',
      ],
    };
    const fuse = new Fuse(data, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedTrainings(results);
  }, [keyword, data]);
  const [selectedRow, setSelectedRow] = useState<IGroupedTrainings>();
  const [isTrainersSettled, setIsTrainersSettled] = useState(false);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  const [selectedValue, setSelectedValue] = useState<boolean>();
  useEffect(() => {
    return isTrainersSettled
      ? setSelectedValue(selectedRow?.isTrainersSettled)
      : setSelectedValue(selectedRow?.isSettled);
  }, [isTrainersSettled, selectedRow]);

  const dispatch = useDispatch();
  const handleSetRegulation = () => {
    dispatch(
      isTrainersSettled
        ? updateTrainingRegulation({
            id: selectedRow?.id,
            isTrainersSettled: selectedValue,
            type: selectedRow?.type,
          })
        : updateTrainingRegulation({
            id: selectedRow?.id,
            isSettled: selectedValue,
            type: selectedRow?.type,
          }),
    );
    setModalOpen(false);
  };
  const handleChangeRegle = (
    training: IGroupedTrainings,
    isTrainers: boolean,
  ) => {
    setSelectedRow(training);
    isTrainers ? setIsTrainersSettled(true) : setIsTrainersSettled(false);
    setModalOpen(true);
  };

  return (
    <>
      <DataTable
        data={sortedTrainings}
        columns={columns(handleChangeRegle)}
        subHeader
        noHeader
        keyField="timestamps"
        persistTableHead
        subHeaderAlign="center"
        expandOnRowClicked
        highlightOnHover
        subHeaderComponent={TabHeader()}
        noDataComponent="Aucun élément"
        pagination
      />
      <Modal
        title="Modifier Le règlement"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        {selectedRow && (
          <div className="container space-1 d-flex flex-column">
            <div>
              <h4>{`Modifier Le règlement de : ${selectedRow?.title}`}</h4>
            </div>
            <NativeSelect
              value={selectedValue}
              onChange={event => {
                setSelectedValue(event.target.value === 'true');
              }}
            >
              <option value="true">réglé</option>
              <option value="false">non réglé</option>
            </NativeSelect>
            <div className="actions mt-5">
              <button
                type="button"
                className="btn btn-wide btn-primary mr-10"
                onClick={() => handleSetRegulation()}
              >
                Confirmer
              </button>
              <button
                type="button"
                className="btn btn-wide btn-primary"
                onClick={(): void => {
                  setModalOpen(false);
                }}
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
export default AllTrainingListTable;
