/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import AddBreakTypeForm from 'containers/AddBreakTypeForm';
import {
  addBreakType,
  addBreakTypeCsv,
  getBreakTypes,
  getBreakType,
  editBreakType,
} from 'actions/BreakType';
import BreakTypesTable from 'components/BreakTypesTable';
import ModifyBreakTypeForm from 'containers/ModifyBreakTypeForm';
import { RootState } from 'reducers';
import BreakTypeModel from 'models/BreakType';
import Modal from 'components/Modal';
import { BreakTypeForm } from 'view-models/BreakTypeViewModel';
import CsvBreak from 'components/CsvBreak';
import { BreakToAdd } from 'models/Break';
import { API } from 'api';

const BreakTypes: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBreakTypes());
  }, []);

  const { BreakType } = useSelector((state: RootState) => state.breakType);

  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleAddBreak = (values: BreakTypeModel) => {
    dispatch(addBreakType(values));
    setModalOpen(false);
  };

  const handleEditBreakType = (id: number) => {
    dispatch(getBreakType(id));
    setEditModalOpen(true);
  };

  const handleSubmitEdit = (values: BreakTypeForm) => {
    dispatch(editBreakType(values, BreakType?.id));
    setEditModalOpen(false);
  };

  const handleChange = (data: BreakToAdd[]): void => {
    dispatch(addBreakTypeCsv(data));
  };

  const { BreakTypeList } = useSelector((state: RootState) => state.breakType);

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Types de pause</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Ajouter un type de pause
                </li>
              </ol>
            </div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <button
                    type="button"
                    className="btn btn btn-soft-light"
                    onClick={() => setModalOpen(true)}
                  >
                    Ajouter un type de pause
                  </button>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <a
                    href={`${API}/api/containers/CSV/download/break_template.csv`}
                    download
                    type="button"
                    className="btn btn-soft-light"
                  >
                    Télécharger modèle CSV
                  </a>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <CsvBreak onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des types de pause</h5>
          </div>
          <div className="card-body">
            <BreakTypesTable
              breakTypes={BreakTypeList}
              handleEdit={handleEditBreakType}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Ajouter un type de pause"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <AddBreakTypeForm onSubmit={handleAddBreak} />
      </Modal>
      <Modal
        title="Modifier Le type de pause"
        isOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
      >
        <ModifyBreakTypeForm onSubmit={handleSubmitEdit} />
      </Modal>
    </>
  );
};

export default BreakTypes;
