/* eslint-disable max-lines */
import {
  selectModuleCard,
  selectModuleCertifCard,
} from 'actions/Module/selectModuleCard';
import * as React from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  isCertif?: boolean;
  title?: string;
  content?: string;
  methods?: string;
  duration?: string;
  semesterId?: number;
  certificateId?: number;
  days?: number;
  courseNumber?: number;
  id?: number;
  deleteModule?: (id: number) => void;
  setisSaved?: (isSaved: boolean) => void;
  toggleModule?: () => void;
  toggleCalendarBar?: () => void;
}

const ModuleCard: React.FC<Props> = ({
  title,
  isCertif,
  content,
  methods,
  duration,
  days,
  semesterId,
  courseNumber,
  certificateId,
  id,
  deleteModule,
  toggleModule,
  toggleCalendarBar,
  setisSaved,
}) => {
  const dispatch = useDispatch();

  const getSelectedCertifModule = (): void => {
    dispatch(
      selectModuleCertifCard({
        id,
        title,
        content,
        methods,
        certificateId,
      }),
    );
    toggleModule?.();
    toggleCalendarBar?.();
  };
  const getSelectedModule = (): void => {
    dispatch(selectModuleCard({ id, title, content, methods, semesterId }));

    toggleModule?.();
    toggleCalendarBar?.();
    setisSaved?.(true);
  };
  return (
    <div className="mt-1 mb-2 position-relative">
      <div
        role="button"
        key={id}
        onClick={isCertif ? getSelectedCertifModule : getSelectedModule}
        onKeyPress={isCertif ? getSelectedCertifModule : getSelectedModule}
        tabIndex={id}
        className="p-1 shadow rounded text-center mx-auto"
        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
      >
        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3
              className="h6 text-left mx-auto text-primary"
              style={{ marginBottom: '1rem' }}
            >
              Module:
            </h3>
          </div>
          <div className="col-9">
            <h3
              style={{ wordBreak: 'break-word' }}
              className="h6 text-left mx-auto mb-1"
            >
              {title}
            </h3>
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              Contenu:
            </h3>
          </div>
          <div className="col-9">
            {content && (
              <h3
                style={{ wordBreak: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
                className="h6 text-left mx-auto mb-1"
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              Méthodes:
            </h3>
          </div>
          <div className="col-9">
            {methods && (
              <h3
                style={{ wordBreak: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: methods }}
                className="h6 text-left mx-auto mb-1"
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              N° Séances:
            </h3>
          </div>
          <div className="col-9">
            {courseNumber ? <h5>{courseNumber}</h5> : <h5>0</h5>}
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              N° Heures:
            </h3>
          </div>
          <div className="col-9">
            {duration !== null ? <h5>{duration}</h5> : <h5>0</h5>}
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="col-3">
            <h3 className="h6 text-left mx-auto  mb-1 text-primary">
              N° Jours:
            </h3>
          </div>
          <div className="col-9">{days ? <h5>{days}</h5> : <h5>0</h5>}</div>
        </div>
      </div>
      {id && deleteModule && (
        <div
          className="position-absolute bottom-0 right-0"
          style={{ cursor: 'pointer', zIndex: 1000 }}
        >
          <button
            type="button"
            className="btn btn-icon transition-3d-hover"
            onClick={(): void => deleteModule(id)}
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ModuleCard;
