import { TrainerInterfaceEnum } from 'constants/types/trainer';
import Trainer, { TrainerSessions } from 'models/Trainer';
import { IReduxAction } from 'constants/types/IReduxAction';
import { AxiosError } from 'axios';
import Session from 'models/Session';

export interface TrainerState {
  error?: AxiosError | Error;
  trainerList: Trainer[];
  selectedtrainer?: Trainer;
  TrainerSessions?: Session[];
  TrainerFilteredSessions?: TrainerSessions;
}

const initialState: TrainerState = { trainerList: [] };

export default (
  state: TrainerState = initialState,
  action: IReduxAction,
): TrainerState => {
  const { type, error, payload } = action;
  switch (type) {
    case TrainerInterfaceEnum.FETCH_TRAINER_SUCCESS:
      return {
        ...state,
        trainerList: payload,
      };
    case TrainerInterfaceEnum.FETCH_TRAINER_FAILURE:
      return { ...state, error };
    case TrainerInterfaceEnum.ADD_TRAINER_FAILURE:
      return {
        ...state,
        error,
      };
    case TrainerInterfaceEnum.SELECT_TRAINER:
      return {
        ...state,
        selectedtrainer: payload,
      };
    case TrainerInterfaceEnum.FETCH_SESSION_TRAINER_SUCCESS:
      return { ...state, TrainerSessions: payload };
    case TrainerInterfaceEnum.FETCH_SESSION_TRAINER_FAILURE:
      return { ...state, error };
    case TrainerInterfaceEnum.FETCH_TRAINER_SESSIONS_SUCCESS:
      return { ...state, TrainerFilteredSessions: payload };
    case TrainerInterfaceEnum.FETCH_TRAINER_SESSIONS_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
