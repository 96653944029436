/* eslint-disable max-lines */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { getFinishedTrainings } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Training from 'models/Training';
import TrainingSidebar from 'components/TrainingSidebar';
import { draftEnum } from 'constants/enum/draftEnum';
import TrainingDraftCard from 'components/TrainingDraftCard';
import Certificate from 'models/Certificate';
import Cycle from 'models/Cycle';
import { getFinishedCertificates } from 'actions/Certificate/getFinishedCertif';
import { getFinishedCycles } from 'actions/Cycle/getFinishedCycle';
import moment from 'moment';
import { getFinishedExames } from 'actions/Exam/getFinishedExames';
import Exam from 'models/Exam';

interface IProps {
  toggleDraft?: (
    draft: Training | Certificate | Cycle|Exam,
    draftType: draftEnum,
  ) => void;
}

const AddTrainingPublishedSection: React.FC<IProps> = ({ toggleDraft }) => {
  const dispatch = useDispatch();
  const [selectedProduct, setselectedProduct] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<draftEnum>();
  const { oneshot, certificate, cycle,exam } = draftEnum;
  const toggleProductMenu = (): void => {
    setselectedProduct(!selectedProduct);
  };

  const toggleOneShotMenu = (): void => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu(oneshot);
  };
  const toggleCertificateMenu = (): void => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu(certificate);
  };
  const toggleCycleMenu = (): void => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu(cycle);
  };
  const toggleExamMenu = (): void => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu(exam);
  };

  useEffect(() => {
    dispatch(getFinishedTrainings());
    dispatch(getFinishedCertificates());
    dispatch(getFinishedCycles());
    dispatch(getFinishedExames());
  }, []);

  const { finishedTrainingsList } = useSelector(
    (state: RootState) => state.training,
  );
  const { finishedCertificateList } = useSelector(
    (state: RootState) => state.certificate,
  );
  const { finishedCycleList } = useSelector((state: RootState) => state.cycle);
  const { finishedExamList } = useSelector((state: RootState) => state.exam);

  const renderContent = () => {
    switch (selectedMenu) {
      case oneshot:
        return (
          <>
            {finishedTrainingsList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index}
                onToggleDraft={(): void => toggleDraft?.(el, oneshot)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );
      case certificate:
        return (
          <>
            {/* certificate list  */}
            {finishedCertificateList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 7889}
                onToggleDraft={(): void => toggleDraft?.(el, certificate)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );
      case cycle:
        return (
          <>
            {/* Cycle  list  */}
            {finishedCycleList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 84894}
                onToggleDraft={(): void => toggleDraft?.(el, cycle)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );


      case exam:
        return (
          <>
            {/* Cycle  list  */}
            {finishedExamList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 84894}
                onToggleDraft={(): void => toggleDraft?.(el, exam)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );

      default:
        return null;
    }
  };
  return (
    <TrainingSidebar header="Formations publiées" className="p-4">
      <div
        className="d-flex flex-column"
        style={{ justifyContent: 'space-evenly', marginTop: '25px', flex: 0.5 }}
      >
        {selectedProduct ? (
          <>
            {renderContent()}
            <button
              type="button"
              className="btn btn-soft-primary btn-sm-wide"
              onClick={toggleProductMenu}
            >
              Revenir au Menu
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-soft-primary btn-sm-wide"
              onClick={toggleOneShotMenu}
            >
              Formation continue
            </button>
            <button
              type="button"
              onClick={toggleCertificateMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Certification
            </button>
            <button
              type="button"
              onClick={toggleCycleMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Cycle
            </button>
            <button
              type="button"
              onClick={toggleExamMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Exam
            </button>
          </>
        )}
      </div>
    </TrainingSidebar>
  );
};

export default AddTrainingPublishedSection;
