import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TrainerSessions, SessionElement } from 'models/Trainer';
import DataTable from 'react-data-table-component';
import { useCallback, useEffect, useState } from 'react';
import AppDateRangePicker, {
  DateChangeCallback,
} from 'components/AppDateRangePicker';
import moment, { Moment } from 'moment';
import { setSessionPayment } from 'actions/Sessions';
import { useParams } from 'react-router-dom';
import { FetchTrainerSessions } from 'actions';
import columns from './columns';

const minDate = moment().subtract(2, 'years');
const maxDate = moment().add(2, 'years');

interface Props {
  trainer: string;
  sessions: TrainerSessions['sessions'];
  refToPrint: React.RefObject<HTMLDivElement>;
}

const TrainerSessionTable: React.FC<Props> = ({
  trainer,
  sessions,
  refToPrint,
}) => {
  const [startDate, setStartDate] = React.useState<Moment>();
  const [endDate, setEndDate] = React.useState<Moment>();
  const [totalHours, setTotalHours] = useState(0);
  const [sortedSessions, setSortedSessions] = React.useState<SessionElement[]>(
    [],
  );

  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const refetchTrainerSessions = useCallback(
    () => dispatch(FetchTrainerSessions(params.id)),
    [params.id],
  );

  const handleSessionPayment = (id: number) => {
    dispatch(setSessionPayment(id, true, () => refetchTrainerSessions()));
  };

  const handleUncheckPayment = (id: number) => {
    dispatch(setSessionPayment(id, false, () => refetchTrainerSessions()));
  };

  const onDatesChange = useCallback(
    ({ startDate: start, endDate: end }: DateChangeCallback) => {
      if (start) {
        setStartDate(start);
      }
      if (end) {
        setEndDate(end);
      }
    },
    [],
  );

  const TabHeader = () => (
    <div>
      <div className="mr-5">
        <AppDateRangePicker
          onDatesChange={onDatesChange}
          startDate={startDate as Moment}
          endDate={endDate as Moment}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );

  useEffect(() => {
    const sorted: SessionElement[] = [];
    sessions.forEach((ses: SessionElement) => {
      if (moment(ses.date, 'DD/MM/YYYY').isBetween(startDate, endDate)) {
        sorted.push(ses);
      }
    });
    setTotalHours(0);
    setSortedSessions(sorted);
  }, [startDate, endDate, sessions]);

  useEffect(() => {
    sortedSessions.forEach(ses => setTotalHours(hour => hour + ses.duration));
  }, [sortedSessions]);

  const cols = columns(handleSessionPayment, handleUncheckPayment);
  return (
    <div style={{ maxWidth: '25cm' }} className="card">
      <div ref={refToPrint}>
        <div className="card-header">
          <h5 className="card-header-title">
            {`Liste des Séances pour: ${trainer}`}
          </h5>
        </div>
        <div className="card-body">
          <div>
            <DataTable
              data={sortedSessions}
              columns={cols}
              subHeader
              noHeader
              persistTableHead
              subHeaderAlign="center"
              expandOnRowClicked
              highlightOnHover
              subHeaderComponent={TabHeader()}
              noDataComponent="Aucun élément"
            />
          </div>
          <div className="border-top" />
          <div className="mt-2 d-flex justify-content-end mr-0">
            <span className="font-weight-bold">{`Total En Heures: ${totalHours}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TrainerSessionTable;
