import { JobInterfaceEnum } from 'constants/types/job';
import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const getJob = (): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('Jobs');

    dispatch({
      type: JobInterfaceEnum.FETCH_JOB_SUCCESS,
      payload: response.data,
    });

    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: JobInterfaceEnum.FETCH_JOB_FAILURE, error });
    stopLoading()(dispatch);
  }
};
