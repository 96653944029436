import * as React from 'react';
import { ProgramWithDays } from 'view-models/ProgramViewModel';
import moment from 'moment';

const columns = (handleImport: (id: number) => void) => {
  const data = [
    {
      name: 'Titre',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Cible',
      selector: 'target',
      sortable: true,
    },
    {
      name: 'Date de Création',
      cell: (row: ProgramWithDays) => (
        <div>{moment(row.createdAt).format('LL')}</div>
      ),
      selector: 'CreatedAt',
      sortable: true,
    },
    {
      center: true,
      name: 'Importer',
      maxWidth: '8vw',
      cell: (row: ProgramWithDays) => (
        <button
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
              align-items-center justify-content-center"
          onClick={(): void => handleImport(row.id)}
        >
          <i className="fa fa-edit" />
        </button>
      ),
    },
  ];
  return data;
};

export default columns;
