import React from 'react';
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { addCandidateExam, DeleteCandidateFromExam } from 'actions';
import Candidate from 'models/Candidate';
import { useCandidate } from 'hooks/useCandidate';
import columns from './columns';

export interface IProps {
  examId?: number;
}

const ViewExamCandidateSection: React.FC<IProps> = ({ examId }) => {
  const {
    keyword,
    selectedIndex,
    setKeyword,
    setSelectedIndex,
    sortedCandidates,
  } = useCandidate('Exam');

  const dispatch = useDispatch();
  const handleAddCandidate = (value: Candidate) => {
    if (examId) {
      const data = { examId, candidateId: value.id };
      dispatch(addCandidateExam(data));
    }
  };
  const handleDeleteCandidate = (value: Candidate) => {
    if (examId) {
      dispatch(DeleteCandidateFromExam(examId, value.id));
    }
  };

  const cols = columns(
    handleAddCandidate,
    handleDeleteCandidate,
    selectedIndex,
  );
  const TabHeader = () => (
    <div className="text-center mt-2">
      <ul
        className="nav nav-segment nav-pills scrollbar-horizontal mb-2"
        role="tablist"
      >
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link border-0 ${selectedIndex === 0 && 'active'}`}
            onClick={() => setSelectedIndex(0)}
          >
            Candidats non-ajoutés
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link border-0 ${selectedIndex === 1 && 'active'}`}
            onClick={() => setSelectedIndex(1)}
          >
            Candidats ajoutés
          </button>
        </li>
      </ul>
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h4 className="card-title">
                  Ajouter ou supprimer les candidats concernés par cette Examen
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <DataTable
            data={sortedCandidates}
            columns={cols}
            subHeader
            noHeader
            persistTableHead
            subHeaderAlign="center"
            expandOnRowClicked
            highlightOnHover
            subHeaderComponent={TabHeader()}
            noDataComponent="Aucun élément"
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export default ViewExamCandidateSection;
