import { AddTrainerContainer } from 'containers';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addTrainer } from 'actions';
import { TrainerToAdd } from 'models/Trainer';

const AddTrainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (values: TrainerToAdd): Promise<void> => {
    await dispatch(addTrainer(values));
    history.goBack();
  };

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Formateurs</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Ajouter un formateur
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-body">
            <AddTrainerContainer onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTrainer;
