export enum CertificateEnum {
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_ALL_CERTIFICATES_SUCCESS = ' FETCH_ALL_CERTIFICATES_SUCCESS',
  FETCH_ALL_CERTIFICATES_FAILURE = 'FETCH_ALL_CERTIFICATES_FAILURE',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  ADD_CERTIFICATE_SUCCESS = 'ADD_CERTIFICATE_SUCCESS',
  ADD_CERTIFICATE_FAILURE = 'ADD_CERTIFICATE_FAILURE',
  FETCH_UNFINISHED_CERTIFICATE_SUCCESS = 'FETCH_UNFINISHED_CERTIFICATE_SUCCESS',
  FETCH_UNFINISHED_CERTIFICATE_FAILURE = 'FETCH_UNFINISHED_CERTIFICATE_FAILURE',
  FETCH_MODULE_DATA_SUCCESS = 'FETCH_MODULE_DATA_SUCCESS',
  FETCH_MODULE_DATA_FAILURE = 'FETCH_MODULE_DATA_FAILURE',
  RESET_CERTIFICATE = 'RESET_CERTIFICATE',
  SET_SELECTED_CERTIFICATE_SUCCESS = 'SET_SELECTED_CERTIFICATE_SUCCESS',
  SELECT_CERTIFICATE_SUCCESS = 'SELECT_CERTIFICATE_SUCCESS',
  UNSELECT_CERTIFICATE_SUCCESS = 'UNSELECT_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_FAILURE = 'GET_CERTIFICATE_FAILURE',
  PUBLISH_CERTIFICATE_SUCCESS = 'PUBLISH_CERTIFICATE_SUCCESS',
  PUBLISH_CERTIFICATE_FAILURE = 'PUBLISH_CERTIFICATE_FAILURE',
  FETCH_FINISHED_CERTIFICATE_SUCCESS = 'FETCH_FINISHED_CERTIFICATE_SUCCESS',
  FETCH_FINISHED_CERTIFICATE_FAILURE = 'FETCH_FINISHED_CERTIFICATE_FAILURE',
  FETCH_USER_CERTIFICATES_SUCCESS = 'FETCH_USER_CERTIFICATES_SUCCESS',
  FETCH_USER_CERTIFICATES_FAIL = 'FETCH_USER_CERTIFICATES_FAIL',
  FETCH_CERTIFICATE_DETAILS_SUCCESS = 'FETCH_CERTIFICATE_DETAILS_SUCCESS',
  FETCH_CERTIFICATE_DETAILS_FAILURE = 'FETCH_CERTIFICATE_DETAILS_FAILURE',
}
