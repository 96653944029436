import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { IGroupedTrainings } from 'models/IGroupedTrainings';
import { fetchAccountingNotes } from 'actions';
import AccountingNotesDetails from 'containers/AccountingNotesDetails';

const AccountingNotes: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    id: string;
    type: IGroupedTrainings['type'];
  }>();
  const AccountingNotesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchAccountingNotes(params.id, params.type));
  }, [params.id, params.type]);

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Note De Comptabilité</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Détails des formations , et Fiche de Paie
                </li>
              </ol>
            </div>
            <div className="col-4">
              <div
                className="d-none d-lg-block"
                style={{
                  borderRadius: '5px',
                }}
              >
                <ReactToPrint
                  trigger={() => (
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-soft-light btn-block p-2"
                        type="button"
                      >
                        Génerer Note de Comptabilité ↓
                        <i className="fa fa-print" />
                      </button>
                    </div>
                  )}
                  content={() => AccountingNotesRef.current}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <AccountingNotesDetails refToPrint={AccountingNotesRef} />
      </div>
    </>
  );
};
export default AccountingNotes;
