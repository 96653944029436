import { BankInterfaceEnum } from 'constants/types/Bank';
import Bank from 'models/Bank';
import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';

export interface BankState {
  error?: AxiosError | Error;
  bankList: Bank[];
  selectedbank?: Bank;
}

const initialState: BankState = {
  bankList: [],
};

export default (
  state: BankState = initialState,
  action: IReduxAction,
): BankState => {
  const { type, error, payload } = action;
  switch (type) {
    case BankInterfaceEnum.FETCH_BANK_SUCCESS:
      return {
        ...state,
        bankList: payload,
      };
    case BankInterfaceEnum.FETCH_BANK_FAILURE:
    case BankInterfaceEnum.ADD_BANK_FAILURE:
    case BankInterfaceEnum.DELETE_BANK_FAILURE:
      return {
        ...state,
        error,
      };
    case BankInterfaceEnum.SELECT_BANK:
      return {
        ...state,
        selectedbank: payload,
      };

    default:
      return state;
  }
};
