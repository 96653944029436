import { authInterfaceEnum } from 'constants/types/Auth';
import api, { resetAuthToken, db } from 'api';
import { RouteComponentProps } from 'react-router-dom';
import { IThunkAction } from 'constants/types/IThunkAction';
import { startLoading, stopLoading } from 'actions';
import { errorHandler } from 'helpers/errorHandler';
import UserViewModel from 'view-models/UserViewModel';

const setCurrentUser = (userId: number): IThunkAction => async dispatch => {
  const response = await api.get<UserViewModel>(`/defaultUsers/${userId}`);
  dispatch({
    type: authInterfaceEnum.FETCH_CURRENT_USER_SUCCESS,
    payload: response.data,
  });
};

export const checkLoggingStatus = (): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  dispatch(startLoading());
  try {
    const id = Number(window.localStorage.getItem('id'));

    if (!id) throw Error('disconnected');
    else {
      const { token } = await db.table('user').get({ id });
      resetAuthToken(token);
      await setCurrentUser(id)(dispatch, getState, null);

      dispatch({
        type: authInterfaceEnum.SIGNIN_SUCCESS,
        payload: {
          id: token,
          userId: id,
        },
      });
    }
  } catch (error) {
    dispatch({ type: authInterfaceEnum.LOGGED_OUT });
  }

  dispatch(stopLoading());
};

export const login = (
  username: string,
  password: string,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const response = await api.post('DefaultUsers/login', {
      username,
      password,
    });
    const { id, userId } = response.data;
    resetAuthToken(id);
    localStorage.setItem('id', userId);
    db.table('user').put({ id: userId, token: id });
    await setCurrentUser(userId)(dispatch, getState, null);
    dispatch({
      type: authInterfaceEnum.SIGNIN_SUCCESS,
      payload: response.data,
    });
    history.push('/');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: authInterfaceEnum.SIGNIN_FAILURE, error });
  }
};

export const signOut = (): IThunkAction => async (dispatch): Promise<void> => {
  try {
    await api.post('defaultusers/logout');

    localStorage.removeItem('id');

    await db.table('user').clear();
    dispatch({ type: authInterfaceEnum.LOGGED_OUT });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: authInterfaceEnum.LOGGED_OUT_FAILED, error });
  }
};
