/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import Training from 'models/Training';
import AutoCompleteField from 'components/AutoCompleteFiled';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteSession, UpdateSession } from 'actions';
import CalendarCard from 'components/CalendarCard';
import { DeleteBreak } from 'actions/Break';
import BreakCard from 'components/BreakCard';
import { useOneShot } from 'hooks/useOneShot';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
import Modal from 'components/Modal';
import { useSession } from 'hooks/useSession';
import moment from 'moment';
import ISelectFieldOption from 'models/ISelectFieldOption';
import UpdateSessionSection from 'containers/UpdateSessionSection';
import { SessionForm } from 'view-models/SessionViewModel';

interface Props {
  toggleCalendar?: () => void;
  toggleBreakBar?: () => void;
  toggleProgramBar?: () => void;
  toggleUserBar?: () => void;
  handlePublish: () => void;
  toggleOneShot?: () => void;
  isSaved: boolean;
  trainingId?: number;
}
const AddTrainingOneShotSection: React.FC<Props &
  InjectedFormProps<Training, Props>> = ({
  toggleCalendar,
  toggleBreakBar,
  toggleProgramBar,
  toggleUserBar,
  handleSubmit,
  handlePublish,
  isSaved,
  initialValues,
  trainingId,
  toggleOneShot,
}) => {
  const [selectedSessionId, setSelectedSessionId] = useState<number>();
  const [withSessions, setWithSessions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState<string>();

  const dispatch = useDispatch();
  const {
    formattedBreaks,
    formattedSessions,
    themeList,
    categoryList,
    SessionList,
  } = useOneShot(initialValues);

  const {
    setstartTime,
    setendTime,
    selectedClassroom,
    setselectedClassroom,
    filtredDataClassrooms,
    formattedDataClassroomsNum,
  } = useSession(moment(date), trainingId);
  const { loading } = useSelector((state: RootState) => state.loading);
  const handleDeleteSession = (value: number): void => {
    dispatch(DeleteSession(value));
  };
  const handleEditSession = (value: number, day: string): void => {
    const editedSession = SessionList.find(session => session.id === value);
    const formattedStartTime = {
      label: moment(editedSession?.from).format('LT A'),
      value: moment(editedSession?.from),
    };
    const formattedEndTime = {
      label: moment(editedSession?.to).format('LT A'),
      value: moment(editedSession?.to),
    };

    setSelectedSessionId(value);
    setstartTime(formattedStartTime);
    setendTime(formattedEndTime);
    setDate(day);
    setModalOpen(true);
  };

  const handleDeleteBreak = (value: number): void => {
    dispatch(DeleteBreak(value));
  };
  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };
  const handleSessionUpdate = (values: SessionForm): void => {
    if (selectedSessionId) {
      dispatch(
        UpdateSession(selectedSessionId, { classroomId: values.classroomId }),
      );
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (!formattedSessions?.length) setWithSessions(false);
    else setWithSessions(true);
  }, [formattedSessions.length]);

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Changer La Salle de Cours"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <UpdateSessionSection
          onSubmit={handleSessionUpdate}
          filtredDataClassrooms={filtredDataClassrooms}
          formattedDataClassroomsNum={formattedDataClassroomsNum}
          handleClassroom={handleClassroom}
          selectedClassroom={selectedClassroom}
        />
      </Modal>
      <TrainingSidebar
        header="Formation continue"
        className="p-4"
        onClose={toggleOneShot}
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="Nom de la formation"
              validate={[Validators.required]}
            />
            <AutoCompleteField
              className="orange badge-outline-primary"
              label="Thème"
              name="themeId"
              validate={[Validators.required]}
              suggestions={themeList}
            />
            <AutoCompleteField
              className="orange badge-outline-primary"
              label="Categorie"
              name="categoryId"
              suggestions={categoryList}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleCalendar}
              disabled={!isSaved}
            >
              Créer les séances
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            {formattedSessions.map(session => {
              return (
                <div key={session.id}>
                  <CalendarCard
                    data={session.list}
                    day={session.day}
                    id={session.id}
                    deleteSession={handleDeleteSession}
                    editClassroom={handleEditSession}
                  />
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleBreakBar}
              disabled={!isSaved}
            >
              Ajouter les Pauses café
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            {formattedBreaks.map(item => (
              <div key={item.id}>
                <BreakCard data={item} deleteBreak={handleDeleteBreak} />
              </div>
            ))}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleProgramBar}
              disabled={!withSessions}
            >
              Générer la fiche programme
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleUserBar}
              disabled={!isSaved}
            >
              Ajouter des organisateurs
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-primary mb-2"
              onClick={handlePublish}
              disabled={!isSaved}
            >
              PUBLIER
            </button>
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<Training, Props>({
  form: 'addTrainingForm',
  enableReinitialize: true,
})(AddTrainingOneShotSection);
