import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const getFinishedCertificates = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `certificates/user-certificates?filter={"where":{"isFinished":true}}`,
    );
    dispatch({
      type: CertificateEnum.FETCH_FINISHED_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: CertificateEnum.FETCH_FINISHED_CERTIFICATE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
