/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { reset } from 'redux-form';
import { resetProgram } from 'actions/Program';
import formatProgramDays from 'helpers/formatProgramDays';
import Certificate from 'models/Certificate';
import { ModuleEnum } from 'constants/types/Module';
import { setSelectedCertificate } from 'actions/Certificate/setSelectCerificate';
import { useTrainingHandler } from './useTrainingHandler';

export const useCertificateHandler = (
  endDivRef: React.RefObject<HTMLDivElement>,
) => {
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [isModuleListOpen, setIsModuleListOpen] = useState(false);
  const [isDraftOpen, setisDraftOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isProgramOpen, setIsProgramOpen] = useState(false);
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [isBreakOpen, setIsBreakOpen] = useState(false);
  const [isModuleSaved, setIsModuleSaved] = useState(false);
  const [isWithFile, setIsWithFile] = useState(false);
  const [isPublishedCertificatesOpen] = useState(false);
  const [date, setDate] = useState<Moment | null>(moment());
  const dispatch = useDispatch();
  const { selectedDraft } = useSelector(
    (state: RootState) => state.certificate,
  );

  const { certifProgram } = useSelector((state: RootState) => state.program);

  const setselectedDraft = (certificate?: Certificate) => {
    dispatch(setSelectedCertificate(certificate));
  };
  // cerificate
  const { isCertificateOpen, setIsCertificateOpen } = useTrainingHandler(
    endDivRef,
  );
  useEffect(() => {
    if (isCertificateOpen) {
      setisSaved(false);
      setisDraftOpen(false);
      setIsCertificateOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    dispatch(resetProgram());
    setIsProgramOpen(false);
    setIsCalendarOpen(false);
    setIsSessionOpen(false);
    setIsUserOpen(false);
  }, [isCertificateOpen]);

  useEffect(() => {
    if (isSessionOpen) {
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSessionOpen]);
  useEffect(() => {
    if (!isDraftOpen) return;
    setIsCertificateOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isDraftOpen]);
  // handle published Training
  useEffect(() => {
    if (!isPublishedCertificatesOpen) return;
    setisDraftOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isPublishedCertificatesOpen]);
  // handle calendar
  useEffect(() => {
    if (isCalendarOpen) {
      setIsProgramOpen(false);
      setIsUserOpen(false);
      setIsBreakOpen(false);
      setIsSessionOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      // return;
    }
  }, [isCalendarOpen]);

  useEffect(() => {
    if (isModuleOpen) {
      setIsProgramOpen(false);
      setIsBreakOpen(false);
      setIsUserOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      // return;
    }
  }, [isModuleOpen]);

  // handle program
  useEffect(() => {
    if (!isProgramOpen) {
      dispatch(reset('addProgramForm'));
      return;
    }
    setIsUserOpen(false);
    setIsBreakOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isProgramOpen]);

  // handle user what do we mean by user ??
  useEffect(() => {
    if (isUserOpen) {
      setIsModuleOpen(false);
      setIsProgramOpen(false);
      setIsBreakOpen(false);
    }
    if (!isUserOpen) return;
    setIsModuleOpen(false);
    setIsProgramOpen(false);
    setIsCalendarOpen(false);
    setIsBreakOpen(false);

    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isUserOpen]);

  useEffect(() => {
    if (!isBreakOpen) return;
    setIsProgramOpen(false);
    setIsUserOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isBreakOpen]);
  // selectors
  const { newCertificate } = useSelector(
    (state: RootState) => state.certificate,
  );
  const { userCertificate } = useSelector(
    (state: RootState) => state.certificate,
  );

  const { Program } = useSelector((state: RootState) => state.program);
  // togglers
  const toggleDraft = (): void => {
    setisDraftOpen(!isDraftOpen);
  };

  const toggleSessionBar = (value: Moment) => {
    setDate(value);
    setIsSessionOpen(!isSessionOpen);
  };

  const togglePublishedTrainings = () => setIsCertificateOpen(prev => !prev);

  const toggleCalendarBar = (): void => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const toggleEditedModule = () => {
    setIsModuleOpen(true);
  };

  const toggleModule = (): void => {
    setIsModuleOpen(!isModuleOpen);
    dispatch({ type: ModuleEnum.RESET_MODULE });
    if (!isModuleOpen) {
      setIsModuleSaved(false);
    }
    setIsCalendarOpen(false);
  };

  const toggleModuleList = (): void => {
    setIsModuleListOpen(!isModuleListOpen);
  };
  const toggleBreakBar = (): void => {
    setIsBreakOpen(!isBreakOpen);
  };
  let programInitialValues = {};
  if (Program)
    programInitialValues = {
      ...Program,
      programDays: formatProgramDays(Program?.programDays),
    };

  const toggleProgramBar = (): void => {
    setIsProgramOpen(!isProgramOpen);
  };

  const toggleUserBar = (): void => {
    setIsUserOpen(!isUserOpen);
  };

  return {
    setisSaved,
    toggleUserBar,
    toggleProgramBar,
    toggleCalendarBar,
    toggleModule,
    toggleModuleList,
    toggleDraft,
    toggleSessionBar,
    toggleEditedModule,
    newCertificate,
    userCertificate,
    setIsModuleSaved,
    isSaved,
    isModuleSaved,
    setIsCalendarOpen,
    date,
    selectedDraft,
    certifProgram,
    setselectedDraft,
    isUserOpen,
    setIsModuleOpen,
    setIsModuleListOpen,
    isDraftOpen,
    isModuleOpen,
    isModuleListOpen,
    isCalendarOpen,
    isProgramOpen,
    setIsProgramOpen,
    programInitialValues,
    isWithFile,
    toggleBreakBar,
    isBreakOpen,
    setIsBreakOpen,
    setIsWithFile,
    togglePublishedTrainings,
    isPublishedCertificatesOpen,
    setIsSessionOpen,
    isSessionOpen,
    setDate,
  };
};
