import { getBoockedClassrooms } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import DatePickerComponent from 'components/DatePickerComponent';
import moment, { Moment } from 'moment';
import { RootState } from 'reducers';
import BookedClassroomTable from 'components/BookedClassroomTable';
import { CSVLink } from 'react-csv';
import formatToCsv from 'helpers/formatToCsv';

const BookedClassrooms: React.FC = () => {
  const dispatch = useDispatch();
  const { bookedClassrooms } = useSelector(
    (state: RootState) => state.Classroom,
  );

  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  useEffect(() => {
    dispatch(getBoockedClassrooms(selectedDate));
  }, []);
  const handleDateChange = (newDate: Moment | null): void => {
    if (newDate) setSelectedDate(newDate);
  };

  useEffect(() => {
    if (!selectedDate) return;
    dispatch(getBoockedClassrooms(selectedDate));
  }, [selectedDate]);
  const formattedBookedClassroomsToCSV = bookedClassrooms.map(
    bookedClassroom => ({
      title: bookedClassroom.title,
      date: moment(bookedClassroom.date).format('YYYY-MM-DD'),
      trainingType: bookedClassroom.type,
      ...bookedClassroom.classroom,
    }),
  );

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="d-flex justify-content-between">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Salles Réservées</h1>
              </div>
            </div>
            <CSVLink
              className="btn btn-soft-light"
              filename={`Salles réservées de la semaine-${selectedDate?.format(
                'YYYY-MM-DD',
              )}.csv`}
              data={formatToCsv(formattedBookedClassroomsToCSV, [
                {
                  label: 'Nom du formation',
                  value: 'title',
                },
                {
                  label: 'Date formation',
                  value: 'date',
                },
                {
                  label: 'Type de formation',
                  value: 'trainingType',
                },
                {
                  label: 'Nom du centre',
                  value: 'name',
                },
                {
                  label: 'Type',
                  value: 'type',
                },
                {
                  label: 'Numéro de salle',
                  value: 'number',
                },
                {
                  label: 'Capacité',
                  value: 'capacity',
                },
                {
                  label: 'Coût (DT)',
                  value: 'cost',
                },
              ])}
            >
              Exporter en tant que CSV
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-header-title">Liste des Salles Réservées</h5>
            <div className="col-4">
              <div
                className="d-none d-lg-block"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                }}
              >
                <DatePickerComponent
                  setDate={handleDateChange}
                  value={selectedDate}
                  step={7}
                />
              </div>
            </div>
          </div>

          <div className="card-body">
            <BookedClassroomTable bookedClassrooms={bookedClassrooms} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedClassrooms;
