import IModule from 'models/IModule';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';

import columns from './columns';

interface Props {
  modules: IModule[];
  handleImport: (id: number) => void;
}

const ModulesTable: React.FC<Props> = ({ modules, handleImport }) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedModules, setSortedModules] = React.useState<IModule[]>([]);

  React.useEffect(() => {
    if (keyword.length === 0) {
      setSortedModules(modules);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'theme', 'category'],
    };
    const fuse = new Fuse(modules, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedModules(results);
  }, [keyword, modules]);
  const cols = columns(handleImport);
  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <DataTable
      data={sortedModules}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default ModulesTable;
