import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { ICycleModule } from 'models/IModule';
import { errorHandler } from 'helpers/errorHandler';

export const addModuleToSemester = (data: ICycleModule): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.post('cycleModules', data);

    dispatch({
      type: ModuleCycleEnum.ADD_CYCLE_MODULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleCycleEnum.FETCH_FINISHED_CYCLE_MODULE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
