import * as React from 'react';
import { InjectedFormProps, reduxForm, reset as RFReset } from 'redux-form';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { getUsers } from 'actions/User';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import TrainingSidebar from 'components/TrainingSidebar';
import { DeleteUserFromExam, fetchExamUserList } from 'actions';
import Exam from 'models/Exam';

interface IProps {
  currentExam?: Exam;
  toggleUserBar?: () => void;
}

const AddExamUserSection: React.FC<IProps &
  InjectedFormProps<{ users: number[] }, IProps>> = ({
  handleSubmit,
  toggleUserBar,
  currentExam,
}) => {
  const dispatch = useDispatch();
  const handleDelete = (id: number) => {
    currentExam && dispatch(DeleteUserFromExam(id, currentExam.id));
    dispatch(fetchExamUserList(currentExam?.id));
  };
  useEffect(() => {
    dispatch(getUsers());
    dispatch(fetchExamUserList(currentExam?.id));
  }, [dispatch]);

  const { UserList } = useSelector((state: RootState) => state.users);
  const userList = useSelector((state: RootState) => state.exam.usersExam);

  const filtredUsers = UserList.filter(
    a => !currentExam?.defaultUser?.some(b => a.id === b.id),
  );
  const formattedData: ISelectFieldOption[] = filtredUsers.map(el => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));

  return (
    <div className="col-4 p-0">
      <TrainingSidebar
        header="Gérer les organisateurs"
        onClose={() => toggleUserBar?.()}
        className=" p-4"
      >
        <div className="my-4">
          {currentExam?.defaultUser && (
            <ul className="list-group list-group-flush">
              {userList?.map(el => (
                <li
                  key={el?.email}
                  className="list-group-item d-flex justify-content-between 
            align-items-center"
                >
                  {`${el?.firstName} ${el?.lastName}`}
                  <div
                    className="position-absolute right-0"
                    style={{ cursor: 'pointer' }}
                  >
                    <button
                      type="button"
                      className="btn btn-icon"
                      onClick={() => handleDelete(el.id)}
                    >
                      <i className="far fa-trash-alt" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <SelectField
            options={formattedData}
            name="users"
            placeholder="Choisir un utilisateur"
            validate={[Validators.required]}
            label="Ajouter des surveillants"
            isMulti
          />
          <button type="submit" className="btn btn-primary py-2 align-self-end">
            Enregistrer
          </button>
        </form>
      </TrainingSidebar>
    </div>
  );
};

export default reduxForm<{ users: number[] }, IProps>({
  form: 'addUserToExam',
  onSubmitSuccess: (_, dispatch) => dispatch(RFReset('addUserToExam')),
})(AddExamUserSection);
