/* eslint-disable max-lines */
import { getClassrooms, getSessionsForClassroomCalendar } from 'actions';
import DatePickerComponent from 'components/DatePickerComponent';
import ClassroomReplacementModal from 'containers/ClassroomReplacementModal';
import formatSessionsPerClassroom from 'helpers/formatSessionsPerClassroom';
import Classroom from 'models/Classroom';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ISessionResponse } from 'view-models/SessionViewModel';
import ClassroomCalendarItem from 'components/ClassroomCalendarItem';
import ReactToPrint from 'react-to-print';

const CLASSROOM_NAME_COL_WIDTH = 80;
const DAY_ARRAY = Array(7).fill(null);
const getClassroomName = (classroom: Classroom) =>
  `${classroom.type} ${classroom.name !== null ? classroom.name : ''} ${
    classroom.number
  }`;
interface ClassroomListProps {
  classrooms: Classroom[];
  sessions: ISessionResponse[];
  selectedDate: Moment;
  onSessionSelect: (sessionId: number) => void;
}
const ClassroomList = React.memo(
  ({
    classrooms,
    sessions,
    selectedDate,
    onSessionSelect,
  }: ClassroomListProps) => (
    <div>
      {classrooms.map(classroom => (
        <div key={classroom.id} className="d-flex ">
          <div
            className="border border-primary border-top-0 d-flex"
            style={{
              width: CLASSROOM_NAME_COL_WIDTH,
            }}
          >
            <div
              className="text-center font-size-1 font-weight-semi-bold align-self-center w-100 p-1"
              style={{
                wordBreak: 'break-all',
              }}
            >
              {getClassroomName(classroom)}
            </div>
          </div>
          {DAY_ARRAY.map((_el, i) => {
            const day = moment(selectedDate).add(i, 'day');
            return (
              <div
                className=" border border-primary border-left-0 border-top-0"
                style={{ flex: 1, minHeight: CLASSROOM_NAME_COL_WIDTH }}
                key={`${classroom.id}-${moment(day).format('L')}`}
              >
                {formatSessionsPerClassroom(sessions, classroom.id, day).map(
                  (el, index) => (
                    <div key={el.id} className="d-flex flex-column">
                      {index > 0 && (
                        <div
                          className="border w-80 align-self-center rounded my-1 
                        border-primary border-bottom-0"
                        />
                      )}
                      <ClassroomCalendarItem
                        {...el}
                        onClick={() => onSessionSelect(el.id)}
                      />
                    </div>
                  ),
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  ),
);

type IntermediateViewProps = {
  loading: boolean;
};

const IntermediateView = ({ loading }: IntermediateViewProps) => (
  <div>
    <span className="h3 text-dark">
      {loading ? 'Chargement ..' : 'Pas de salles disponibles'}
    </span>
  </div>
);

const ClassroomCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(() =>
    moment().startOf('week'),
  );
  const [
    isClassroomReplacementModalOpen,
    setIsClassroomReplacementModalOpen,
  ] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<number>();
  const dispatch = useDispatch();
  const { sessions, classrooms, loading } = useSelector(
    ({
      session,
      Classroom: classroomReducer,
      loading: loadingReducer,
    }: RootState) => ({
      sessions: session.SessionList,
      classrooms: classroomReducer.classroomList,
      loading: loadingReducer.loading,
    }),
  );

  const componentRef = React.useRef(null);
  useEffect(() => {
    dispatch(getSessionsForClassroomCalendar());
    dispatch(getClassrooms());
  }, [dispatch]);

  const handleDateChange = useCallback((newDate: Moment | null) => {
    if (newDate) setSelectedDate(newDate);
  }, []);

  const onSessionSelect = useCallback((sessionId: number) => {
    setSelectedSessionId(sessionId);
    setIsClassroomReplacementModalOpen(true);
  }, []);

  return (
    <div>
      <div className="bg-primary mb-2">
        <div className="space-1 mx-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 mb-0 text-white">Calendrier des Salles</h1>
              </div>
            </div>
            <div className="col-sm">
              <ReactToPrint
                trigger={() => (
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-soft-light mt-6  mb-6"
                      type="button"
                    >
                      Imprimer le Calendrier ↓
                      <i className="fa fa-print" />
                    </button>
                  </div>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="col-4">
              <div
                className="d-none d-lg-block"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                }}
              >
                <DatePickerComponent
                  setDate={handleDateChange}
                  value={selectedDate}
                  step={7}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-5" ref={componentRef}>
        {loading || !classrooms.length ? (
          <IntermediateView loading={loading} />
        ) : (
          <>
            <div className="d-flex">
              <div
                className="border border-primary border-top-0 border-left-0"
                style={{
                  width: CLASSROOM_NAME_COL_WIDTH,
                }}
              />
              <div className="d-flex" style={{ flex: 1 }}>
                {DAY_ARRAY.map((_e, i) => {
                  const day = moment(selectedDate).add(i, 'day');
                  const dayName = day.format('dddd');
                  const dayDateString = day.format('L');
                  return (
                    <div
                      key={dayDateString}
                      className="text-center font-size-1 border border-left-0 border-primary py-1 
                  font-weight-semi-bold"
                      style={{ flex: 1, wordBreak: 'break-all' }}
                    >
                      <div className="text-capitalize">{dayName}</div>
                      <div>{dayDateString}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <ClassroomList
              classrooms={classrooms}
              selectedDate={selectedDate}
              sessions={sessions}
              onSessionSelect={onSessionSelect}
            />
          </>
        )}
      </div>
      {selectedSessionId && (
        <ClassroomReplacementModal
          isOpen={isClassroomReplacementModalOpen}
          setIsOpen={setIsClassroomReplacementModalOpen}
          sessionId={selectedSessionId}
        />
      )}
    </div>
  );
};
export default ClassroomCalendar;
