/* eslint-disable max-lines */
import { getCvUrl } from 'helpers/getCvUrl';
import { getImageUrl } from 'helpers/getImageUrl';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  DropzoneComponent,
  DropzoneComponentConfig,
  DropzoneComponentHandlers,
} from 'react-dropzone-component';
import { Document, Page, pdfjs } from 'react-pdf/';
import { WrappedFieldProps } from 'redux-form';

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};
export interface Props {
  isPdf?: boolean;
  maxFiles?: number;
  label?: string;
}

const ImagePicker: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange, value },
  meta: { submitFailed, error, warning },
  maxFiles = 1,
  label,
  isPdf,
}) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [initiated, setInitiated] = useState(false);
  // Typed incorrectly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropZoneRef = React.useRef<any | null>(null);

  const removeFile = (): void => {
    if (dropZoneRef.current) {
      dropZoneRef.current.removeFile(
        dropZoneRef.current.files[dropZoneRef.current.files.length - 1],
      );
    }
  };

  useEffect(() => {
    if (value) {
      fetch(isPdf ? getCvUrl(value) : getImageUrl(value))
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          dropZoneRef.current.addFile(blob);
          setInitiated(true);
        });
    } else setInitiated(true);
  }, []);

  useEffect(() => {
    if (initiated) onChange(photos);
  }, [photos]);
  const handleAddedFile = (file: File): void => {
    const { type } = file;
    if (
      (isPdf && type !== 'application/pdf') ||
      (!isPdf && !type.startsWith('image/'))
    ) {
      removeFile();
      return;
    }
    setPhotos(prevArray => [...prevArray, file]);
  };
  const handleRemovedFile = (file: File): void => {
    setPhotos(prevArray =>
      prevArray.filter(element => element.name !== file.name),
    );
  };
  const djsConfig = {
    maxFiles,
    acceptedFiles: isPdf ? 'application/pdf' : 'image/jpeg,image/png',
    autoProcessQueue: false,
    dictDefaultMessage: label,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="col h-100 mb-5">
        <div className="dz-preview dz-file-preview">
          <div className="d-flex justify-content-end dz-close-icon">
            <small className="fa fa-times" data-dz-remove="true" />
          </div>
          <div className="dz-details media">
            <div className="dz-img">
              <img
                className="img-fluid"
                data-dz-thumbnail="true"
                alt="upload"
              />
            </div>
            <div className="media-body dz-file-wrapper">
              <h6 className="dz-filename">
                <span className="dz-title" data-dz-name="true" />
              </h6>
              <div className="dz-size" data-dz-size="true" />
            </div>
          </div>
        </div>
      </div>,
    ),
  };

  const initCallBack = (dropzone: string): void => {
    dropZoneRef.current = dropzone;
  };

  const componentConfig: DropzoneComponentConfig = {
    iconFiletypes: ['.jpg', '.png'],
    showFiletypeIcon: true,
    postUrl: 'no-url',
  };
  const eventHandlers: DropzoneComponentHandlers = {
    addedfile: handleAddedFile,
    removedfile: handleRemovedFile,
    init: initCallBack,
    maxfilesexceeded: removeFile,
  };

  const handleRemovePdf = (): void => {
    removeFile();
    setPhotos([]);
  };

  return (
    <>
      {photos.length > 0 && isPdf && (
        <div className="card  shadow col h-100 mb-5 pt-2 pb-3">
          <div className="d-flex flex-column justify-content-center">
            <div
              className="d-flex justify-content-end dz-close-icon"
              onClick={handleRemovePdf}
              style={{ cursor: 'pointer' }}
              role="button"
              onKeyPress={handleRemovePdf}
              tabIndex={0}
            >
              <small className="fa fa-times" data-dz-remove="true" />
            </div>

            <div className="d-flex align-items-center justify-content-start">
              <Document options={options} file={photos[0]}>
                <Page height={200} width={200} pageNumber={1} />
              </Document>

              <div className="w-100">
                <div className="h6 font-weight-bold">{photos[0].name}</div>

                <div className="h6 font-weight-bold">
                  {`${(photos[0].size / (1024 * 1024)).toFixed(3)} MB`}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <DropzoneComponent
        config={componentConfig}
        djsConfig={djsConfig}
        eventHandlers={eventHandlers}
        className={`${photos.length > 0 &&
          isPdf &&
          'd-none'}  u-dropzone ${submitFailed &&
          (error || warning) &&
          'u-dropzone-has-error'}`}
      />

      {submitFailed &&
        ((error && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {error}
          </div>
        )) ||
          (warning && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {warning}
            </div>
          )))}
    </>
  );
};

export default ImagePicker;
