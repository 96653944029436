import TrainingSidebar from 'components/TrainingSidebar';
import * as React from 'react';

interface IProps {
  toggleOneShot?: () => void;
  toggleDraft?: () => void;
  togglePublishedTrainings?: () => void;
  toggleCertification?: () => void;
  toggleCycle?: () => void;
  toggleExam?: () => void;
}

const AddTrainingMenuSection: React.FC<IProps> = ({
  toggleOneShot,
  toggleCertification,
  toggleDraft,
  togglePublishedTrainings,
  toggleCycle,
  toggleExam,
  
}) => {
  return (
    <TrainingSidebar header="Ajouter" className="p-4">
      <div
        className="d-flex flex-column"
        style={{ justifyContent: 'space-evenly', flex: 1 }}
      >
        <button
          type="button"
          className="btn btn-soft-primary btn-sm-wide"
          onClick={toggleOneShot}
        >
          Formation continue
        </button>
        <button
          type="button"
          onClick={toggleCertification}
          className="btn btn-soft-primary btn-sm-wide"
        >
          Certification
        </button>
        <button
          type="button"
          onClick={toggleCycle}
          className="btn btn-soft-primary btn-sm-wide"
        >
          Cycle
        </button>
        <button
          type="button"
          onClick={toggleExam}
          className="btn btn-soft-primary btn-sm-wide"
        >
          Examen
        </button>
        <button
          type="button"
          className="btn btn-soft-secondary btn-sm-wide"
          onClick={toggleDraft}
        >
          Formations non publiées
        </button>
        <button
          type="button"
          className="btn btn-soft-secondary btn-sm-wide"
          onClick={togglePublishedTrainings}
        >
          Formations publiées
        </button>
      </div>
    </TrainingSidebar>
  );
};
export default AddTrainingMenuSection;
