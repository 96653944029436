import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { filterTrainers } from 'helpers/filterTrainers';
import moment from 'moment';
import _ from 'lodash';
interface Props {
  refToPrint: React.RefObject<HTMLDivElement>;
}

const ViewSemesterDetails: React.FC<Props> = ({ refToPrint }) => {
  const { semesterDetails } = useSelector((state: RootState) => state.semester);

  return (
    <div
      ref={refToPrint}
      className="container space-1 space-top-lg-0 ml-2 mt-lg-n10 col-7"
    >
      <div className="card-body" style={{ margin: '0px' }}>
        <div className="card">
          <div
            className="card-header"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <h4
              className="card-header-title text-center"
              style={{ marginRight: 0 }}
            >
              {`Formation : ${semesterDetails?.title || ''}`}
            </h4>
            <span>{`Fiche Générée le ${moment().format('L')}`}</span>
          </div>
          <div className="card-body row">
            <div className="container col-9 space-1 d-flex flex-column ">
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Type de La Formation : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${semesterDetails?.type || ''}`}
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Cycle Associé : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${semesterDetails?.cycleTitle ||
                      ''} ${semesterDetails?.cycleYear || ''}`}
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Total des Modules Associés : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${semesterDetails?.cycleModulesTable.length || 0}`}
                  </p>
                </div>
              </div>
              <hr style={{ width: '80%' }} />
              {semesterDetails &&
                semesterDetails.cycleModulesTable.map((module, i) => {
                  return (
                    <div key={i.toString()}>
                      <div className="row pt-2 mt-0">
                        <h2>{`Nom Du Module : ${module.cmdTitle}`}</h2>
                      </div>
                      <div className="row mt-2">
                        <div className="col-8">
                          <h5 className="mr-0">{`Nombre de Jours et Durée : `}</h5>
                        </div>
                        <div className="col-4">
                          <p className="font-weight-light font-italic">
                            {`${module?.nmbDays ||
                              0} jours / ${module?.nmbHours || 0} heures`}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-8">
                          <h5 className="mr-0">{`Dates Associées : `}</h5>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            {_.uniq(module?.dates).map((d, ind) => {
                              return (
                                <p
                                  key={ind.toString() + d.toString()}
                                  className="font-weight-light font-italic mr-2"
                                >
                                  {` ${d}, `}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-8">
                          <h5 className="mr-0">{`Formateurs : `}</h5>
                        </div>
                        <div className="col-4">
                          {' '}
                          <div className="row">
                            {filterTrainers(module).map((t, index) => {
                              return (
                                <p
                                  key={index.toString() + t.toString()}
                                  className="font-weight-light font-italic  mr-2"
                                >
                                  {`${t.tr} (${t.hr} heures),`}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewSemesterDetails;
