export enum SemesterEnum {
  FETCH_SEMESTERS = 'FETCH_SEMESTERS',
  FETCH_SEMESTERS_FAILURE = 'FETCH_SEMESTERS_FAILURE',
  FETCH_CYCLE_RELATED_SEMESTER_SUCCESS = 'FETCH_CYCLE_RELATED_SEMESTER_SUCCESS',
  FETCH_CYCLE_RELATED_SEMESTER_FAILURE = 'FETCH_CYCLE_RELATED_SEMESTER_FAILURE',
  DELETE_SEMESTER_SUCCESS = 'DELETE_SEMESTER_SUCCESS',
  DELETE_SEMESTER_FAILURE = 'DELETE_SEMESTER_FAILURE',
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_ALL_SEMESTERS_SUCCESS = ' FETCH_ALL_SEMESTERS_SUCCESS',
  FETCH_ALL_SEMESTERS_FAILURE = 'FETCH_ALL_SEMESTERS_FAILURE',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  ADD_SEMESTER_SUCCESS = 'ADD_SEMESTER_SUCCESS',
  ADD_SEMESTER_FAILURE = 'ADD_SEMESTER_FAILURE',
  FETCH_UNFINISHED_SEMESTER_SUCCESS = 'FETCH_UNFINISHED_SEMESTER_SUCCESS',
  FETCH_UNFINISHED_SEMESTER_FAILURE = 'FETCH_UNFINISHED_SEMESTER_FAILURE',
  FETCH_MODULE_DATA_SUCCESS = 'FETCH_MODULE_DATA_SUCCESS',
  FETCH_MODULE_DATA_FAILURE = 'FETCH_MODULE_DATA_FAILURE',
  RESET_SEMESTER = 'RESET_SEMESTER',
  RESET_SEMESTER_LIST = 'RESET_SEMESTER_LIST',
  SET_SELECTED_SEMESTER_SUCCESS = 'SET_SELECTED_SEMESTER_SUCCESS',
  SELECT_SEMESTER_SUCCESS = 'SELECT_SEMESTER_SUCCESS',
  UNSELECT_SEMESTER_SUCCESS = 'UNSELECT_SEMESTER_SUCCESS',
  GET_SEMESTER_SUCCESS = 'GET_SEMESTER_SUCCESS',
  GET_SEMESTER_FAILURE = 'GET_SEMESTER_FAILURE',
  PUBLISH_SEMESTER_SUCCESS = 'PUBLISH_SEMESTER_SUCCESS',
  PUBLISH_SEMESTER_FAILURE = 'PUBLISH_SEMESTER_FAILURE',
  FETCH_FINISHED_SEMESTER_SUCCESS = 'FETCH_FINISHED_SEMESTER_SUCCESS',
  FETCH_FINISHED_SEMESTER_FAILURE = 'FETCH_FINISHED_SEMESTER_FAILURE',
  FETCH_USER_SEMESTERS_SUCCESS = 'FETCH_USER_SEMESTERS_SUCCESS',
  FETCH_USER_SEMESTERS_FAIL = 'FETCH_USER_SEMESTERS_FAIL',
  FETCH_SEMESTER_DETAILS_SUCCESS = 'FETCH_SEMESTER_DETAILS_SUCCESS',
  FETCH_SEMESTER_DETAILS_FAILURE = 'FETCH_SEMESTER_DETAILS_FAILURE',
}
