import * as React from 'react';
import DataTable from 'react-data-table-component';
import CycleViewModel from 'view-models/CycleViewModel';
import Fuse from 'fuse.js';
import { useEffect } from 'react';
import columns from './columns';

interface Props {
  cycles: CycleViewModel[];
  handleImport: (id?: number) => void;
}

const CycleTable: React.FC<Props> = ({ cycles, handleImport }) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedCycles, setSortedCycles] = React.useState<CycleViewModel[]>([]);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedCycles(cycles);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['candidate', 'presence'],
    };
    const fuse = new Fuse(cycles, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCycles(results);
  }, [keyword, cycles]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  const cols = columns(handleImport);

  return (
    <DataTable
      data={sortedCycles}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default CycleTable;
