import { Dispatch } from 'redux';
import { SidebarInterfaceTypesEnum } from 'constants/types/Sidebars';

export interface ToggleSidebarPayload {
  type: SidebarInterfaceTypesEnum;
  sidebarId: string;
}

export const toggleSidebar = (sidebarId: string) => (
  dispatch: Dispatch,
): void => {
  dispatch({ type: SidebarInterfaceTypesEnum.TOGGLE_SIDEBAR, sidebarId });
};
