import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import { ProgramEnum } from 'constants/types/Program';
import { ProgramWithDays } from 'view-models/ProgramViewModel';

export interface ProgramState {
  error?: AxiosError | Error;
  importedProgram?: ProgramWithDays;
}

const initialState: ProgramState = {};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): ProgramState => {
  switch (type) {
    case ProgramEnum.IMPORT_PROGRAM_SUCCESS:
      return { ...state, importedProgram: payload };
    case ProgramEnum.IMPORT_PROGRAM_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
