import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { CandidateToAdd } from 'models/Candidate';

interface Props {
  onChange: (data: CandidateToAdd[]) => void;
}

const Csv: React.FC<Props> = ({ onChange }) => {
  const [data, setData] = useState<CandidateToAdd[]>([]);
  useEffect(() => {
    if (data.length) onChange(data);
  }, [data]);
  const formatData = (_data: Array<Array<string>>): void => {
    const newArray = _data.slice(1, _data.length - 1);
    const header = _data[0];
    const formattedData: CandidateToAdd[] = [];

    newArray.forEach((singleUser: Array<string>) => {
      let user = {} as CandidateToAdd;
      singleUser.forEach((field: string, fieldId: number) => {
        header.forEach((singleHeader: string, headerId: number) => {
          if (
            singleHeader === 'lastName' ||
            singleHeader === 'firstName' ||
            singleHeader === 'phoneNumber' ||
            singleHeader === 'email' ||
            singleHeader === 'CIN' ||
            singleHeader === 'bank' ||
            singleHeader === 'job'
          ) {
            if (headerId === fieldId && field) {
              user = { ...user, [singleHeader]: field };
            }
          }
        });
      });

      formattedData.push(user);
    });
    setData(formattedData);
  };
  return (
    <CSVReader
      cssClass="btn btn btn-soft-light"
      onFileLoaded={formatData}
      inputId="ObdcsiWan"
    />
  );
};

export default Csv;
