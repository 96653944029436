/* eslint-disable max-len */
import SessionViewModel from 'view-models/SessionViewModel';
import moment, { Moment } from 'moment';
import Theme from 'models/Theme';
import Category from 'models/Category';


const formatData = (list: SessionViewModel[], date: moment.Moment | null) => {
  const filtred = list.filter(el => {
    return (
      date?.format('L') === moment(el.date).format('L') &&
      (el.training?.isFinished ||
        el.module?.certificate?.isFinished ||
        el.cycleModule?.semester?.cycle?.isFinished ||
        el.exam?.isFinished)
    );
  });

  const sorted = filtred.sort((a, b) => {
    if (moment(a.from).format('HH:mm') > moment(b.from).format('HH:mm'))
      return 1;
    if (moment(b.from).format('HH:mm') > moment(a.from).format('HH:mm'))
      return -1;
    return 0;
  });
  const filterFormationTypes = (el: SessionViewModel) => {
    if (el.trainingId) {
      return {
        colorId: `training-${el?.trainingId}`,
        trainingId: el?.trainingId,
        title: el?.training.title,
        type: 'One shot',
        fomationTypeTitle: el?.training?.title,
        theme: el?.training?.theme?.title,
        category: el?.training?.category?.title,
      };
    }
    if (el.moduleId) {
      return {
        colorId: `module-${el?.moduleId}`,
        trainingId: el?.moduleId,
        title: el?.module?.title,
        type: 'Certification',
        fomationTypeTitle: el?.module?.certificate?.title,
        theme: el?.module?.certificate?.theme?.title,
        category: el?.module?.certificate?.category?.title,
      };
    }
    if (el.examId) {
      return {
        colorId: `exam-${el?.examId}`,
        examId: el?.examId,
        title: el?.exam.title,
        type: 'Exam',
        fomationTypeTitle: el?.exam?.title,
        examSubject: el?.exam?.examSubject,
      };
    }
    return {
      colorId: `cycle-${el?.cycleModuleId}`,
      trainingId: el?.cycleModuleId,
      title: el?.cycleModule?.title,
      fomationTypeTitle: el?.cycleModule.semester?.cycle?.title,
      type: 'Cycle',
      theme: (el?.cycleModule?.semester?.theme as Theme)?.title,
      category: (el?.cycleModule?.semester?.category as Category)?.title,
    };
  };

  const formatted = sorted.map(el => {
    const {
      title,
      trainingId,
      theme,
      category,
      type,
      fomationTypeTitle,
      colorId,
      examId,
      examSubject,
    } = filterFormationTypes(el);

    
    return {
      examId,
      examSubject,
      colorId,
      title,
      trainingId,
      fomationTypeTitle,
      theme,
      type,
      category,
      trainer: el.examId ? el.exam.defaultUser.map(superivor => `${superivor.firstName} ${superivor.lastName}`).toString()   : `${el.trainer.firstName} ${el.trainer.lastName}`,
      trainerId: el.examId ? el.exam.defaultUser[0].id  : el.trainer.id,
      courseNumber: el.courseNumber,
      time: `${moment(el.from).format('HH:mm')} - ${moment(el.to).format(
        'HH:mm',
      )}`,
      room: `${el.classroom.type} ${
        el.classroom.name !== null ? el.classroom.name : ''
      } ${el.classroom.number}`,
    };
  });

  return formatted;
};

export default formatData;
