import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { StatTypes } from 'constants/types/Stats';
import { errorHandler } from 'helpers/errorHandler';
import fileDownload from 'js-file-download';
import { Moment } from 'moment';

export const setStartDate = (startDate: Moment): IThunkAction => dispatch =>
  dispatch({ type: StatTypes.SET_START_DATE, payload: startDate });

export const setEndDate = (endDate: Moment): IThunkAction => dispatch =>
  dispatch({ type: StatTypes.SET_END_DATE, payload: endDate });

export const fetchStats = (): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { startDate, endDate } = getState().statsReducer;
    if (!startDate || !endDate) throw new Error('Internal Error');
    const response = await api.get(`stats`, {
      params: {
        from: startDate.toDate(),
        to: endDate.toDate(),
      },
    });

    dispatch({
      type: StatTypes.FETCH_STATS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const exportTraningsPerTraningCV = (): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    const { startDate, endDate } = getState().statsReducer;
    if (!startDate || !endDate) throw new Error('Internal Error');
    const response = await api.get(`/stats/get-training-per-trainerCSV`, {
      params: {
        from: startDate.toDate(),
        to: endDate.toDate(),
      },
      responseType: 'blob',
    });

    fileDownload(response.data, `Formations par formateur.xlsx`);

    dispatch({
      type: StatTypes.EXPORT_CSV_TRAININGS_PER_TRANING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
