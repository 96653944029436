import React from 'react';
interface ISpinner {
  style?: React.CSSProperties | undefined;
}
const Spinner: React.FC<ISpinner> = ({ style }) => {
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={style}
      >
        <div
          className="spinner-border"
          style={{ color: '#247275' }}
          role="status"
        />
      </div>
    </div>
  );
};
export default Spinner;
