/* eslint-disable max-lines */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect } from 'react';
import AppDateRangePicker, {
  DateChangeCallback,
} from 'components/AppDateRangePicker';
import CandidatesPerTrainingType from 'components/Stats/CandidatesPerTrainingType';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  exportTraningsPerTraningCV,
  fetchStats,
  setEndDate,
  setStartDate,
} from 'actions/Stats';
import moment from 'moment';
import ClassroomsPieChart from 'components/Stats/ClassroomsPieChart';
import TopTraining from 'components/Stats/TopTraining';
import DaysPerClassroom from 'components/Stats/DaysPerClassroom';
import TrainerTrainings from 'components/Stats/TrainerTrainings';
import BreaksPerBreakType from 'components/Stats/BreaksPerBreakType';
import DaysPerTrainer from 'components/Stats/DaysPerTrainer';
import DaysPerTrainingType from 'components/Stats/DaysPerTrainingType';
import { CSVLink } from 'react-csv';
import formatToCsv from 'helpers/formatToCsv';
import DaysPerDefaultUser from 'components/Stats/DaysPerDefaultUser';

const minDate = moment().subtract(2, 'years');
const maxDate = moment().add(2, 'years');

const Statistique = () => {
  const { startDate, endDate, stats } = useSelector(
    ({ statsReducer }: RootState) => statsReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate && endDate) dispatch(fetchStats());
  }, [dispatch, startDate, endDate]);
  const exportCSVTrainingsPerTraining = () => {
    dispatch(exportTraningsPerTraningCV());
  };
  const onDatesChange = useCallback(
    ({ startDate: start, endDate: end }: DateChangeCallback) => {
      if (start) dispatch(setStartDate(start));
      if (end) dispatch(setEndDate(end));
    },
    [dispatch],
  );

  const getClassroomTypePercentage = useCallback(
    (x: number) => {
      if (!stats.classroomPercentages) return 'Invalid';
      const percentage =
        (x * 100) /
        (stats.classroomPercentages.ABF + stats.classroomPercentages.other);
      return `${Math.round(percentage)}%`;
    },
    [stats.classroomPercentages],
  );

  return (
    <div>
      {/* Header Start */}
      <div
        className="bg-primary mb-2 d-flex justify-content-between 
      align-items-center px-1 space-1"
      >
        <div className="col">
          <span className="h1 text-white">Statistiques</span>
        </div>
        <div className="mr-5">
          <AppDateRangePicker
            {...{ startDate, endDate, onDatesChange, minDate, maxDate }}
          />
        </div>
      </div>
      {/* Header End | Body Start */}

      <div className="masonry-grid">
        {stats.candidatesPerTraining && (
          <div className="cell">
            <CandidatesPerTrainingType
              {...{
                ...stats.candidatesPerTraining,
                exportCSVButton: (
                  <CSVLink
                    className="btn btn-sm-wide btn-primary btn"
                    filename="Nombre participants.csv"
                    data={formatToCsv(
                      [stats.candidatesPerTraining],
                      [
                        {
                          label: 'Cycle',
                          value: 'cycle',
                        },
                        {
                          label: 'Certificate',
                          value: 'certificate',
                        },
                        {
                          label: 'Formation continue',
                          value: 'training',
                        },
                      ],
                    )}
                  >
                    Export CSV
                  </CSVLink>
                ),
              }}
            />
          </div>
        )}
        {stats.classroomPercentages && (
          <div className="cell">
            <ClassroomsPieChart
              {...{
                ...stats.classroomPercentages,
                exportCSVButton: (
                  <CSVLink
                    className="btn btn-sm-wide btn-primary btn"
                    filename="Répartition des salles.csv"
                    data={formatToCsv(
                      [stats.classroomPercentages],
                      [
                        {
                          label: 'Abf',
                          value: 'ABF',
                          transform: getClassroomTypePercentage,
                        },
                        {
                          label: 'Autre',
                          value: 'other',
                          transform: getClassroomTypePercentage,
                        },
                      ],
                    )}
                  >
                    Export CSV
                  </CSVLink>
                ),
              }}
            />
          </div>
        )}
        {stats.trainingsPerTrainer && (
          <div className="cell">
            <TrainerTrainings
              exportCSVButton={
                <button
                  type="submit"
                  className="btn btn-sm-wide btn-primary btn"
                  onClick={exportCSVTrainingsPerTraining}
                >
                  Export CSV
                </button>
              }
              data={stats.trainingsPerTrainer}
            />
          </div>
        )}
        {stats.daysPerTrainer && (
          <div className="cell">
            <DaysPerTrainer
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Nombre de jours par formateur.csv"
                  data={formatToCsv(stats.daysPerTrainer, [
                    {
                      label: 'Nom et prénom',
                      value: 'name',
                    },
                    {
                      label: 'Nombre de jours',
                      value: 'count',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              data={stats.daysPerTrainer}
            />
          </div>
        )}
        {stats.topOneshots && (
          <div className="cell">
            <TopTraining
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Top 5 formations continues.csv"
                  data={formatToCsv(stats.topOneshots, [
                    {
                      label: 'Titre de formation continue',
                      value: 'title',
                    },
                    {
                      label: 'Nombre de participants',
                      value: 'candidates',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              title="Top 5 formations continues"
              trainings={stats.topOneshots}
            />
          </div>
        )}
        {stats.daysPerTrainingType && (
          <div className="cell">
            <DaysPerTrainingType
              {...{
                ...stats.daysPerTrainingType,
                exportCSVButton: (
                  <CSVLink
                    className="btn btn-sm-wide btn-primary btn"
                    filename="Jours homme par type de formation.csv"
                    data={formatToCsv(
                      [stats.daysPerTrainingType],
                      [
                        {
                          label: 'Cycle',
                          value: 'cycle',
                        },
                        {
                          label: 'Certificate',
                          value: 'certificate',
                        },
                        {
                          label: 'Formation continue',
                          value: 'training',
                        },
                      ],
                    )}
                  >
                    Export CSV
                  </CSVLink>
                ),
              }}
            />
          </div>
        )}
        {stats.topCertificates && (
          <div className="cell">
            <TopTraining
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Top 5 cértificats.csv"
                  data={formatToCsv(stats.topCertificates, [
                    {
                      label: 'Titre de formation continue',
                      value: 'title',
                    },
                    {
                      label: 'Nombre de participants',
                      value: 'candidates',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              title="Top 5 cértificats"
              trainings={stats.topCertificates}
            />
          </div>
        )}
        {stats.candidatesPerClient && (
          <div className="cell">
            <TopTraining
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="N° Candidats par client.csv"
                  data={formatToCsv(stats.candidatesPerClient, [
                    {
                      label: 'Titre de formation continue',
                      value: 'title',
                    },
                    {
                      label: 'Nombre de participants',
                      value: 'candidates',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              title="N° Candidats par client"
              trainings={stats.candidatesPerClient}
            />
          </div>
        )}
        {stats.breaksPerBreakType && (
          <div className="cell">
            <BreaksPerBreakType
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Répartition des types de pause.csv"
                  data={formatToCsv(stats.breaksPerBreakType, [
                    {
                      label: 'Type de pause',
                      value: 'title',
                    },
                    {
                      label: 'Total',
                      value: 'count',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              data={stats.breaksPerBreakType}
            />
          </div>
        )}

        {stats.daysBookedPerClassroom && (
          <div className="cell">
            <DaysPerClassroom
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Jours par salle.csv"
                  data={formatToCsv(stats.daysBookedPerClassroom, [
                    {
                      label: 'Salle',
                      value: 'classroom',
                    },
                    {
                      label: 'Nombre de jours',
                      value: 'days',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              data={stats.daysBookedPerClassroom}
            />
          </div>
        )}
        {stats.daysPerDefaultUser && (
          <div className="cell">
            <DaysPerDefaultUser
              exportCSVButton={
                <CSVLink
                  className="btn btn-sm-wide btn-primary btn"
                  filename="Jours par créer organisateur.csv"
                  data={formatToCsv(stats.daysPerDefaultUser, [
                    {
                      label: 'Nom prénom',
                      value: 'fullName',
                    },
                    {
                      label: 'Nombre de jours',
                      value: 'days',
                    },
                  ])}
                >
                  Export CSV
                </CSVLink>
              }
              data={stats.daysPerDefaultUser}
            />
          </div>
        )}
      </div>

      {/* Body End */}
    </div>
  );
};

export default Statistique;
