import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const getUnfinishedCertificates = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      // eslint-disable-next-line max-len
      'certificates/user-certificates?filter={"where":{"isFinished":false}, "include" : "defaultUser"}',
    );
    dispatch({
      type: CertificateEnum.FETCH_UNFINISHED_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: CertificateEnum.FETCH_UNFINISHED_CERTIFICATE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
