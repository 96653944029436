import { Field, Validator } from 'redux-form';
import Input, { Props as InputProps } from 'components/Input';
import * as React from 'react';

interface Props extends InputProps {
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  validate?: Validator[];
  name: string;
}

const InputField: React.FC<Props> = ({
  label,
  name,
  placeholder,
  type = 'text',
  forgotPasswordLink,
  forgotPasswordMessage,
  validate,
  leftIcon,
  rightIcon,
  className,
  disabled,
  ...props
}) => {
  const isPassword = type === 'password';
  return (
    <Field
      name={name}
      id={name}
      label={label}
      component={Input}
      isPassword={isPassword}
      forgotPasswordLink={forgotPasswordLink}
      placeholder={placeholder}
      forgotPasswordMessage={forgotPasswordMessage}
      type={type}
      aria-label="name"
      className={className}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      validate={validate}
      disabled={disabled}
      {...props}
    />
  );
};

export default InputField;
