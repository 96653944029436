import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ModuleEnum } from 'constants/types/Module';
import { errorHandler } from 'helpers/errorHandler';
import IImportedCertificate from 'models/IImportedCertificate';

export const importCertificate = (
  certificate?: IImportedCertificate,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    const response = await api.post(
      '/certificates/import-certificate',
      certificate,
    );
    dispatch({
      type: CertificateEnum.ADD_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ModuleEnum.FETCH_MODULES_SUCCESS,
      payload: response.data.module,
    });
  } catch (error) {
    dispatch({ type: CertificateEnum.ADD_CERTIFICATE_FAILURE, error });
    errorHandler(error);
  }
};
