/* eslint-disable max-lines */
import { IThunkAction } from 'constants/types/IThunkAction';
import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { UserEnum } from 'constants/types/User';
import { TrainingEnum } from 'constants/types/Training';
import UserViewModel from 'view-models/UserViewModel';
import { CycleEnum } from 'constants/types/cycle';
import { errorHandler } from 'helpers/errorHandler';

export const getUsers = (): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('DefaultUsers');
    dispatch({
      type: UserEnum.FETCH_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: UserEnum.FETCH_USERS_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const addUser = (
  data: UserViewModel,
  onSuccess?: () => void,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.patch('/DefaultUsers', data);
    dispatch({
      type: UserEnum.ADD_USERS_SUCCESS,
      payload: response.data,
    });
    onSuccess?.();
  } catch (error) {
    dispatch({ type: UserEnum.ADD_USERS_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const DeleteUser = (userId: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/DefaultUsers/${userId}`);
    await getUsers()(dispatch, getState, null);
    dispatch({
      type: UserEnum.DELETE_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: UserEnum.DELETE_USER_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const changePassword = (
  data: { id?: number; password: string },
  onSuccess?: () => void,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.patch('/DefaultUsers', data);
    dispatch({
      type: UserEnum.CHANGE_PASSWORD_SUCCESS,
      payload: response.data,
    });
    onSuccess?.();
  } catch (error) {
    dispatch({ type: UserEnum.CHANGE_PASSWORD_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const setSelectedUser = (user: UserViewModel): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: UserEnum.SET_SELECTED_USER_SUCCESS,
    payload: user,
  });
};

const updateSelectedTraining = (): IThunkAction => async (
  dispatch,
  getState,
) => {
  const { selectedDraft, newTraining } = getState().training;

  const response = await api.get(
    `trainings/${selectedDraft?.id ||
      newTraining?.id}?filter={"include" : "defaultUser"}`,
  );
  dispatch({
    type: TrainingEnum.ADD_TRAINING_SUCCESS,
    payload: response.data,
  });
};
export const addUsersToTraining = (data: {
  users: number[];
  trainingId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.post('/DefaultUserTrainings/many', data);
    await updateSelectedTraining()(dispatch, getState, null);
    dispatch({
      type: UserEnum.ADD_USERS_TO_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: UserEnum.ADD_USERS_TO_TRAINING_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const DeleteUserFromTraining = (
  userId: number,
  trainingId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/DefaultUsers/${userId}/trainings/rel/${trainingId}`);
    await updateSelectedTraining()(dispatch, getState, null);
    dispatch({
      type: UserEnum.DELETE_USER_FROM_TRAINING_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: UserEnum.DELETE_USER_FROM_TRAINING_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

const updateSelectedCycle = (): IThunkAction => async (dispatch, getState) => {
  const { newCycle } = getState().cycle;
  try {
    const currentDraft = await api.get(
      `cycles/${newCycle?.id}?filter={"include" : "defaultUser"}`,
    );
    dispatch({
      type: CycleEnum.SET_SELECTED_CYCLE_SUCCESS,
      payload: currentDraft.data,
    });
  } catch (error) {
    errorHandler(error);
  }
};

export const fetchCycleUserList = (
  cycleId: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`/Cycles/${cycleId}/defaultUser`);
    dispatch({
      type: UserEnum.ADD_USERS_TO_CYCLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: UserEnum.ADD_USERS_TO_CYCLE_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const addUsersToCycle = (data: {
  users: number[];
  cycleId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.post('/UserCycles/many', data);
    await updateSelectedCycle()(dispatch, getState, null);
    await fetchCycleUserList(data.cycleId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: UserEnum.ADD_USERS_TO_CYCLE_FAILURE, payload :error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const DeleteUserFromCycle = (
  defaultUserId: number,
  cycleId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`DefaultUsers/${defaultUserId}/cycles/rel/${cycleId}`);
    await updateSelectedCycle()(dispatch, getState, null);
    await fetchCycleUserList(cycleId)(dispatch, getState, null);

    dispatch({
      type: UserEnum.DELETE_USER_FROM_TRAINING_SUCCESS,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UserEnum.DELETE_USER_FROM_TRAINING_FAILURE, error });
  }
  stopLoading()(dispatch);
};
