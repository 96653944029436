import React from 'react';

interface IProps {
  index: number;
  onToggleDraft?: (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  title: string;
  createdAt: string;
}

const TrainingDraftCard: React.FC<IProps> = ({
  index,
  onToggleDraft,
  title,
  createdAt,
}) => {
  return (
    <>
      <div className="mb-5">
        <div
          role="button"
          tabIndex={index}
          className="p-3 shadow rounded text-center mx-auto"
          style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
          onClick={onToggleDraft}
          onKeyDown={onToggleDraft}
        >
          <div className="row">
            <div className="col-4">
              <h3 className="h6 text-left mx-auto mb-1">{title}</h3>
            </div>
            <div className="col-8">
              <h3 className="h6 text-right mx-auto mb-1">{createdAt}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrainingDraftCard;
