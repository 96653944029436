import { ClassInterfaceEnum } from 'constants/types/Classrooms';
import { AxiosError } from 'axios';
import Classroom from 'models/Classroom';
import { IReduxAction } from 'constants/types/IReduxAction';
import Session from 'models/Session';
import { BookedClassroom } from 'models/IBookedClassroom';

export interface ClassroomState {
  error?: AxiosError | Error;
  classroomList: Classroom[];
  selectedclassroom?: Classroom;
  classroomSessions?: Session[];
  bookedClassrooms: BookedClassroom[];
}

const initialState: ClassroomState = {
  classroomList: [],
  bookedClassrooms: [],
};

export default (
  state: ClassroomState = initialState,
  action: IReduxAction,
): ClassroomState => {
  const { type, error, payload } = action;
  switch (type) {
    case ClassInterfaceEnum.FETCH_CLASSROOM_SUCCESS:
      return {
        ...state,
        classroomList: payload,
      };
    case ClassInterfaceEnum.FETCH_CLASSROOM_FAILURE:
      return {
        ...state,
        error,
      };
    case ClassInterfaceEnum.FETCH_SESSION_CLASSROOM_SUCCESS:
      return {
        ...state,
        classroomSessions: payload,
      };
    case ClassInterfaceEnum.FETCH_SESSION_CLASSROOM_FAILURE:
      return {
        ...state,
        error,
      };
    case ClassInterfaceEnum.SELECT_CLASSROOM:
      return {
        ...state,
        selectedclassroom: payload,
      };
    case ClassInterfaceEnum.FETCH_BOOCKED_CLASSROOM_SUCCESS:
      return {
        ...state,
        bookedClassrooms: payload,
      };
    case ClassInterfaceEnum.FETCH_BOOCKED_CLASSROOM_FAILURE:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};
