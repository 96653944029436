import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { errorHandler } from 'helpers/errorHandler';
import { ExamEnum } from 'constants/types/Exam';


  export const getFinishedExames = (): IThunkAction => async (
    dispatch,
  ): Promise<void> => {
    try {
      startLoading()(dispatch);
      const response = await api.get(
        `Exams/user-exam?filter={"where":{"isFinished":true}}`,
      );
      dispatch({
        type: ExamEnum.FETCH_FINISHED_EXAM_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error);
      dispatch({
        type: ExamEnum.FETCH_FINISHED_EXAM_FAILURE,
        error,
      });
    }
    stopLoading()(dispatch);
  };