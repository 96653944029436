/* eslint-disable max-len */
import { DisplayData } from 'components/BigCalendarCard';
import React, { useMemo } from 'react';
import { ISessionResponse } from 'view-models/SessionViewModel';
import moment from 'moment';

interface ClassroomCalendarItemProps extends ISessionResponse {
  onClick: () => void;
}

const ClassroomCalendarItem: React.FC<ClassroomCalendarItemProps> = ({
  trainer,
  trainingId,
  moduleId,
  cycleModuleId,
  examId,
  training,
  module,
  cycleModule,
  exam,
  from,
  to,
  onClick,
}) => {
  const [title, candidateCount] = useMemo(() => {
    if (trainingId) return [training?.title, training.candidate?.length || 0];

    if (moduleId)
      return [module?.title, module.certificate?.candidate?.length || 0];

    if (cycleModuleId)
      return [
        cycleModule?.title,
        cycleModule.semester?.cycle?.candidate?.length || 0,
      ];

    if (examId) return [exam?.title, exam.candidate?.length || 0];

    return ['', 0];
  }, [
    trainingId,
    moduleId,
    cycleModuleId,
    examId,
    training,
    module,
    cycleModule,
    exam,
  ]);
  return (
    <button
      type="button"
      className=" p-1 m-1 mt-0 transition-3d-hover border-0 bg-transparent text-left "
      onClick={onClick}
      style={{
        wordBreak: 'break-all',
      }}
    >
      <DisplayData title="Titre" value={title} className="font-size-1" />
      <DisplayData
        title={examId ? 'Surveillant' : 'Assurée Par'}
        value={
          examId
            ? exam.defaultUser
                ?.map(
                  superivor => `${superivor.firstName} ${superivor.lastName}`,
                )
                .toString()
            : `${trainer.firstName} ${trainer.lastName}`
        }
        className="font-size-1"
      />
      <DisplayData
        title="N° Candidats"
        value={candidateCount}
        className="font-size-1"
      />
      <DisplayData
        title="De"
        className="font-size-1"
        value={moment(from).format('HH:mm')}
      />
      <DisplayData
        title="À"
        value={moment(to).format('HH:mm')}
        className="font-size-1"
      />
    </button>
  );
};

export default ClassroomCalendarItem;
