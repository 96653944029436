import React from 'react';
import { NotFoundSVG } from 'components/SVG';
import NotFoundHeader from 'components/NotFoundHeader';

const NotFound: React.FC = () => {
  return (
    <>
      <NotFoundHeader />
      <NotFoundSVG className="not-found-svg z-index-2 " />
    </>
  );
};

export default NotFound;
