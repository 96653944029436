/* eslint-disable max-lines */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import IModule from 'models/IModule';
import Category from 'models/Category';
import { useCandidate } from 'hooks/useCandidate';
import Theme from 'models/Theme';
import formatSessionsDetails from 'helpers/formatSessionsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from 'actions/SemesterProgram';
import { useHistory } from 'react-router';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import { setSelectedCycle } from 'actions/Cycle/setSelectCycle';
import { SemesterEnum } from 'constants/types/semester';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import columns from 'containers/ViewCertificatesAboutSection/columns';
import Modal from 'components/Modal';
import CandidatePresenceTable from 'components/CandidatePresenceTable';
import Candidate from 'models/Candidate';
import { IFormattedSessionAttendance } from 'view-models/SessionViewModel';
import { fetchCandidateAttendanceList, saveCandidatePresence } from 'actions';
import { RootState } from 'reducers';
import { CandInterfaceEnum } from 'constants/types/candidates';

export interface IProps {
  moduleWithSessions?: IModule;
  downloadProgramFile: () => void;
}

const ViewCycleModulesAboutSection: React.FC<IProps> = ({
  moduleWithSessions,
}) => {
  const [selectedSession, setSelectedSession] = useState<
    IFormattedSessionAttendance
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { candidateWithAttendance } = useSelector(
    (state: RootState) => state.candidates,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const formattedData = formatSessionsDetails(moduleWithSessions?.session);
  const togglePresence = () => setIsModalOpen(!isModalOpen);
  const { downloadCondiadatesFile, currentCandidates } = useCandidate('Cycle');

  const handlePresence = (session: IFormattedSessionAttendance) => {
    setSelectedSession(session);
    dispatch(
      fetchCandidateAttendanceList({
        sessionId: session.id,
        candidateList: currentCandidates,
      }),
    );
    togglePresence();
  };

  const cols = columns(
    downloadCondiadatesFile,
    moduleWithSessions as IModule,
    true,
    false,
    handlePresence,
  );

  const handleEdit = () => {
    dispatch({ type: mecansimTypesEnum.TOGGLE_EDIT_CYCLE });
    dispatch(setSelectedCycle(moduleWithSessions?.semester?.cycle));
    dispatch({
      type: SemesterEnum.ADD_SEMESTER_SUCCESS,
      payload: moduleWithSessions?.semester,
    });
    dispatch({
      type: ModuleCycleEnum.ADD_CYCLE_MODULE_SUCCESS,
      payload: moduleWithSessions,
    });
    history.push('/addtraining');
  };
  const downloadProgramFile = () => {
    dispatch(downloadFile(moduleWithSessions?.semester?.id));
  };
  const handleSavePresence = (selectedCandidate: Candidate[]) => {
    const absentCandidate = currentCandidates.filter(
      el =>
        !selectedCandidate.some(e => {
          return e.id === el.id;
        }),
    );
    selectedSession &&
      dispatch(
        saveCandidatePresence(
          selectedSession,
          selectedCandidate,
          absentCandidate,
        ),
      );
    dispatch({ type: CandInterfaceEnum.RESET_CANDIDATE_ATTENDANCE_LIST });
    togglePresence();
  };
  return (
    <div className="col-8 p-1 abf-sidebar mt-lg-n8">
      <Modal
        title="Reporting présence"
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        className="modal-xl"
      >
        {currentCandidates?.length && (
          <CandidatePresenceTable
            candidates={candidateWithAttendance || currentCandidates}
            handleSavePresence={handleSavePresence}
          />
        )}
      </Modal>
      {moduleWithSessions && (
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h4 className="card-title">Informations générales</h4>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <button
                    type="button"
                    onClick={handleEdit}
                    className="btn btn-sm btn-soft-primary"
                  >
                    Modifier
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row form-group">
              <span className="col-auto m-0 h5 font-weight">Semester :</span>
              <span className="col-auto h5 m-0 font-weight">
                {moduleWithSessions?.semester?.title}
              </span>
              <div className="ml-auto" style={{ marginRight: '15px' }}>
                <button
                  type="button"
                  className="btn btn-sm btn-soft-dark"
                  onClick={downloadProgramFile}
                >
                  Télécharger fiche programme
                </button>
              </div>
            </div>
            <div className="row form-group">
              <span className="col-auto h5 m-0 font-weight">Module :</span>
              <span className="col-auto h5 m-0 font-weight">
                {moduleWithSessions?.title}
              </span>
            </div>
            <div className="row form-group">
              <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
                Thème :
              </span>
              <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
                {(moduleWithSessions?.semester?.theme as Theme).title}
              </span>
            </div>
            <div className="row form-group">
              <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
                Catégorie :
              </span>
              <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
                {(moduleWithSessions?.semester?.category as Category).title}
              </span>
            </div>
            <div className="row form-group">
              <span className="col-auto mb-1 mt-3 mr-2 h5 font-weight">
                Séances :
              </span>
              <div className="col-9">
                {formattedData && (
                  <DataTable
                    data={formattedData}
                    columns={cols}
                    noHeader
                    persistTableHead
                    subHeaderAlign="center"
                    expandOnRowClicked
                    highlightOnHover
                    noDataComponent="Aucun élément"
                    pagination
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCycleModulesAboutSection;
