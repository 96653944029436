import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { getThemes, getCategories, getSessions } from 'actions';
import { getBreaks } from 'actions/Break';
import moment from 'moment';
import Training from 'models/Training';

export const useOneShot = (initialValues: Partial<Training>) => {
  const dispatch = useDispatch();
  const { categoryList, themeList, newTraining } = useSelector(
    (state: RootState) => state.training,
  );

  useEffect(() => {
    dispatch(getThemes());
    dispatch(getCategories());
  }, []);

  const { SessionList } = useSelector((state: RootState) => state.session);

  useEffect(() => {
    dispatch(getSessions());
    dispatch(getBreaks());
  }, []);

  const formattedSessions = SessionList?.filter(
    item =>
      (initialValues && item.trainingId === initialValues.id) ||
      (newTraining && item.trainingId === newTraining.id),
  ).map(el => ({
    list: {
      name: `${el.trainer.firstName} ${el.trainer.lastName}`,
      time: `de ${moment(el.from).format('h:mm A')} à ${moment(el.to).format(
        'h:mm A',
      )}`,
      room: `${el.classroom.type} ${
        el.classroom.name !== null ? el.classroom.name : ''
      } ${el.classroom.number}`,
      courseNumber: el.courseNumber,
    },
    day: moment(el.date).format('L'),
    id: el.id,
  }));

  const { BreakList } = useSelector((state: RootState) => state.breaks);
  const formattedBreaks = BreakList.filter(
    item =>
      (initialValues && item.trainingId === initialValues.id) ||
      (newTraining && item.trainingId === newTraining.id),
  ).map(el => ({
    id: el.id,
    type: el.breakType.title,
    date: moment(el.dateTime).format('L'),
    time: moment(el.dateTime).format('h:mm A'),
  }));

  return {
    formattedBreaks,
    formattedSessions,
    themeList,
    categoryList,
    SessionList,
  };
};
