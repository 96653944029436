/* eslint-disable max-lines */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import { ICertifProgram } from 'models/IProgramForm';
import RichField from 'components/RichField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
import { getAllProgramsWithDays } from 'actions/Program';
import RenderCertifProgramDays from 'components/RenderCertifProgramDays';
import AutoCompleteField from 'components/AutoCompleteFiled';
interface IProps {
  setIsWithFile: (value: boolean) => void;
  toggleProgramBar?: () => void;
  // semesterProgram?: ICertifProgramReturn;
}
const AddCycleModuleProgram: React.FC<IProps &
  InjectedFormProps<ICertifProgram, IProps>> = ({
  toggleProgramBar,
  handleSubmit,
  setIsWithFile,
  initialValues,
}) => {
  const {
    loading: { loading },
    semester: { newSemester, themeList },
    program: { semesterProgram },
  } = useSelector((state: RootState) => state);

  const { semesterModuleList } = useSelector(
    (state: RootState) => state.cycleModule,
  );
  const formattedSemesterModules = semesterModuleList?.filter(item => {
    return (
      (initialValues?.id && item.semesterId === initialValues?.id) ||
      (newSemester?.id && item.semesterId === newSemester?.id)
    );
  });

  // let formattedProgramValues = {
  //   methodology: '',
  //   pedGoals: '',
  //   trGoals: '',
  // };
  // if (newSemester?.title === semesterProgram?.program.title) {
  //   formattedProgramValues = {
  //     methodology: semesterProgram?.program.methods || '',
  //     pedGoals: semesterProgram?.program.pedGoals || '',
  //     trGoals: semesterProgram?.program.trGoals || '',
  //   };
  // }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProgramsWithDays());
  }, []);

  useEffect(() => {
    dispatch(change('addCycleModuleProgramForm', 'title', newSemester?.title));
    dispatch(
      change(
        'addCycleModuleProgramForm',
        'target',
        semesterProgram?.program.target,
      ),
    );
  }, [newSemester]);

  if (loading) return null;

  return (
    <form onSubmit={handleSubmit} className="col-12 p-0">
      <TrainingSidebar
        header="Fiche programme"
        onClose={() => toggleProgramBar?.()}
        className="p-4"
      >
        <div className="row my-4">
          <div className="col-4">
            <InputField
              name="title"
              label="Titre"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-4">
            <AutoCompleteField
              importedValue={newSemester?.themeId}
              className="orange badge-outline-primary"
              label="Thème"
              name="themeId"
              validate={[Validators.required]}
              suggestions={themeList}
            />
          </div>
          <div className="col-4">
            <InputField
              name="target"
              label="Cible"
              validate={[Validators.required]}
              defaultValue={semesterProgram?.program.target}
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6">
            <RichField
              name="trGoals"
              label="Objectifs formation"
              validate={[Validators.required]}
              pickedContent={semesterProgram?.program.trGoals}
            />
          </div>
          <div className="col-6">
            <RichField
              name="pedGoals"
              label="Objectifs pédagogiques"
              validate={[Validators.required]}
              pickedContent={semesterProgram?.program.pedGoals}
            />
          </div>
        </div>

        {formattedSemesterModules && (
          <RenderCertifProgramDays isCycle data={formattedSemesterModules} />
        )}

        <div className="row my-4 align-items-center">
          <div className="col-8">
            <RichField
              name="methodology"
              label="Méthodologies et outils d'évaluation"
              validate={[Validators.required]}
              pickedContent={semesterProgram?.program.methodology}
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalTheoreticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalPracticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(true)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer et télécharger PDF
            </button>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(false)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<ICertifProgram, IProps>({
  form: 'addCycleModuleProgramForm',
  // onSubmitSuccess: (_, dispatch) => dispatch(reset('addProgramForm')),
  enableReinitialize: true,
})(AddCycleModuleProgram);
