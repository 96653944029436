/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  getTrainers,
  getClassrooms,
  getFiltredSessions,
  getUsers,
} from 'actions';
import moment, { Moment } from 'moment';
import Classroom from 'models/Classroom';
import { getActiveRooms } from 'helpers/getActiveRooms';
import generateArrayOfTimes from 'helpers/generateArrayOfTimes';
import { sliceArrayOfTimes } from 'helpers/sliceArrayofTimes';
import subtractArray from 'helpers/subtractArray';
import DefaultUser from 'models/DefaultUser';
import { isEqual, isEqualWith } from 'lodash';

export const useSession = (
  date: Moment | null,
  trainingId?: number,
  isExam?: boolean,
  userList?: DefaultUser[],
) => {


  const [selectedClassroom, setselectedClassroom] = useState<
    ISelectFieldOption
  >();
  const [selectedTrainer, setselectedTrainer] = useState<ISelectFieldOption>();
  const [selectedCourseNumber, setselectedCourseNumber] = useState<
    ISelectFieldOption
  >();
  const [startTime, setstartTime] = useState<ISelectFieldOption<Moment>>();
  const [endTime, setendTime] = useState<ISelectFieldOption<Moment>>();

  const dispatch = useDispatch();
  const { trainerList, TrainerSessions } = useSelector(
    (state: RootState) => state.trainer,
  );
  const { UserList } = useSelector((state: RootState) => state.users);
  const { classroomList } = useSelector((state: RootState) => state.Classroom);
  const { SessionList } = useSelector((state: RootState) => state.session);


  useEffect(() => {
    dispatch(getTrainers());
    dispatch(getUsers());
    if (!startTime || !endTime) return;
    dispatch(getClassrooms());
  }, [startTime, endTime, SessionList]);

  useEffect(() => {
    if (selectedTrainer && date)
      dispatch(getFiltredSessions(selectedTrainer?.value as number, date));
  }, [selectedTrainer, date]);

  const courseNumber: ISelectFieldOption[] = Array.from(
    { length: 8 },
    (_, index) => index + 1,
  ).map(o => ({ label: `${o}`, value: o }));

  const formattedDataTrainers = trainerList.map(el => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));

  const formattedDataSupervisors = UserList.map(el => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));

  const filtredSessions = SessionList.filter(el => {
    if (isExam) {
      return (
        date?.format('L') === moment(el.date).format('L')
      );
    }
    return date?.format('L') === moment(el.date).format('L');
  });

  let rooms: Classroom[] = [];

  if (startTime && endTime) {
    rooms = classroomList.filter(
      el =>
        !getActiveRooms(filtredSessions, startTime, endTime)
          .map(item => item.id)
          .includes(el.id),
    );
  }

  const formattedDataClassrooms = rooms.map(el => ({
    label: `${el.type} ${el.name !== null ? el.name : ''}`,
    value: el.id,
  }));

  const filtredDataClassrooms = [
    ...new Map(formattedDataClassrooms.map(el => [el.label, el])).values(),
  ];

  const formattedDataClassroomsNum = rooms
    .filter(
      el =>
        `${el.type} ${el.name !== null ? el.name : ''}` ===
        selectedClassroom?.label,
    )
    .map(el => ({
      label: el.number,
      value: el.id,
    }));

  const unavailable = isExam
    ? filtredSessions
    .filter(el => 
      isEqualWith(el?.exam?.defaultUser, userList, isEqual) )
    .map(session => ({
        from: moment(session.from),
        to: moment(session.to),
      }))
    : 
      TrainerSessions?.map(el => ({
        from: moment(el.from),
        to: moment(el.to),
      }));

      
      const trainingSessions = filtredSessions
      .filter(el => {
        return el.trainingId === trainingId;
      })
      .map(el => ({
        from: moment(el.from),
        to: moment(el.to),
      }));
      
      const times = generateArrayOfTimes(8, 18);
      const filtredPerTraining = subtractArray(times, trainingSessions);
      const available = subtractArray(filtredPerTraining, unavailable);
  const formattedDataStartTime = available.map(el => ({
    label: el.format('h:mm A'),
    value: el,
  }));

  const filtredDataEndTime = available.slice(
    available.findIndex(
      el => el.format('HH:mm') === moment(startTime?.value).format('HH:mm'),
    ),
  );
  const formattedDataEndTime = sliceArrayOfTimes(
    filtredDataEndTime,
    unavailable,
  ).map(el => ({
    label: el.format('h:mm A'),
    value: el,
  }));

  return {
    formattedDataEndTime,
    formattedDataStartTime,
    formattedDataClassroomsNum,
    filtredDataClassrooms,
    courseNumber,
    formattedDataTrainers,
    formattedDataSupervisors,
    setselectedCourseNumber,
    setselectedClassroom,
    setselectedTrainer,
    setstartTime,
    setendTime,
    selectedTrainer,
    selectedCourseNumber,
    classroomList,
    startTime,
    filtredSessions,
    endTime,
    selectedClassroom,
  };
};
