import moment, { Moment } from 'moment';

const generateArrayOfTimes = (startHour: number, endHour: number): Moment[] => {
  const formatString = Array.from(
    {
      length: endHour - startHour,
    },
    (_, index) => {
      return [0, 15, 30, 45].map(interval => {
        return moment({ hour: index, minute: interval }).add(
          startHour,
          'hours',
        );
      });
    },
  );

  // eslint-disable-next-line no-extra-parens
  const result = ([] as Moment[]).concat(...formatString);
  return result;
};

export default generateArrayOfTimes;
