/* eslint-disable max-lines */
import React, { useEffect, useRef } from 'react';
import AddTrainingMenuSection from 'containers/AddTrainingMenuSection';
import Training from 'models/Training';
// import { resetTraining } from 'actions';
import AddTrainingDraftSection from 'containers/AddTrainingDraftSection';
// import { useDispatch } from 'react-redux';
import AddTrainingPublishedSection from 'containers/AddTrainingPublishedSection';
import { AddOneShotContainer } from 'containers/AddOneShotContainer';
import { AddCertificateContainer } from 'containers/AddCertificateContainer';
import { useTrainingHandler } from 'hooks/useTrainingHandler';
import { AddCycleContainer } from 'containers/AddCycleContainer';
import { draftEnum } from 'constants/enum/draftEnum';
import Certificate from 'models/Certificate';
import Cycle from 'models/Cycle';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { TrainingEnum } from 'constants/types/Training';
import AddExamContainer from 'containers/AddExamContainer';
import Exam from 'models/Exam';
import { ExamEnum } from 'constants/types/Exam';

const AddTraining: React.FC = () => {
  const endDivRef = useRef<HTMLDivElement>(null);
  const {
    toggleDraft,
    toggleOneShot,
    toggleCertificate,
    toggleCycle,
    toggleExam,
    isCycleOpen,
    setselectedDraft,
    setTrainingProgram,
    setCertificateProgram,
    isDraftOpen,
    isOneShotOpen,
    isCertificateOpen,
    isPublishedTrainingsOpen,
    togglePublishedTrainings,
    isExamOpen,
  } = useTrainingHandler(endDivRef);

  // const dispatch = useDispatch();
  // in case it's edited , it follow a mecanism
  const {
    isCertificateEdited,
    isOneShotEdited,
    isCycleEdited,
    isExamEdited,
  } = useSelector((state: RootState) => state.mecansim);
  useEffect(() => {
    if (isOneShotEdited) {
      toggleOneShot();
    }
    if (isCertificateEdited) {
      toggleCertificate();
    }
    if (isCycleEdited) {
      toggleCycle();
    }
  }, [isCertificateEdited, isOneShotEdited, isCycleEdited]);
  const dispatch = useDispatch();
  const handleDraft = (
    draft: Training | Certificate | Cycle| Exam,
    trainingType: draftEnum,
  ): void => {
    const { oneshot, certificate, cycle,exam } = draftEnum;
    switch (trainingType) {
      case oneshot:
        setselectedDraft(draft as Training, oneshot);
        setTrainingProgram(draft.id);
        toggleOneShot();
        break;
      case certificate:
        // we need to dispatch reset certificate
        setselectedDraft(draft as Certificate, certificate);
        setCertificateProgram(draft.id);
        toggleCertificate();
        break;
      case cycle:
        // reset the cycle field
        setselectedDraft(draft as Cycle, cycle);
        toggleCycle();
        break;
      case exam:
        setselectedDraft(draft as Exam, exam);
        toggleExam();
        break;
      default:
        break;
    }
  };
  return (
    <div className="d-flex h-100 training-container">
      <div className="abf-training-header-bar" />
      <AddTrainingMenuSection
        toggleOneShot={(): void => {
          dispatch({ type: TrainingEnum.RESET_TRAINING });
          setselectedDraft(undefined, draftEnum.oneshot);
          toggleOneShot();
        }}
        toggleCertification={(): void => {
          setselectedDraft(undefined, draftEnum.certificate);
          toggleCertificate();
        }}
        toggleCycle={(): void => {
          setselectedDraft(undefined, draftEnum.cycle);
          toggleCycle();
        }}
        toggleExam={(): void => {
          dispatch({ type: ExamEnum.RESET_EXAM });
          setselectedDraft(undefined, draftEnum.exam);
          toggleExam();
        }}
        {...{ toggleDraft, togglePublishedTrainings }}
      />
      {isOneShotOpen && (
        <AddOneShotContainer
          isOneShotEdited={isOneShotEdited}
          endDivRef={endDivRef}
          isOneShotOpen={isOneShotOpen}
          toggleOneShot={toggleOneShot}
        />
      )}
      {isCertificateOpen && (
        <AddCertificateContainer
          isCertificateEdited={isCertificateEdited}
          endDivRef={endDivRef}
          toggleCertificate={toggleCertificate}
        />
      )}
      {isCycleOpen && (
        <AddCycleContainer
          isCycleEdited={isCycleEdited}
          endDivRef={endDivRef}
          isCycleOpen={isCycleOpen}
          toggleCycle={toggleCycle}
        />
      )}
      {isExamOpen && (
        <AddExamContainer
          isExamOpen={isExamOpen}
          endDivRef={endDivRef}
          isExamEdited={isExamEdited}
          toggleExam={toggleExam}
        />
      )}
      {isDraftOpen && <AddTrainingDraftSection toggleDraft={handleDraft} />}
      {isPublishedTrainingsOpen && (
        <AddTrainingPublishedSection toggleDraft={handleDraft} />
      )}
      <div ref={endDivRef} className="ml-11" />
    </div>
  );
};
export default AddTraining;
