/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { get } from 'lodash';

type Selector = {
  label: string;
  value: string;
  transform?: Function;
};

const formatToCsv = <T extends Record<string, any>>(
  rawData: T[],
  selector: Selector[],
): string[][] => {
  const formattedData = [selector.map(el => el.label)];
  rawData.forEach((element, index) => {
    formattedData[index + 1] = selector
      .map(el => el.value)
      .map(
        (el, i) =>
          selector[i].transform?.(get(element, el)) || get(element, el),
      );
  });
  return formattedData.sort((a: any, b: any) => a[0] - b[0]);
};

export default formatToCsv;
