import { ExamNote } from 'models/ExamNote';

export const formatNoteExam = (
  examNotes: Partial<ExamNote & { examNoteId: number }>[],
  examId: number,
) =>
  examNotes.map(candidate => ({
    examId,
    candidateId: candidate.id,
    note: candidate.note,
    id: candidate.examNoteId,
  })) as { examId: number; candidateId: number; note?: number; id?: number }[];
