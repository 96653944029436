import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { UserEnum } from 'constants/types/User';
import { errorHandler } from 'helpers/errorHandler';
import { fetchCertificateUserList } from './fetchCertificateUserList';

export const addUsersToCertificate = (data: {
  users: number[];
  certificateId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);

    await api.post('/DefaultUserCertificates/many', data);
    await fetchCertificateUserList(data?.certificateId)(
      dispatch,
      getState,
      null,
    );
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UserEnum.ADD_USERS_TO_CERTIFICATE_FAILURE, error });
  }
  stopLoading()(dispatch);
};
