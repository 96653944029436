import IModule from 'models/IModule';
import React from 'react';

import { IDataTableColumn } from 'react-data-table-component';
import { isDateAfter } from 'helpers/isDateBefore';
import {
  IFormattedSessionAttendance,
  IFormattedSessionDetails,
} from 'view-models/SessionViewModel';
import { Link } from 'react-router-dom';

const columns = (
  handleDownloadFile: (
    data: IFormattedSessionDetails,
    theme: string,
    cycle: boolean,
  ) => void,
  themeOrModule?: IModule | string,
  isCycle = false,
  isExam = false,
  handlePresence?: (session: IFormattedSessionAttendance) => void,
  examId?: number,
  isDefaultUser?: boolean,
) => {
  const onHandlePresence = (session: IFormattedSessionAttendance) => {
    handlePresence?.(session);
  };

  const onDownload = (formation: IFormattedSessionDetails) => {
    if (!themeOrModule) return;

    let title: string;

    if (typeof themeOrModule === 'string') title = themeOrModule;
    else if (
      typeof themeOrModule.certificate?.theme?.title === 'string' ||
      typeof themeOrModule.semester?.theme?.title === 'string'
    )
      title = (themeOrModule.certificate?.theme.title ||
        themeOrModule.semester?.theme.title) as string;
    else return;
    handleDownloadFile(formation, title, isCycle);
  };

  const data: IDataTableColumn<IFormattedSessionAttendance>[] = [
    {
      name: 'Date',
      selector: 'date',
      maxWidth: '7vw',
      sortable: true,
    },
    {
      name: 'De',
      selector: 'from',
      maxWidth: '6vw',
      sortable: true,
    },
    {
      name: `Jusqu'à`,
      selector: 'to',
      maxWidth: '6vw',
      sortable: true,
    },
    {
      name: `${isExam ? 'Surveillant' : 'Formateur'}`,
      selector: 'trainer',
      maxWidth: '10vw',
      sortable: true,
    },
    {
      name: 'Salle',
      selector: 'room',
      maxWidth: '10vw',
      sortable: true,
    },

    {
      center: true,
      name: 'Presence en Ligne',
      cell: formation => (
        <button
          className="btn btn-block btn-primary btn-icon"
          onClick={() => onDownload(formation)}
          type="button"
        >
          <i className="fas fa-download" />
        </button>
      ),
    },
    {
      name: `Reporting Présence`,
      center: true,
      cell: session => (
        <>
          {isDateAfter(session.date) && (
            <button
              className="btn btn-block btn-primary btn-icon"
              onClick={() => {
                onHandlePresence?.(session);
              }}
              type="button"
            >
              <i className="fas fa-list " />
            </button>
          )}
        </>
      ),
    },
    {
      center: true,
      name: isExam ? 'Résultat éxamen' : '',
      cell: () => (
        <>
          {isExam && (
            <Link
              to={
                isDefaultUser ? `/CandidatsExamen/${examId}` : '/détailsExamen'
              }
              type="button"
              className={`btn btn-block btn-icon d-flex 
          align-items-center justify-content-center ${
            isDefaultUser ? 'btn-primary' : 'btn-gray'
          }`}
            >
              <i className="fa fa-link" />
            </Link>
          )}
        </>
      ),
    },
  ];
  return data;
};

export default columns;
