import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import { ModuleEnum } from 'constants/types/Module';
import IModule from 'models/IModule';

export interface ProgramState {
  error?: AxiosError | Error;
  modules?: IModule[];
  moduleWithSession?: IModule;
}

const initialState: ProgramState = {};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): ProgramState => {
  switch (type) {
    case ModuleEnum.FETCH_MODULES_SUCCESS:
      return { ...state, modules: payload };
    case ModuleEnum.ADD_MODULE_SUCCESS:
      return { ...state, modules: [...(state.modules || []), payload] };
    case ModuleEnum.FETCH_MODULES_FAILURE:
      return { ...state, error };
    case ModuleEnum.FETCH_MODULES_SESSIONS_SUCCESS:
      return { ...state, moduleWithSession: payload };
    case ModuleEnum.FETCH_MODULES_SESSIONS_FAILURE:
      return { ...state, error };

    default:
      return state;
  }
};
