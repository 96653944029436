import { Field, Validator } from 'redux-form';
import * as React from 'react';
import Select from 'components/Select';
import ISelectFieldOption from 'models/ISelectFieldOption';

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  options: ISelectFieldOption[];
  validate?: Validator[];
  isDisabled?: boolean;
  isMulti?: boolean;
  getSelectedValue?: (result: ISelectFieldOption) => void;
  isSearchable?: boolean;
}

const SelectField: React.FC<Props> = ({
  label,
  name,
  placeholder,
  options,
  validate,
  getSelectedValue,
  isDisabled,
  isMulti,
  ...props
}) => {
  return (
    <Field
      label={label}
      name={name}
      component={Select}
      placeholder={placeholder}
      aria-label={name}
      id={name}
      validate={validate}
      options={options}
      changeHandler={getSelectedValue}
      isDisabled={isDisabled}
      multi={isMulti}
      {...props}
    />
  );
};

export default SelectField;
