export enum ModuleCycleEnum {
  RESET_SEMESTER_MODULE_LIST = 'RESET_SEMESTER_MODULE_LIST',
  GET_CYCLE_MODULE_SUCCESS = 'GET_CYCLE_MODULE_SUCCESS',
  GET_CYCLE_MODULE_FAILURE = 'GET_CYCLE_MODULE_FAILURE',
  ADD_CYCLE_MODULE_SUCCESS = 'ADD_CYCLE_MODULE_SUCCESS',
  ADD_CYCLE_MODULE_FAILURE = 'ADD_CYCLE_MODULE_FAILURE',
  DELETE_CYCLE_MODULE_SUCCESS = 'DELETE_CYCLE_MODULE_SUCCESS',
  DELETE_CYCLE_MODULE_FAILURE = 'DELETE_CYCLE_MODULE_FAILURE',
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_CYCLE_MODULES_SUCCESS = 'FETCH_CYCLE_MODULES_SUCCESS',
  FETCH_CYCLE_MODULES_FAILURE = 'FETCH_CYCLE_MODULES_FAILURE',
  FETCH_CYCLE_MODULE_RELATED_SEMESTER_SUCCESS = 'FETCH_CYCLE_MODULE_RELATED_SEMESTER_SUCCESS',
  FETCH_CYCLE_MODULE_RELATED_SEMESTER_FAILURE = 'FETCH_CYCLE_MODULE_RELATED_SEMESTER_FAILURE',

  PUBLISH_CYCLE_MODULE_SUCCESS = 'PUBLISH_CYCLE_MODULE_SUCCESS',
  PUBLISH_CYCLE_MODULE_FAILURE = 'PUBLISH_CYCLE_MODULE_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  FETCH_PROGRAM_DATA_SUCCESS = 'FETCH_PROGRAM_DATA_SUCCESS',
  FETCH_PROGRAM_DATA_FAILURE = 'FETCH_PROGRAM_DATA_FAILURE',
  FETCH_UNFINISHED_CYCLE_MODULE_SUCCESS = 'FETCH_UNFINISHED_CYCLE_MODULE_SUCCESS',
  FETCH_UNFINISHED_CYCLE_MODULE_FAILURE = 'FETCH_UNFINISHED_CYCLE_MODULE_FAILURE',
  RESET_CYCLE_MODULE = 'RESET_CYCLE_MODULE',
  SELECT_CYCLE_MODULE_SUCCESS = 'SELECT_CYCLE_MODULE_SUCCESS',
  UNSELECT_CYCLE_MODULE_SUCCESS = 'UNSELECT_CYCLE_MODULE_SUCCESS',
  SET_SELECTED_CYCLE_MODULE_SUCCESS = 'SET_SELECTED_CYCLE_MODULE_SUCCESS',
  FETCH_FINISHED_CYCLE_MODULE_SUCCESS = 'FETCH_FINISHED_CYCLE_MODULE_SUCCESS',
  FETCH_FINISHED_CYCLE_MODULE_FAILURE = 'FETCH_FINISHED_CYCLE_MODULE_FAILURE',
  FETCH_USER_CYCLE_MODULE_SUCCESS = 'FETCH_USER_CYCLE_MODULE_SUCCESS',
  IMPORT_CERTIFICATE_WITH_CYCLE_MODULES_SUCCES = 'IMPORT_CERTIFICATE_WITH_CYCLE_MODULES_SUCCES',
  UPDATE_CYCLE_MODULE_SUCCESS = 'UPDATE_CYCLE_MODULE_SUCCESS',
  UPDATE_CYCLE_MODULE_FAILURE = 'UPDATE_CYCLE_MODULE_FAILURE',
  FETCH_SEMESTER_MODULE_SUCCESS = 'FETCH_SEMESTER_MODULE_SUCCESS',
  FETCH_SEMESTER_MODULE_FAILURE = 'FETCH_SEMESTER_MODULE_FAILURE',
  FETCH_CYCLE_MODULES_SESSIONS_SUCCESS = 'FETCH_CYCLE_MODULES_SESSIONS_SUCCESS',
  FECH_CYCLE_MODULES_SESSIONS_FAILURE = 'FECH_CYCLE_MODULES_SESSIONS_FAILURE',
}
