import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useTrainingView } from 'hooks/useTrainingView';
import ViewTrainingClientSection from 'containers/ViewTrainingClientSection';
import { BankInvitationForm } from 'models/Bank';
import { useDispatch, useSelector } from 'react-redux';
import { SendMails, SendMailsToAllClients, unselectTrainingId } from 'actions';
import { useEffect } from 'react';
import { getProgramFile } from 'actions/Program';
import { RootState } from 'reducers';
import { getCycleModuleSessions } from 'actions/Module/getCycleModuleSessions';
import ViewCycleModulesAboutSection from 'containers/ViewCycleModulesAboutSection';
import ViewCycleCandidateSection from 'containers/ViewCycleCandidateSection';
import ViewInviteAllClientsSection from 'containers/ViewInviteAllClientsSection';

const CycleDetails: React.FC = () => {
  const {
    toggleCandidatSection,
    isCandidatOpen,
    isAboutOpen,
    toggleAboutSection,
    isClientOpen,
    isInviteAllClientsOpen,
    toggleClientSection,
    toggleInviteAllClients,
  } = useTrainingView();
  const dispatch = useDispatch();

  const { selectedTrainingId } = useSelector(
    (state: RootState) => state.training,
  );
  useEffect(() => {
    if (!selectedTrainingId) return;
    dispatch(getCycleModuleSessions(selectedTrainingId));
  }, [selectedTrainingId]);

  useEffect(() => {
    return () => {
      dispatch(unselectTrainingId());
    };
  }, []);

  const { moduleWithSession } = useSelector(
    (state: RootState) => state.cycleModule,
  );

  const downloadProgramFile = () => {
    if (!moduleWithSession?.title || !moduleWithSession) return;
    dispatch(getProgramFile(moduleWithSession.title));
  };

  if (selectedTrainingId === null) return <Redirect to="/formation" />;

  const handleInvitation = (values: BankInvitationForm[]) => {
    dispatch(SendMails(values));
  };
  const handleInvitationToAllClients = (
    values: Partial<BankInvitationForm>,
  ) => {
    dispatch(SendMailsToAllClients(values));
  };

  return (
    <>
      <div className="bg-primary mb-2">
        <div className="space-1 space-top-lg-1 space-bottom-lg-2 mx-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 mb-0 text-white">
                  {`Formation : ${moduleWithSession?.semester?.cycle?.title}`}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-3">
          <div className="card mt-11">
            <div className="card-body">
              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleAboutSection}
              >
                Informations générales
              </button>
              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleClientSection}
              >
                Inviter clients
              </button>
              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleInviteAllClients}
              >
                Inviter Tous les Clients et Contacts
              </button>
              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleCandidatSection}
              >
                Ajouter des candidats
              </button>
            </div>
          </div>
        </div>
        {isCandidatOpen && moduleWithSession?.semester?.cycle?.id && (
          <ViewCycleCandidateSection
            cycleId={moduleWithSession?.semester?.cycle?.id}
          />
        )}
        {isAboutOpen && (
          <ViewCycleModulesAboutSection
            moduleWithSessions={moduleWithSession}
            downloadProgramFile={downloadProgramFile}
          />
        )}
        {isClientOpen && (
          <ViewTrainingClientSection
            trainingId={moduleWithSession?.id}
            onSubmit={handleInvitation}
          />
        )}
        {isInviteAllClientsOpen && (
          <ViewInviteAllClientsSection
            onSubmit={handleInvitationToAllClients}
          />
        )}
      </div>
    </>
  );
};
export default CycleDetails;
