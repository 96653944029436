import { FieldArray, WrappedFieldArrayProps } from 'redux-form';
import InputField from 'components/InputField';
import * as React from 'react';
import Validators from 'helpers/formValidator';

const renderMembers: React.FC<WrappedFieldArrayProps> = ({
  fields,
  meta: { error, submitFailed },
}) => (
  <>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member, index) => (
      <div key={index.toString()} className="space-top-2 d-flex flex-column">
        <div className="align-self-end">
          <button
            type="button"
            className="btn btn-sm btn-soft-dark"
            onClick={(): void => fields.remove(index)}
          >
            Supprimer
          </button>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <InputField
              name={`${member}.firstName`}
              label="Nom"
              validate={[Validators.required, Validators.string]}
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name={`${member}.lastName`}
              label="Prénom"
              validate={[Validators.required, Validators.string]}
            />
          </div>
          <div className="col-lg-4">
            <InputField name={`${member}.job`} label="Statut professionnel" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <InputField
              name={`${member}.email`}
              label="Email 1"
              validate={[Validators.required, Validators.email]}
            />
          </div>
          <div className="col-lg-4">
            <InputField name={`${member}.emailtwo`} label="Email 2" />
          </div>
          <div className="col-lg-4">
            <InputField name={`${member}.emailthree`} label="Email 3" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <InputField
              name={`${member}.phoneNumber`}
              validate={[Validators.required, Validators.phone]}
              label="Numéro de téléphone 1"
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name={`${member}.phoneNumbertwo`}
              label="Numéro de téléphone 2"
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name={`${member}.phoneNumberthree`}
              label="Numéro de téléphone 3"
            />
          </div>
        </div>
      </div>
    ))}
    <button
      type="button"
      className="btn btn-wide btn-soft-primary my-4 align-self-center"
      onClick={(): void => fields.push({})}
    >
      Ajouter nouveau contact
    </button>
  </>
);

const FieldArraysForm: React.FC = () => {
  return (
    <FieldArray
      props={renderMembers}
      name="contact"
      component={renderMembers}
    />
  );
};

export default FieldArraysForm;
