import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const FetchCertificateInformations = (
  id: number | string,
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`certificates/certificate-details/${id}`);
    dispatch({
      type: CertificateEnum.FETCH_CERTIFICATE_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: CertificateEnum.FETCH_CERTIFICATE_DETAILS_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
