import Autocomplete, {
  createFilterOptions,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import Selector from 'models/Selector';
import { WrappedFieldProps } from 'redux-form';

const filter = createFilterOptions();

export interface Props {
  suggestions: Selector[];
  label?: string;
  disabled?: boolean;
  importedValue?: number;
}

const AutoComplete: React.FC<Props & WrappedFieldProps> = ({
  suggestions,
  input,
  label,
  importedValue,
}) => {
  const getSelectedValue = (initialValue: number): Selector | undefined => {
    return suggestions.find(el => el.id === initialValue);
  };
  const [value, setValue] = useState<Selector | undefined | null>();
  const [postNew, setPostNew] = useState(false);
  const [isInitialValueSetUp, setisInitialValueSetUp] = useState(false);
  const importedResult = suggestions.find(sug => sug.id === importedValue);

  useEffect(() => {
    if (!importedValue || !importedResult) return;
    setValue({ title: importedResult.title, id: importedResult.id });
    input.onChange(importedValue);
  }, [importedValue, importedResult]);

  useEffect(() => {
    if (isInitialValueSetUp) return;
    const initialValue = getSelectedValue(input.value);

    setValue(initialValue);
    setisInitialValueSetUp(true);
  }, [suggestions]);

  useEffect(() => {
    if (!value) return;
    input.onChange(postNew ? value?.title : value?.id);
  }, [value]);

  const handleChange = (newValue: string, action: string): void => {
    setValue({
      title: newValue,
      id:
        action === 'input'
          ? -1
          : suggestions.find(el => el.title === newValue)?.id || -1,
    });
    if (action === 'input') setPostNew(true);
    else setPostNew(false);
  };

  if (!isInitialValueSetUp) return <div />;

  return (
    <Autocomplete
      value={value || ''}
      filterOptions={(options, params): Selector[] => {
        const filtered = filter(
          options,
          params as FilterOptionsState<unknown>,
        ) as Selector[];
        filtered.push({ title: params.inputValue, id: 0 });
        return filtered;
      }}
      id={input.name}
      options={suggestions}
      getOptionLabel={(option): string => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.title) {
          return option.title;
        }
        return option.title;
      }}
      renderOption={(option: Selector): string => option.title}
      freeSolo
      onInputChange={(_, newValue, action) => handleChange(newValue, action)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <div className="form-group" ref={params.InputProps.ref}>
          <label className="form-label" htmlFor={input.name}>
            <span className="d-flex justify-content-between align-items-center">
              {label}
            </span>
          </label>
          <input {...params.inputProps} className="form-control" type="text" />
        </div>
      )}
    />
  );
};
export default AutoComplete;
