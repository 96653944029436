import * as React from 'react';
import Trainer from 'models/Trainer';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import { useEffect } from 'react';
import columns from './columns';

interface Props {
  trainers: Trainer[];
  handleDisable: (id: number) => void;
  HandleUpdate: (trainer: Trainer) => void;
}

const TrainerTable: React.FC<Props> = ({
  trainers,
  handleDisable,
  HandleUpdate,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedTrainers, setSortedTrainers] = React.useState<Trainer[]>([]);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedTrainers(trainers);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'lastName',
        'firstName',
        'phoneNumber',
        'email',
        'CIN',
        'BadgeNumber',
      ],
    };
    const fuse = new Fuse(trainers, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedTrainers(results);
  }, [keyword, trainers]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  const cols = columns(HandleUpdate, handleDisable);

  return (
    <DataTable
      data={sortedTrainers}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default TrainerTable;
