import * as React from 'react';
import IProgramData from 'models/IProgramData';
import RenderProgramDay from 'components/RenderProgramDay';
import ProgramDays from 'models/ProgramDays';

interface IProps {
  data: IProgramData;
  programDays?: ProgramDays[];
}

const RenderProgramDays: React.FC<IProps> = ({ data, programDays }) => {
  const programWithImportedData = Object.keys(data.days).map(el => ({
    ...programDays?.[Object.keys(data.days).indexOf(el)],
    duration: data.days[el],
    date: el,
  })) as {
    duration: number;
    date: string;
    content?: string;
    methods?: string;
  }[];

  return (
    <>
      {programWithImportedData.length &&
        programWithImportedData.map((program, index) => (
          <RenderProgramDay
            day={program.date}
            duration={program.duration.toString()}
            content={program.content}
            methods={program.methods}
            key={index.toString() + program.date}
          />
        ))}
    </>
  );
};

export default RenderProgramDays;
