import { AddTrainerContainer } from 'containers';
import * as React from 'react';
import { RootState } from 'reducers';
import { TrainerToAdd } from 'models/Trainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UpdateTrainer as updateTrainerAction } from 'actions';

const UpdateTrainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedtrainer } = useSelector((state: RootState) => state.trainer);
  const handleUpdate = async (data: TrainerToAdd): Promise<void> => {
    await dispatch(updateTrainerAction(data.id, data));

    history.goBack();
  };

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Formateurs</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier un formateur
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-body">
            <AddTrainerContainer
              initialValues={selectedtrainer}
              onSubmit={handleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTrainer;
