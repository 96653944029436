import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { isAxiosError } from 'constants/types/typesGuard/axiosTypeGuard';

export const errorHandler = (error: AxiosError | Error): void => {
  if (isAxiosError(error)) {
    toast(error?.response?.data?.error?.message || error.message, {
      type: 'error',
    });
  } else {
    toast(error?.message, { type: 'error' });
  }
};
