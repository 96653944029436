/* eslint-disable max-lines */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { IAccountingNotes, IClient } from 'models/IAccountingNotes';
import moment from 'moment';

interface Props {
  refToPrint: React.RefObject<HTMLDivElement>;
}

const AccountingNotesDetails: React.FC<Props> = ({ refToPrint }) => {
  const { accountingNotes } = useSelector((state: RootState) => state.training);
  const totalDates = (accNotes: IAccountingNotes) => {
    let sum = 0;
    accNotes?.modules?.forEach(module => {
      sum += module.dates.length;
    });
    return sum;
  };
  const renderSessionDates = (data?: Date[]) => {
    return (
      data && (
        <div className="flex-column">
          {data.map(d => {
            return (
              <p
                key={`date-${d.toDateString}`}
                className="font-weight-light font-italic mr-2"
              >
                {`${moment(d).format('L')} `}
              </p>
            );
          })}
        </div>
      )
    );
  };
  const renderClients = (data?: IClient[]) => {
    return data?.map(cli => {
      const { candidates } = cli;
      return (
        <div key={cli.id}>
          <h4 className="font-weight-light font-italic row">
            {`${cli.bankName} (${cli.nbrCandidates} candidat), `}
          </h4>
          <div className="row">
            <table className="table" width="100%">
              <thead>
                <tr>
                  <th scope="col">CIN</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Prenom</th>
                  <th scope="col">email</th>
                  <th scope="col">Numero Telephone</th>
                  <th scope="col">Assiduité</th>
                </tr>
              </thead>
              <tbody>
                {candidates?.map(candidate => {
                  return (
                    <tr key={candidate.id}>
                      <th scope="row">{candidate.CIN}</th>
                      <td>{candidate.firstName}</td>
                      <td>{candidate.lastName}</td>
                      <td>{candidate.email}</td>
                      <td>{candidate.phoneNumber}</td>
                      <td>{candidate.attendanceDays}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    });
  };

  return (
    <div ref={refToPrint} className="container ">
      {typeof accountingNotes === 'string' ? (
        <div className="card-body" style={{ margin: '0px' }}>
          <h3>{`${accountingNotes}`}</h3>
        </div>
      ) : (
        accountingNotes?.title && (
          <div className="card-body" style={{ margin: '0px' }}>
            <div className="card">
              <div
                className="card-header"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                <h4
                  className="card-header-title text-center"
                  style={{ marginRight: 0 }}
                >
                  {`Formation: ${accountingNotes?.title}`}
                </h4>
                <span>{`Fiche Générée le ${moment().format('L')}`}</span>
              </div>
              <div className="card-body row">
                <div className="container col-9 space-1 d-flex flex-column ">
                  <div className="d-flex justify-content-between mt-2">
                    <h5>{`Type de La Formation : `}</h5>
                    <p className="font-weight-bold font-italic">
                      {accountingNotes?.type}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <h5>{`Nombre de jours : `}</h5>
                    <p className="font-weight-bold font-italic">
                      {`${accountingNotes?.nbrDays ||
                        (accountingNotes && totalDates(accountingNotes))} `}
                    </p>
                  </div>
                  {accountingNotes?.type === 'formation continue' ? (
                    <>
                      <div className="d-flex justify-content-between mt-2">
                        <h5>{`Dates Associées : `}</h5>
                        <div className="row">
                          {renderSessionDates(accountingNotes?.dates)}
                        </div>
                      </div>
                      <h5>{`List des Clients : `}</h5>
                      <div>{renderClients(accountingNotes?.client)}</div>
                    </>
                  ) : (
                    <>
                      {accountingNotes?.modules &&
                        accountingNotes.modules.map(module => {
                          return (
                            <div key={module.id}>
                              <div className="row border-top pt-2 mt-0">
                                <h2>{`Nom Du Module : ${module.title}`}</h2>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <h5>{`Nombre de Jours : `}</h5>
                                <p className="font-weight-light font-italic">
                                  {`${module?.nbrDays || 0} jours `}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                {renderSessionDates(module.dates)}
                              </div>
                              <h5>{`List des Clients : `}</h5>
                              <div>{renderClients(module.client)}</div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default AccountingNotesDetails;
