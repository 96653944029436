import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { UserEnum } from 'constants/types/User';
import { errorHandler } from 'helpers/errorHandler';
import { fetchCertificateUserList } from './fetchCertificateUserList';

export const DeleteUserFromCertificate = (
  userId: number,
  certificateId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(
      `/DefaultUsers/${userId}/certificates/rel/${certificateId}`,
    );
    await fetchCertificateUserList(certificateId)(dispatch, getState, null);
    dispatch({
      type: UserEnum.DELETE_USER_FROM_CERTIFICATE_SUCCESS,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UserEnum.DELETE_USER_FROM_CERTIFICATE_FAILURE, error });
  }
  stopLoading()(dispatch);
};
