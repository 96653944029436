import * as React from 'react';
// import { MAIN_SIDEBAR } from 'constants/Sidebars';
import { connect } from 'react-redux';
import { toggleSidebar as toggleSidebarAction } from 'actions/Sidebar';

interface PropsInterface {
  toggleSidebar: Function;
}

// { toggleSidebar }

const HomePage: React.FC<PropsInterface> = () => {
  // const toggleMainSidebar = (): void => {
  //   toggleSidebar(MAIN_SIDEBAR);
  // };

  return (
    <>
      {/* <button
        type="button"
        className="btn btn-primary"
        onClick={toggleMainSidebar}
      >
        {' '}
        ...
      </button> */}
    </>
  );
};

export default connect(null, { toggleSidebar: toggleSidebarAction })(HomePage);
