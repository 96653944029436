import * as React from 'react';
import { IGroupedTrainings } from 'models/IGroupedTrainings';
import { Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

const columns = (
  handleChangeRegle: (
    training: IGroupedTrainings,
    isTrainersSettled: boolean,
  ) => void,
) => {
  const headerCols = [
    {
      name: 'Titre',
      selector: 'title',
      center: true,
      sortable: true,
    },
    {
      name: 'Cycle',
      selector: 'cycleTitle',
      center: true,
      sortable: true,
    },
    {
      name: 'Date creation',
      selector: (d: IGroupedTrainings) => {
        return moment(d.createdAt).format('LLL');
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (e: IGroupedTrainings) =>
        e.type === 'Cycle' ? <>Cycle / Semester</> : <>{e.type}</>,
      center: true,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (d: IGroupedTrainings) => {
        return d.isFinished ? 'publiées' : 'non publiées';
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Règle Paiement',
      selector: 'isSettled',
      cell: (d: IGroupedTrainings) => {
        return (
          <Chip
            key={d.id}
            label={d.isSettled ? <b>réglé</b> : <b>non réglé</b>}
            onClick={(): void => {
              handleChangeRegle(d, false);
            }}
            style={{
              backgroundColor: d.isSettled
                ? 'rgba(0, 214, 143, 0.16)'
                : 'rgba(255, 61, 113, 0.24)',
            }}
          />
        );
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Règle Formateur',
      selector: 'isTrainersSettled',
      cell: (d: IGroupedTrainings) => {
        return (
          <Chip
            key={d.id}
            label={d.isTrainersSettled ? <b>réglé</b> : <b>non réglé</b>}
            onClick={(): void => {
              handleChangeRegle(d, true);
            }}
            style={{
              backgroundColor: d.isTrainersSettled
                ? 'rgba(0, 214, 143, 0.16)'
                : 'rgba(255, 61, 113, 0.24)',
            }}
          />
        );
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Paiement',
      selector: 'isTrainersSettled',
      cell: (d: IGroupedTrainings) => {
        return (
          <Link
            key={d.id}
            className="col-sm p-1 d-flex justify-content-center align-items-center"
            to={`/détailsPaiement/${d.type}/${d.id}`}
          >
            <div className="btn btn-sm-wide btn-primary btn">Voir</div>
          </Link>
        );
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Note de comptabilité',
      cell: (d: IGroupedTrainings) => {
        return (
          <Link
            key={d.id}
            className="col-sm p-1 d-flex justify-content-center align-items-center"
            to={`/noteComptabilite/${d.type}/${d.id}`}
          >
            <div className="btn btn-sm-wide btn-primary btn">Voir</div>
          </Link>
        );
      },
      center: true,
      sortable: true,
    },
  ];
  return headerCols;
};
export default columns;
