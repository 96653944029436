import Legend from 'components/Legend';
import { TrainingTypeCountData } from 'models/Stats';
import React, { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import StatCard from './StatCard';

const DaysPerTrainingType = ({
  certificate,
  cycle,
  training,
  exportCSVButton,
}: TrainingTypeCountData) => {
  const pieChartData = useMemo<DataEntry[]>(
    () => [
      { title: 'Formation Continue', value: training, color: '#247275' },
      { title: 'Cértificat', value: certificate, color: '#000000' },
      { title: 'Cycle', value: cycle, color: '#fd7e14' },
    ],
    [certificate, cycle, training],
  );

  const legendData = useMemo(
    () => pieChartData.map(el => ({ color: el.color, label: el.title })),
    [pieChartData],
  );

  return (
    <StatCard
      exportCSVButton={exportCSVButton}
      title="Jours homme par type de formation"
    >
      <div className="d-flex justify-content-center">
        <PieChart
          className="my-3 w-50"
          data={pieChartData}
          lineWidth={20}
          paddingAngle={18}
          rounded
          animate
          label={({ dataEntry: { value } }) => value}
          labelStyle={index => ({
            fill: pieChartData[index].color,
            fontSize: '5px',
            fontFamily: 'Poppins',
          })}
          labelPosition={60}
        />
      </div>
      <Legend data={legendData} />
    </StatCard>
  );
};

export default DaysPerTrainingType;
