import _ from 'lodash';

import IProgramDetails from 'models/IProgramDetails';
import { ICycleModuleElement } from 'models/ISemesterDetails';

export const filterTrainers = (
  module:
    | {
        mdTitle: string;
        nmbHours: number;
        nmbDays: number;
        dates: string[];
        hours: { trainer: string; hours: number }[];
      }
    | ICycleModuleElement,
) => {
  const trainersHours = [];
  if (module !== undefined) {
    const trainerAndHours = module?.hours;
    for (let i = 0; i < trainerAndHours.length; i++) {
      for (let j = 0; j < trainerAndHours.length; j++) {
        if (
          trainerAndHours[j].trainer === trainerAndHours[i].trainer &&
          i !== j
        ) {
          trainerAndHours[i].hours += trainerAndHours[j].hours;
        }
      }
      const tr = trainerAndHours[i].trainer;
      const hr = trainerAndHours[i].hours;
      const element = {
        tr,
        hr,
      };

      trainersHours.push(element);
    }
  }
  const filteredtrainerArray = _.uniqBy(trainersHours, 'tr');
  return filteredtrainerArray;
};

export const filterTrainingTrainers = (trainingDetails: IProgramDetails) => {
  const trainersHours = [];
  if (trainingDetails !== undefined) {
    const trainerAndHours = trainingDetails?.hours;
    for (let i = 0; i < trainerAndHours.length; i++) {
      for (let j = 0; j < trainerAndHours.length; j++) {
        if (
          trainerAndHours[j].trainer === trainerAndHours[i].trainer &&
          i !== j
        ) {
          trainerAndHours[i].hours += trainerAndHours[j].hours;
        }
      }
      const tr = trainerAndHours[i].trainer;
      const hr = trainerAndHours[i].hours;
      const element = {
        tr,
        hr,
      };

      trainersHours.push(element);
    }
  }
  const filteredTrainingTrainerArray = _.uniqBy(trainersHours, 'tr');
  return filteredTrainingTrainerArray;
};
