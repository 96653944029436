/* eslint-disable max-lines */
import { resetProgram, startLoading, stopLoading } from 'actions';
import api from 'api';
import { TrainingEnum } from 'constants/types/Training';
import { IThunkAction } from 'constants/types/IThunkAction';
import Training from 'models/Training';
import { errorHandler } from 'helpers/errorHandler';
import { IDefaultUserTraining } from 'models/DefaultUserTraining';
import { toast } from 'react-toastify';
import { UserEnum } from 'constants/types/User';
import { AxiosRequestConfig } from 'axios';
import { IFormationResponse } from 'constants/types/IFormationResponse';
// Get Themes
export const getThemes = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('themes');
    dispatch({
      type: TrainingEnum.FETCH_THEMES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: TrainingEnum.FETCH_THEMES_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

// Fetch Category
export const getCategories = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('categories');
    dispatch({
      type: TrainingEnum.FETCH_CATEGORIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: TrainingEnum.FETCH_CATEGORIES_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
export const getTraining = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`trainings/${id}`, {
      params: {
        filter: {
          include: [
            'session',
            'theme',
            'category',
            { candidate: ['job', 'bank'] },
          ],
        },
      },
    });
    dispatch({
      type: TrainingEnum.GET_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.GET_TRAINING_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
// Fetch unfinished training
export const getUnfinishedTrainings = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      'trainings/user-trainings?filter={"where":{"isFinished":false}, "include" : "defaultUser"}',
    );
    dispatch({
      type: TrainingEnum.FETCH_UNFINISHED_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.FETCH_UNFINISHED_TRAINING_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
export const getFinishedTrainings = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      'trainings/user-trainings?filter={"where":{"isFinished":true}, "include" : "defaultUser"}',
    );
    dispatch({
      type: TrainingEnum.FETCH_FINISHED_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.FETCH_FINISHED_TRAINING_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
// create new training with new theme and new category if they don't existe
export const addTraining = (training: Training): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/trainings', training);
    dispatch({
      type: TrainingEnum.ADD_TRAINING_SUCCESS,
      payload: response.data,
    });
    dispatch(getThemes());
    dispatch(getCategories());
    dispatch(resetProgram());
    toast('Formation Continue Sauvegardée', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: TrainingEnum.ADD_TRAINING_FAILURE, error });
    errorHandler(error);
  }
};
export const publishTraining = (trainingId?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.patch('/trainings', {
      id: trainingId,
      isFinished: true,
    });
    dispatch({
      type: TrainingEnum.PUBLISH_TRAINING_SUCCESS,
      payload: response.data,
    });
    toast('formation continue publié', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: TrainingEnum.PUBLISH_TRAINING_FAILURE, error });
    errorHandler(error);
  }
};
// get the program relate to training oneshot
export const getProgramData = (id?: number): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`trainings/${id}/program-data`);
    dispatch({
      type: TrainingEnum.FETCH_PROGRAM_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.FETCH_PROGRAM_DATA_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
// select draft (edit training)
export const setSelectedTraining = (training?: Training): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: TrainingEnum.SET_SELECTED_TRAINING_SUCCESS,
    payload: training,
  });
};

// select training view
export const selectTrainingId = (id?: number): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: TrainingEnum.SELECT_TRAINING_SUCCESS,
    payload: id,
  });
};
// unselect training with id
export const unselectTrainingId = (): IThunkAction => (dispatch): void => {
  dispatch({
    type: TrainingEnum.UNSELECT_TRAINING_SUCCESS,
  });
};
// reset a training
export const resetTraining = (): IThunkAction => (dispatch): void => {
  dispatch({
    type: TrainingEnum.RESET_TRAINING,
  });
};
// fetch training by user Id
export const fetchUserTrainings = (): IThunkAction => async (
  dispatch,
  getState,
) => {
  try {
    startLoading()(dispatch);

    const { currentUser } = getState().user;

    if (!currentUser) throw new Error('No token');
    const { id: userId } = currentUser;

    const response = await api.get<IDefaultUserTraining[]>(
      `/defaultUserTrainings?filter={"where":{"defaultUserId":${userId}}}`,
    );

    dispatch({
      type: TrainingEnum.FETCH_USER_TRAININGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const fetchTrainingUserList = (
  trainingId: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`/Trainings/${trainingId}/defaultUser`);
    dispatch({
      type: UserEnum.FETCH_USERS_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: UserEnum.FETCH_USERS_TRAINING_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
// get All training ,certification ,semesters
export const getAllTraining = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get<IFormationResponse>(
      'trainings/all-trainings?filter={"order":"createdAt DESC"}',
    );
    
    dispatch({
      type: TrainingEnum.FETCH_ALL_TRAINING_SUCCESS,
      payload: response.data.formation,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.FETCH_ALL_TRAINING_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

// update Regulation(reglement de la formation)

export const updateTrainingRegulation = (data: {
  id?: number;
  isSettled?: boolean;
  isTrainersSettled?: boolean;
  type?: string;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const config: AxiosRequestConfig = {
      headers: api.defaults.headers,
    };
    const response = await api.patch(
      'trainings/update-training-regle',
      data,
      config,
    );
    dispatch({
      type: TrainingEnum.UPDATE_TRAINING_REGULATION_SUCCESS,
      payload: response.data,
    });
    getAllTraining()(dispatch, getState, null);
    toast('Reglement sauvegardé', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: TrainingEnum.UPDATE_TRAINING_REGULATION_FAILURE, error });
    errorHandler(error);
  }
};

export const FetchTrainingInformations = (
  id: number | string,
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`trainings/training-details/${id}`);
    dispatch({
      type: TrainingEnum.FETCH_TRAINING_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TrainingEnum.FETCH_TRAINING_DETAILS_FAILURE,
      error,
    });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
const checkFetchAccountingType = (type: string) => {
  if (type === 'Formation Continue') {
    return 'trainings';
  }
  if (type === 'Certification') {
    return 'certificates';
  }
  if (type === 'Cycle') {
    return 'semesters';
  }
  return null;
};

export const fetchAccountingNotes = (
  id: string,
  type: string,
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const config: AxiosRequestConfig = {
      headers: api.defaults.headers,
    };
    const response = await api.get(
      `${checkFetchAccountingType(type)}/accounting-notes/${id}`,
      config,
    );

    dispatch({
      type: TrainingEnum.FETCH_ACCOUNTING_NOTES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: TrainingEnum.FETCH_ACCOUNTING_NOTES_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
