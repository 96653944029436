import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessions } from 'actions/Sessions';
import { RootState } from 'reducers';
import CalendarCard from 'components/CalendarCard';
import DatePickerComponent from 'components/DatePickerComponent';
import moment, { Moment } from 'moment';
import TrainingSidebar from 'components/TrainingSidebar';
import formatData from './formatData';

interface IProps {
  toggleSidebar: (date: Moment | null) => void;
  toggleCalendarBar?: () => void;
}

const AddTrainingCalendarSection: React.FC<IProps> = ({
  toggleSidebar,
  toggleCalendarBar,
}) => {
  const dispatch = useDispatch();
  const { SessionList } = useSelector((state: RootState) => state.session);

  useEffect(() => {
    dispatch(getSessions());
  }, []);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const handleDateChange = (newDate: Moment | null): void => {
    if (newDate) setSelectedDate(newDate);
  };

  const [formattedData, setformattedData] = useState<
    {
      list: {
        name: string;
        time: string;
        room: string;
        courseNumber: number;
        isExam: boolean;
      }[];
      day: moment.Moment | null;
    }[]
  >();
  useEffect(() => {
    const data = [
      { list: formatData(SessionList, selectedDate), day: selectedDate },
      {
        list: formatData(SessionList, moment(selectedDate)?.add(1, 'day')),
        day: moment(selectedDate)?.add(1, 'day'),
      },
      {
        list: formatData(SessionList, moment(selectedDate)?.add(2, 'day')),
        day: moment(selectedDate)?.add(2, 'day'),
      },
    ];
    setformattedData(data);
  }, [selectedDate, SessionList]);

  return (
    <div className="col-8 p-0">
      <TrainingSidebar
        onClose={() => toggleCalendarBar?.()}
        header="Calendrier des prochaines séances"
        className="p-4"
        footer={(
          <div className="row w-100">
            {formattedData?.map(el => (
              <div
                key={`${el.day}`}
                className="col-sm p-1 d-flex justify-content-center align-items-center"
              >
                <button
                  type="submit"
                  className="btn btn-sm-wide btn-primary btn"
                  onClick={(): void => toggleSidebar(el.day)}
                >
                  Créer
                </button>
              </div>
            ))}
          </div>
        )}
      >
        <div className="container">
          <DatePickerComponent
            setDate={handleDateChange}
            value={selectedDate}
            step={1}
            withoutPrev
          />
          <div className="row">
            {formattedData &&
              formattedData.map((el, index) => (
                <div
                  className="col-sm p-1 d-flex flex-column 
              align-items-center justify-content-between"
                  key={index.toString() + el.day?.toString()}
                >
                  <div
                    className="w-100 mt-1 mb-2"
                    style={{ height: '50vh', maxHeight: '50vh' }}
                  >
                    <div
                      className="shadow rounded text-center mx-auto"
                      style={{ backgroundColor: 'lightgrey' }}
                    >
                      <h3
                        className="h6 text-center text-primary 
                      mx-auto font-weight-semi-bold mb-1"
                      >
                        {el.day?.format('L')}
                      </h3>
                    </div>
                    {el.list.map(session => (
                      <div key={session.name + session.time}>
                        <CalendarCard data={session} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </TrainingSidebar>
    </div>
  );
};
export default AddTrainingCalendarSection;
