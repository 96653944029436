import { IReduxAction } from 'constants/types/IReduxAction';
import { StatTypes } from 'constants/types/Stats';
import {
  TrainingTypeCountData,
  IClassroomPercentages,
  CandidatesStatData,
  IClassroomDaysData,
  ITrainerTrainingsData,
  BreaksPerBreakTypeData,
  IDaysPerTrainerData,
  IDaysPerDefaultUserData,
} from 'models/Stats';
import moment, { Moment } from 'moment';

export interface IStatState {
  startDate: Moment;
  endDate: Moment;
  stats: {
    candidatesPerTraining?: TrainingTypeCountData;
    classroomPercentages?: IClassroomPercentages;
    topOneshots?: CandidatesStatData[];
    topCertificates?: CandidatesStatData[];
    candidatesPerClient?: CandidatesStatData[];
    daysBookedPerClassroom?: IClassroomDaysData[];
    trainingsPerTrainer?: ITrainerTrainingsData[];
    breaksPerBreakType?: BreaksPerBreakTypeData[];
    daysPerTrainer?: IDaysPerTrainerData[];
    daysPerTrainingType?: TrainingTypeCountData;
    daysPerDefaultUser?: IDaysPerDefaultUserData[];
  };
}

const initialState: IStatState = {
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
  stats: {},
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IStatState => {
  switch (type) {
    case StatTypes.SET_START_DATE:
      return { ...state, startDate: payload };
    case StatTypes.SET_END_DATE:
      return { ...state, endDate: payload };
    case StatTypes.FETCH_STATS:
      return { ...state, stats: payload };

    default:
      return state;
  }
};
