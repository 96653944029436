/* eslint-disable max-lines */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
import Cycle from 'models/Cycle';
import CycleTable from 'components/CycleTable';
import { getAllCycles } from 'actions/Cycle/getAllCycles';
import Modal from 'components/Modal';
import CycleViewModel from 'view-models/CycleViewModel';
import { getCycleSemesters } from 'actions/Cycle/getCycleSemesters';
import SemesterCard from 'components/SemesterCard';
import { deleteSemester } from 'actions/Semester/deleteSemester';
import { importCycle } from 'actions/Cycle/importCycle';
import Semester from 'models/Semester';
import { CycleEnum } from 'constants/types/cycle';
import { SemesterEnum } from 'constants/types/semester';

interface Props {
  toggleSemester?: () => void;
  setisSaved: (isSaved: boolean) => void;
  selectSemester?: () => void;
  toggleUserBar?: () => void;
  toggleCycle?: () => void;
  isSaved: boolean;
}
const AddCycleSection: React.FC<Props & InjectedFormProps<Cycle, Props>> = ({
  toggleSemester,
  toggleCycle,
  setisSaved,
  toggleUserBar,
  handleSubmit,
  selectSemester,
  isSaved,
}) => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const dispatch = useDispatch();
  const { cycleSemesters, newCycle } = useSelector(
    (state: RootState) => state.cycle,
  );
  const { newSemester } = useSelector((state: RootState) => state.semester);
  const { Cycles } = useSelector((state: RootState) => state.cycles);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState<CycleViewModel>();
  const importCertif = (id?: number) => {
    const findedCycle = Cycles?.find(cycle => cycle.id === id);
    delete findedCycle?.id;
    if (findedCycle) {
      const filtredModules: Partial<Semester>[] = findedCycle?.semester.map(
        semester => ({
          title: semester.title,
          themeId: semester.themeId,
          categoryId: semester.categoryId,
          cycleModule:
            semester &&
            semester?.cycleModule?.map(module => ({
              title: module.title,
              content: module.content,
              methods: module.methods,
            })),
        }),
      );
      setSelectedCycle(findedCycle);
      dispatch(
        importCycle({
          ...findedCycle,
          id: newCycle?.id,
          semester: filtredModules as Semester[],
        }),
      );
      setisSaved(true);
      setModalOpen(false);
    }
  };
  useEffect(() => {
    dispatch(getAllCycles());

    if (!selectedCycle) {
      dispatch({ type: CycleEnum.RESET_CYCLE_SEMESTER });
    }
  }, []);
  useEffect(() => {
    dispatch(change('addCycleForm', 'title', selectedCycle?.title));
    dispatch(change('addCycleForm', 'year', selectedCycle?.year));
  }, [selectedCycle]);

  useEffect(() => {
    if (!newSemester) return;
    const CycleId = newCycle?.id || selectedCycle?.id;

    CycleId && dispatch(getCycleSemesters(CycleId));
  }, [newSemester]);
  const handleDeleteSemester = (value: number): void => {
    if (!newCycle?.id) return;
    dispatch(deleteSemester(value, newCycle.id));
    if (value === newSemester?.id)
      dispatch({ type: SemesterEnum.RESET_SEMESTER });
  };

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Ancient Cycles"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        {Cycles?.length && (
          <CycleTable handleImport={importCertif} cycles={Cycles} />
        )}
      </Modal>
      <TrainingSidebar
        header="Cycle"
        className="p-4"
        onClose={() => toggleCycle?.()}
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="Nom du cycle"
              validate={[Validators.required]}
            />
            <InputField
              name="year"
              type="number"
              label="L'année du cycle"
              validate={[Validators.required]}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleSemester}
              disabled={!isSaved}
            >
              Créer les semesters
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={() => setModalOpen(true)}
            >
              Importer un cycle
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            {cycleSemesters?.map(semester => {
              return (
                <div key={module.id}>
                  <SemesterCard
                    semester={semester}
                    toggleSemester={selectSemester}
                    deleteSemester={handleDeleteSemester}
                    setisSaved={setisSaved}
                  />
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleUserBar}
              disabled={!isSaved}
            >
              Ajouter des organisateurs
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<Cycle, Props>({
  form: 'addCycleForm',
  enableReinitialize: true,
})(AddCycleSection);
