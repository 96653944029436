import { getCvUrl } from 'helpers/getCvUrl';
import ConfirmationPopUp from 'components/ConfirmationPopUp';
import Trainer from 'models/Trainer';
import * as React from 'react';
import { Link } from 'react-router-dom';

const columns = (
  HandleUpdate: (trainer: Trainer) => void,
  handleDisable: (id: number) => void,
) => {
  const data = [
    {
      name: 'Nom',
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'Prénom',
      selector: 'firstName',
      sortable: true,
    },
    {
      name: 'Téléphone',
      selector: 'phoneNumber',
      sortable: true,
    },
    {
      name: 'E-mail',
      sortable: true,
      selector: 'email',
    },
    {
      name: 'CIN',
      selector: 'CIN',
      sortable: true,
    },
    {
      name: 'Numéro de badge',
      selector: 'BadgeNumber',
      sortable: true,
    },
    {
      center: true,
      name: 'CV',
      maxWidth: '8vw',
      cell: (trainer: Trainer) => (
        <>
          {trainer.CVUrl ? (
            <a
              href={getCvUrl(trainer.CVUrl)}
              download
              type="button"
              className="btn btn-block btn-primary btn-icon d-flex 
            align-items-center justify-content-center"
            >
              <i className="fab fa-readme" />
            </a>
          ) : (
            <div
              className="btn btn-block btn-warning btn-icon d-flex 
          align-items-center justify-content-center"
            >
              <i className="far fa-question-circle" />
            </div>
          )}
        </>
      ),
    },
    {
      center: true,
      name: 'Modifier',
      maxWidth: '8vw',
      cell: (trainer: Trainer) => (
        <Link
          to="/UpdateTrainer"
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
            align-items-center justify-content-center"
          onClick={(): void => HandleUpdate(trainer)}
        >
          <i className="fa fa-edit" />
        </Link>
      ),
    },
    {
      center: true,
      name: 'Historique',
      maxWidth: '8vw',
      cell: (trainer: Trainer) => (
        <Link
          to={`/HistoriqueFormateur/${trainer.id}`}
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
            align-items-center justify-content-center"
          onClick={(): void => HandleUpdate(trainer)}
        >
          <i className="fa fa-history" />
        </Link>
      ),
    },
    {
      center: true,
      name: 'Supprimer',
      maxWidth: '8vw',
      cell: (trainer: Trainer) => (
        <ConfirmationPopUp
          handleDelete={(): void => handleDisable(trainer.id)}
        />
      ),
    },
  ];
  return data;
};

export default columns;
