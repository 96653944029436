import * as React from 'react';
import SelectField from 'components/SelectField';
import ISelectFieldOption from 'models/ISelectFieldOption';
import Validators from 'helpers/formValidator';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useState } from 'react';
import Bank from 'models/Bank';

interface IProps {
  contactsName: string;
  bankName: string;
}

const RenderInvitationFields: React.FC<IProps> = ({
  contactsName,
  bankName,
}) => {
  const [formattedContacts, setformattedContacts] = useState<
    ISelectFieldOption[]
  >([]);
  const { bankList } = useSelector((state: RootState) => state.banks);
  const formattedBanks: ISelectFieldOption[] = bankList.map(el => ({
    label: el.name,
    value: el,
  }));

  const handleBank = (value: ISelectFieldOption) => {
    const bank = value?.value as Bank;
    const data = bank?.contact?.map(el => ({
      label: el.job,
      value: el,
    }));
    setformattedContacts(data);
  };

  return (
    <>
      <div className="col-4 px-1 ml-2">
        <SelectField
          options={formattedBanks}
          name={bankName}
          getSelectedValue={handleBank}
          isSearchable
          placeholder="Banques"
          label="Sélectionner la banque"
          validate={[Validators.required]}
        />
      </div>
      <div className="col-6 px-1">
        <SelectField
          options={formattedContacts}
          name={contactsName}
          placeholder="Contact"
          label="Sélectionner les contacts"
          validate={[Validators.required]}
          isMulti
        />
      </div>
    </>
  );
};

export default RenderInvitationFields;
