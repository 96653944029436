import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import IImportedSemester from 'models/IimportedSemester';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { SemesterEnum } from 'constants/types/semester';
import { errorHandler } from 'helpers/errorHandler';
export const importSemester = (
  semester?: IImportedSemester,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    const response = await api.post('/semesters/import-Semester', semester);
    dispatch({
      type: SemesterEnum.ADD_SEMESTER_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ModuleCycleEnum.FETCH_SEMESTER_MODULE_SUCCESS,
      payload: response.data.cycleModule,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SemesterEnum.ADD_SEMESTER_FAILURE, error });
  }
};
