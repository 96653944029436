import SessionViewModel from 'view-models/SessionViewModel';
import moment from 'moment';

const formatData = (list: SessionViewModel[], trainingId?: number) => {
  const filtred = list.filter(el => trainingId === el.trainingId);

  const formatted = filtred.map(el => ({
    id: el.id,
    trainer: `${el.trainer.firstName} ${el.trainer.lastName}`,
    date: moment(el.date).format('L'),
    from: moment(el.from).format('HH:mm'),
    to: moment(el.to).format('HH:mm'),
    room: `${el.classroom.type} ${
      el.classroom.name !== null ? el.classroom.name : ''
    } ${el.classroom.number}`,
  }));

  return formatted;
};

export default formatData;
