/* eslint-disable react/no-array-index-key */
import { IDaysPerDefaultUserData } from 'models/Stats';
import React from 'react';
import StatCard from './StatCard';

const DaysPerDefaultUserDisplay = React.memo(
  ({
    title,
    value,
    className,
  }: {
    title: string;
    value: number;
    className?: string;
  }) => (
    <div className={className}>
      <strong>{`${title} : `}</strong>
      {`${value} jour${value > 1 ? 's' : ''}`}
    </div>
  ),
);

export type IDaysPerDefaultUserProps = {
  data: IDaysPerDefaultUserData[];
  exportCSVButton: React.ReactNode;
};

const DaysPerDefaultUser: React.FC<IDaysPerDefaultUserProps> = ({
  data,
  exportCSVButton,
}) => {
  return (
    <StatCard
      exportCSVButton={exportCSVButton}
      title="Jours par créer organisateur"
    >
      {data.map((el, i) => (
        <DaysPerDefaultUserDisplay
          className={i > 0 ? 'mt-2' : ''}
          key={`${el.fullName}-${i}`}
          title={el.fullName}
          value={el.days}
        />
      ))}
    </StatCard>
  );
};

export default DaysPerDefaultUser;
