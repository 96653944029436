import * as React from 'react';
import IImportedCertificate from 'models/IImportedCertificate';
import Semester from 'models/Semester';

const columns = (handleImport: (id: number | undefined) => void) => {
  const data = [
    {
      name: 'Titre',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Theme',
      selector: 'theme',
      sortable: true,
    },
    {
      name: 'Categorie',
      selector: 'category',
      sortable: true,
    },
    {
      center: true,
      name: 'Importer',
      maxWidth: '8vw',
      cell: (row: IImportedCertificate | Semester) => (
        <button
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
              align-items-center justify-content-center"
          onClick={(): void => handleImport(row?.id)}
        >
          <i className="fa fa-edit" />
        </button>
      ),
    },
  ];
  return data;
};

export default columns;
