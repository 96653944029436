import moment from 'moment';
import ProgramDays from 'models/ProgramDays';
import { IProgramFormDays } from 'models/IProgramForm';

const formatProgramDays = (
  programDays: ProgramDays[],
): Record<string, IProgramFormDays> => {
  let payload = {};
  for (let i = 0; i < programDays.length; i++) {
    const {
      id,
      content,
      methods,
      practicalDuration,
      theoreticalDuration,
      date: dayDate,
    } = programDays[i];
    payload = {
      ...payload,
      [moment(dayDate).format('L')]: {
        id,
        content,
        methods,
        practicalDuration,
        theoreticalDuration,
      },
    };
  }
  return payload;
};

export default formatProgramDays;
