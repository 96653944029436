import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ProgramEnum } from 'constants/types/Program';
import { errorHandler } from 'helpers/errorHandler';
import fileDownload from 'js-file-download';
import { ICertifProgramReturn } from 'models/IProgramForm';
import { toast } from 'react-toastify';

export const selectSemesterProgram = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `SemesterPrograms/${id}?filter={"include" : ["programModules"]}`,
    );

    const program = {
      title: response.data.title,
      target: response.data.target,
      themeId: response.data.themeId,
      trGoals: response.data.trGoals,
      pedGoals: response.data.pedGoals,
      methodology: response.data.methodology,
      totalTheoreticalDuration: response.data.totalTheoreticalDuration,
      totalPracticalDuration: response.data.totalPracticalDuration,
      cycleId: response.data.cycleId,
      id: response.data.id,
    };

    const { programModules } = response.data;

    dispatch({
      type: ProgramEnum.FETCH_SEMESTER_PROGRAM_SUCCESS,
      payload: { program, programModules },
    });
  } catch (error) {
    dispatch({ type: ProgramEnum.FETCH_PROGRAM_FAILURE });
  }
  stopLoading()(dispatch);
};

export const addSemesterProgram = (
  data: ICertifProgramReturn,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post(
      '/SemesterPrograms/with-program-modules',
      data,
    );
    dispatch({
      type: ProgramEnum.ADD_SEMESTER_PROGRAM_SUCCESS,
      payload: response.data,
    });
    toast('le programme a éte sauvegardé avec succès');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProgramEnum.ADD_SEMESTER_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addSemesterProgramAndGeneratFile = (
  data: ICertifProgramReturn,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post(
      '/SemesterPrograms/createAndGenerateFile',
      data,
    );
    dispatch({
      type: ProgramEnum.ADD_SEMESTER_PROGRAM_SUCCESS,
      payload: response.data,
    });
    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    toast('le programme a éte sauvegardé avec succès');

    fileDownload(file.data, `${response.data.document}`);
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProgramEnum.ADD_SEMESTER_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const downloadFile = (data?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  startLoading()(dispatch);
  try {
    const response = await api.post('/SemesterPrograms/downlaodFile', data);
    dispatch({
      type: ProgramEnum.ADD_SEMESTER_PROGRAM_SUCCESS,
      payload: response.data,
    });
    const file = await api.get(
      `containers/docs/download/${response.data.document}`,
      {
        responseType: 'blob',
      },
    );
    fileDownload(file.data, `${response.data.document}`);
    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data.document,
    );
  } catch (error) {
    toast(error?.response?.data?.error?.message, { type: 'error' });
    dispatch({ type: ProgramEnum.ADD_SEMESTER_PROGRAM_FAILURE, error });
  }
  stopLoading()(dispatch);
};
