/* eslint-disable max-lines */
/* eslint-disable import/no-unresolved */
import React, { RefObject, useEffect } from 'react';
import AddTrainingCertifSection from 'containers/AddTrainingCertifSection';
import AddCertificateModuleSection from 'containers/AddCertificateModuleSection';
import { useCertificateHandler } from 'hooks/useCertificateHandler';
import { useDispatch, useSelector } from 'react-redux';
import { publishCertificate } from 'actions/Certificate/publishCertificate';
import { useTrainingHandler } from 'hooks/useTrainingHandler';
import { addCertificate } from 'actions/Certificate/addCertificate';
import Certificate from 'models/Certificate';
import { addUsersToCertificate } from 'actions/Certificate/addUsersToCertificate';
import { addModuleToCertificate } from 'actions/Module/addModuleToCertificate';
import Module from 'models/Module';
// import AddSessionToModuleSection from 'containers/AddSessionToModuleSection';
import AddCertificateProgramSection from 'containers/AddCertificateProgramSection';
import { ISessionModuleForm } from 'view-models/SessionViewModel';
import AddCertificateSessionSection from 'containers/AddCertificateSessionSection';
import AddCertificateCalendarSection from 'containers/AddCertificateCalendarSection';
import { addSession } from 'actions';
import { RootState } from 'reducers';
import { Moment } from 'moment';
import { ICertifProgram } from 'models/IProgramForm';
import handleCertifModuleValues from 'helpers/handleCertifModuleValues';
import {
  addCertifProgram,
  addCertifProgramAndGeneratFile,
} from 'actions/CertificateProgram';
import AddCertificateUserSection from 'containers/AddCertificateUserSection';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import AddTrainingBreakSection from 'containers/AddTrainingBreakSection';
import IModule from 'models/IModule';
import { addBreaks } from 'actions/Break';
import { formatBreakForCertificate } from 'helpers/formatBreak';
import { BreakForm } from 'view-models/BreakViewModel';
// import IProgramForm from 'models/IProgramForm';
interface IProps {
  endDivRef: RefObject<HTMLDivElement>;
  isCertificateEdited: boolean;
  toggleCertificate?: () => void;
}
export const AddCertificateContainer: React.FC<IProps> = ({
  endDivRef,
  isCertificateEdited,
  toggleCertificate,
}) => {
  const dispatch = useDispatch();
  const { setIsCertificateOpen } = useTrainingHandler(endDivRef);
  const {
    toggleUserBar,
    toggleProgramBar,
    toggleCalendarBar,
    setIsCalendarOpen,
    toggleModule,
    toggleModuleList,
    toggleSessionBar,
    isSessionOpen,
    isCalendarOpen,
    setIsModuleOpen,
    setIsSessionOpen,
    newCertificate,
    setisSaved,
    setIsModuleSaved,
    setIsModuleListOpen,
    isSaved,
    isModuleSaved,
    selectedDraft,
    isUserOpen,
    isModuleOpen,
    isModuleListOpen,
    isProgramOpen,
    date,
    toggleBreakBar,
    toggleEditedModule,
    setIsProgramOpen,
    isBreakOpen,
    setIsBreakOpen,
    isWithFile,
    setIsWithFile,
    certifProgram,
  } = useCertificateHandler(endDivRef);
  const { newModule } = useSelector((state: RootState) => state.module);
  useEffect(() => {
    dispatch({ type: mecansimTypesEnum.RESET_ALL });
  }, []);

  useEffect(() => {
    if (isCertificateEdited) {
      toggleEditedModule();
    }
  }, [isCertificateEdited]);

  useEffect(() => {
    if (isModuleListOpen) {
      setIsModuleListOpen(false);
    }
  }, [isModuleListOpen]);

  const handlePublishCertificate = (): void => {
    const certificateId = newCertificate?.id || selectedDraft?.id;

    certificateId && dispatch(publishCertificate(certificateId));
    setIsCertificateOpen(false);
  };

  const handleProgram = (values: ICertifProgram): void => {
    const programModuleIds: number[] = [];
    if (certifProgram) {
      certifProgram.programModules?.forEach(progModule => {
        programModuleIds.push(progModule.id as number);
      });
    }

    if (isWithFile)
      dispatch(
        addCertifProgramAndGeneratFile(
          handleCertifModuleValues(
            {
              ...values,
              certificationId: newCertificate?.id,
            },
            programModuleIds,
          ),
        ),
      );
    else
      dispatch(
        addCertifProgram(
          handleCertifModuleValues(
            {
              ...values,
              certificationId: newCertificate?.id,
            },
            programModuleIds,
          ),
        ),
      );
    setIsProgramOpen(false);
  };

  const handleBreak = (data: { breaks: BreakForm[] }): void => {
    const formattedData = formatBreakForCertificate(data, newModule as IModule);
    dispatch(addBreaks(formattedData));
    setIsBreakOpen(false);
  };
  const handleSaveCertificate = (values: Certificate): void => {
    const certificateId = newCertificate?.id || selectedDraft?.id;
    dispatch(addCertificate(values, certificateId));
    setisSaved(true);
  };

  const handleModule = (values: Module): void => {
    const certificateId = newCertificate?.id || selectedDraft?.id;
    certificateId && dispatch(addModuleToCertificate(values, certificateId));
    toggleModuleList();
    setIsModuleSaved(true);
  };

  const handleUsers = (values: { users: number[] }): void => {
    const certificateId = newCertificate?.id || selectedDraft?.id;
    certificateId &&
      dispatch(addUsersToCertificate({ users: values.users, certificateId }));
  };

  const handleModuleSession = (values: ISessionModuleForm) => {
    const newSesison = {
      ...values,
      date,
      moduleId: newModule?.id,
    };

    dispatch(addSession(newSesison as ISessionModuleForm));
    setIsSessionOpen(false);
  };
  return (
    <>
      <AddTrainingCertifSection
        setisSaved={setisSaved}
        toggleCertificate={() => toggleCertificate?.()}
        toggleCalendarBar={() => setIsCalendarOpen(true)}
        toggleProgramBar={toggleProgramBar}
        isSaved={isSaved || selectedDraft !== undefined}
        toggleModule={toggleModule}
        openModuleSection={(): void => setIsModuleOpen(true)}
        toggleUserBar={(): void => toggleUserBar()}
        toggleBreakBar={(): void => toggleBreakBar()}
        handlePublish={handlePublishCertificate}
        onSubmit={handleSaveCertificate}
        initialValues={selectedDraft || newCertificate}
      />
      {isModuleOpen && (
        <AddCertificateModuleSection
          isSaved={isModuleSaved || selectedDraft !== undefined}
          toggleCalendarBar={(): void => toggleCalendarBar()}
          onSubmit={handleModule}
          initialValues={newModule}
          toggleModule={toggleModule}
        />
      )}
      {isCalendarOpen && (
        <AddCertificateCalendarSection
          toggleSidebar={(value: Moment | null): void => {
            toggleSessionBar(value as Moment);
          }}
          toggleCalendarBar={toggleCalendarBar}
        />
      )}
      {isSessionOpen && (
        <AddCertificateSessionSection
          toggleSessionBar={() => setIsSessionOpen(false)}
          date={date}
          onSubmit={handleModuleSession}
        />
      )}

      {isProgramOpen && (
        <AddCertificateProgramSection
          onSubmit={handleProgram}
          setIsWithFile={setIsWithFile}
          initialValues={selectedDraft || newCertificate}
          certificateProgram={certifProgram}
        />
      )}

      {isUserOpen && (
        <AddCertificateUserSection
          onSubmit={handleUsers}
          currentCertificate={newCertificate || selectedDraft}
          toggleUserBar={toggleUserBar}
        />
      )}
      {isBreakOpen && (
        <AddTrainingBreakSection
          onSubmit={handleBreak}
          module={newModule as IModule}
          toggleBreakBar={toggleBreakBar}
        />
      )}
    </>
  );
};
