import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { ModuleEnum } from 'constants/types/Module';
import { IThunkAction } from 'constants/types/IThunkAction';
import Module from 'models/Module';
import { errorHandler } from 'helpers/errorHandler';

export const addModuleToCertificate = (
  module: Module,
  certificateId: number,
): IThunkAction => async (dispatch): Promise<void> => {
  module.certificateId = certificateId;
  const data = { module, certificateId };
  try {
    startLoading()(dispatch);
    const response = await api.post(
      // eslint-disable-next-line max-len
      'modules',
      data,
    );
    dispatch({
      type: ModuleEnum.FETCH_FINISHED_MODULE_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ModuleEnum.ADD_MODULE_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: ModuleEnum.SET_SELECTED_MODULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleEnum.FETCH_FINISHED_MODULE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
