import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import { BreakTypeEnum } from 'constants/types/BreakType';
import BreakTypeViewModel from 'view-models/BreakTypeViewModel';

export interface BreakTypeState {
  error?: AxiosError | Error;
  BreakTypeList: BreakTypeViewModel[];
  BreakType?: BreakTypeViewModel;
}

const initialState: BreakTypeState = {
  BreakTypeList: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): BreakTypeState => {
  switch (type) {
    case BreakTypeEnum.FETCH_BREAK_TYPE_SUCCESS:
      return { ...state, BreakTypeList: payload };
    case BreakTypeEnum.FETCH_BREAK_TYPE_FAILURE:
      return { ...state, error };
    case BreakTypeEnum.GET_BREAK_TYPE_SUCCESS:
      return { ...state, BreakType: payload };
    case BreakTypeEnum.GET_BREAK_TYPE_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
