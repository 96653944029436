import * as React from 'react';
// import IProgramData from 'models/IProgramData';
import RenderProgramDay from 'components/RenderProgramDay';
import IModule from 'models/IModule';
import RenderCycleProgramDay from 'components/RenderCycleProgramDay';
// import ProgramDays from 'models/ProgramDays';

interface IProps {
  data?: IModule[];
  isCycle?: boolean;
}

const RenderCertifProgramDays: React.FC<IProps> = ({ data, isCycle }) => {
  return (
    <>
      {isCycle ? (
        <>
          {data &&
            data.map((program, index) => (
              <RenderCycleProgramDay
                uniqKey={index}
                seance={program?.courseNumber}
                moduleTitle={program?.title}
                day={program?.days?.toString()}
                duration={program?.duration}
                content={program?.content}
                methods={program?.methods}
              />
            ))}
        </>
      ) : (
        <>
          {data &&
            data.map((program, index) => (
              <RenderProgramDay
                isCertif
                uniqKey={index}
                seance={program?.courseNumber}
                moduleTitle={program?.title}
                day={program?.days?.toString()}
                duration={program?.duration}
                content={program?.content}
                methods={program?.methods}
              />
            ))}
        </>
      )}
    </>
  );
};

export default RenderCertifProgramDays;
