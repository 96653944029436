/* eslint-disable max-lines */
// import { useOneShot } from 'hooks/useOneShot';

import React, { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSession, addExam, addUsersToExam, publishExam } from 'actions';
import { ISessionModuleForm } from 'view-models/SessionViewModel';

import { useTrainingHandler } from 'hooks/useTrainingHandler';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import { RootState } from 'reducers';
import AddExamSection from 'containers/AddExamSection';
import AddExamCalendarSection from 'containers/AddExamCalendarSection';
import { useExamHandler } from 'hooks/useExamHandler';
import AddExamDate from 'containers/AddExamDate';
import AddExamUserSection from 'containers/AddExamUserSection';
import Exam from 'models/Exam';

interface IProp {
  isExamOpen: boolean;
  endDivRef: RefObject<HTMLDivElement>;
  isExamEdited: boolean;
  toggleExam: () => void;
}

const AddExamContainer: React.FC<IProp> = ({
  isExamOpen,
  endDivRef,
  isExamEdited,
  toggleExam,
}) => {
  const {
    toggleCalendarBar,
    toggleSideBar,
    isCalendarOpen,
    isExamDateOpen,
    isUserOpen,
    isSaved,
    hasDate,
    date,
    toggleUserBar,
    setIsSaved,
    setHasDate,
    setIsExamDateOpen,
    formattedTrainingSuggestions,
    closeSessionBar,
  } = useExamHandler(endDivRef);

  const { setIsExamOpen } = useTrainingHandler(endDivRef);

  const { selectedDraft, newExam } = useSelector(
    (state: RootState) => state.exam,
  );

  const { currentUser } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: mecansimTypesEnum.RESET_ALL });
  }, []);

  useEffect(() => {
    if (isExamEdited) {
      toggleCalendarBar();
    }
  }, [isExamEdited]);

  const handleExam = (values: Exam): void => {
    currentUser &&
      dispatch(addExam({ ...values, supervisorId: currentUser.id }));
    setIsSaved(true);
  };

  const handleSubmitExamDate = (values: ISessionModuleForm): void => {
    const newSession = {
      ...values,
      date,
      examId: newExam?.id || selectedDraft?.id,
      isExam: true,
      defaultUserId: currentUser?.id,
    };

    dispatch(addSession(newSession as ISessionModuleForm));
    setIsExamDateOpen(false);
    setHasDate(true);
    toggleCalendarBar();
  };

  const handlePublishExam = (): void => {
    dispatch(publishExam(selectedDraft?.id));
    setIsExamOpen(false);
  };

  const handleUsers = (value: { users: number[] }) => {
    newExam?.id &&
      dispatch(addUsersToExam({ users: value.users, examId: newExam.id }));
  };

  return (
    <>
      {isExamOpen && (
        <AddExamSection
          isSaved={isSaved || selectedDraft !== undefined}
          toggleCalendar={(): void => toggleCalendarBar()}
          toggleUserBar={toggleUserBar}
          handlePublish={handlePublishExam}
          toggleExam={toggleExam}
          onSubmit={handleExam}
          initialValues={selectedDraft}
          examId={newExam?.id || selectedDraft?.id}
          hasDate={hasDate}
          setHasDate={setHasDate}
          formattedTrainingSuggestions={formattedTrainingSuggestions}
        />
      )}
      {isCalendarOpen && (
        <AddExamCalendarSection
          toggleSidebar={(value): void => toggleSideBar(value)}
          toggleCalendarBar={() => toggleCalendarBar()}
        />
      )}
      {isExamDateOpen && (
        <AddExamDate
          date={date}
          currentExam={newExam || selectedDraft}
          examId={newExam?.id || 1}
          onSubmit={handleSubmitExamDate}
          toggleSessionBar={closeSessionBar}
        />
      )}
      {isUserOpen && (
        <AddExamUserSection
          currentExam={newExam || selectedDraft}
          onSubmit={handleUsers}
          toggleUserBar={toggleUserBar}
        />
      )}
    </>
  );
};
export default AddExamContainer;
