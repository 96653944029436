import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';

const LoggedOutRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const { isLogged } = useSelector((state: RootState) => state.user);

  return isLogged ? (
    <Redirect to="/" />
  ) : (
    <Route path={path} exact={exact} component={component} />
  );
};
export default LoggedOutRoute;
