import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { CycleEnum } from 'constants/types/cycle';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import CycleViewModel from 'view-models/CycleViewModel';

export const importCycle = (cycle?: CycleViewModel): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/cycles/import-Cycle', cycle);
    dispatch({
      type: CycleEnum.ADD_CYCLE_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: CycleEnum.FETCH_CYCLE_SEMESTERS,
      payload: response.data.semester,
    });
  } catch (error) {
    dispatch({ type: CertificateEnum.ADD_CERTIFICATE_FAILURE, error });
    errorHandler(error);
  }
};
