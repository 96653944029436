import { SessionEnum } from 'constants/types/Session';
import { AxiosError } from 'axios';
import {
  ISessionCycleModule,
  ISessionResponse,
} from 'view-models/SessionViewModel';
import { IReduxAction } from 'constants/types/IReduxAction';
import Session from 'models/Session';
import Classroom from 'models/Classroom';

export interface SessionState {
  error?: AxiosError | Error;
  SessionList: ISessionResponse[];
  SessionCycleModuleList?: ISessionCycleModule[];
  newSession?: Session;
  replacementClassrooms: Classroom[];
}

const initialState: SessionState = {
  SessionList: [],
  replacementClassrooms: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): SessionState => {
  switch (type) {
    case SessionEnum.ADD_SESSION_SUCCESS:
      return { ...state, newSession: payload };
    case SessionEnum.FETCH_SESSION_SUCCESS:
      return { ...state, SessionList: payload };
    case SessionEnum.RESET_SESSION:
      return { ...state, newSession: undefined, SessionList: [] };
    case SessionEnum.FETCH_SESSION_FAILURE:
    case SessionEnum.FETCH_CYCLE_MODULE_SESSION_FAILURE:
    case SessionEnum.UPDATE_SESSION_FAILURE:
      return { ...state, error };
    case SessionEnum.DELETE_SESSION_FAILURE:
      return { ...state, error };
    case SessionEnum.DELETE_SESSION_SUCCESS:
    case SessionEnum.FETCH_CYCLE_MODULE_SESSION_SUCCESS:
      return { ...state, SessionCycleModuleList: payload };
    case SessionEnum.RESET_SESSION_MODULE:
      return { ...state, SessionCycleModuleList: [] };
    case SessionEnum.RESET_NEW_SESSION:
      return { ...state, newSession: undefined };
    case SessionEnum.GET_REPLACEMENT_CLASSROOMS:
      return { ...state, replacementClassrooms: payload };
    default:
      return state;
  }
};
