import { addUser, changePassword } from 'actions/User';
import AddUserContainer from 'containers/AddUserContainer';
import ChangePasswordContainer, {
  IchangePasswordForm,
} from 'containers/ChangePasswordContainer';
import { queryToSearchObject } from 'helpers/queryToObject';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'reducers';
import UserViewModel from 'view-models/UserViewModel';

const UpdateUser: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedUser } = useSelector((state: RootState) => state.users);
  const handleAddUser = (data: UserViewModel) => {
    dispatch(addUser(data, () => history.goBack()));
  };

  const handlePassword = (values: IchangePasswordForm) => {
    const data = { id: selectedUser?.id, password: values.password };
    dispatch(changePassword(data, () => history.goBack()));
  };

  const { search } = useLocation();
  const { operation } = queryToSearchObject(search);

  const getInitialValues = () => {
    const tempSelectedUser: Partial<UserViewModel> = JSON.parse(
      JSON.stringify(selectedUser),
    );
    tempSelectedUser.username = undefined;
    return tempSelectedUser;
  };

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Utilisateur</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier / ajouter un utilisateur
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        {operation === 'add' && (
          <div className="card">
            <div className="card-header">
              <h5 className="card-header-title">Nouvel utilisateur</h5>
            </div>
            <div className="card-body">
              <AddUserContainer onSubmit={handleAddUser} />
            </div>
          </div>
        )}
        {operation === 'password' && (
          <div className="card">
            <div className="card-header">
              <h5 className="card-header-title">
                {`Changer le mot de passe de ${selectedUser?.username}`}
              </h5>
            </div>
            <div className="card-body">
              <ChangePasswordContainer onSubmit={handlePassword} />
            </div>
          </div>
        )}
        {operation === 'update' && (
          <div className="card">
            <div className="card-header">
              <h5 className="card-header-title">
                {`Modifier ${selectedUser?.username}`}
              </h5>
            </div>
            <div className="card-body">
              <AddUserContainer
                onSubmit={handleAddUser}
                initialValues={getInitialValues()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateUser;
