import Candidate from 'models/Candidate';
import React from 'react';
import { CandidateWithNote } from '.';

const columns = (
  examId: string,
  onChangeNote: (value: number, id: number) => void,
) => {
  const headerCols = [
    {
      name: 'Nom',
      selector: (d: Candidate) => {
        return d.firstName;
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Prénom',
      selector: (d: Candidate) => {
        return d.lastName;
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Présence',
      selector: (d: CandidateWithNote) => {
        return d.isPresent ? 'Présent' : 'Absent';
      },
      center: true,
      sortable: true,
    },
    {
      center: true,
      name: 'note',
      maxWidth: '10vw',
      cell: (d: CandidateWithNote) => (
        <input
          className="form-control"
          type="number"
          onChange={e => {
            onChangeNote(Number(e.target.value), d.id);
          }}
          step={0.25}
          max={20}
          min={0}
          value={d.note || ''}
        />
      ),
    },
  ];
  return headerCols;
};

export default columns;
