/* eslint-disable max-len */
import {
  AddCandidateCsv,
  DeleteCandidate,
  getBank,
  getCandidates,
  getJob,
  selectCandidate,
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import CandTable from 'components/CandTable';
import Candidate from 'models/Candidate';
import Csv from 'components/Csv';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';

const Candidats: React.FC = () => {
  const dispatch = useDispatch();
  const { candidateList } = useSelector((state: RootState) => state.candidates);

  useEffect(() => {
    dispatch(getCandidates());
    dispatch(getBank());
    dispatch(getJob());
  }, [dispatch]);

  const HandleUpdate = (candidate: Candidate): void => {
    dispatch(selectCandidate(candidate));
  };
  const handleDelete = (candidateId: number): void => {
    dispatch(DeleteCandidate(candidateId));
  };
  const handleChange = (data: Candidate[]): void => {
    dispatch(AddCandidateCsv(data));
  };
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Candidats</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier, ajouter ou supprimer un candidat
                </li>
              </ol>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Ajouter une liste des candidats via un fichier CSV
                </li>
              </ol>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <Link
                  to="/AddCandidate"
                  type="button"
                  className="btn btn-soft-light"
                >
                  Ajouter un candidat
                </Link>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <a
                  href="https://abf-api.edonec.com/api/containers/CSV/download/candidates_template.csv"
                  download
                  type="button"
                  className="btn btn-soft-light"
                >
                  Télécharger modèle CSV
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <Csv onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des candidats</h5>
          </div>
          <div className="card-body">
            <CandTable
              candidates={candidateList}
              handleDelete={handleDelete}
              HandleUpdate={HandleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Candidats;
