import { Field, Validator } from 'redux-form';
import * as React from 'react';
import WysiwygEditor, {
  IProps as IComponentProps,
} from 'components/WysiwygEditor';

interface Props extends IComponentProps {
  name: string;
  disabled?: boolean;
  validate?: Validator[];
}

const RichField: React.FC<Props> = ({ name, disabled, validate, ...props }) => {
  return (
    <Field
      disabled={disabled}
      name={name}
      component={WysiwygEditor}
      aria-label={name}
      id={name}
      validate={validate}
      {...props}
    />
  );
};

export default RichField;
