import { ClassInterfaceEnum } from 'constants/types/Classrooms';
import { startLoading, stopLoading } from 'actions/Loading';
import Classroom, { ClassroomToAdd } from 'models/Classroom';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { Moment } from 'moment';
import { errorHandler } from 'helpers/errorHandler';

export const getClassrooms = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('Classrooms');

    dispatch({
      type: ClassInterfaceEnum.FETCH_CLASSROOM_SUCCESS,
      payload: response.data,
    });

    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: ClassInterfaceEnum.FETCH_CLASSROOM_FAILURE, error });
    errorHandler(error);
    stopLoading()(dispatch);
  }
};

export const getClassroomSessions = (
  classroomId: number,
  date: Date,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `Classroom/${classroomId}/session?filter={"where":{"date":"${date.toISOString()}"}}`,
    );
    dispatch({
      type: ClassInterfaceEnum.FETCH_SESSION_CLASSROOM_SUCCESS,
      payload: response.data,
    });
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ClassInterfaceEnum.FETCH_SESSION_CLASSROOM_FAILURE,
      error,
    });
    stopLoading()(dispatch);
  }
};

export const selectClassroom = (classroom: Classroom): IThunkAction => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: ClassInterfaceEnum.SELECT_CLASSROOM,
    payload: classroom,
  });
};

export const UpdateClassroom = (
  id: number,
  body: Classroom,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);

    await api.patch(`/Classrooms/${id}`, body);
    getClassrooms()(dispatch, getState, null);
    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: ClassInterfaceEnum.ADD_CLASSROOM_SUCCESS, error });
    errorHandler(error);
    stopLoading()(dispatch);
  }
};

export const addClassroom = (classroom: ClassroomToAdd): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/Classrooms/', classroom);

    dispatch({
      type: ClassInterfaceEnum.ADD_CLASSROOM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ClassInterfaceEnum.ADD_CLASSROOM_FAILURE, error });
  }
};

export const DeleteClassroom = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.delete(`/Classrooms/${id}`);
    getClassrooms()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ClassInterfaceEnum.DELETE_CLASSROOM_FAILURE, error });
  }
};
export const getBoockedClassrooms = (
  selectedDate?: Moment | null,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const weekFormater = {
      startWeek: selectedDate?.startOf('week').format('YYYY-MM-DD'),
      endWeek: selectedDate?.endOf('week').format('YYYY-MM-DD'),
    };
    const response = await api.get(
      `Sessions/boocked-classroom-per-week/${weekFormater?.startWeek}/
      ${weekFormater?.endWeek}`,
    );

    dispatch({
      type: ClassInterfaceEnum.FETCH_BOOCKED_CLASSROOM_SUCCESS,
      payload: response.data,
    });

    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ClassInterfaceEnum.FETCH_BOOCKED_CLASSROOM_FAILURE,
      error,
    });
    stopLoading()(dispatch);
  }
};

export const AddClassroomCsv = (
  classroom: ClassroomToAdd[],
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    await api.post('/Classrooms/csv', classroom);

    getClassrooms()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ClassInterfaceEnum.ADD_CLASSROOM_FAILURE, error });
  }
};
