import { CycleEnum } from 'constants/types/cycle';
import { IThunkAction } from 'constants/types/IThunkAction';
import Cycle from 'models/Cycle';
import { getCycleSemesters } from './getCycleSemesters';

export const setSelectedCycle = (cycle?: Cycle): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: CycleEnum.SET_SELECTED_CYCLE_SUCCESS,
    payload: cycle,
  });
  dispatch(getCycleSemesters(cycle?.id));
};
