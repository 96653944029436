import Classroom from 'reducers/Classroom';
import Job from 'reducers/Job';
import banks from 'reducers/banks';
import candidates from 'reducers/Candiates';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loading from 'reducers/Loading';
import sidebar from 'reducers/Sidebar';
import trainer from 'reducers/Trainer';
import user from 'reducers/User';
import training from 'reducers/Training';
import exam from 'reducers/Exam'
import module from 'reducers/Module';
import session from 'reducers/Session';
import breaks from 'reducers/Breaks';
import breakType from 'reducers/BreakType';
import program from 'reducers/Program';
import importedProgram from 'reducers/Program/importedProgram';
import programs from 'reducers/Programs';
import users from 'reducers/Users';
import certificate from 'reducers/Certificate';
import modules from 'reducers/Modules';
import certificates from 'reducers/Certificates';
import cycle from 'reducers/Cycle';
import semester from 'reducers/Semester';
import cycles from 'reducers/Cycles';
import cycleModule from 'reducers/CycleModule';
import mecansim from 'reducers/Mecanism';
import statsReducer from 'reducers/Stats';

const rootReducer = combineReducers({
  banks,
  breaks,
  breakType,
  candidates,
  certificate,
  certificates,
  Classroom,
  cycle,
  cycleModule,
  cycles,
  form,
  importedProgram,
  Job,
  loading,
  modules,
  module,
  exam,
  mecansim,
  program,
  programs,
  trainer,
  training,
  session,
  sidebar,
  semester,
  users,
  user,
  statsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
