import { getAllExams } from 'actions';
import AllExamsListTable from 'components/AllExamsListTable';
import moment from 'moment';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import formatToCsv from 'helpers/formatToCsv';

const AllExamsList: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllExams());
  }, []);
  const { examsList } = useSelector((state: RootState) => state.exam);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const examsListCurrentUser =
    currentUser &&
    examsList.filter(exam => exam.defaultUser[0].id === currentUser.id);

  const formattedExamsToCSV = examsList.map(exam => ({
    title: exam.title,
    Surveillant: exam.defaultUser[0].username,
    Etablissement: exam.session[0]?.classroom.type || 'Non Assigné',
    Salle: exam.session[0]?.classroom.number || 'Non Assigné',
    date: exam.session[0]
      ? moment(exam.session[0]?.from).format('YYYY-MM-DD')
      : 'Non Assigné',
  }));

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Examens</h1>
              </div>
            </div>
            <CSVLink
              className="btn btn-soft-light"
              filename="liste des examens.csv"
              data={formatToCsv(formattedExamsToCSV, [
                {
                  label: "Nom de l'examen",
                  value: 'title',
                },
                {
                  label: 'Surveillant',
                  value: 'Surveillant',
                },
                {
                  label: 'Etablissement',
                  value: 'Etablissement',
                },
                {
                  label: 'Numéro de salle',
                  value: 'Salle',
                },
                {
                  label: 'Date de creation',
                  value: 'date',
                },
              ])}
            >
              Exporter en tant que CSV
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des Examens</h5>
          </div>
          <div className="card-body">
            {examsListCurrentUser && (
              <AllExamsListTable data={examsListCurrentUser} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllExamsList;
