import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getSessions } from 'actions';
import { ISessionResponse } from 'view-models/SessionViewModel';
import moment, { Moment } from 'moment';
import getDaysOfMonth from 'helpers/getDaysOfMonth';
import { numberOfDivs } from 'helpers/numberOfDivs';
import formatMonthCalendarData from 'components/CalendarToPrint/formatMonthCalendarData';
import { getMonthAndYear } from 'helpers/monthAndYear';

interface Props {
  selectedMonth: {
    label: string;
    value: string;
  };
  selectedYear: {
    label: string;
    value: string | undefined;
  };
  SessionList: ISessionResponse[];
  refToPrint: React.LegacyRef<HTMLDivElement> | undefined;
}

export interface ICalendarPerMonth {
  date: string;
  day: number;
  fullDate: string;
  moment: Moment;
}

const CalendarToPrint: React.FC<Props> = ({
  selectedMonth,
  selectedYear,
  SessionList,
  refToPrint,
}) => {
  const [calendarPerMonth, setCalendarPerMonth] = React.useState<
    ICalendarPerMonth[]
  >([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSessions());
    setCalendarPerMonth(
      getDaysOfMonth(selectedMonth.value, selectedYear.value),
    );
  }, [selectedMonth, selectedYear]);

  React.useEffect(() => {
    numberOfDivs(calendarPerMonth);
  }, [calendarPerMonth]);

  return (
    <div className="p-2" ref={refToPrint}>
      <table className="calendarCal">
        <caption>
          {`${getMonthAndYear(selectedMonth.label, selectedYear.value).month} ${
            getMonthAndYear(selectedMonth.label, selectedYear.value).year
          }`}
        </caption>
        <tbody>
          <tr className="weekdays">
            <th scope="col">Dimanche</th>
            <th scope="col">Lundi</th>
            <th scope="col">Mardi</th>
            <th scope="col">Mercredi</th>
            <th scope="col">Jeudi</th>
            <th scope="col">Vendredi</th>
            <th scope="col">Samedi</th>
          </tr>
        </tbody>
        <tbody>
          <tr className="days">
            {calendarPerMonth.length > 0 &&
              numberOfDivs(calendarPerMonth, true).map((div, index) => {
                const uk = index * 0.003;
                return <td key={div + uk} className="day other-month" />;
              })}
            {calendarPerMonth &&
              calendarPerMonth.map((day, index) => {
                const uk = index * 0.003;
                return (
                  <td key={day?.date + uk} className="day">
                    <div className="date">{day?.date}</div>

                    {SessionList.length ? (
                      formatMonthCalendarData(SessionList).map(e => {
                        return (
                          moment(e.date).format('YYYY-MM-DD') ===
                            day.fullDate && (
                            <div key={e.id} className="event">
                              <div className="event-desc">
                                <div className="breakWord">
                                  <b>Titre: </b>
                                  {e?.title}
                                </div>
                                <div className="breakWord">
                                  <b>Type: </b>
                                  {e?.type}
                                </div>
                                <div className="breakWord">
                                  <b>Assuré par: </b>
                                  {e?.trainer}
                                </div>
                                <div className="breakWord">
                                  <b>Salle: </b>
                                  {e?.room}
                                </div>
                              </div>

                              <div className="event-time">{e?.time}</div>
                            </div>
                          )
                        );
                      })
                    ) : (
                      <div />
                    )}
                  </td>
                );
              })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CalendarToPrint;
