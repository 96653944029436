import { AddCandidateContainer } from 'containers';
import Candidate from 'models/Candidate';
import * as React from 'react';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UpdateCandidate as updateCandidateAction } from 'actions';

const UpdateCandidate: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedcandidate } = useSelector(
    (state: RootState) => state.candidates,
  );
  const handleUpdate = async (data: Candidate): Promise<void> => {
    await dispatch(updateCandidateAction(data.id, data));
    history.goBack();
  };

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Candidats</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier un candidat
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-body">
            <AddCandidateContainer
              initialValues={selectedcandidate}
              onSubmit={handleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCandidate;
