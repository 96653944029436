import ReactSelect, { OptionTypeBase, ValueType } from 'react-select';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import * as React from 'react';
import ISelectFieldOption from 'models/ISelectFieldOption';
import _ from 'lodash';
import customStyles from './styles';

interface ISelectProps {
  id: string;
  input: WrappedFieldInputProps;
  options: ISelectFieldOption[];
  multi: boolean;
  meta: WrappedFieldMetaProps;
  isClearable: boolean;
  isSearchable: boolean;
  label: string;
  isDisabled?: boolean;
  changeHandler?: (result: ISelectFieldOption) => void;
}
const singleChangeHandler = (
  func: (value: ValueType<OptionTypeBase, false>) => void,
): ((value: ValueType<OptionTypeBase, false>) => void) => {
  return (value: ValueType<OptionTypeBase, false>): void => {
    func(value ? value.value : '');
  };
};

const multiChangeHandler = (func: (value: ISelectFieldOption) => void) => (
  values: ValueType<OptionTypeBase, boolean>,
): void => {
  func(values?.map((value: { value: [] }) => value.value));
};

const transformValue = (
  value: string | unknown[],
  options: ISelectFieldOption[],
  multi: boolean,
) => {
  if (multi && typeof value === 'string') return [];
  const filteredOptions =
    options &&
    options.filter(option => {
      return multi
        ? (value as unknown[]).indexOf(option.value) !== -1
        : _.isEqual(option.value, value);
    });

  if (multi) return filteredOptions;
  if (filteredOptions) return filteredOptions[0];

  return undefined;
};

const Select: React.FC<ISelectProps> = ({
  id,
  input: { name, value, onChange, onFocus },
  options,
  multi = false,
  meta: { submitFailed, error, warning },
  isClearable = false,
  isSearchable,
  label,
  changeHandler,
  isDisabled,
}) => {
  React.useEffect(() => {
    changeHandler?.(
      transformValue(value, options, multi) as ISelectFieldOption,
    );
  }, [value]);

  const transformedValue = transformValue(value, options, multi);

  const handleChange = (event: ValueType<OptionTypeBase, boolean>): void => {
    if (changeHandler) changeHandler(event as ISelectFieldOption);

    if (multi) {
      return multiChangeHandler(onChange)(event);
    }

    return singleChangeHandler(onChange)(event);
  };

  return (
    <div
      className={`form-group ${submitFailed &&
        (error || warning) &&
        'u-has-error'}`}
    >
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">
          {label}
        </span>
      </label>
      <ReactSelect
        valueKey="value"
        name={name}
        value={transformedValue || null}
        isMulti={multi}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={customStyles}
        onChange={handleChange}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      {submitFailed &&
        ((error && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {error}
          </div>
        )) ||
          (warning && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {warning}
            </div>
          )))}
    </div>
  );
};

export default Select;
