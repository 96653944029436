import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCycleSemesterModuleSessions, getSessions } from 'actions/Sessions';
import { RootState } from 'reducers';
import CalendarCard from 'components/CalendarCard';
import DatePickerComponent from 'components/DatePickerComponent';
import moment, { Moment } from 'moment';
import TrainingSidebar from 'components/TrainingSidebar';
import formatData from 'containers/AddTrainingCalendarSection/formatData';
interface IProps {
  toggleCalendarBar?: () => void;
  toggleSidebar: (date: Moment) => void;
  moduleId?: number;
}

const AddCycleCalendarSection: React.FC<IProps> = ({
  toggleCalendarBar,
  toggleSidebar,
  moduleId,
}) => {
  const dispatch = useDispatch();
  const { SessionCycleModuleList, SessionList } = useSelector(
    (state: RootState) => state.session,
  );

  useEffect(() => {
    dispatch(getSessions());
    dispatch(getCycleSemesterModuleSessions(moduleId));
  }, [SessionCycleModuleList?.length, SessionList.length]);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const handleDateChange = (newDate: Moment | null): void => {
    if (newDate) setSelectedDate(newDate);
  };

  const [formattedData, setformattedData] = useState<
    {
      list: {
        name: string;
        time: string;
        room: string;
        courseNumber: number;
      }[];
      day: moment.Moment | null;
    }[]
  >();
  useEffect(() => {
    const data = [
      { list: formatData(SessionList, selectedDate), day: selectedDate },
      {
        list: formatData(SessionList, moment(selectedDate)?.add(1, 'day')),
        day: moment(selectedDate)?.add(1, 'day'),
      },
      {
        list: formatData(SessionList, moment(selectedDate)?.add(2, 'day')),
        day: moment(selectedDate)?.add(2, 'day'),
      },
    ];
    setformattedData(data);
  }, [selectedDate, SessionList]);

  return (
    <div className="col-8 p-0">
      <TrainingSidebar
        header="Calendrier des prochaines sessions"
        onClose={() => toggleCalendarBar?.()}
        className="p-4"
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <div className="row w-100">
              {formattedData?.map(el => {
                return (
                  <div className="col-sm p-1 d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      className="btn btn-sm-wide btn-primary btn"
                      onClick={(): void => toggleSidebar(moment(el.day))}
                    >
                      Créer
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        }
      >
        <div className="container">
          <DatePickerComponent
            setDate={handleDateChange}
            value={selectedDate}
            step={1}
            withoutPrev
          />
          <div className="row">
            {formattedData &&
              formattedData.map((el, index) => (
                <div
                  className="col-sm p-1 d-flex flex-column 
              align-items-center justify-content-between"
                  key={index.toString() + el.day?.toString()}
                >
                  <div
                    className="w-100 mt-1 mb-2"
                    style={{ height: '50vh', maxHeight: '50vh' }}
                  >
                    <div
                      className="shadow rounded text-center mx-auto"
                      style={{ backgroundColor: 'lightgrey' }}
                    >
                      <h3
                        className="h6 text-center text-primary 
                      mx-auto font-weight-semi-bold mb-1"
                      >
                        {el.day?.format('L')}
                      </h3>
                    </div>
                    {el.list.map(session => (
                      <div key={session.name + session.time}>
                        <CalendarCard data={session} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </TrainingSidebar>
    </div>
  );
};
export default AddCycleCalendarSection;
