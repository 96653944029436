/* eslint-disable max-lines */
/* eslint-disable max-len */
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import { useDispatch } from 'react-redux';
import { DeleteSession, UpdateSession } from 'actions';
import CalendarCard from 'components/CalendarCard';
import Modal from 'components/Modal';
import UpdateSessionSection from 'containers/UpdateSessionSection';
import TrainingSidebar from 'components/TrainingSidebar';
import Validators from 'helpers/formValidator';
import React, { FormEvent, useState } from 'react';
import { change, InjectedFormProps, reduxForm } from 'redux-form';
import { useAddExamSection } from 'hooks/useAddExamSection';
import moment from 'moment';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useSession } from 'hooks/useSession';
import { SessionForm } from 'view-models/SessionViewModel';
import Exam from 'models/Exam';

interface IProps {
  toggleCalendar?: () => void;
  toggleUserBar?: () => void;
  handlePublish: () => void;
  toggleExam?: () => void;
  isSaved: boolean;
  hasDate: boolean;
  setHasDate: (state: boolean) => void;
  examId?: number;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  formattedTrainingSuggestions?: ISelectFieldOption[];
}
const AddExamSection: React.FC<IProps & InjectedFormProps<Exam, IProps>> = ({
  toggleExam,
  toggleCalendar,
  toggleUserBar,
  examId,
  handleSubmit,
  isSaved,
  hasDate,
  setHasDate,
  initialValues,
  handlePublish,
  formattedTrainingSuggestions,
}) => {
  const [selectedSessionId, setSelectedSessionId] = useState<number>();
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState<string>();
  const dispatch = useDispatch();
  const { formattedSessions, SessionList, loading } = useAddExamSection(
    initialValues,
  );

  const {
    setstartTime,
    setendTime,
    selectedClassroom,
    setselectedClassroom,
    filtredDataClassrooms,
    formattedDataClassroomsNum,
  } = useSession(moment(date), examId);

  const handleDeleteSession = (value: number): void => {
    dispatch(DeleteSession(value));
    setHasDate(false);
  };

  const handleEditSession = (value: number, day: string): void => {
    const editedSession = SessionList.find(session => session.id === value);
    const formattedStartTime = {
      label: moment(editedSession?.from).format('LT A'),
      value: moment(editedSession?.from),
    };
    const formattedEndTime = {
      label: moment(editedSession?.to).format('LT A'),
      value: moment(editedSession?.to),
    };

    setSelectedSessionId(value);
    setstartTime(formattedStartTime);
    setendTime(formattedEndTime);
    setDate(day);
    setModalOpen(true);
  };

  const handleSessionUpdate = (values: SessionForm): void => {
    if (selectedSessionId) {
      dispatch(
        UpdateSession(selectedSessionId, { classroomId: values.classroomId }),
      );
    }
    setModalOpen(false);
  };

  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Changer La Salle de Cours"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <UpdateSessionSection
          onSubmit={handleSessionUpdate}
          filtredDataClassrooms={filtredDataClassrooms}
          formattedDataClassroomsNum={formattedDataClassroomsNum}
          handleClassroom={handleClassroom}
          selectedClassroom={selectedClassroom}
        />
      </Modal>
      <TrainingSidebar header="Examen" className="p-4" onClose={toggleExam}>
        {!loading && (
          <>
            <InputField
              name="title"
              label="titre de l'examen"
              validate={[Validators.required]}
            />
            <SelectField
              options={
                formattedTrainingSuggestions || [
                  { label: 'Aucune Formation', value: 'Aucune Formation' },
                ]
              }
              label="formation associée"
              name="examSubject"
              isSearchable
              placeholder="formation associée"
              validate={[Validators.required]}
            />

            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleUserBar}
              disabled={!isSaved}
            >
              Ajouter un Surveillant
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleCalendar}
              disabled={!isSaved || hasDate}
            >
              Ajouter une date
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            {formattedSessions.map(session => {
              return (
                <div key={session.id}>
                  <CalendarCard
                    data={session.list}
                    day={session.day}
                    id={session.id}
                    deleteSession={handleDeleteSession}
                    editClassroom={handleEditSession}
                  />
                </div>
              );
            })}

            <button
              type="button"
              className="btn btn-sm-wide btn-primary mb-2"
              onClick={handlePublish}
              disabled={!isSaved}
            >
              PUBLIER
            </button>
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};

export default reduxForm<Exam, IProps>({
  form: 'addExamForm',
  enableReinitialize: true,
})(AddExamSection);
