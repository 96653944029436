import { AxiosError } from 'axios';
import { CertificateEnum } from 'constants/types/Certificate';
import { IReduxAction } from 'constants/types/IReduxAction';
import IImportedCertificate from 'models/IImportedCertificate';

export interface CertificateState {
  error?: AxiosError | Error;
  certificates?: IImportedCertificate[];
}

const initialState: CertificateState = {};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): CertificateState => {
  switch (type) {
    case CertificateEnum.FETCH_ALL_CERTIFICATES_SUCCESS:
      return { ...state, certificates: payload };
    case CertificateEnum.FETCH_ALL_CERTIFICATES_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
