import {
  getReplacementClassrooms,
  resetReplacementClassrooms,
  updateSessionClassroom,
} from 'actions';
import Modal from 'components/Modal';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import ClassroomReplacementForm from './ClassroomReplacementForm';

export interface IReplacementForm {
  type: string;
  classroomId: number;
}

interface ClassroomReplacementModalProps {
  isOpen: boolean;
  sessionId: number;
  setIsOpen: (open: boolean) => void;
}

const ClassroomReplacementModal = ({
  isOpen,
  setIsOpen,
  sessionId,
}: ClassroomReplacementModalProps) => {
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(resetReplacementClassrooms);
    setIsOpen(false);
  }, [dispatch, setIsOpen]);

  useEffect(() => {
    dispatch(getReplacementClassrooms(sessionId));
    return () => {
      dispatch(resetReplacementClassrooms);
    };
  }, [sessionId, dispatch]);

  const { replacementClassrooms } = useSelector(
    ({ session }: RootState) => session,
  );

  const onSubmit = useCallback(
    (values: IReplacementForm) => {
      dispatch(
        updateSessionClassroom(sessionId, values.classroomId, () =>
          setIsOpen(false),
        ),
      );
    },
    [dispatch, setIsOpen, sessionId],
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="Changer de salle">
      <ClassroomReplacementForm
        onSubmit={onSubmit}
        replacementClassrooms={replacementClassrooms}
      />
    </Modal>
  );
};

export default ClassroomReplacementModal;
