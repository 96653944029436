/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { reduxForm, InjectedFormProps } from 'redux-form';
import RenderProgramDays from 'components/RenderProgramDays';
import IProgramForm from 'models/IProgramForm';
import { useProgram } from 'hooks/useProgram';
import RichField from 'components/RichField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
import Modal from 'components/Modal';
import {
  getAllProgramsWithDays,
  getProgram,
  impProgramWithDays,
} from 'actions/Program';
import ProgramsTable from 'components/ProgramsTable';

interface IProps {
  trainingId?: number;
  setIsWithFile: (value: boolean) => void;
  toggleProgramBar?: () => void;
  // trainingProgram?: IProgramForm;
}
const AddTrainingProgramSection: React.FC<IProps &
  InjectedFormProps<IProgramForm, IProps>> = ({
  toggleProgramBar,
  handleSubmit,
  trainingId,
  setIsWithFile,
  // trainingProgram,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: { loading },
    programs: { Programs },
    program: { Program },
  } = useSelector((state: RootState) => state);

  const { importedProgram } = useSelector(
    (state: RootState) => state.importedProgram,
  );
  const { programData } = useProgram(trainingId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProgramsWithDays());
    dispatch(getProgram(trainingId as number));
  }, []);

  if (loading) return null;
  const findProgram = (id: number) => {
    dispatch(impProgramWithDays(Programs?.find(prog => prog.id === id)));
    setModalOpen(false);
  };

  // const formattedProgramValues = {
  //   methodology: trainingProgram?.methodology,
  //   pedGoals: trainingProgram?.pedGoals,
  //   trGoals: trainingProgram?.trGoals,
  // };

  return (
    <form onSubmit={handleSubmit} className="col-12 p-0">
      <TrainingSidebar
        header="Fiche programme"
        className="p-4"
        onClose={() => toggleProgramBar?.()}
      >
        <button
          type="button"
          onClick={() => setModalOpen(true)}
          className="btn btn-sm-wide btn-primary"
        >
          Importer Fiche Programme
        </button>
        <Modal
          title="Ancient Programs"
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
        >
          {Programs?.length && (
            <ProgramsTable programs={Programs} handleImport={findProgram} />
          )}
        </Modal>
        <div className="row my-4">
          <div className="col-4">
            <InputField
              name="title"
              label="Titre"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-4">
            <div className="form-label">
              <span className="d-flex mb-2 justify-content-between align-items-center">
                thème
              </span>
            </div>
            <span className="form-control">{programData?.theme}</span>
          </div>
          <div className="col-4">
            <InputField
              name="target"
              label="Population cible"
              validate={[Validators.required]}
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6">
            <RichField
              name="trGoals"
              label="Objectifs formation"
              validate={[Validators.required]}
              pickedContent={importedProgram?.trGoals || Program?.trGoals}
            />
          </div>
          <div className="col-6">
            <RichField
              name="pedGoals"
              label="Objectifs pédagogiques"
              validate={[Validators.required]}
              pickedContent={importedProgram?.pedGoals || Program?.pedGoals}
            />
          </div>
          {/* <div className="col-4">
            {programData?.trainer && <ProgramCard data={programData} />}
          </div> */}
        </div>
        {programData?.days && (
          <RenderProgramDays
            data={programData}
            programDays={importedProgram?.programDays || Program?.programDays}
          />
        )}
        <div className="row my-4 align-items-center">
          <div className="col-8">
            <RichField
              name="methodology"
              label="Méthodologies et outils d'évaluation"
              validate={[Validators.required]}
              pickedContent={
                importedProgram?.methodology || Program?.methodology
              }
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalTheoreticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
          <div className="col-2">
            <InputField
              name="totalPracticalDuration"
              label="Total"
              type="number"
              validate={[Validators.required]}
              disabled
            />
          </div>
        </div>
        <div className="row my-4 align-items-center">
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(true)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer et télécharger PDF
            </button>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <button
              type="submit"
              onClick={() => setIsWithFile(false)}
              className="btn btn-sm-wide btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<IProgramForm, IProps>({
  form: 'addProgramForm',
  // onSubmitSuccess: (_, dispatch) => dispatch(reset('addProgramForm')),
  enableReinitialize: true,
})(AddTrainingProgramSection);
