import Classroom from 'models/Classroom';
import ConfirmationPopUp from 'components/ConfirmationPopUp';
import { Link } from 'react-router-dom';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import Fuse from 'fuse.js';

interface Props {
  classrooms: Classroom[];
  handleDelete: (id: number) => void;
  HandleUpdate: (classroom: Classroom) => void;
}

const ClassroomTable: React.FC<Props> = ({
  classrooms,
  handleDelete,
  HandleUpdate,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedClassrooms, setSortedClassrooms] = React.useState<Classroom[]>(
    [],
  );

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedClassrooms(classrooms);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['type', 'capacity', 'number', 'cost', 'name'],
    };
    const fuse = new Fuse(classrooms, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedClassrooms(results);
  }, [keyword, classrooms]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  const columns = () => {
    const data = [
      {
        name: 'Type',
        selector: 'type',
        center: true,
        sortable: true,
      },
      {
        name: 'Nom du centre',
        selector: 'name',
        center: true,
        sortable: true,
      },
      {
        name: 'Numéro de salle',
        selector: 'number',
        center: true,
        sortable: true,
      },
      {
        name: 'Capacité',
        sortable: true,
        center: true,
        selector: 'capacity',
      },
      {
        name: 'Coût (DT)',
        selector: 'cost',
        center: true,
        sortable: true,
      },
      {
        center: true,
        name: 'Modifier',
        maxWidth: '8vw',
        cell: (room: Classroom) => (
          <Link
            to="/UpdateClassroom"
            type="button"
            className="btn btn-block btn-primary btn-icon d-flex 
            align-items-center justify-content-center"
            onClick={(): void => HandleUpdate(room)}
          >
            <i className="fa fa-edit" />
          </Link>
        ),
      },
      {
        center: true,
        name: 'Supprimer',
        maxWidth: '8vw',
        cell: (room: Classroom) => (
          <ConfirmationPopUp handleDelete={(): void => handleDelete(room.id)} />
        ),
      },
    ];
    return data;
  };

  return (
    <DataTable
      data={sortedClassrooms}
      columns={columns()}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default ClassroomTable;
