/* eslint-disable @typescript-eslint/camelcase */
import moment, { Moment } from 'moment';
import { ISessionResponse } from 'view-models/SessionViewModel';

const filterSessionArrayByStartHour = (
  a: ISessionResponse,
  b: ISessionResponse,
): number => {
  const a_from = new Date(a.from);
  const a_minutes = a_from.getHours() * 60 + a_from.getMinutes();
  const b_from = new Date(b.from);
  const b_minutes = b_from.getHours() * 60 + b_from.getMinutes();
  return a_minutes - b_minutes;
};

const transformDate = (momentDate: Moment) => momentDate.format('DDMMYYYY');

export default (
  sessions: ISessionResponse[],
  classroom: number,
  date: Moment,
) => {
  const transformedDate = transformDate(date);

  return sessions
    .filter(session => {
      const {
        date: sessionDate,
        classroomId,
        training,
        module,
        cycleModule,
        exam,
      } = session;
      const isFinished =
        training?.isFinished ||
        module?.certificate?.isFinished ||
        cycleModule?.semester?.cycle?.isFinished ||
        exam?.isFinished ||
        false;

      return (
        isFinished &&
        classroomId === classroom &&
        transformedDate === transformDate(moment(sessionDate))
      );
    })
    .sort(filterSessionArrayByStartHour);
};
