/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getBreakTypes } from 'actions/BreakType';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { BreakForm } from 'view-models/BreakViewModel';

const ModifyBreakForm: React.FC<InjectedFormProps<BreakForm>> = ({
  handleSubmit,
}) => {
  const { BreakTypeList } = useSelector((state: RootState) => state.breakType);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBreakTypes());
  }, []);

  const formattedTypes: ISelectFieldOption[] = BreakTypeList.map(el => ({
    label: el.title,
    value: el.id,
  }));

  return (
    <form onSubmit={handleSubmit} className="col-12 p-0">
      <div className="col-6 ">
        <SelectField
          options={formattedTypes}
          name="breakTypeId"
          placeholder="Choisir le type"
          validate={[Validators.required]}
          label="Type"
        />
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-outline-primary py-2">
          Sauvegarder
        </button>
      </div>
    </form>
  );
};
export default reduxForm<BreakForm>({
  form: 'modifyBreakForm',
  enableReinitialize: true,
})(ModifyBreakForm);
