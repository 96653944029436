import { InjectedFormProps, reduxForm } from 'redux-form';
import React, { useState, useEffect } from 'react';
import Classroom from 'models/Classroom';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import HorizontalSelectorField from 'components/HorizontalSelectorField';

const AddClassroom: React.FC<InjectedFormProps<Classroom, {}, string>> = ({
  handleSubmit,
  initialValues,
}) => {
  const [selectedRadio, setSelectedRadio] = useState<string>();

  useEffect(() => {
    let selected = '';
    if (!initialValues || initialValues.type === 'ABF') selected = 'ABF';
    else if (initialValues.type === 'CDF') selected = 'CDF';
    else selected = 'Other';
    setSelectedRadio(selected);
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <div className="row">
        <div className="col-lg-6 m-auto">
          <InputField
            name="capacity"
            label="capacité"
            validate={[Validators.required, Validators.numeric]}
          />
        </div>
      </div>
      <div className="mt-7 mb-7">
        <HorizontalSelectorField
          name="type"
          onChange={(value): void => setSelectedRadio(value as string)}
          options={[
            { label: 'ABF', value: 'ABF' },
            { label: 'CDF', value: 'CDF' },
            { label: 'Classe virtuelle', value: 'virtualClass' },
            { label: 'Autre', value: 'Other' },
          ]}
        />
      </div>
      {selectedRadio === 'ABF' && (
        <div className="row">
          <div className="col-lg-6 m-auto">
            <InputField
              name="number"
              label="Numero de salle"
              validate={[Validators.required]}
            />
          </div>
        </div>
      )}
      {selectedRadio === 'CDF' && (
        <div className="row">
          <div className="col-lg-4">
            <InputField
              name="name"
              label="Non du centre de formation"
              validate={[Validators.required]}
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name="number"
              label="Numero de salle"
              validate={[Validators.required]}
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name="cost"
              label="montant"
              validate={[Validators.required, Validators.numeric]}
            />
          </div>
        </div>
      )}
      {selectedRadio === 'virtualClass' && (
        <div className="row">
          <div className="col-lg-6 m-auto">
            <InputField
              name="number"
              label="Numero de salle"
              validate={[Validators.required]}
            />
          </div>
        </div>
      )}
      {selectedRadio === 'Other' && (
        <div className="row">
          <div className="col-lg-4">
            <InputField
              name="type"
              label="type"
              validate={[Validators.required]}
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name="number"
              label="Numero de salle"
              validate={[Validators.required]}
            />
          </div>
          <div className="col-lg-4">
            <InputField
              name="cost"
              label="montant"
              validate={[Validators.required, Validators.numeric]}
            />
          </div>
        </div>
      )}
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<Classroom>({ form: 'addclassroomform' })(AddClassroom);
