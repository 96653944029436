import { InjectedFormProps, reduxForm } from 'redux-form';
import React from 'react';
import { useSelector } from 'react-redux';
import AutoCompleteField from 'components/AutoCompleteFiled';
import Candidate from 'models/Candidate';
import InputField from 'components/InputField';
import { RootState } from 'reducers';
import Validators from 'helpers/formValidator';

const AddCandidate: React.FC<InjectedFormProps<Candidate, {}, string>> = ({
  handleSubmit,
}) => {
  const { bankList } = useSelector((state: RootState) => state.banks);
  const { jobList } = useSelector((state: RootState) => state.Job);

  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <div className="row">
        <div className="col-lg-6">
          <InputField
            name="lastName"
            label="Nom"
            validate={[Validators.required]}
          />
        </div>
        <div className="col-lg-6">
          <InputField
            name="firstName"
            label="Prenom"
            validate={[Validators.required]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <InputField name="phoneNumber" label="tel" />
        </div>
        <div className="col-lg-6">
          <InputField name="CIN" label="cin" validate={[Validators.numeric]} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <AutoCompleteField
            className="orange badge-outline-primary"
            label="Bank"
            name="bankId"
            suggestions={bankList.map(el => ({ title: el.name, id: el.id }))}
          />
        </div>
        <div className="col-lg-6">
          <AutoCompleteField
            className="orange badge-outline-primary"
            label="Job"
            name="jobId"
            suggestions={jobList.map(el => ({ title: el.title, id: el.id }))}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <InputField name="email" label="email" />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<Candidate>({
  form: 'addcandidateform',
  enableReinitialize: true,
})(AddCandidate);
