/* eslint-disable react/jsx-one-expression-per-line */
import Bank from 'models/Bank';
import ComfirmationPopUp from 'components/ConfirmationPopUp';
import * as React from 'react';
import { getImageUrl } from 'helpers/getImageUrl';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import ContactInfo from 'components/ContactInfo';

interface Props extends Bank {
  handleUpdate: (bank: Bank) => void;
  handleDelete: (id: number) => void;
}

const BankCard: React.FC<Props> = ({ handleUpdate, handleDelete, ...bank }) => {
  const { image, name, id, contact } = bank;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="card card-frame h-100">
        <div className="card-body">
          <div className="media">
            <div className="avatar avatar-xs mt-1 mr-3">
              {image && (
                <img
                  src={getImageUrl(image)}
                  alt="logo"
                  style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '40px',
                  }}
                />
              )}
            </div>
            <div className="media-body">
              <div className="row align-items-center">
                <div className="col-8">
                  <span className="d-block h4 text-dark font-weight-bold">
                    {name}
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm btn-link p-0"
                    onClick={showModal}
                  >
                    Voir contacts
                  </button>
                </div>
                <div className="col-auto">
                  <Link
                    to="/updatebank"
                    type="button"
                    className="btn btn-block btn-soft-primary btn-icon d-flex 
                                align-items-center justify-content-center"
                    onClick={(): void => handleUpdate(bank)}
                  >
                    <i className="fa fa-edit" />
                  </Link>
                  <ComfirmationPopUp
                    handleDelete={(): void => handleDelete(id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        title={<div className="h4 font-weight-bold">{name}</div>}
      >
        {contact.length > 0 ? (
          <ContactInfo contact={contact} />
        ) : (
          <p>Aucun contact</p>
        )}
      </Modal>
    </>
  );
};

export default BankCard;
