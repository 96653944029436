import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import * as React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

export interface IchangePasswordForm {
  password: string;
  confirmPassword: string;
}

const ChangePasswordContainer: React.FC<InjectedFormProps<
  IchangePasswordForm
>> = ({ handleSubmit }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="container space-1 d-flex flex-column"
    >
      <div className="row">
        <div className="col-lg-8">
          <InputField
            name="password"
            label="nouveau mot de passe"
            type="password"
            validate={[Validators.required, Validators.min8]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <InputField
            name="passwordConfirm"
            label="Confirmer votre mot de passe"
            type="password"
            validate={[Validators.required, Validators.passwordConfirm]}
          />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-wide btn-primary mt-2 align-self-end"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<IchangePasswordForm>({
  form: 'changePasswordform',
})(ChangePasswordContainer);
