import Popup from 'reactjs-popup';
import * as React from 'react';

interface Props {
  handleDelete: () => void;
}

const ComfirmationPopUp: React.FC<Props> = ({ handleDelete }) => (
  <Popup
    trigger={(
      <button className="btn btn-block btn-primary btn-icon " type="button">
        <i className="fa fa-trash" />
      </button>
    )}
    modal
  >
    {(close): JSX.Element => (
      <div className="custom-modal animated">
        <div className="header "> Êtes-vous sûr ?</div>
        <div className="actions mt-5">
          <button
            type="button"
            className="btn btn-wide btn-primary mr-10"
            onClick={(): void => {
              handleDelete();
              close();
            }}
          >
            Confirmer
          </button>
          <button
            type="button"
            className="btn btn-wide btn-primary"
            onClick={(): void => {
              close();
            }}
          >
            Fermer
          </button>
        </div>
      </div>
    )}
  </Popup>
);

export default ComfirmationPopUp;
