import { SessionForm } from 'view-models/SessionViewModel';
import Training from 'models/Training';
import moment from 'moment';

const formatSession = (
  values: SessionForm,
  date: moment.Moment | null,
  newTraining: Training | undefined,
  selectedDraft: Training | undefined,
) => {
  const { classroomId, trainerId, from, to, courseNumber } = values;
  const sessionDate = moment(date)
    .startOf('d')
    .toDate();
  const fromDate = new Date(sessionDate);
  fromDate.setHours(from.toDate().getHours(), from.toDate().getMinutes(), 0, 0);
  const toDate = new Date(sessionDate);
  toDate.setHours(to.toDate().getHours(), to.toDate().getMinutes(), 0, 0);
  const formattedData = {
    date: sessionDate,
    classroomId,
    courseNumber,
    trainerId,
    trainingId: newTraining?.id || selectedDraft?.id || 0,
    from: fromDate,
    to: toDate,
  };
  return formattedData;
};

export default formatSession;
