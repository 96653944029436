import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import Classroom from 'models/Classroom';
import React, { useMemo } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { uniqBy } from 'lodash';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { IReplacementForm } from '.';

const getClassroomType = (classroom: Classroom) =>
  `${classroom.type}${classroom.name !== null ? ` ${classroom.name}` : ''}`;

const FORM_NAME = 'classroomReplacementForm';

interface Props {
  replacementClassrooms: Classroom[];
}

const ClassroomReplacementForm: React.FC<Props &
  InjectedFormProps<IReplacementForm, Props>> = ({
  handleSubmit,
  replacementClassrooms,
}) => {
  const formState = useSelector((state: RootState) => state.form);
  const classroomTypeSelectorOptions = useMemo((): ISelectFieldOption<
    string
  >[] => {
    return uniqBy(
      replacementClassrooms.map(el => {
        const classroomType = getClassroomType(el);
        return {
          label: classroomType,
          value: classroomType,
        };
      }),
      'value',
    );
  }, [replacementClassrooms, getClassroomType]);

  const classrooms = useMemo((): ISelectFieldOption<number>[] => {
    const selectedType = formState[FORM_NAME]?.values?.type;
    if (!selectedType) return [];
    return replacementClassrooms
      .filter(el => getClassroomType(el) === selectedType)
      .map(el => ({ label: el.number, value: el.id }));
  }, [replacementClassrooms, formState[FORM_NAME]?.values?.type]);

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <SelectField
        options={classroomTypeSelectorOptions}
        name="type"
        isSearchable
        placeholder="Choisir par type"
        label="Salle"
        validate={[Validators.required]}
      />
      <SelectField
        options={classrooms}
        name="classroomId"
        isSearchable
        placeholder="Choisir par type"
        label="N° de salle"
        validate={[Validators.required]}
      />
      <button
        type="submit"
        className="btn btn-soft-primary align-self-end mt-5"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default reduxForm<IReplacementForm, Props>({
  form: FORM_NAME,
})(ClassroomReplacementForm);
