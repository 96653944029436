import * as React from 'react';
import DataTable from 'react-data-table-component';
// import Fuse from 'fuse.js';
// import { useEffect } from 'react';
import { ProgramWithDays } from 'view-models/ProgramViewModel';
import columns from './columns';

interface Props {
  programs: ProgramWithDays[];
  handleImport: (id: number) => void;
}

const ProgramsTable: React.FC<Props> = ({ programs, handleImport }) => {
  //   const [keyword, setKeyword] = React.useState('');
  //   const [sortedTrainers, setSortedTrainers] = React.useState<Trainer[]>([]);

  //   useEffect(() => {
  //     if (keyword.length === 0) {
  //       setSortedTrainers(trainers);
  //       return;
  //     }
  //     const options = {
  //       threshold: 0.4,
  //       location: 0,
  //       distance: 100,
  //       maxPatternLength: 32,
  //       minMatchCharLength: 1,
  //       keys: [
  //         'lastName',
  //         'firstName',
  //         'phoneNumber',
  //         'email',
  //         'CIN',
  //         'BadgeNumber',
  //       ],
  //     };
  //     const fuse = new Fuse(trainers, options);
  //     const results = fuse.search(keyword).map(el => el.item);
  //     setSortedTrainers(results);
  //   }, [keyword, trainers]);

  //   const TabHeader = () => (
  //     <div className="text-center mt-2">
  //       <input
  //         className="form-control mb-3"
  //         placeholder="Recherche.."
  //         value={keyword}
  //         onChange={event => setKeyword(event.target.value)}
  //       />
  //     </div>
  //   );

  const cols = columns(handleImport);

  return (
    <DataTable
      data={programs}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      //   subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default ProgramsTable;
