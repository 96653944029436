import Exam from 'models/Exam';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import columns from './columns';
interface Props {
  data: Exam[];
}

const AllExamsListTable: React.FC<Props> = ({ data }) => {
  const [keyword, setKeyword] = useState('');
  const [sortedExams, setSortedExams] = useState<Exam[]>([]);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedExams(data);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'username', 'type', 'number', 'createdAt', 'updatedAt'],
    };
    const fuse = new Fuse(data, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedExams(results);
  }, [keyword, data]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <>
      <DataTable
        data={sortedExams}
        columns={columns()}
        subHeader
        noHeader
        keyField="timestamps"
        persistTableHead
        subHeaderAlign="center"
        expandOnRowClicked
        highlightOnHover
        subHeaderComponent={TabHeader()}
        noDataComponent="Aucun élément"
        pagination
      />
    </>
  );
};

export default AllExamsListTable;
