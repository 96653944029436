import AutoComplete, { Props as ComponentProps } from 'components/AutoComplete';
import { Field, Validator } from 'redux-form';
import * as React from 'react';

interface Props extends ComponentProps {
  name: string;
  validate?: Validator[];
  className: string;
  importedValue?: number;
}
const AutoCompleteField: React.FC<Props> = ({
  className,
  name,
  validate,
  ...props
}) => {
  return (
    <Field
      className={className}
      name={name}
      validate={validate}
      component={AutoComplete}
      {...props}
    />
  );
};

export default AutoCompleteField;
