import moment from 'moment';

// in case you go string date like DD/MM/YYYY
export const isDateAfter = (date: string) => {
  return moment().isSameOrAfter(
    date
      .split('/')
      .reverse()
      .join('-'),
    'day',
  );
};
