import {
  FIFTH_SIDEBAR,
  FORTH_SIDEBAR,
  MAIN_SIDEBAR,
  SECOND_SIDEBAR,
  THIRD_SIDEBAR,
} from 'constants/Sidebars';
import { SidebarInterfaceTypesEnum } from 'constants/types/Sidebars';
import { ToggleSidebarPayload } from 'actions/Sidebar';
export interface SidebarState {
  sidebarId: string;
  isOpen: boolean;
}
export interface SidebarsState {
  sidebars: Array<SidebarState>;
}
const initialState = {
  sidebars: [
    {
      sidebarId: MAIN_SIDEBAR,
      isOpen: true,
    },
    {
      sidebarId: SECOND_SIDEBAR,
      isOpen: false,
    },
    {
      sidebarId: THIRD_SIDEBAR,
      isOpen: false,
    },
    {
      sidebarId: FORTH_SIDEBAR,
      isOpen: false,
    },
    {
      sidebarId: FIFTH_SIDEBAR,
      isOpen: false,
    },
  ],
};

export default (
  state: SidebarsState = initialState,
  { type, sidebarId }: ToggleSidebarPayload,
): SidebarsState => {
  let index = 0;
  const sidebars = [...state.sidebars];
  const { isOpen } = sidebars[index];
  switch (type) {
    case SidebarInterfaceTypesEnum.TOGGLE_SIDEBAR:
      index = sidebars.map(e => e.sidebarId).indexOf(sidebarId);
      if (index === -1)
        return {
          ...state,
          sidebars: [...sidebars, { sidebarId, isOpen: true }],
        };
      sidebars[index] = { ...sidebars[index], isOpen: !isOpen };
      return { ...state, sidebars };

    default:
      return { ...state };
  }
};
