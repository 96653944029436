/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';

import { RootState } from 'reducers';
import { DeleteSession, UpdateSession } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { getFinishedModules } from 'actions/Module/getFinishedModule';

import Module from 'models/Module';
import Modal from 'components/Modal';
import ModulesTable from 'components/ModulesTable';
import TrainingSidebar from 'components/TrainingSidebar';
import IModule from 'models/IModule';
import RichField from 'components/RichField';
import CalendarCard from 'components/CalendarCard';

import moment from 'moment';
import { updateModule } from 'actions/Module/updateModule';
import { ModuleEnum } from 'constants/types/Module';
import { useSession } from 'hooks/useSession';
import UpdateSessionSection from 'containers/UpdateSessionSection';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { SessionForm } from 'view-models/SessionViewModel';
// import { DeleteSession } from 'actions';

interface Props {
  toggleModule?: () => void;
  toggleCalendarBar?: () => void;
  isSaved: boolean;
}
// we need to create a new container for this component
const AddCertificateModuleSection: React.FC<Props &
  InjectedFormProps<Module, Props>> = ({
  handleSubmit,
  toggleCalendarBar,
  isSaved,
  toggleModule,
  // initialValues,
  // isSessionOpen,
}) => {
  const dispatch = useDispatch();
  // useModule
  // const { formattedSessions } = useOneShot(initialValues);
  // const handleDeleteSession = (value: number): void => {
  //   dispatch(DeleteSession(value));
  // };
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState<string>();
  const [selectedSessionId, setSelectedSessionId] = useState<number>();

  const { loading } = useSelector((state: RootState) => state.loading);
  const { modules } = useSelector((state: RootState) => state.modules);
  const { newModule } = useSelector((state: RootState) => state.module);
  const { SessionList } = useSelector((state: RootState) => state.session);

  const formattedSessions = SessionList.filter(
    item => item.moduleId === newModule?.id,
  );

  const {
    setstartTime,
    setendTime,
    selectedClassroom,
    setselectedClassroom,
    filtredDataClassrooms,
    formattedDataClassroomsNum,
  } = useSession(moment(date));

  const findModule = (id: number) => {
    const selectedModule = modules?.find(module => module.id === id);
    const ImportedModule = {
      content: selectedModule?.content,
      methods: selectedModule?.methods,
      title: selectedModule?.title,
    };
    dispatch({ type: ModuleEnum.ADD_MODULE_SUCCESS, payload: ImportedModule });
    setImportModalOpen(false);
  };

  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };
  const handleSessionUpdate = (values: SessionForm): void => {
    if (selectedSessionId) {
      dispatch(
        UpdateSession(selectedSessionId, { classroomId: values.classroomId }),
      );
    }
    setModalOpen(false);
  };
  const handleDeleteSession = (value: number): void => {
    dispatch(DeleteSession(value));
  };

  const handleEditSession = (value: number, day: string): void => {
    const editedSession = SessionList.find(session => session.id === value);
    const formattedStartTime = {
      label: moment(editedSession?.from).format('LT A'),
      value: moment(editedSession?.from),
    };
    const formattedEndTime = {
      label: moment(editedSession?.to).format('LT A'),
      value: moment(editedSession?.to),
    };

    setSelectedSessionId(value);
    setstartTime(formattedStartTime);
    setendTime(formattedEndTime);
    setDate(day);
    setModalOpen(true);
  };

  useEffect(() => {
    dispatch(getFinishedModules());
  }, []);

  useEffect(() => {
    dispatch(change('AddCertificateModuleForm', 'title', newModule?.title));
  }, [newModule]);

  useEffect(() => {
    if (SessionList && newModule) {
      dispatch(updateModule(newModule.id));
    }
  }, [SessionList, newModule]);

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Changer La Salle de Cours"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <UpdateSessionSection
          onSubmit={handleSessionUpdate}
          filtredDataClassrooms={filtredDataClassrooms}
          formattedDataClassroomsNum={formattedDataClassroomsNum}
          handleClassroom={handleClassroom}
          selectedClassroom={selectedClassroom}
        />
      </Modal>
      <TrainingSidebar
        header="Module"
        className=" p-4"
        onClose={() => toggleModule?.()}
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="Nom du Module"
              validate={[Validators.required]}
            />
            <RichField
              name="content"
              label="Contenu"
              validate={[Validators.required]}
              pickedContent={(newModule as IModule)?.content}
            />
            <RichField
              name="methods"
              label="Méthode"
              pickedContent={(newModule as IModule)?.methods}
              validate={[Validators.required]}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              onClick={toggleCalendarBar}
              className="btn btn-sm-wide btn-soft-primary mb-2"
              disabled={!isSaved}
            >
              Créer les sessions
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={() => setImportModalOpen(true)}
            >
              Importer Module
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <Modal
              title="Ancient Modules"
              isOpen={importModalOpen}
              closeModal={() => setImportModalOpen(false)}
            >
              {modules?.length && (
                <ModulesTable handleImport={findModule} modules={modules} />
              )}
            </Modal>
            {formattedSessions.map(session => (
              <div key={session.id}>
                <CalendarCard
                  data={{
                    name: `${session.trainer.lastName} ${session.trainer.firstName}`,
                    time: moment(session.from).format('h:mm'),
                    room: `${session.classroom.type} ${session.classroom.number}`,
                    courseNumber: session.courseNumber,
                  }}
                  day={moment(session.date).format('L')}
                  id={session.id}
                  deleteSession={handleDeleteSession}
                  editClassroom={handleEditSession}
                />
              </div>
            ))}
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<Module, Props>({
  form: 'AddCertificateModuleForm',
  enableReinitialize: true,
})(AddCertificateModuleSection);
