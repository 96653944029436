import moment, { Moment } from 'moment';

const subtractArray = (
  originalArray: moment.Moment[],
  tab?: {
    from?: moment.Moment;
    to?: moment.Moment;
  }[],
): Moment[] => {
  if (tab)
    tab.forEach(el => {
      const startIndex = originalArray.findIndex(
        item => item.format('h:mm A') === el?.from?.format('h:mm A'),
      );
      const endIndex = originalArray.findIndex(
        item => item.format('h:mm A') === el?.to?.format('h:mm A'),
      );
      originalArray.splice(startIndex, endIndex - startIndex);
    });
  return originalArray;
};

export default subtractArray;
