import Theme from 'models/Theme';
import Category from 'models/Category';
import { IReduxAction } from 'constants/types/IReduxAction';
import { ModuleEnum } from 'constants/types/Module';
import { AxiosError } from 'axios';
import { IDefaultUserTraining } from 'models/DefaultUserTraining';
import Module from 'models/Module';
import IProgramData from 'models/IProgramData';
import IModule from 'models/IModule';

export interface ModuleState {
  error?: AxiosError | Error;
  themeList: Theme[];
  categoryList: Category[];
  newModule?: Module | IModule;
  selectedModuleDraft?: Module | IModule;
  finishedModule?: Module;
  selectedModuleId?: number | null;
  unfinishedTrainingsList?: Module[];
  finishedModuleList?: Module[];
  programData?: IProgramData;
  userTrainings: IDefaultUserTraining[];
}

const initialState: ModuleState = {
  themeList: [],
  categoryList: [],
  selectedModuleId: null,
  userTrainings: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): ModuleState => {
  switch (type) {
    case ModuleEnum.FETCH_THEMES_SUCCESS:
      return { ...state, themeList: payload };
    case ModuleEnum.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload };
    case ModuleEnum.FETCH_UNFINISHED_MODULE_SUCCESS:
      return { ...state, unfinishedTrainingsList: payload };
    case ModuleEnum.FETCH_THEMES_FAILURE:
    case ModuleEnum.FETCH_CATEGORIES_FAILURE:
    case ModuleEnum.FETCH_FINISHED_MODULE_FAILURE:
    case ModuleEnum.FETCH_UNFINISHED_MODULE_FAILURE:
    case ModuleEnum.FETCH_PROGRAM_DATA_FAILURE:
    case ModuleEnum.GET_MODULE_FAILURE:
      return { ...state, error };
    case ModuleEnum.ADD_MODULE_SUCCESS:
      return { ...state, newModule: payload };
    case ModuleEnum.SET_SELECTED_MODULE_SUCCESS:
      return { ...state, selectedModuleDraft: payload };
    case ModuleEnum.SELECT_MODULE_SUCCESS:
      return { ...state, selectedModuleId: payload };
    case ModuleEnum.UNSELECT_MODULE_SUCCESS:
      return { ...state, selectedModuleId: null };
    case ModuleEnum.FETCH_PROGRAM_DATA_SUCCESS:
      return { ...state, programData: payload };
    case ModuleEnum.GET_MODULE_SUCCESS:
      return { ...state, finishedModule: payload };
    case ModuleEnum.RESET_MODULE:
      return { ...state, newModule: undefined };
    case ModuleEnum.FETCH_FINISHED_MODULE_SUCCESS:
      return { ...state, finishedModuleList: payload };
    case ModuleEnum.FETCH_USER_MODULE_SUCCESS:
      return { ...state, userTrainings: payload };
    default:
      return state;
  }
};
