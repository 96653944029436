import { Login as LoginContainer } from 'containers';
import * as React from 'react';
import { abf } from 'styles';

import { ILoginForm } from 'containers/Login';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from 'actions';
const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (values: ILoginForm): void => {
    const { username, password } = values;
    dispatch(login(username, password, history));
  };

  return (
    <div className="loginbody">
      <div className="licontainer">
        <div>
          <img src={abf} className="logo" alt="ABF_LOGO" />
          <LoginContainer onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};
export default Login;
