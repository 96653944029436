import * as React from 'react';
import SelectField from 'components/SelectField';
import { WrappedFieldArrayProps } from 'redux-form';
import { useEffect } from 'react';
import ISelectFieldOption from 'models/ISelectFieldOption';
import Validators from 'helpers/formValidator';

interface IProps {
  formattedTypes: ISelectFieldOption[];
  formattedTimes: ISelectFieldOption[];
  formattedDates: ISelectFieldOption[];
}

const RenderBreakArrayFields: React.FC<IProps & WrappedFieldArrayProps> = ({
  fields,
  formattedTimes,
  formattedTypes,
  formattedDates,
}) => {
  useEffect(() => {
    fields.push(null);
  }, []);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary py-2 mb-5"
        onClick={(): void => fields.push(null)}
      >
        Ajouter
      </button>
      {fields.map((el, index) => (
        <div className="row mx-0" key={el}>
          <div className="col-3 px-1">
            <SelectField
              options={formattedTypes}
              name={`${el}.breakTypeId`}
              placeholder="Choisir le type"
              validate={[Validators.required]}
              label="Type"
            />
          </div>
          <div className="col-4 px-1">
            <SelectField
              options={formattedDates}
              name={`${el}.date`}
              placeholder="Choisir la date"
              validate={[Validators.required]}
              label="Date"
            />
          </div>
          <div className="col-3 px-1">
            <SelectField
              options={formattedTimes}
              name={`${el}.time`}
              placeholder="Heure"
              validate={[Validators.required]}
              label="Heure"
            />
          </div>
          <div className="col-1 d-flex justify-content-center px-0 m-auto pb-0">
            <button
              type="button"
              className="btn btn-outline-primary py-2"
              onClick={(): void => fields.remove(index)}
            >
              <i className="far fa-trash-alt text-hover-primary" />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default RenderBreakArrayFields;
