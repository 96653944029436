export const monthsArray = [
  { label: 'janvier', value: '1' },
  { label: 'février ', value: '2' },
  { label: 'mars ', value: '3' },
  { label: 'avril', value: '4' },
  { label: 'mai', value: '5' },
  { label: 'juin', value: '6' },
  { label: 'juillet', value: '7' },
  { label: 'août', value: '8' },
  { label: 'septembre', value: '9' },
  { label: 'octobre', value: '10' },
  { label: 'novembre', value: '11' },
  { label: 'décembre', value: '12' },
];
export const yearsArray = [
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022 ', value: '2022' },
  { label: '2023 ', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
];

export const pageStyle = `
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
`;
