import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Moment } from 'moment';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import CalendarToPrint from 'components/CalendarToPrint';
import { monthsArray, pageStyle, yearsArray } from './calendarData';

export interface ICalendarPerMonth {
  date: string;
  day: number;
  fullDate: string;
  moment: Moment;
}

const MonthCalendar: React.FC<{}> = () => {
  const componentRef = React.useRef(null);
  const { SessionList } = useSelector((state: RootState) => ({
    SessionList: state.session.SessionList,
    currentUser: state.user.currentUser,
    userTrainings: state.training.userTrainings,
    token: state.user.token,
  }));

  const [selectedMonth, setSelectedMonth] = React.useState({
    label: '',
    value: '',
  });
  const [selectedYear, setSelectedYear] = React.useState({
    label: '',
    value: '',
  });

  const handleMonthChange = (newValue: { label: string; value: string }) => {
    if (newValue) setSelectedMonth(newValue);
  };
  const handleYearChange = (newValue: { label: string; value: string }) => {
    if (newValue) setSelectedYear(newValue);
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="bg-primary mb-2">
        <div className="space-1 mx-4">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="d-none d-lg-block">
                <h1 className="h1 mb-0 text-white">Calendrier par mois</h1>
              </div>
            </div>
            <div className="col-sm">
              <ReactToPrint
                pageStyle={pageStyle}
                trigger={() => (
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-soft-light mt-6  mb-6"
                      type="button"
                    >
                      Imprimer le Calendrier ↓
                      <i className="fa fa-print" />
                    </button>
                  </div>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="col-sm">
              <div
                className="d-none d-lg-block"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                }}
              >
                <div className="row bg-primary">
                  <div className="col-sm">
                    <Select
                      placeholder="mois"
                      options={monthsArray}
                      onChange={e =>
                        handleMonthChange(e as { label: string; value: string })}
                    />
                  </div>
                  <div className="col-sm">
                    <Select
                      placeholder="année"
                      options={yearsArray}
                      onChange={e =>
                        handleYearChange(e as { label: string; value: string })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          overflowX: 'scroll',
          flex: 1,
        }}
      >
        <CalendarToPrint
          refToPrint={componentRef}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          SessionList={SessionList}
        />
      </div>
    </div>
  );
};

export default MonthCalendar;
