import ImagePickerField from 'components/ImagePickerField';
import InputField from 'components/InputField';
import * as React from 'react';
import { useState } from 'react';
import { TrainerToAdd } from 'models/Trainer';
import Validators from 'helpers/formValidator';
import { reduxForm, InjectedFormProps } from 'redux-form';
import HorizontalSelectorField from 'components/HorizontalSelectorField';

const AddTrainer: React.FC<InjectedFormProps<TrainerToAdd>> = ({
  handleSubmit,
}) => {
  const [selectedRadio, setSelectedRadio] = useState<string>('Etranger');

  return (
    <div className="body">
      <form
        onSubmit={handleSubmit}
        className="container space-1 d-flex flex-column"
      >
        <div className="row">
          <div className="col-lg-6">
            <InputField
              name="firstName"
              label="Nom"
              validate={[Validators.required]}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              name="lastName"
              label="Prenom"
              validate={[Validators.required]}
            />
          </div>
        </div>
        <HorizontalSelectorField
          name="type"
          onChange={(value): void => setSelectedRadio(value as string)}
          options={[
            { label: 'Tunisien', value: 'Tunisien' },
            { label: 'Etranger', value: 'Etranger' },
          ]}
        />
        <div className="row">
          <div className="col-lg-6">
            <InputField
              name="phoneNumber"
              label="tel"
              validate={[Validators.phone]}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              name="CIN"
              label={`${selectedRadio === 'Tunisien' ? 'CIN' : 'Passeport'}`}
              validate={[Validators.required, Validators.numeric]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <InputField
              name="BadgeNumber"
              label="Numero de badge "
              required={selectedRadio === 'Tunisien'}
              disabled={selectedRadio !== 'Tunisien'}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              name="email"
              label="mail"
              validate={[Validators.email]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 m-auto">
            <ImagePickerField
              isPdf
              name="CVUrl"
              label="CV"
              maxFiles={1}
              validate={[Validators.required]}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-wide btn-primary mt-2 align-self-end"
        >
          Enregistrer
        </button>
      </form>
    </div>
  );
};

export default reduxForm<TrainerToAdd>({ form: 'addtrainerform' })(AddTrainer);
