import { IReduxAction } from 'constants/types/IReduxAction';
import { ExamEnum } from 'constants/types/Exam';
import { AxiosError } from 'axios';
import { IDefaultUserTraining } from 'models/DefaultUserTraining';
import Exam, { ExamCandidats } from 'models/Exam';
import IProgramData from 'models/IProgramData';
import { UserEnum } from 'constants/types/User';
import DefaultUser from 'models/DefaultUser';
import Attendance from 'models/Attendence';
import { ExamNote } from 'models/ExamNote';

export interface ExamState {
  error?: AxiosError | Error;
  newExam?: Exam;
  selectedDraft?: Exam;
  finishedExam?: Exam;
  selectedExamId?: number | null;
  unfinishedExamList?: Exam[];
  finishedExamList?: Exam[];
  programData?: IProgramData;
  userTrainings: IDefaultUserTraining[];
  examsList: Exam[];
  candidatsExam?: ExamCandidats;
  usersExam: DefaultUser[];
  examAttendance?: Attendance[];
  examNotes: ExamNote[];

}

const initialState: ExamState = {
  selectedExamId: null,
  userTrainings: [],
  examsList: [],
  examAttendance: [],
  examNotes: [],
  usersExam: [],
  
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): ExamState => {
  switch (type) {
    case ExamEnum.ADD_EXAM_SUCCESS:
      return { ...state, newExam: payload, selectedDraft: payload };
    case ExamEnum.SET_SELECTED_EXAM_SUCCESS:
      return { ...state, selectedDraft: payload};
    case ExamEnum.FETCH_ALL_EXAM_SUCCESS:
      return {
        ...state,
        examsList: payload,
      };
    case ExamEnum.FETCH_ALL_CANDIDATS_EXAM:
      return {
        ...state,
        examAttendance: payload.attendance,
        examNotes: payload.examNotes
      };
    case ExamEnum.UPDATE_EXAM_NOTES:
      return {
        ...state,
        examNotes: payload,
      }
    case ExamEnum.FETCH_ALL_EXAM_FAILURE:
      return { ...state, error };
    case ExamEnum.FETCH_UNFINISHED_EXAM_SUCCESS:
      return { ...state, unfinishedExamList: payload };
    case ExamEnum.FETCH_UNFINISHED_EXAM_FAILURE:
      return { ...state, error: payload };
    case ExamEnum.FETCH_FINISHED_EXAM_FAILURE:
      return { ...state, error };
    case ExamEnum.FETCH_FINISHED_EXAM_SUCCESS:
      return { ...state, finishedExamList: payload };
    case UserEnum.FETCH_USERS_EXAMS_SUCCESS:
      return { ...state, usersExam: payload };
    case UserEnum.RESET_USERS_EXAM:   
    return { ...state, usersExam: [] };
    case UserEnum.FETCH_USERS_EXAMS_FAILURE:
      return { ...state, error: payload };
    case ExamEnum.SELECT_EXAM_SUCCESS: 
    return   { ...state, selectedExamId: payload,   };
    case ExamEnum.RESET_EXAM:
      return { ...state, newExam: undefined, selectedDraft: undefined };
    case ExamEnum.FETCH_EXAM_SUCCESS: 
      return   { ...state, finishedExam: payload  };
    case ExamEnum.UNSELECT_EXAM_SUCCESS:
      return { ...state, selectedExamId: null };

    default:
      return state;
  }
};
