import { ITrainerTrainingsData, ITrainingPerTrainerData } from 'models/Stats';
import React, { useMemo } from 'react';
import moment from 'moment';
import StatCard from './StatCard';

const TrainingRow = ({
  date,
  type,
  title,
  from,
  to,
}: ITrainingPerTrainerData) => {
  const trainingType = useMemo(() => {
    switch (type) {
      case 'Cycle':
        return 'Cycle';
      case 'OneShot':
        return 'Formation continue';
      case 'Certificate':
        return 'Cértificat';

      default:
        return '';
    }
  }, [type]);

  return (
    <li
      className="font-size-1"
      style={{
        lineHeight: 2,
      }}
    >
      <strong>{`${title} (${trainingType}) : `}</strong>
      {`${moment(date).format('[Le] l')} de ${moment(from).format(
        'HH:mm',
      )} à ${moment(to).format('HH:mm')}`}
    </li>
  );
};

export type TrainerTrainingsProps = {
  data: ITrainerTrainingsData[];
  exportCSVButton: React.ReactNode;
};

const TrainerTrainings = ({ data, exportCSVButton }: TrainerTrainingsProps) => {
  return (
    <StatCard
      exportCSVButton={exportCSVButton}
      title="Formations par formateur"
    >
      <ul>
        {data.map(el => (
          <li key={el.id}>
            <strong>{`${el.name} : `}</strong>
            <ul>
              {el.trainings.map(training => (
                <TrainingRow {...training} key={training.id} />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </StatCard>
  );
};

export default TrainerTrainings;
