/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps, change } from 'redux-form';

import Modal from 'components/Modal';
import RichField from 'components/RichField';
import InputField from 'components/InputField';
import ModulesTable from 'components/ModulesTable';
import CalendarCard from 'components/CalendarCard';
import TrainingSidebar from 'components/TrainingSidebar';

import moment from 'moment';
import { RootState } from 'reducers';
import Validators from 'helpers/formValidator';
import { IModuleValuesForm } from 'models/IModule';

import { updateCycleModule } from 'actions/Module/updateCycleModule';
import {
  DeleteSession,
  getCycleSemesterModuleSessions,
  UpdateSession,
} from 'actions';
import { getCycleModules } from 'actions/Module/getCycleModules';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { SessionForm } from 'view-models/SessionViewModel';
import { useSession } from 'hooks/useSession';
import UpdateSessionSection from 'containers/UpdateSessionSection';
interface Props {
  toggleModule?: () => void;
  toggleCalendarBar?: () => void;
  isSaved: boolean;
}
// we need to create a new container for this component
const AddCycleModuleSection: React.FC<Props &
  InjectedFormProps<IModuleValuesForm, Props>> = ({
  toggleCalendarBar,
  toggleModule,
  handleSubmit,
  isSaved,
}) => {
  const dispatch = useDispatch();

  const [date, setDate] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<number>();

  const { loading } = useSelector((state: RootState) => state.loading);
  const { modules } = useSelector((state: RootState) => state.modules);
  const { newSession } = useSelector((state: RootState) => state.session);
  const { SessionList } = useSelector((state: RootState) => state.session);
  const { newCycleModule } = useSelector(
    (state: RootState) => state.cycleModule,
  );
  const { SessionCycleModuleList } = useSelector(
    (state: RootState) => state.session,
  );

  const {
    setstartTime,
    setendTime,
    selectedClassroom,
    setselectedClassroom,
    filtredDataClassrooms,
    formattedDataClassroomsNum,
  } = useSession(moment(date));

  const findModule = (id: number) => {
    const selectedModule = modules?.find(module => module.id === id);
    // generate a new id
    const newModule = {
      content: selectedModule?.content,
      methods: selectedModule?.methods,
      title: selectedModule?.title,
    };
    dispatch({
      type: ModuleCycleEnum.ADD_CYCLE_MODULE_SUCCESS,
      payload: newModule,
    });
    setImportModalOpen(false);
  };

  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };

  const handleSessionUpdate = (values: SessionForm): void => {
    if (selectedSessionId) {
      dispatch(
        UpdateSession(selectedSessionId, { classroomId: values.classroomId }),
      );
    }
    setModalOpen(false);
  };

  const handleEditSession = (value: number, day: string): void => {
    const editedSession = SessionList.find(session => session.id === value);
    const formattedStartTime = {
      label: moment(editedSession?.from).format('LT A'),
      value: moment(editedSession?.from),
    };
    const formattedEndTime = {
      label: moment(editedSession?.to).format('LT A'),
      value: moment(editedSession?.to),
    };

    setSelectedSessionId(value);
    setstartTime(formattedStartTime);
    setendTime(formattedEndTime);
    setDate(day);
    setModalOpen(true);
  };

  useEffect(() => {
    dispatch(getCycleModules());
  }, []);

  const handleDeleteSession = (value: number): void => {
    dispatch(DeleteSession(value));
  };

  useEffect(() => {
    if (newSession && newCycleModule) {
      dispatch(updateCycleModule(newCycleModule.id));
    }
  }, [newSession, newCycleModule]);

  useEffect(() => {
    dispatch(change('AddCycleModuleForm', 'title', newCycleModule?.title));
  }, [newCycleModule]);
  // get module list
  useEffect(() => {
    const moduleId = newCycleModule && newCycleModule.id;
    moduleId && dispatch(getCycleSemesterModuleSessions(newCycleModule?.id));
  }, [SessionCycleModuleList?.length, newCycleModule]);

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Changer La Salle de Cours"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <UpdateSessionSection
          onSubmit={handleSessionUpdate}
          filtredDataClassrooms={filtredDataClassrooms}
          formattedDataClassroomsNum={formattedDataClassroomsNum}
          handleClassroom={handleClassroom}
          selectedClassroom={selectedClassroom}
        />
      </Modal>
      <TrainingSidebar
        header="Module"
        onClose={() => toggleModule?.()}
        className=" p-4"
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="Nom du Module"
              validate={[Validators.required]}
            />
            <RichField
              name="content"
              label="Contenu"
              validate={[Validators.required]}
              pickedContent={newCycleModule?.content}
            />
            <RichField
              name="methods"
              label="Méthode"
              pickedContent={newCycleModule?.methods}
              validate={[Validators.required]}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              onClick={toggleCalendarBar}
              className="btn btn-sm-wide btn-soft-primary mb-2"
              disabled={!isSaved}
            >
              Créer les sessions
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={() => setImportModalOpen(true)}
            >
              Importer Module
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <Modal
              title="Ancient Modules"
              isOpen={importModalOpen}
              closeModal={() => setImportModalOpen(false)}
            >
              {modules?.length && (
                <ModulesTable handleImport={findModule} modules={modules} />
              )}
            </Modal>
            {isSaved &&
              SessionCycleModuleList?.map(session => (
                <div key={session.id}>
                  <CalendarCard
                    data={{
                      name: `${session.trainer.lastName} ${session.trainer.firstName}`,
                      time: moment(session.from).format('h:mm'),
                      room: `${session.classroom.type} ${session.classroom.number}`,
                      courseNumber: session.courseNumber,
                    }}
                    day={moment(session.date).format('L')}
                    id={session.id}
                    deleteSession={handleDeleteSession}
                    editClassroom={handleEditSession}
                  />
                </div>
              ))}
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<IModuleValuesForm, Props>({
  form: 'AddCycleModuleForm',
  enableReinitialize: true,
})(AddCycleModuleSection);
