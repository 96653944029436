import { CloseButton } from 'components/CloseButton';
import React from 'react';

interface IProps {
  className?: string;
  header?: string;
  footer?: React.ReactNode;
  onClose?: () => void;
}

const TrainingSidebar: React.FC<IProps> = ({
  header,
  footer,
  children,
  className,
  onClose,
}) => {
  return (
    <div className="abf-sidebar p-0 ">
      {header && (
        <div
          // className="d-flex align-items-center bg-primary border border-light border-right-0"
          className="d-flex align-items-center bg-primary sidebar-training-container "
          style={{
            height: 80,
            minHeight: 80,
            maxHeight: 80,
          }}
        >
          {onClose && <CloseButton onClose={onClose} />}
          <span className=" text-white h4 font-weight-semi-bold ml-2">
            {header}
          </span>
        </div>
      )}
      <div
        id="abf-sidebar"
        {...{ className }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        {children}
      </div>
      {footer}
    </div>
  );
};

export default TrainingSidebar;
