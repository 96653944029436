export enum BankInterfaceEnum {
  FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS',
  FETCH_BANK_FAILURE = 'FETCH_BANK_FAILURE',
  ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS',
  ADD_BANK_FAILURE = 'ADD_BANK_FAILURE',
  DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS',
  DELETE_BANK_FAILURE = 'DELETE_BANK_FAILURE',
  SELECT_BANK = 'SELECT_BANK',
  SEND_MAILS_FAILURE = 'SEND_MAILS_FAILURE',
}
