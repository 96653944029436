import React, { useState, useEffect } from 'react';

import { WrappedFieldInputProps } from 'redux-form';
import ISelectFieldOption from 'models/ISelectFieldOption';
import RadioButton from '../RadioButton';

export interface Props {
  onChange?: (value?: unknown) => void;
  options: ISelectFieldOption[];
  label?: string;
}
interface ComponentProps extends Props {
  input?: WrappedFieldInputProps;
}
const HorizontalSelector: React.FC<ComponentProps> = ({
  onChange,
  options,

  label,
  input,
}) => {
  const [selectedOption, setSelectedOption] = useState<
    ISelectFieldOption | string | number | undefined
  >(input?.value || (options.length > 0 ? options[0] : undefined));
  useEffect(() => {
    const newValue =
      typeof selectedOption === 'string' || typeof selectedOption === 'number'
        ? selectedOption
        : selectedOption?.value;
    if (onChange) onChange(newValue);
    if (input && input.onChange) input.onChange(newValue);
  }, [selectedOption]);
  useEffect(() => {
    const newValue =
      typeof selectedOption === 'string' || typeof selectedOption === 'number'
        ? selectedOption
        : selectedOption?.value;
    setSelectedOption(newValue as ISelectFieldOption | string | number);
  }, []);
  return (
    <div>
      {label && <span>{label}</span>}
      <div className="d-flex justify-content-around">
        {options.map(option => {
          const optionValue =
            typeof option === 'string' || typeof selectedOption === 'number'
              ? option
              : (option?.value as ISelectFieldOption);
          return (
            <RadioButton
              item={option}
              key={JSON.stringify(option.value)}
              title={option.label}
              onPress={(): void => setSelectedOption(optionValue)}
              selected={optionValue === selectedOption}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HorizontalSelector;
