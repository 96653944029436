import api from 'api';
import { CycleEnum } from 'constants/types/cycle';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import Cycle from 'models/Cycle';
import { toast } from 'react-toastify';

export const addCycle = (
  cycle: Cycle,
  cycleId?: number,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    if (!cycleId) {
      const response = await api.post('/cycles', cycle);
      dispatch({
        type: CycleEnum.ADD_CYCLE_SUCCESS,
        payload: response.data,
      });
    } else {
      const response = await api.patch('/cycles', cycle);
      dispatch({
        type: CycleEnum.ADD_CYCLE_SUCCESS,
        payload: response.data,
      });
    }
    toast('Cycle sauvegardé', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CycleEnum.ADD_CYCLE_FAILURE, error });
  }
};
