import { resetProgram } from 'actions';
import { getCategories, getThemes } from 'actions/Training';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { SemesterEnum } from 'constants/types/semester';
import { errorHandler } from 'helpers/errorHandler';
import { ISemester } from 'models/Semester';

export const addSemesterToCycle = (semester: ISemester): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/semesters', semester);
    dispatch({
      type: SemesterEnum.ADD_SEMESTER_SUCCESS,
      payload: response.data,
    });
    dispatch(getThemes());
    dispatch(getCategories());
    dispatch(resetProgram())
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SemesterEnum.ADD_SEMESTER_FAILURE, error });
  }
};
