
import { ExamEnum } from 'constants/types/Exam';
import { IThunkAction } from 'constants/types/IThunkAction';

import Exam from 'models/Exam';
import { getExamById } from './getExam';


export const setSelectedExam = (exam?: Exam): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: ExamEnum.SET_SELECTED_EXAM_SUCCESS,
    payload: exam,
  });
  exam&&dispatch(getExamById(exam?.id));
};
