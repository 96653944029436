import * as React from 'react';
import {
  InjectedFormProps,
  reduxForm,
  FieldArray,
  reset as RFReset,
} from 'redux-form';
import RenderBreakArrayFields from 'components/RenderBreakArrayFields';
import generateArrayOfTimes from 'helpers/generateArrayOfTimes';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import moment from 'moment';
import Training from 'models/Training';
import { getBreakTypes } from 'actions/BreakType';
import { useEffect } from 'react';
import { BreakForm } from 'view-models/BreakViewModel';
import TrainingSidebar from 'components/TrainingSidebar';
import IModule from 'models/IModule';

interface IProps {
  training?: Training;
  module?: IModule;
  cycleModule?: IModule;
  toggleBreakBar?: () => void;
}

const TimeList = generateArrayOfTimes(8, 18);

const formattedTimes: ISelectFieldOption[] = TimeList.map(el => ({
  label: el.format('h:mm A'),
  value: el,
}));

const AddTrainingBreakSection: React.FC<IProps &
  InjectedFormProps<{ breaks: BreakForm[] }, IProps>> = ({
  training,
  handleSubmit,
  module,
  cycleModule,
  toggleBreakBar,
}) => {
  const dispatch = useDispatch();
  const { BreakTypeList } = useSelector((state: RootState) => state.breakType);
  useEffect(() => {
    dispatch(getBreakTypes());
  }, []);

  const formattedTypes: ISelectFieldOption[] = BreakTypeList.map(el => ({
    label: el.title,
    value: el.id,
  }));

  const { SessionList } = useSelector((state: RootState) => state.session);
  const dates: ISelectFieldOption[] = SessionList.filter(item => {
    if (training) {
      return item.trainingId === training.id;
    }
    if (module) {
      return item.moduleId === module.id;
    }
    if (cycleModule) {
      return item.cycleModuleId === cycleModule.id;
    }
    return undefined;
  }).map(el => ({
    label: moment(el.date).format('L'),
    value: moment(el.date),
  }));

  const formattedDates = [...new Map(dates.map(el => [el.label, el])).values()];

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0 d-flex flex-column">
      <TrainingSidebar
        header="Pause café"
        className="sidebar-training-container p-4"
        onClose={() => toggleBreakBar?.()}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <button
            type="submit"
            className="btn btn-primary py-2 mb-7 align-self-end mr-4"
          >
            Sauvegarder
          </button>
        }
      >
        <FieldArray
          name="breaks"
          component={RenderBreakArrayFields}
          formattedTimes={formattedTimes}
          formattedTypes={formattedTypes}
          formattedDates={formattedDates}
        />
      </TrainingSidebar>
    </form>
  );
};

export default reduxForm<{ breaks: BreakForm[] }, IProps>({
  form: 'addBreakForm',
  onSubmitSuccess: (_, dispatch) => dispatch(RFReset('addBreakForm')),
})(AddTrainingBreakSection);
