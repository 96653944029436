import { useDispatch, useSelector } from 'react-redux';
import { AddBankContainer } from 'containers';
import Bank from 'models/Bank';
import * as React from 'react';
import { UpdateBank as updateBankAction } from 'actions';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';

const UpdateBank: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedbank } = useSelector((state: RootState) => state.banks);
  const handleUpdate = async (data: Bank): Promise<void> => {
    await dispatch(updateBankAction(data));
    history.goBack();
  };
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Banques</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier une banque
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-body">
            <AddBankContainer
              initialValues={selectedbank}
              onSubmit={handleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBank;
