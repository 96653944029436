import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { TrainerToAdd } from 'models/Trainer';

interface Props {
  onChange: (data: TrainerToAdd[]) => void;
}

const CsvTrainer: React.FC<Props> = ({ onChange }) => {
  const [data, setData] = useState<TrainerToAdd[]>([]);
  useEffect(() => {
    onChange(data);
  }, [data]);
  const formatData = (_data: Array<Array<string>>): void => {
    const newArray = _data.slice(1, _data.length - 1);
    const header = _data[0];
    const formattedData: TrainerToAdd[] = [];

    newArray.forEach((singleUser: Array<string>) => {
      let user = {} as TrainerToAdd;
      singleUser.forEach((field: string, fieldId: number) => {
        header.forEach((singleHeader: string, headerId: number) => {
          if (
            singleHeader === 'firstName' ||
            singleHeader === 'lastName' ||
            singleHeader === 'email' ||
            singleHeader === 'phoneNumber' ||
            singleHeader === 'CVUrl' ||
            singleHeader === 'BadgeNumber' ||
            singleHeader === 'CIN'
          ) {
            if (headerId === fieldId && field) {
              user = { ...user, [singleHeader]: field };
            }
          }
        });
      });

      formattedData.push(user);
    });
    setData(formattedData);
  };

  return (
    <CSVReader
      cssClass="btn btn btn-soft-light"
      onFileLoaded={formatData}
      inputId="ObiWan"
    />
  );
};

export default CsvTrainer;
