import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useTrainingView } from 'hooks/useTrainingView';
import { useDispatch, useSelector } from 'react-redux';
import { unselectExamId } from 'actions/Exam';
import { useEffect } from 'react';
import { RootState } from 'reducers';
import { getExamById } from 'actions/Exam/getExam';
import ViewExamAboutSection from 'containers/ViewExamAboutSection';
import ViewExamCandidateSection from 'containers/ViewExamCandidateSection';

const ExamDetails: React.FC = () => {
  const {
    toggleCandidatSection,
    isCandidatOpen,
    isAboutOpen,
    toggleAboutSection,
  } = useTrainingView();

  const dispatch = useDispatch();
  const { selectedExamId } = useSelector((state: RootState) => state.exam);

  useEffect(() => {
    if (!selectedExamId) return;
    dispatch(getExamById(selectedExamId));
  }, [selectedExamId]);

  useEffect(() => {
    return () => {
      dispatch(unselectExamId());
    };
  }, []);

  const { finishedExam } = useSelector((state: RootState) => state.exam);

  if (selectedExamId === null) return <Redirect to="/formation" />;

  return (
    <>
      <div className="bg-primary mb-2">
        <div className="space-1 space-top-lg-1 space-bottom-lg-2 mx-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 mb-0 text-white">
                  {`Evaluation : ${finishedExam?.title}`}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-3">
          <div className="card mt-11">
            <div className="card-body">
              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleAboutSection}
              >
                Informations générales
              </button>

              <button
                type="button"
                className="btn btn-soft-primary btn-block p-2"
                onClick={toggleCandidatSection}
              >
                Ajouter des candidats
              </button>
            </div>
          </div>
        </div>
        {isCandidatOpen && (
          <ViewExamCandidateSection examId={finishedExam?.id} />
        )}
        {isAboutOpen && finishedExam && (
          <ViewExamAboutSection exam={finishedExam} />
        )}
      </div>
    </>
  );
};
export default ExamDetails;
