/* eslint-disable max-lines */
import * as React from 'react';
import {
  AddBank,
  AddClassroom,
  Trainer,
  Training,
  CertificationDetails,
  CycleDetails,
  BreakTypes,
  TrainerHistory,
  ExamDetails,
} from 'pages';
import AllTrainingList from 'pages/AllTrainingList';
import { MAIN_SIDEBAR } from 'constants/Sidebars';
import { Switch } from 'react-router-dom';
import AddCandidate from 'pages/AddCandidate';
import AddTrainer from 'pages/AddTrainer';
import AddTraining from 'pages/AddTraining';
import Candidats from 'pages/Candidats';
import Classrooms from 'pages/Classrooms';
import Clients from 'pages/Clients';
import Documents from 'pages/Documents';
import Evaluation from 'pages/Evaluation';
import Feedbacks from 'pages/Feedbacks';
import Calendar from 'pages/Calendar';
import HomePage from 'pages/HomePage';
import Login from 'pages/Login';
import { RootState } from 'reducers';
import Sidebar from 'react-sidebar';
import { SidebarState } from 'reducers/Sidebar';
import StaticSideBarLinks from 'components/StaticSideBarLinks';
import Statistique from 'pages/Statistique';
import UpdateBank from 'pages/UpdateBank';
import UpdateCandidate from 'pages/UpdateCandidate';
import UpdateClassroom from 'pages/UpdateClassroom';
import UpdateTrainer from 'pages/UpdateTrainer';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'actions/Sidebar';
import { checkLoggingStatus } from 'actions';
import MonthCalendar from 'pages/MonthCalendar';
import UpdateUser from 'pages/UpdateUser';
import CandidatsExamen from 'pages/CandidatsExamen';
import Users from 'pages/Users';
import Spinner from 'components/Spinner';
import BreakPerWeek from 'pages/BreaksPerWeek';
import ClassroomCalendar from 'pages/ClassroomCalendar';
import NotFound from 'components/NotFound';
import BookedClassrooms from 'pages/BookedClassrooms';
import PaymentDetails from 'pages/PaymentDetails';
import AccountingNotes from 'pages/AccountingNotes';
import AllExamsList from 'pages/AllExamsList';
import LoggedOutRoute from './LoggedOutRoute';
import PrivateRoute from './PrivateRoute';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const mainSidebar = useSelector(
    (state: RootState) =>
      state.sidebar.sidebars[
        state.sidebar.sidebars
          .map((e: SidebarState) => e.sidebarId)
          .indexOf(MAIN_SIDEBAR)
      ],
  );

  const { isLogged, isVerified, currentUser } = useSelector(
    (state: RootState) => state.user,
  );

  const toggleMainSidebar = (sidebarId: string) => (): void => {
    dispatch(toggleSidebar(sidebarId));
  };

  React.useEffect(() => {
    dispatch(checkLoggingStatus());
  }, []);

  const renderSwitch = (): JSX.Element => (
    <Switch>
      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute exact path="/addcandidate" component={AddCandidate} />
      <PrivateRoute exact path="/formation" component={Calendar} />
      <PrivateRoute
        exact
        isNotOrganizator={currentUser?.role}
        path="/calendrier-salle"
        component={ClassroomCalendar}
      />
      <PrivateRoute
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        exact
        path="/users"
        component={Users}
      />
      <PrivateRoute
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        exact
        path="/user"
        component={UpdateUser}
      />
      <PrivateRoute
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        exact
        path="/statistique"
        component={Statistique}
      />
      <PrivateRoute exact path="/formateur" component={Trainer} />
      <PrivateRoute exact path="/candidats" component={Candidats} />
      <PrivateRoute exact path="/clients" component={Clients} />
      <PrivateRoute exact path="/evaluation" component={Evaluation} />
      <PrivateRoute exact path="/feedbacks" component={Feedbacks} />
      <PrivateRoute exact path="/documents" component={Documents} />
      <PrivateRoute exact path="/addformateur" component={AddTrainer} />
      <PrivateRoute exact path="/addtraining" component={AddTraining} />
      <PrivateRoute exact path="/détailsformation" component={Training} />
      <PrivateRoute
        exact
        path="/détailsCertification"
        component={CertificationDetails}
      />
      <PrivateRoute exact path="/détailsExamen" component={ExamDetails} />
      <PrivateRoute exact path="/détailsCycle" component={CycleDetails} />
      <PrivateRoute exact path="/updatecandidate" component={UpdateCandidate} />
      <PrivateRoute exact path="/updatetrainer" component={UpdateTrainer} />
      <PrivateRoute exact path="/classrooms" component={Classrooms} />
      <PrivateRoute exact path="/AddClassroom" component={AddClassroom} />
      <PrivateRoute exact path="/addbank" component={AddBank} />
      <PrivateRoute exact path="/UpdateClassroom" component={UpdateClassroom} />
      <PrivateRoute exact path="/UpdateBank" component={UpdateBank} />
      <PrivateRoute exact path="/BreakTypes" component={BreakTypes} />
      <PrivateRoute exact path="/MonthCalendar" component={MonthCalendar} />
      <PrivateRoute
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        exact
        path="/BreaksPerWeek"
        component={BreakPerWeek}
      />

      <PrivateRoute
        exact
        path="/formations"
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        component={AllTrainingList}
      />

      <PrivateRoute
        exact
        path="/examens"
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        component={AllExamsList}
      />
      <PrivateRoute
        exact
        path="/CandidatsExamen/:id"
        isNotLogisitic={currentUser?.role}
        isNotOrganizator={currentUser?.role}
        component={CandidatsExamen}
      />
      <PrivateRoute
        exact
        isNotOrganizator={currentUser?.role}
        path="/booked-classrooms"
        component={BookedClassrooms}
      />
      <PrivateRoute
        exact
        path="/détailsPaiement/:type/:id"
        component={PaymentDetails}
      />
      <PrivateRoute
        exact
        path="/HistoriqueFormateur/:id"
        component={TrainerHistory}
      />
      <PrivateRoute
        exact
        path="/noteComptabilite/:type/:id"
        component={AccountingNotes}
      />
      <LoggedOutRoute exact path="/login" component={Login} />
      <PrivateRoute component={NotFound} />
    </Switch>
  );

  return (
    <div>
      {isVerified ? (
        <Sidebar
          sidebar={<StaticSideBarLinks />}
          open={isLogged && mainSidebar.isOpen}
          docked={isLogged && mainSidebar.isOpen}
          onSetOpen={toggleMainSidebar(MAIN_SIDEBAR)}
          transitions={false}
        >
          {renderSwitch()}
        </Sidebar>
      ) : (
        <Spinner style={{ height: 'calc(90vh - 5rem)' }} />
      )}
    </div>
  );
};

export default App;
