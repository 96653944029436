/* eslint-disable max-lines */
import { CandInterfaceEnum } from 'constants/types/candidates';
import { startLoading, stopLoading } from 'actions/Loading';
import Candidate, { CandidateToAdd } from 'models/Candidate';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { getTraining } from 'actions/Training';
import { getCycleModuleSessions } from 'actions/Module/getCycleModuleSessions';
import { getModuleSessions } from 'actions/Module/getModuleSessions';
import fileDownload from 'js-file-download';
import {
  IFormattedSessionDetails,
  IFormattedSessionAttendance,
} from 'view-models/SessionViewModel';
import { toast } from 'react-toastify';
import { errorHandler } from 'helpers/errorHandler';
import { getExamById } from 'actions/Exam/getExam';

export const getCandidates = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      'Candidates?filter={"where": {"isDeleted":false},"include":["job","bank"]}',
    );
    dispatch({
      type: CandInterfaceEnum.FETCH_CANDIDATES_SUCCESS,
      payload: response.data,
    });

    stopLoading()(dispatch);
  } catch (error) {
    dispatch({ type: CandInterfaceEnum.FETCH_CANDIDATES_FAILURE, error });
    stopLoading()(dispatch);
  }
};

export const addCandidateTraining = (data: {
  trainingId: number;
  candidateId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const response = await api.post('/TrainingCandidates/', data);
    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_TRAINING_SUCCESS,
      payload: response.data,
    });
    getTraining(data.trainingId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_TRAINING_FAILURE, error });
  }
};
export const addCandidateExam = (data: {
  examId: number;
  candidateId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const response = await api.post('/ExamCandidates/', data);

    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_EXAM_SUCCESS,
      payload: response.data,
    });
    getExamById(data.examId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_EXAM_FAILURE, error });
  }
};
export const addCandidateCycle = (data: {
  cycleId: number;
  candidateId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  const moduleId = getState().cycleModule.moduleWithSession?.id;
  try {
    const response = await api.post('/CycleCandidates/', data);
    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_CYCLE_SUCCESS,
      payload: response.data,
    });
    if (moduleId) getCycleModuleSessions(moduleId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_CYCLE_FAILURE, error });
  }
};
export const addCandidateCertificate = (data: {
  certificateId?: number;
  candidateId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const moduleId = getState().modules.moduleWithSession?.id;

    const response = await api.post('/CertificateCandidates/', data);
    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
    if (moduleId) getModuleSessions(moduleId)(dispatch, getState, null);
  } catch (error) {
    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_CERTIFICATE_FAILURE,
      error,
    });
  }
};

export const DeleteCandidateFromExam = (
  examId: number,
  candidateId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/exams/${examId}/candidate/rel/${candidateId}`);
    await api.delete(
      `/attendances/exam/${examId}/candidate/rel/${candidateId}`,
    );

    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_EXAM_SUCCESS,
    });
    getExamById(examId)(dispatch, getState, null);
  } catch (error) {
    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_EXAM_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
export const DeleteCandidateFromTraining = (
  trainingId: number,
  candidateId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/trainings/${trainingId}/candidate/rel/${candidateId}`);
    await api.delete(
      `/attendances/training/${trainingId}/candidate/rel/${candidateId}`,
    );

    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_TRAINING_SUCCESS,
    });
    getTraining(trainingId)(dispatch, getState, null);
  } catch (error) {
    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_TRAINING_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};

export const DeleteCandidateFromCycle = (
  cycleId: number,
  candidateId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  const moduleId = getState().cycleModule.moduleWithSession?.id;

  try {
    startLoading()(dispatch);
    await api.delete(`/cycles/${cycleId}/candidate/rel/${candidateId}`);
    await api.delete(
      `/attendances/semester/${cycleId}/candidate/rel/${candidateId}`,
    );

    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_CYCLE_SUCCESS,
    });

    if (moduleId) getCycleModuleSessions(moduleId)(dispatch, getState, null);
  } catch (error) {
    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_CYCLE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
export const DeleteCandidateFromCerficate = (
  certifiateId: number,
  candidateId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  const moduleId = getState().modules.moduleWithSession?.id;

  try {
    startLoading()(dispatch);
    await api.delete(
      `/certificates/${certifiateId}/candidate/rel/${candidateId}`,
    );
    await api.delete(
      `/attendances/certification/${certifiateId}/candidate/rel/${candidateId}`,
    );

    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_CYCLE_SUCCESS,
    });
    if (moduleId) getModuleSessions(moduleId)(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: CandInterfaceEnum.DELETE_CANDIDATE_FROM_CYCLE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
export const selectCandidate = (candidate: Candidate): IThunkAction => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: CandInterfaceEnum.SELECT_CANDIDATE,
    payload: candidate,
  });
};
export const UpdateCandidate = (
  id: number,
  body: Candidate,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);

    await api.patch(`/Candidates/${id}`, body);
    getCandidates()(dispatch, getState, null);
    stopLoading()(dispatch);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_FAILURE, error });
    stopLoading()(dispatch);
  }
};

export const addCandidate = (candidate: CandidateToAdd): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/Candidates/', candidate);

    dispatch({
      type: CandInterfaceEnum.ADD_CANDIDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_FAILURE, error });
  }
};

export const AddCandidateCsv = (candidate: Candidate[]): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.post('/Candidates/csv', candidate);

    getCandidates()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CandInterfaceEnum.ADD_CANDIDATE_FAILURE, error });
  }
};

export const DeleteCandidate = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.patch(`/Candidates/remove-canditate/${id}`);
    getCandidates()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CandInterfaceEnum.DELETE_CANDIDATE_FAILURE, error });
  }
};
export const generateCondidatesFile = (
  condidates: Candidate[],
  formation: IFormattedSessionDetails,
  theme: string,
  cycle: boolean,
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  const data = {
    condidates,
    formation,
    theme,
    cycle,
  };
  try {
    const response = await api.post('/Candidates/generateFile', data);
    dispatch({
      type: CandInterfaceEnum.GENERATE_CANDIDATES_FILE_SUCCESS,
    });

    const file = await api.get(`containers/docs/download/${response.data}`, {
      responseType: 'blob',
    });
    fileDownload(file.data, `${response.data}`);

    await api.post(
      '/CertificationPrograms/deleteFileAfterCreation',
      response.data,
    );
  } catch (error) {
    toast(error?.response?.data?.error?.message, {
      type: 'error',
    });

    dispatch({
      type: CandInterfaceEnum.GENERATE_CANDIDATES_FILE_FAILURE,
    });
  }
  stopLoading()(dispatch);
};

export const saveCandidatePresence = (
  session: IFormattedSessionAttendance,
  presentCandidates: Candidate[],
  absentCandidates: Candidate[],
): IThunkAction => async (dispatch): Promise<void> => {
  startLoading()(dispatch);
  // need to set isPresent true
  const presentList = presentCandidates.map(e => ({
    candidateId: e.id,
    sessionId: session.id,
    isPresent: true,
  }));
  //
  const absentList = absentCandidates.map(e => ({
    candidateId: e.id,
    sessionId: session.id,
    isPresent: false,
  }));
  const data = [...presentList, ...absentList];
  try {
    await api.post('/Attendances/saveCandidatePresence', data);
    dispatch({ type: CandInterfaceEnum.SAVE_CANDIDATE_PRESENCE_SUCCESS });
  } catch (error) {
    dispatch({ type: CandInterfaceEnum.SAVE_CANDIDATE_PRESENCE_FAILURE });
    toast(error.response.data.error.message, {
      type: 'error',
    });
  }

  stopLoading()(dispatch);
};
export const fetchCandidateAttendanceList = (data: {
  sessionId: number;
  candidateList: Candidate[];
}): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.post('Attendances/candidatesPresenceList', data);
    dispatch({
      type: CandInterfaceEnum.FETCH_CANDIDATES_ATTENDANCE_SUCCESS,
      payload: response.data,
    });

    stopLoading()(dispatch);
  } catch (error) {
    dispatch({
      type: CandInterfaceEnum.FETCH_CANDIDATES_ATTENDANCE_FAILURE,
      error,
    });
    stopLoading()(dispatch);
  }
};

export const getCandidateById = (id: string): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);

    const response = await api.get(`/Candidates/${id}`);
    dispatch({
      type: CandInterfaceEnum.FETCH_CANDIDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // dispatch({
    //   type: ExamEnum.FETCH_ALL_EXAM_FAILURE,
    //   error,
    // });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
