import React from 'react';
import { Link } from 'react-router-dom';
import UserViewModel from 'view-models/UserViewModel';
import ConfirmationPopUp from 'components/ConfirmationPopUp';

const columns = (
  handleDelete: (user: UserViewModel) => void,
  selectUser: (user: UserViewModel) => void,
) => {
  const data = [
    {
      name: 'Nom',
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'Prénom',
      sortable: true,
      selector: 'firstName',
    },
    {
      name: "Nom d'utilisateur",
      selector: 'username',
      sortable: true,
    },
    {
      center: true,
      name: 'Modifier',
      maxWidth: '8vw',
      cell: (user: UserViewModel) => (
        <Link
          to="/user?operation=update"
          type="button"
          className="btn btn-block btn-primary btn-icon 
        d-flex align-items-center justify-content-center"
          onClick={() => selectUser(user)}
        >
          <i className="fa fa-edit" />
        </Link>
      ),
    },
    {
      center: true,
      name: 'Changer MDP',
      maxWidth: '8vw',
      cell: (user: UserViewModel) => (
        <Link
          to="/user?operation=password"
          type="button"
          className="btn btn-block btn-primary btn-icon 
          d-flex align-items-center justify-content-center"
          onClick={() => selectUser(user)}
        >
          <i className="fas fa-key" />
        </Link>
      ),
    },
    {
      center: true,
      name: 'Supprimer',
      maxWidth: '8vw',
      cell: (user: UserViewModel) => (
        <ConfirmationPopUp handleDelete={(): void => handleDelete(user)} />
      ),
    },
  ];
  return data;
};

export default columns;
