import { AxiosError } from 'axios';
import BreakViewModel from 'view-models/BreakViewModel';
import { IReduxAction } from 'constants/types/IReduxAction';
import { BreakEnum } from 'constants/types/Break';
import BreakListPerWeekViewModel from 'view-models/BreakPerWeekViewModel';

export interface BreakState {
  error?: AxiosError | Error;
  BreakList: BreakViewModel[];
  BreakListPerWeek: BreakListPerWeekViewModel[];
  currentBreak?: BreakViewModel;
}

const initialState: BreakState = {
  BreakList: [],
  BreakListPerWeek: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): BreakState => {
  switch (type) {
    case BreakEnum.FETCH_BREAK_SUCCESS:
      return { ...state, BreakList: payload };
    case BreakEnum.FETCH_SEARCHED_BREAKS_PER_WEEK_SUCCESS:
      return { ...state, BreakListPerWeek: payload };
    case BreakEnum.FETCH_BREAK_BY_ID_SUCCESS:
      return { ...state, currentBreak: payload };
    case BreakEnum.FETCH_SEARCHED_BREAKS_PER_WEEK_FAILURE:
      return { ...state, error };
    case BreakEnum.FETCH_BREAK_FAILURE:
      return { ...state, error };
    case BreakEnum.DELETE_BREAK_FAILURE:
      return { ...state, error };
    case BreakEnum.DELETE_BREAK_SUCCESS:
    case BreakEnum.UPDATE_BREAK_SUCCESS:
    case BreakEnum.UPDATE_BREAK_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
