import React from 'react';

type StatCardProps = {
  title: string;
  exportCSVButton: React.ReactNode;
};

const StatCard: React.FC<StatCardProps> = ({
  children,
  title,
  exportCSVButton,
}) => {
  return (
    <div className="card border-0 shadow card-body">
      <div className="pb-2 border-bottom">
        <div className="d-flex justify-content-around align-items-center">
          <span className="h4 font-weight-normal">{title}</span>
          {exportCSVButton}
        </div>
      </div>
      <div className="my-3">{children}</div>
    </div>
  );
};

export default StatCard;
