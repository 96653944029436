export enum ProgramEnum {
  ADD_SEMESTER_PROGRAM_SUCCESS = 'ADD_SEMESTER_PROGRAM_SUCCESS',
  ADD_SEMESTER_PROGRAM_FAILURE = 'ADD_SEMESTER_PROGRAM_FAILURE',
  FETCH_SEMESTER_PROGRAM_SUCCESS = 'FETCH_SEMESTER_PROGRAM_SUCCESS',
  FETCH_SEMESTER_PROGRAM_FAILURE = 'FETCH_SEMESTER_PROGRAM_FAILURE',
  ADD_PROGRAM_SUCCESS = 'ADD_PROGRAM_SUCCESS',
  ADD_CERTIF_PROGRAM_SUCCESS = 'ADD_CERTIF_PROGRAM_SUCCESS',
  ADD_PROGRAM_FAILURE = 'ADD_PROGRAM_FAILURE',
  ADD_CERTIF_PROGRAM_FAILURE = 'ADD_CERTIF_PROGRAM_FAILURE',
  FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS',
  FETCH_PROGRAM_FAILURE = 'FETCH_PROGRAM_FAILURE',
  FETCH_ALL_PROGRAM_SUCCESS = 'FETCH_ALL_PROGRAM_SUCCESS',
  FETCH_ALL_PROGRAM_FAILURE = 'FETCH_ALL_PROGRAM_FAILURE',
  IMPORT_PROGRAM_SUCCESS = 'IMPORT_PROGRAM_SUCCESS',
  IMPORT_PROGRAM_FAILURE = 'IMPORT_PROGRAM_FAILURE',
  RESET_PROGRAM = 'RESET_PROGRAM',
}
