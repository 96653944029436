import {
  AddTrainerCsv,
  disableTrainer,
  getTrainers,
  selectTrainer,
} from 'actions';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';
import TrainerModel, { TrainerToAdd } from 'models/Trainer';
import TrainerTable from 'components/TrainerTable';
import CsvTrainer from 'components/CsvTrainer';

const Trainer: React.FC = () => {
  const { trainerList } = useSelector((state: RootState) => state.trainer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTrainers());
  }, []);
  const HandleUpdate = (trainerToUpdate: TrainerModel): void => {
    dispatch(selectTrainer(trainerToUpdate));
  };
  const handleDisableTrainer = (id: number): void => {
    dispatch(disableTrainer(id));
  };
  const handleChange = (data: TrainerToAdd[]): void => {
    dispatch(AddTrainerCsv(data));
  };
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Formateurs</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier, ajouter ou supprimer un formateur
                </li>
              </ol>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Ajouter une liste des formateurs via un fichier CSV
                </li>
              </ol>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <Link
                  to="/AddFormateur"
                  type="button"
                  className="btn btn-soft-light"
                >
                  Ajouter un formateur
                </Link>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <a
                  href="https://abf-api.edonec.com/api/containers/CSV/download/trainer_template.csv"
                  download
                  type="button"
                  className="btn btn-soft-light"
                >
                  Télécharger modèle CSV
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <CsvTrainer onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des formateurs</h5>
          </div>
          <div className="card-body">
            <TrainerTable
              trainers={trainerList}
              handleDisable={handleDisableTrainer}
              HandleUpdate={HandleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trainer;
