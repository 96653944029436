import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import moment from 'moment';
import { BookedClassroom } from 'models/IBookedClassroom';

interface Props {
  bookedClassrooms: BookedClassroom[];
}

const BookedClassroomTable: React.FC<Props> = ({ bookedClassrooms }) => {
  const [keyword, setKeyword] = useState('');
  const [sortedClassrooms, setSortedClassrooms] = useState<BookedClassroom[]>(
    [],
  );

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedClassrooms(bookedClassrooms);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'title',
        'date',
        'type',
        'classroom.type',
        'classroom.capacity',
        'classroom.number',
        'classroom.cost',
        'classroom.name',
      ],
    };
    const fuse = new Fuse(bookedClassrooms, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedClassrooms(results);
  }, [keyword, bookedClassrooms]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  const columns = () => {
    const data = [
      {
        name: 'Nom du formation',
        selector: 'title',
        center: true,
        sortable: true,
      },
      {
        name: 'Date formation',
        selector: (d: BookedClassroom) => {
          return moment(d.date).format('YYYY-MM-DD');
        },
        center: true,
        sortable: true,
      },
      {
        name: 'Type de formation',
        selector: 'type',
        center: true,
        sortable: true,
      },
      {
        name: 'Nom du centre',
        selector: 'classroom.name',
        center: true,
        sortable: true,
      },
      {
        name: 'Type',
        selector: 'classroom.type',
        center: true,
        sortable: true,
      },
      {
        name: 'Numéro de salle',
        selector: 'classroom.number',
        center: true,
        sortable: true,
      },
      {
        name: 'Capacité',
        sortable: true,
        center: true,
        selector: 'classroom.capacity',
      },
      {
        name: 'Coût (DT)',
        selector: 'classroom.cost',
        center: true,
        sortable: true,
      },
    ];
    return data;
  };

  return (
    <DataTable
      data={sortedClassrooms}
      columns={columns()}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default BookedClassroomTable;
