import IModule from 'models/IModule';
import Training from 'models/Training';
import { BreakForm } from 'view-models/BreakViewModel';

const formatBreak = (
  data: { breaks: BreakForm[] },
  newTraining: Training | undefined,
  selectedDraft: Training | undefined,
) => {
  const formattedData = data.breaks.map(el => ({
    trainingId: newTraining?.id || selectedDraft?.id || 0,
    breakTypeId: el.breakTypeId,
    dateTime: new Date(
      el.date
        .toDate()
        .setHours(
          el.time.toDate().getHours(),
          el.time.toDate().getMinutes(),
          0,
          0,
        ),
    ),
  }));
  return formattedData;
};

export default formatBreak;

export const formatBreakForCertificate = (
  data: { breaks: BreakForm[] },
  newModule?: IModule,
) => {
  const formattedData = data.breaks.map(el => ({
    certificateId: newModule?.certificateId,
    breakTypeId: el.breakTypeId,
    dateTime: new Date(
      el.date
        .toDate()
        .setHours(
          el.time.toDate().getHours(),
          el.time.toDate().getMinutes(),
          0,
          0,
        ),
    ),
  }));
  return formattedData;
};

export const formatBreakForSemester = (
  data: { breaks: BreakForm[] },
  newModule?: IModule,
) => {
  const formattedData = data.breaks.map(el => ({
    semesterId: newModule?.semesterId,
    breakTypeId: el.breakTypeId,
    dateTime: new Date(
      el.date
        .toDate()
        .setHours(
          el.time.toDate().getHours(),
          el.time.toDate().getMinutes(),
          0,
          0,
        ),
    ),
  }));
  return formattedData;
};
