import {
  DeleteClassroom,
  getClassrooms,
  selectClassroom,
  AddClassroomCsv,
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import ClassroomModel, { ClassroomToAdd } from 'models/Classroom';
import ClassroomTable from 'components/ClassroomTable';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';
import CsvClassroom from 'components/CsvClassroom';

import { API } from 'api';

const Classroom: React.FC = () => {
  const dispatch = useDispatch();
  const { classroomList } = useSelector((state: RootState) => state.Classroom);
  useEffect(() => {
    dispatch(getClassrooms());
  }, []);

  const HandleUpdate = (classroom: ClassroomModel): void => {
    dispatch(selectClassroom(classroom));
  };
  const HandleDelete = (id: number): void => {
    dispatch(DeleteClassroom(id));
  };

  const handleChange = (data: ClassroomToAdd[]): void => {
    dispatch(AddClassroomCsv(data));
  };
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Salles</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier, ajouter ou supprimer une salle
                </li>
              </ol>
            </div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <Link
                    to="/AddClassroom"
                    type="button"
                    className="btn btn btn-soft-light"
                  >
                    Ajouter une salle
                  </Link>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <a
                    href={`${API}/api/containers/CSV/download/classroom_template.csv`}
                    download
                    type="button"
                    className="btn btn-soft-light"
                  >
                    Télécharger modèle CSV
                  </a>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <CsvClassroom onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des salles</h5>
          </div>
          <div className="card-body">
            <ClassroomTable
              classrooms={classroomList}
              handleDelete={HandleDelete}
              HandleUpdate={HandleUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Classroom;
