/* eslint-disable react/jsx-wrap-multilines */
import { SessionElement } from 'models/Trainer';
import * as React from 'react';
import Popup from 'reactjs-popup';

const columns = (
  handleSettleSession: (id: number) => void,
  handleUnsettleSession: (id: number) => void,
) => {
  const data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Nombre de Séances',
      selector: 'nmbCourse',
      sortable: true,
    },
    {
      name: 'Module',
      selector: 'moduleTitle',
      sortable: true,
    },
    {
      name: 'Type de La Formation',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Durée ',
      selector: 'duration',
      sortable: true,
    },
    {
      center: true,
      name: 'Payée ?',
      maxWidth: '8vw',
      cell: (session: SessionElement) => (
        <>
          {session.Paid ? (
            <Popup
              trigger={
                <div
                  role="button"
                  tabIndex={session.id}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="fas fa-check" />
                </div>
              }
              modal
            >
              {(close): JSX.Element => (
                <div>
                  <span className=" d-flex justify-content-center mb-2">
                    Etes-Vous Sur de Vouloir Décocher cette Séances ?
                  </span>
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      role="button"
                      tabIndex={session.id}
                      onKeyPress={() => handleUnsettleSession(session.id)}
                      onClick={() => handleUnsettleSession(session.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="font-weight-bold mr-4">Oui</span>
                    </div>
                    <div
                      role="button"
                      style={{ cursor: 'pointer' }}
                      tabIndex={session.id}
                      onKeyPress={(): void => {
                        close();
                      }}
                      onClick={(): void => {
                        close();
                      }}
                    >
                      <span className="font-weight-bold ml-4">Non</span>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          ) : (
            <Popup
              trigger={
                <div
                  role="button"
                  tabIndex={session.id}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="fas fa-times" />
                </div>
              }
              modal
            >
              {(close): JSX.Element => (
                <div>
                  <span className=" d-flex justify-content-center mb-2">
                    Etes-Vous Sur de Vouloir Confirmer le Paiement de Cette
                    Séance ?
                  </span>
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      role="button"
                      tabIndex={session.id}
                      onKeyPress={() => handleSettleSession(session.id)}
                      onClick={() => handleSettleSession(session.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="font-weight-bold mr-4">Oui</span>
                    </div>
                    <div
                      role="button"
                      style={{ cursor: 'pointer' }}
                      tabIndex={session.id}
                      onKeyPress={(): void => {
                        close();
                      }}
                      onClick={(): void => {
                        close();
                      }}
                    >
                      <span className="font-weight-bold ml-4">Non</span>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </>
      ),
    },
  ];
  return data;
};

export default columns;
