import React from 'react';
import Exam from 'models/Exam';
import moment from 'moment';
import { Link } from 'react-router-dom';

const columns = () => {
  const headerCols = [
    {
      name: 'Titre',
      selector: 'title',
      center: true,
      sortable: true,
    },
    {
      name: 'Surveillant',
      selector: (d: Exam) => {
        return d?.defaultUser[0]?.username;
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Etablissement',
      selector: (d: Exam) => {
        return d.session[0]?.classroom.type || 'Non Assigné';
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Numéro de salle',
      selector: (d: Exam) => {
        return d.session[0]?.classroom.number || 'Non Assigné';
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Date passage',
      selector: (d: Exam) => {
        return d.session[0]
          ? moment(d.session[0]?.from).format('LLL')
          : 'Non Assigné';
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Date creation',
      selector: (d: Exam) => {
        return moment(d.createdAt).format('LLL');
      },
      center: true,
      sortable: true,
    },
    {
      center: true,
      name: 'Candidats',
      maxWidth: '8vw',
      cell: (examen: Exam) => (
        <Link
          to={`/CandidatsExamen/${examen.id}`}
          type="button"
          className="btn btn-block btn-primary btn-icon d-flex 
          align-items-center justify-content-center"
        >
          <i className="fa fa-users" />
        </Link>
      ),
    },
  ];
  return headerCols;
};

export default columns;
