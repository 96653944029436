import { IThunkAction } from 'constants/types/IThunkAction';
import { startLoading, stopLoading } from 'actions';
import { BreakTypeEnum } from 'constants/types/BreakType';
import BreakType from 'models/BreakType';
import api from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { BreakTypeForm } from 'view-models/BreakTypeViewModel';
import { BreakToAdd } from 'models/Break';

export const getBreakTypes = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('breakTypes');
    dispatch({
      type: BreakTypeEnum.FETCH_BREAK_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BreakTypeEnum.FETCH_BREAK_TYPE_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addBreakType = (breakData: BreakType): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    const response = await api.post('/breakTypes/', breakData);

    dispatch({
      type: BreakTypeEnum.ADD_BREAK_SUCESS,
      payload: response.data,
    });
    getBreakTypes()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BreakTypeEnum.ADD_BREAK_FAILURE, error });
  }
};

export const addBreakTypeCsv = (
  breakType: BreakToAdd[],
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    await api.post('/breakTypes/csv', breakType);

    getBreakTypes()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BreakTypeEnum.ADD_BREAK_FAILURE, error });
  }
};

export const getBreakType = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    const response = await api.get(`/breakTypes/${id}`);

    dispatch({
      type: BreakTypeEnum.GET_BREAK_TYPE_SUCCESS,
      payload: response.data,
    });
    getBreakTypes()(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: BreakTypeEnum.GET_BREAK_TYPE_FAILURE, error });
  }
};

export const editBreakType = (
  breakType: BreakTypeForm,
  id?: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.patch(`BreakTypes/${id}`, breakType);
    dispatch({
      type: BreakTypeEnum.UPDATE_BREAK_TYPE_SUCCESS,
    });
    getBreakTypes()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: BreakTypeEnum.UPDATE_BREAK_TYPE_FAILURE,
      error,
    });
  }
};

// export const deleteBreakType = (id: number): IThunkAction => async (
//   dispatch,
//   getState,
// ): Promise<void> => {
//   try {
//     const response = await api.post(`/breakTypes/${id}`);

//     dispatch({
//       type: BreakTypeEnum.ADD_BREAK_SUCESS,
//       payload: response.data,
//     });
//     getBreakTypes()(dispatch, getState, null);
//   } catch (error) {
//     dispatch({ type: BreakTypeEnum.ADD_BREAK_FAILURE, error });
//   }
// };
