import React from 'react';
import Contact from 'models/Contact';

export interface IProps {
  contact: Contact[];
}

const ContactInfo: React.FC<IProps> = ({ contact }) => {
  return (
    <>
      {contact &&
        contact.map(el => (
          <div className="mb-5">
            <div className="row align-items-center mb-2">
              <div className="col h5 font-weight-bold text-primary">
                {`${el.firstName} ${el.lastName}`}
              </div>
              <div className="col-auto h6 m-0 font-weight-bold">{el.job}</div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col">{el.email}</div>
              <div className="col-auto">{el.phoneNumber}</div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col">{el.emailtwo}</div>
              <div className="col-auto">{el.phoneNumbertwo}</div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col">{el.emailthree}</div>
              <div className="col-auto">{el.phoneNumberthree}</div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ContactInfo;
