import * as React from 'react';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import BreakListPerWeekViewModel from 'view-models/BreakPerWeekViewModel';
import columns from './columns';

interface Props {
  breaksPerWeek: BreakListPerWeekViewModel[];
  handleEdit: (id?: number) => void;
  handleDelete: (id?: number) => void;
}

const BreaksPerWeekTable: React.FC<Props> = ({
  breaksPerWeek,
  handleDelete,
  handleEdit,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedBreaksPerWeeks, setSortedBreaksPerWeeks] = React.useState<
    BreakListPerWeekViewModel[]
  >([]);

  React.useEffect(() => {
    if (keyword.length === 0) {
      setSortedBreaksPerWeeks(breaksPerWeek);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'totalCandidates', 'trainingType'],
    };
    const fuse = new Fuse(sortedBreaksPerWeeks, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedBreaksPerWeeks(results);
  }, [keyword, breaksPerWeek]);
  const cols = columns(handleEdit, handleDelete);
  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <DataTable
      data={sortedBreaksPerWeeks}
      columns={cols}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default BreaksPerWeekTable;
