/* eslint-disable max-lines */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps, change } from 'redux-form';

import { RootState } from 'reducers';
import Validators from 'helpers/formValidator';
import { useCertificate } from 'hooks/useCertificate';

import { deleteCycleModule } from 'actions/Module/deleteModule';
import { getSemesterModules } from 'actions/Module/getSemesterModules';

import Modal from 'components/Modal';
import InputField from 'components/InputField';
import ModuleCard from 'components/ModuleCard';
import TrainingSidebar from 'components/TrainingSidebar';
import AutoCompleteField from 'components/AutoCompleteFiled';
import { importSemester } from 'actions/Semester/importSemester';
import IModule from 'models/IModule';
import Semester from 'models/Semester';
import SemestersTable from 'components/SemestersTable';
import { getAllSemestersWithModules } from 'actions/Semester/getAllSemesters';
import IImportedSemester from 'models/IimportedSemester';
import { getCategories, getThemes } from 'actions';
import BreakCard from 'components/BreakCard';
import { DeleteBreak } from 'actions/Break';
interface Props {
  toggleSemester?: () => void;
  handlePublish: () => void;
  toggleModule?: () => void;
  setisSaved: (isSaved: boolean) => void;
  setIsModuleSaved: (isSaved: boolean) => void;
  openModuleSection?: () => void;
  toggleBreakBar?: () => void;
  toggleProgramBar?: () => void;
  toggleCalendarBar?: () => void;
  toggleUserBar?: () => void;
  isSaved: boolean;
}
const AddCycleSemesterSection: React.FC<Props &
  InjectedFormProps<Semester, Props>> = ({
  setIsModuleSaved,
  toggleSemester,
  toggleModule,
  handlePublish,
  setisSaved,
  openModuleSection,
  toggleProgramBar,
  toggleBreakBar,
  toggleUserBar,
  handleSubmit,
  isSaved,
  initialValues,
  toggleCalendarBar,
}) => {
  const dispatch = useDispatch();
  const { themeList, categoryList, formattedSemesterBreaks } = useCertificate(
    initialValues,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [generateFile, setGenerateFile] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState<IImportedSemester>();
  const { newSemester } = useSelector((state: RootState) => state.semester);
  const { newCycleModule } = useSelector(
    (state: RootState) => state.cycleModule,
  );
  const { semesterModuleList } = useSelector(
    (state: RootState) => state.cycleModule,
  );

  const { newCycle } = useSelector((state: RootState) => state.cycle);
  const { loading } = useSelector((state: RootState) => state.loading);
  const { semesterList } = useSelector((state: RootState) => state.semester);

  const unhandledModules = semesterModuleList?.find(
    e => e.duration === null || e.duration === '0' || !e.duration,
  );
  const handleDeleteModule = (value: number): void => {
    dispatch(deleteCycleModule(value));
  };

  useEffect(() => {
    if (!newSemester) return;
    dispatch(getThemes());
    dispatch(getCategories());
  }, [newSemester]);

  useEffect(() => {
    dispatch(getAllSemestersWithModules());
  }, []);

  useEffect(() => {
    if (unhandledModules || !semesterModuleList?.length) setGenerateFile(false);
    else setGenerateFile(true);
  }, [semesterModuleList, unhandledModules]);

  useEffect(() => {
    if (!newSemester?.id || !newCycleModule) return;
    dispatch(getSemesterModules(newSemester.id));
  }, [newSemester, newCycleModule]);

  useEffect(() => {
    dispatch(change('addSemesterForm', 'title', selectedSemester?.title));
  }, [selectedSemester]);

  useEffect(() => {
    if (!newSemester) return;
    dispatch(change('addSemesterForm', 'title', newSemester?.title));
  }, [newSemester]);
  const handleDeleteBreak = (value: number): void => {
    dispatch(DeleteBreak(value));
  };
  const importSemesterHundler = (id?: number) => {
    const findedSemester = semesterList?.find(semester => semester.id === id);
    delete findedSemester?.id;
    delete findedSemester?.createdAt;
    delete findedSemester?.updatedAt;
    if (findedSemester) {
      const filtredModules: Partial<
        IModule
      >[] = findedSemester?.cycleModule.map(module => ({
        title: module.title,
        content: module.content,
        methods: module.methods,
      }));
      setSelectedSemester(findedSemester);
      dispatch(
        importSemester({
          ...findedSemester,
          cycleId: newCycle?.id,
          cycleModule: filtredModules,
        }),
      );
      setisSaved(true);
      setModalOpen(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Ancient Semesters"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        {semesterList?.length && (
          <SemestersTable
            categoryList={categoryList}
            themeList={themeList}
            handleImport={importSemesterHundler}
            Semesters={semesterList}
          />
        )}
      </Modal>
      <TrainingSidebar
        header="Semester"
        onClose={() => toggleSemester?.()}
        className="p-4"
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="titre du semester"
              validate={[Validators.required]}
            />
            <AutoCompleteField
              importedValue={
                (newSemester?.themeId as number) || selectedSemester?.themeId
              }
              className="orange badge-outline-primary"
              label="Thème"
              name="themeId"
              validate={[Validators.required]}
              suggestions={themeList}
            />
            <AutoCompleteField
              className="orange badge-outline-primary"
              importedValue={
                (newSemester?.categoryId as number) ||
                selectedSemester?.categoryId
              }
              label="Categorie"
              name="categoryId"
              validate={[Validators.required]}
              suggestions={categoryList}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleModule}
              disabled={!isSaved || !newSemester}
            >
              Créer les Modules
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={() => setModalOpen(true)}
            >
              Importer Semester
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            {semesterModuleList?.map(module => {
              return (
                <div key={module.id}>
                  <ModuleCard
                    setisSaved={setIsModuleSaved}
                    toggleCalendarBar={toggleCalendarBar}
                    toggleModule={openModuleSection}
                    title={module.title}
                    content={module.content}
                    methods={module.methods}
                    duration={module.duration}
                    days={module.days}
                    semesterId={module.semesterId}
                    courseNumber={module.courseNumber}
                    id={module.id}
                    deleteModule={handleDeleteModule}
                  />
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleBreakBar}
              disabled={!isSaved}
            >
              Ajouter les Pauses café
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            {formattedSemesterBreaks.map(item => (
              <div key={item.id}>
                <BreakCard data={item} deleteBreak={handleDeleteBreak} />
              </div>
            ))}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleProgramBar}
              disabled={!generateFile}
            >
              Générer la fiche programme du semestre
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleUserBar}
              disabled={!isSaved}
            >
              Ajouter des organisateurs
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-primary mb-2"
              onClick={handlePublish}
              disabled={!isSaved}
            >
              PUBLIER
            </button>
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<Semester, Props>({
  form: 'addSemesterForm',
  enableReinitialize: true,
})(AddCycleSemesterSection);
