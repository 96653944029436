import { JobInterfaceEnum } from 'constants/types/job';
import Job from 'models/Job';
import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';

export interface BankState {
  error?: AxiosError | Error;
  jobList: Job[];
}

const initialState: BankState = {
  jobList: [],
};

export default (
  state: BankState = initialState,
  action: IReduxAction,
): BankState => {
  const { type, error, payload } = action;
  switch (type) {
    case JobInterfaceEnum.FETCH_JOB_SUCCESS:
      return {
        ...state,
        jobList: payload,
      };
    case JobInterfaceEnum.FETCH_JOB_FAILURE:
      return {
        ...state,
        error,
      };

    default:
      return state;
  }
};
