import { startLoading, stopLoading } from 'actions/Loading';
import api from 'api';
import { CycleEnum } from 'constants/types/cycle';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const getFinishedCycles = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `cycles/user-cycles?filter={"where":{"isFinished":true}}`,
    );
    dispatch({
      type: CycleEnum.FETCH_FINISHED_CYCLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: CycleEnum.FETCH_FINISHED_CYCLE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
