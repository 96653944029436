import { CandInterfaceEnum } from 'constants/types/candidates';
import Candidate, { ICandidateWithAttendance } from 'models/Candidate';
import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';

export interface CandidateState {
  error?: AxiosError | Error;
  candidateList: Candidate[];
  selectedcandidate?: Candidate;
  candidateWithAttendance?: ICandidateWithAttendance[];
  candidate?: Candidate;
}

const initialState: CandidateState = {
  candidateList: [],
};

export default (
  state: CandidateState = initialState,
  action: IReduxAction,
): CandidateState => {
  const { type, error, payload } = action;
  switch (type) {
    case CandInterfaceEnum.FETCH_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidateList: payload,
      };
    case CandInterfaceEnum.FETCH_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidate: payload,
      };
    case CandInterfaceEnum.FETCH_CANDIDATES_ATTENDANCE_FAILURE:
    case CandInterfaceEnum.FETCH_CANDIDATES_FAILURE:
      return {
        ...state,
        error,
      };
    case CandInterfaceEnum.SELECT_CANDIDATE:
      return {
        ...state,
        selectedcandidate: payload,
      };
    case CandInterfaceEnum.FETCH_CANDIDATES_ATTENDANCE_SUCCESS:
      return { ...state, candidateWithAttendance: payload };
    case CandInterfaceEnum.RESET_CANDIDATE_ATTENDANCE_LIST:
      return { ...state, candidateWithAttendance: undefined };
    default:
      return state;
  }
};
