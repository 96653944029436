import { TrainingTypeCountData } from 'models/Stats';
import React from 'react';
import StatCard from './StatCard';

export const DataDisplay = React.memo(
  ({
    title,
    value,
    className,
  }: {
    title: string;
    value: number;
    className?: string;
  }) => (
    <div className={className}>
      <strong>{`${title} : `}</strong>
      {`${value} participant${value > 1 ? 's' : ''}`}
    </div>
  ),
);

const CandidatesPerTrainingType = ({
  cycle,
  certificate,
  training,
  exportCSVButton,
}: TrainingTypeCountData) => {
  return (
    <StatCard
      exportCSVButton={exportCSVButton}
      title="Nombre de participants par type de formation"
    >
      <DataDisplay title="Cycle" value={cycle} />
      <DataDisplay title="Certificats" className="mt-2" value={certificate} />
      <DataDisplay
        title="Formation Continue"
        className="mt-2"
        value={training}
      />
    </StatCard>
  );
};

export default CandidatesPerTrainingType;
