import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ModuleEnum } from 'constants/types/Module';
import IModule from 'models/IModule';

export const selectModuleCard = (
  module: Partial<IModule>,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({
      type: ModuleCycleEnum.ADD_CYCLE_MODULE_SUCCESS,
      payload: module,
    });
  } catch (error) {
    dispatch({ type: ModuleEnum.ADD_MODULE_FAILURE, error });
  }
};

export const selectModuleCertifCard = (
  module: Partial<IModule>,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({
      type: ModuleEnum.ADD_MODULE_SUCCESS,
      payload: module,
    });
  } catch (error) {
    dispatch({ type: ModuleEnum.ADD_MODULE_FAILURE, error });
  }
};
