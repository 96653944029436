import { AxiosError } from 'axios';
import { IReduxAction } from 'constants/types/IReduxAction';
import { UserEnum } from 'constants/types/User';
import UserViewModel from 'view-models/UserViewModel';

export interface UserState {
  error?: AxiosError | Error;
  UserList: UserViewModel[];
  selectedUser?: UserViewModel;
}

const initialState: UserState = {
  UserList: [],
};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): UserState => {
  switch (type) {
    case UserEnum.FETCH_USERS_SUCCESS:
      return { ...state, UserList: payload };
    case UserEnum.FETCH_USERS_FAILURE:
      return { ...state, error };
    case UserEnum.SET_SELECTED_USER_SUCCESS:
      return { ...state, selectedUser: payload };
    default:
      return state;
  }
};
