import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import moment from 'moment';
import { filterTrainers } from 'helpers/filterTrainers';
import _ from 'lodash';
interface Props {
  refToPrint: React.RefObject<HTMLDivElement>;
}
const ViewCertificateDetails: React.FC<Props> = ({ refToPrint }) => {
  const { certificateDetails } = useSelector(
    (state: RootState) => state.certificate,
  );

  return (
    <div
      ref={refToPrint}
      className="container space-1 space-top-lg-0 ml-2 mt-lg-n10 col-7"
    >
      <div className="card-body" style={{ margin: '0px' }}>
        <div className="card">
          <div
            className="card-header"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <h4
              className="card-header-title text-center"
              style={{ marginRight: 0 }}
            >
              {`Formation : ${
                certificateDetails?.title ? certificateDetails.title : ''
              }`}
            </h4>
            <span>{`Fiche Générée le ${moment().format('L')}`}</span>
          </div>
          <div className="card-body row">
            <div className="container col-11 space-1 d-flex flex-column ">
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Type de La Formation : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${
                      certificateDetails?.type ? certificateDetails.type : ''
                    }`}
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <h5 className="mr-0">{`Total des Modules Associés : `}</h5>
                </div>
                <div className="col-4">
                  <p className="font-weight-light font-italic">
                    {`${
                      certificateDetails?.modulesTable.length
                        ? certificateDetails.modulesTable.length
                        : 0
                    }`}
                  </p>
                </div>
              </div>

              {certificateDetails &&
                certificateDetails.modulesTable.map((module, _index) => {
                  return (
                    <div key={_index.toString() + module.mdTitle}>
                      <hr style={{ width: '80%' }} />

                      <div className="row pt-2 mt-0">
                        <h2>{`Nom Du Module : ${module.mdTitle}`}</h2>
                      </div>
                      <div className="row mt-2">
                        <div className="col-8">
                          <h5 className="mr-0">{`Nombre de Jours et Durée : `}</h5>
                        </div>
                        <div className="col-4">
                          <p className="font-weight-light font-italic">
                            {`${module?.nmbDays ||
                              0} jours / ${module?.nmbHours || 0} heures`}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-8">
                          <h5 className="mr-0">{`Dates Associées : `}</h5>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            {_.uniq(module.dates).map((d, ind) => {
                              return (
                                <p
                                  key={ind.toString() + d.toString()}
                                  className="font-weight-light font-italic mr-2"
                                >
                                  {` ${d}, `}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className=" mt-2">
                        <div className="row">
                          <div className="col-sm">
                            <h5>{`Formateurs : `}</h5>
                          </div>

                          {filterTrainers(module).map((t, index) => {
                            return (
                              <div
                                key={index.toString() + t.toString()}
                                className="pl-2"
                              >
                                <p className="font-weight-light font-italic">
                                  {`${t.tr} (${t.hr} heures),`}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCertificateDetails;
