import { AxiosError } from 'axios';
import { CycleEnum } from 'constants/types/cycle';
import { IReduxAction } from 'constants/types/IReduxAction';
import CycleViewModel from 'view-models/CycleViewModel';

export interface CycleState {
  error?: AxiosError | Error;
  Cycles?: CycleViewModel[];
}

const initialState: CycleState = {};

export default (
  state = initialState,
  { type, payload, error }: IReduxAction,
): CycleState => {
  switch (type) {
    case CycleEnum.FETCH_ALL_CYCLES_SUCCESS:
      return { ...state, Cycles: payload };
    case CycleEnum.FETCH_ALL_CYCLES_FAILURE:
      return { ...state, error };
    default:
      return state;
  }
};
