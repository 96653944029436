export const sliceArrayOfTimes = (
  times: moment.Moment[],
  unavailable?: {
    from?: moment.Moment;
    to?: moment.Moment;
  }[],
): moment.Moment[] => {
  if (!unavailable) return times;
  let index;
  let i = 0;

  while (index === undefined && i < unavailable.length) {
    const currentUnavailableFrom = unavailable[i].from;
    if (
      currentUnavailableFrom &&
      currentUnavailableFrom.format('HH:mm') > times[0].format('HH:mm')
    ) {
      index = times.findIndex(
        el => el.format('HH:mm') > currentUnavailableFrom.format('HH:mm'),
      );
    }
    i += 1;
  }
  return times.slice(0, index);
};
