import 'react-dates/lib/css/_datepicker.css';
import React, { useState } from 'react';
import { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';

export type DateChangeCallback = {
  startDate: Moment | null;
  endDate: Moment | null;
};

type AppDateRangePickerProps = {
  onDatesChange: (arg: DateChangeCallback) => void;
  startDate: Moment;
  endDate: Moment;
  minDate?: Moment;
  maxDate?: Moment;
};

const AppDateRangePicker = ({
  onDatesChange,
  startDate,
  endDate,
  minDate,
  maxDate,
}: AppDateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );

  return (
    <DateRangePicker
      startDateId="start-date"
      endDateId="end-date"
      onFocusChange={setFocusedInput}
      {...{ onDatesChange, startDate, endDate, focusedInput, minDate, maxDate }}
      hideKeyboardShortcutsPanel
      noBorder
      small
      isOutsideRange={() => false}
    />
  );
};

export default AppDateRangePicker;
