import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const publishCertificate = (
  certificateId?: number,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    const response = await api.patch('/certificates', {
      id: certificateId,
      isFinished: true,
    });
    dispatch({
      type: CertificateEnum.PUBLISH_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
    toast('Certification publiée', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CertificateEnum.PUBLISH_CERTIFICATE_FAILURE, error });
  }
};
