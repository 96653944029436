import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { SemesterEnum } from 'constants/types/semester';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const FetchSemesterInformations = (
  id: number | string,
): IThunkAction => async dispatch => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`semesters/semester-details/${id}`);
    dispatch({
      type: SemesterEnum.FETCH_SEMESTER_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: SemesterEnum.FETCH_SEMESTER_DETAILS_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
