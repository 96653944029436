import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { IThunkAction } from 'constants/types/IThunkAction';
import { ModuleEnum } from 'constants/types/Module';
import { errorHandler } from 'helpers/errorHandler';

export const getModuleSessions = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `modules/${id}?filter={"include" : [{"session" : ["classroom","trainer"]},
      {"certificate" : ["theme" , "category" , "candidate"]}]}`,
    );
    dispatch({
      type: ModuleEnum.FETCH_MODULES_SESSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleEnum.FETCH_MODULES_SESSIONS_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};


