import React from 'react';
import { Field, Validator } from 'redux-form';
import HorizontalSelector, {
  Props as SelectorProps,
} from '../HorizontalSelector';
interface Props extends SelectorProps {
  name: string;
  validate?: Validator[];
}
const HorizontalSelectorField: React.FC<Props> = ({
  name,
  validate,
  ...selectorProps
}) => (
  <Field
    name={name}
    component={HorizontalSelector}
    validate={validate}
    {...selectorProps}
  />
);
export default HorizontalSelectorField;
