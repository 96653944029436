/* eslint-disable max-lines */
/* eslint-disable max-len */
import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { ExamEnum } from 'constants/types/Exam';
import { IThunkAction } from 'constants/types/IThunkAction';
import { UserEnum } from 'constants/types/User';
import { errorHandler } from 'helpers/errorHandler';
import Exam from 'models/Exam';
import { toast } from 'react-toastify';

export const addExam = (exam: Exam): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.post('/exams', exam);
    dispatch({
      type: ExamEnum.ADD_EXAM_SUCCESS,
      payload: response.data,
    });
    toast('Nouvel Examen Sauvegardé', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
};

export const getAllExams = (): IThunkAction => async (
  dispatch
): Promise<void> => {
  try {
    startLoading()(dispatch);

    const response = await api.get(
      'exams?filter={"include" : [{"session":["classroom", {"attendance": ["candidate"]}]},"defaultUser"]}',
    );

    
    dispatch({
      type: ExamEnum.FETCH_ALL_EXAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // dispatch({
    //   type: ExamEnum.FETCH_ALL_EXAM_FAILURE,
    //   error,
    // });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
export const updateSelectedExam = (): IThunkAction => async (
  dispatch,
  getState,
) => {
  const { selectedDraft } = getState().exam;
  if (!selectedDraft) throw Error('No selected draft');
  const currentDraft = await api.get(
    `Exams/${selectedDraft.id}?filter={"include" : "defaultUser"}`,
  );

  dispatch({
    type: ExamEnum.SELECT_EXAM_SUCCESS,
    payload: currentDraft.data,
  });
};

export const fetchExamUserList = (
  examId: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`/Exams/${examId}/defaultUser`);
    dispatch({
      type: UserEnum.FETCH_USERS_EXAMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: UserEnum.FETCH_USERS_EXAMS_FAILURE, payload: error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const getCandidatsExam = (id: string): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `exams/${id}?filter={"include" : [{"session": [{"attendance": ["candidate"]}]},"examNotes"]}`,
    );


    dispatch({
      type: ExamEnum.FETCH_ALL_CANDIDATS_EXAM,
      payload:  { attendance: response.data.session[0].attendance, examNotes: response.data.examNotes }
    });
  } catch (error) {
    // dispatch({
    //   type: ExamEnum.FETCH_ALL_EXAM_FAILURE,
    //   error,
    // });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
export const addUsersToExam = (data: {
  users: number[];
  examId: number;
}): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.post('/DefaultUserExams/many', data);
    await updateSelectedExam()(dispatch, getState, null);
    await fetchExamUserList(data.examId)(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: UserEnum.ADD_USERS_TO_EXAM_FAILURE, payload: error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const publishExam = (examId?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.patch('/exams', {
      id: examId,
      isFinished: true,
    });
    dispatch({
      type: ExamEnum.PUBLISH_EXAM_SUCCESS,
      payload: response.data,
    });
    toast('Examen publié', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: ExamEnum.PUBLISH_EXAM_FAILURE, payload: error });
    errorHandler(error);
  }
};

export const selectExamId = (id?: number): IThunkAction => (
  dispatch,
): void => {
  dispatch({
    type: ExamEnum.SELECT_EXAM_SUCCESS,
    payload: id,
  });
  
};

export const unselectExamId = (): IThunkAction => (dispatch): void => {
  dispatch({
    type: ExamEnum.UNSELECT_EXAM_SUCCESS,
  });
};

export const DeleteUserFromExam = (
  userId: number,
  examId: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`/DefaultUsers/${userId}/exams/rel/${examId}`);
    await updateSelectedExam()(dispatch, getState, null);
    await fetchExamUserList(examId)(dispatch, getState, null);
    dispatch({
      type: UserEnum.DELETE_USER_FROM_TRAINING_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: UserEnum.DELETE_USER_FROM_EXAMS_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};
