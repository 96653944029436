import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { SemesterEnum } from 'constants/types/semester';
import { errorHandler } from 'helpers/errorHandler';
export const getAllSemestersWithModules = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `semesters?filter={"include" : ["cycleModule"]}`,
    );
    dispatch({
      type: SemesterEnum.FETCH_SEMESTERS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SemesterEnum.FETCH_SEMESTERS_FAILURE, error });
  }
  stopLoading()(dispatch);
};
