import ISelectFieldOption from 'models/ISelectFieldOption';

export const ROLES: ISelectFieldOption[] = [
  {
    label: 'Administrateur',
    value: 10,
  },
  {
    label: 'Logistique',
    value: 9,
  },
  {
    label: 'Organisateur',
    value: 8,
  },
];
