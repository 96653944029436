export enum TrainerInterfaceEnum {
  FETCH_TRAINER_SUCCESS = 'FETCH_TRAINER_SUCCESS',
  FETCH_TRAINER_FAILURE = 'FETCH_TRAINER_FAILURE',
  ADD_TRAINER_SUCCESS = 'ADD_TRAINER_SUCCESS',
  ADD_TRAINER_FAILURE = 'ADD_TRAINER_FAILURE',
  SELECT_TRAINER = 'SELECT_TRAINER',
  FETCH_SESSION_TRAINER_SUCCESS = 'FETCH_SESSION_TRAINER_SUCCESS',
  FETCH_SESSION_TRAINER_FAILURE = 'FETCH_SESSION_TRAINER_FAILURE',
  FETCH_TRAINER_SESSIONS_SUCCESS = 'FETCH_TRAINER_SESSIONS_SUCCESS',
  FETCH_TRAINER_SESSIONS_FAILURE = 'FETCH_TRAINER_SESSIONS_FAILURE',
}
