/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { change, formValueSelector, reset } from 'redux-form';
import { resetProgram } from 'actions/Program';
import formatProgramDays from 'helpers/formatProgramDays';
import { SemesterEnum } from 'constants/types/semester';

import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { IProgramFormDays } from 'models/IProgramForm';
import { getCategories, getThemes } from 'actions';
import { useTrainingHandler } from './useTrainingHandler';

export const useCycleHandler = (endDivRef: React.RefObject<HTMLDivElement>) => {
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [isSemesterOpen, setIsSemesterOpen] = useState(false);
  const [isSemesterListOpen, setIsSemesterListOpen] = useState(false);
  const [isDraftOpen, setisDraftOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isProgramOpen, setIsProgramOpen] = useState(false);
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [isBreakOpen, setIsBreakOpen] = useState(false);
  const [isModuleSaved, setIsModuleSaved] = useState(false);
  const [isWithFile, setIsWithFile] = useState(false);
  const [isPublishedCyclesOpen] = useState(false);
  const [date, setDate] = useState<Moment | null>(moment());
  const dispatch = useDispatch();
  const { selectedDraft } = useSelector((state: RootState) => state.cycle);
  const { semesterProgram } = useSelector((state: RootState) => state.program);

  const { isCycleOpen } = useTrainingHandler(endDivRef);
  useEffect(() => {
    if (isCycleOpen) {
      setisSaved(false);
      setisDraftOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    dispatch(resetProgram());
    setIsProgramOpen(false);
    setIsCalendarOpen(false);
    setIsSessionOpen(false);
    setIsBreakOpen(false);
    setIsUserOpen(false);
  }, [isCycleOpen]);
  useEffect(() => {
    dispatch(getThemes());
    dispatch(getCategories());
  }, []);
  // useEffect(() => {
  //   if (!isDraftOpen) return;
  //   setIsCycleOpen(false);
  //   endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [isDraftOpen]);
  // // handle published Training
  // useEffect(() => {
  //   if (!isPublishedCyclesOpen) return;
  //   setisDraftOpen(false);
  //   endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [isPublishedCyclesOpen]);
  // // handle calendar
  useEffect(() => {
    if (isCalendarOpen) {
      setIsProgramOpen(false);
      setIsUserOpen(false);
      setIsSessionOpen(false);
      setIsBreakOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCalendarOpen]);

  useEffect(() => {
    if (isSemesterOpen) {
      setIsProgramOpen(false);
      setIsUserOpen(false);
      setIsBreakOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSemesterOpen]);

  useEffect(() => {
    if (isModuleOpen) {
      setIsProgramOpen(false);
      setIsUserOpen(false);
      setIsBreakOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isModuleOpen]);

  useEffect(() => {
    if (!isBreakOpen) return;
    setIsProgramOpen(false);
    setIsUserOpen(false);
    setIsSessionOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isBreakOpen]);

  useEffect(() => {
    if (isSessionOpen) {
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSessionOpen]);
  // handle program
  useEffect(() => {
    if (!isProgramOpen) {
      dispatch(reset('addProgramForm'));
      return;
    }
    // setIsModuleOpen(false);
    // setIsSemesterOpen(false);
    // setIsUserOpen(false);
    // setIsCalendarOpen(false);
    // setIsSessionOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isProgramOpen]);

  // handle user what do we mean by user ?? => it 's when we add organizers
  useEffect(() => {
    if (isUserOpen) {
      setIsSemesterOpen(false);
      setIsProgramOpen(false);
      setIsModuleOpen(false);
      setIsCalendarOpen(false);
      setIsBreakOpen(false);
    }
    if (!isUserOpen) return;
    setIsSemesterOpen(false);
    setIsProgramOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isUserOpen]);

  const selector = formValueSelector('addCycleModuleProgramForm');

  const durations: Record<
    string,
    IProgramFormDays
  > = useSelector((state: RootState) => selector(state, 'programDays'));

  useEffect(() => {
    if (!durations) return;
    dispatch(
      change(
        'addCycleModuleProgramForm',
        'totalTheoreticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.theoreticalDuration),
          0,
        ),
      ),
    );
    dispatch(
      change(
        'addCycleModuleProgramForm',
        'totalPracticalDuration',
        Object.values(durations).reduce(
          (a, b) => Number(a) + Number(b.practicalDuration),
          0,
        ),
      ),
    );
  }, [durations]);

  // selectors
  const { newCycle } = useSelector((state: RootState) => state.cycle);

  const { Program } = useSelector((state: RootState) => state.program);
  // togglers
  const toggleDraft = (): void => {
    setisDraftOpen(!isDraftOpen);
  };

  const toggleSessionBar = (value: Moment) => {
    setDate(value);
    setIsSessionOpen(!isSessionOpen);
  };

  const toggleCalendarBar = (): void => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const toggleSemester = (): void => {
    setisSaved(false);
    dispatch({ type: SemesterEnum.RESET_SEMESTER });
    dispatch({ type: ModuleCycleEnum.RESET_SEMESTER_MODULE_LIST });
    setIsSemesterOpen(!isSemesterOpen);
    if (!isSemesterOpen) {
      setisSaved(false);
    }
    if (isModuleOpen) {
      setIsModuleOpen(!isModuleOpen);
      setIsModuleSaved(false);
    }
  };
  const toggleEditModuleSemester = () => {
    setIsSemesterOpen(true);
    setIsModuleOpen(true);
  };

  const toggleSemesterList = (): void => {
    setIsSemesterListOpen(!isSemesterListOpen);
  };

  let programInitialValues = {};
  if (Program)
    programInitialValues = {
      ...Program,
      programDays: formatProgramDays(Program?.programDays),
    };

  const toggleProgramBar = (): void => {
    setIsProgramOpen(!isProgramOpen);
  };

  const toggleUserBar = (): void => {
    setIsUserOpen(!isUserOpen);
  };
  const toggleModule = (): void => {
    setIsModuleOpen(!isModuleOpen);
    dispatch({ type: ModuleCycleEnum.RESET_CYCLE_MODULE });
    if (!isModuleOpen) {
      setIsModuleSaved(false);
    }
    setIsCalendarOpen(false);
  };
  const selectSemester = (): void => {
    setIsSemesterOpen(true);
  };
  const toggleBreakBar = (): void => {
    setIsBreakOpen(!isBreakOpen);
  };

  return {
    setIsModuleOpen,
    toggleBreakBar,
    isBreakOpen,
    setIsBreakOpen,
    toggleUserBar,
    toggleProgramBar,
    toggleCalendarBar,
    toggleSemester,
    toggleSemesterList,
    toggleDraft,
    toggleSessionBar,
    newCycle,
    setisSaved,
    setIsModuleSaved,
    isSaved,
    isModuleSaved,
    date,
    selectedDraft,
    selectSemester,
    setIsCalendarOpen,
    // setselectedDraft,
    isUserOpen,
    setIsSemesterOpen,
    setIsSemesterListOpen,
    isDraftOpen,
    isSemesterOpen,
    isSemesterListOpen,
    isCalendarOpen,
    isProgramOpen,
    setIsProgramOpen,
    programInitialValues,
    isWithFile,
    setIsWithFile,
    isPublishedCyclesOpen,
    setIsSessionOpen,
    isSessionOpen,
    setDate,
    toggleModule,
    isModuleOpen,
    toggleEditModuleSemester,
    semesterProgram,
  };
};
