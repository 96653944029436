/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedTraining, getProgram } from 'actions';
import { setSelectedCertificate } from 'actions/Certificate/setSelectCerificate';
import { setSelectedCycle } from 'actions/Cycle/setSelectCycle';
import { getCertificateProgram } from 'actions/CertificateProgram';

import Training from 'models/Training';
import Certificate from 'models/Certificate';
import Cycle from 'models/Cycle';
import { draftEnum } from 'constants/enum/draftEnum';
import { SessionEnum } from 'constants/types/Session';
import Exam from 'models/Exam';
import { setSelectedExam } from 'actions/Exam/setSelectExam';

export const useTrainingHandler = (
  endDivRef: React.RefObject<HTMLDivElement>,
) => {
  const [isExamOpen, setIsExamOpen] = useState(false)
  const [isOneShotOpen, setIsOneShotOpen] = useState(false);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);
  const [isDraftOpen, setisDraftOpen] = useState(false);
  const [isPublishedTrainingsOpen, setIsPublishedTrainingsOpen] = useState(
    false,
  );
  const [isCycleOpen, setIsCycleOpen] = useState(false);
  const dispatch = useDispatch();

  const setselectedDraft = (
    draft?: Training | Certificate | Cycle|Exam,
    draftType?: draftEnum,
  ) => {
    switch (draftType) {
      case draftEnum.oneshot:
        dispatch(setSelectedTraining(draft as Training));
        break;
      case draftEnum.certificate:
        dispatch(setSelectedCertificate(draft as Certificate));
        break;
      case draftEnum.cycle:
        dispatch(setSelectedCycle(draft as Cycle));
        break;
      case draftEnum.exam:
        dispatch(setSelectedExam(draft as Exam));
        break;
      default:
        break;
    }
  };

  const setTrainingProgram = (draftId: number) => {
    dispatch(getProgram(draftId));
  };

  const setCertificateProgram = (draftId: number) => {
    dispatch(getCertificateProgram(draftId));
  };

  useEffect(() => {
    if (isOneShotOpen) {
      setIsCycleOpen(false);
      setIsCertificateOpen(false);
      setIsExamOpen(false);
      setisDraftOpen(false);
      setIsPublishedTrainingsOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    
  }, [isOneShotOpen]);

  useEffect(() => {
    if (isCertificateOpen) {
      setisDraftOpen(false);
      setIsCycleOpen(false);
      setIsOneShotOpen(false);
      setIsExamOpen(false);
      setIsPublishedTrainingsOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCertificateOpen]);

  useEffect(() => {
    if (isCycleOpen) {
      setIsOneShotOpen(false);
      setIsCertificateOpen(false);
      setIsExamOpen(false);
      setisDraftOpen(false);
      setIsPublishedTrainingsOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCycleOpen]);

  useEffect(() => {
    if (isExamOpen) {
      setIsOneShotOpen(false);
      setIsCertificateOpen(false);
      setIsCycleOpen(false);
      setisDraftOpen(false);
      setIsPublishedTrainingsOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExamOpen]);

  useEffect(() => {
    if (!isDraftOpen) return;
    setIsOneShotOpen(false);
    setIsCertificateOpen(false);
    setIsCycleOpen(false);
    setIsExamOpen(false);
    setIsPublishedTrainingsOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isDraftOpen]);

  useEffect(() => {
    if (!isPublishedTrainingsOpen) return;
    setIsOneShotOpen(false);
    setIsCertificateOpen(false);
    setIsCycleOpen(false);
    setIsExamOpen(false);
    setisDraftOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isPublishedTrainingsOpen]);

  const toggleCertificate = (): void => {
    setIsCertificateOpen(!isCertificateOpen);
  };

  const toggleOneShot = (): void => {
    setIsOneShotOpen(!isOneShotOpen);
    // reset all one shot parameter
    // if (isOneShotOpen === false) {
    //   dispatch({ type: SessionEnum.RESET_SESSION });
    //   !isOneShotEdited && dispatch({ type: TrainingEnum.RESET_TRAINING });
    // }
    if (isOneShotOpen === false) {
      dispatch({ type: SessionEnum.RESET_SESSION });
    }
  };
  const toggleExam = () => {
    setIsExamOpen(!isExamOpen);
    if (isOneShotOpen === false) {
      dispatch({ type: SessionEnum.RESET_SESSION });
    }
  }

  const toggleDraft = (): void => {
    setisDraftOpen(!isDraftOpen);
  };
  const toggleCycle = (): void => {
    setIsCycleOpen(!isCycleOpen);
  };

  const togglePublishedTrainings = () =>
    setIsPublishedTrainingsOpen(prev => !prev);

  return {
    toggleDraft,
    toggleCertificate,
    setselectedDraft,
    toggleExam,
    setTrainingProgram,
    setCertificateProgram,
    setIsOneShotOpen,
    setIsCertificateOpen,
    setIsCycleOpen,
    isDraftOpen,
    isOneShotOpen,
    isCertificateOpen,
    isPublishedTrainingsOpen,
    togglePublishedTrainings,
    toggleOneShot,
    isCycleOpen,
    toggleCycle,
    isExamOpen, 
    setIsExamOpen,
  };
};


