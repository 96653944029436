/* eslint-disable max-lines */
import React, { RefObject, useEffect } from 'react';
import AddCycleSection from 'containers/AddCycleSection';
import { useCycleHandler } from 'hooks/useCycleHandler';
import { RootState } from 'reducers';

import { useDispatch, useSelector } from 'react-redux';
import { addCycle } from 'actions/Cycle/addCycle';
import Cycle from 'models/Cycle';
import Semester from 'models/Semester';
import { ICertifProgram } from 'models/IProgramForm';
import { addSemesterToCycle } from 'actions/Semester/addSemester';
import AddCycleModuleSection from 'containers/AddCycleModuleSection';
import IModule, { IModuleValuesForm } from 'models/IModule';
import AddCycleCalendarSection from 'containers/AddCycleCalendarSection';
import { Moment } from 'moment';
import { addModuleToSemester } from 'actions/Module/addModuleToSemester';
import AddCycleSessionSection from 'containers/AddCycleSessionSection';
import { ISessionModuleForm } from 'view-models/SessionViewModel';
import { addSessionToCycleModule } from 'actions';
import AddUserCycleSection from 'containers/AddUserCycleSection';
import { addUsersToCycle } from 'actions/User';
import AddCycleModuleProgram from 'components/AddCycleModuleProgram';
import handleCycleModuleValues from 'helpers/handleCycleModuleValues';
import {
  addSemesterProgram,
  addSemesterProgramAndGeneratFile,
} from 'actions/SemesterProgram';
import { publishCycle } from 'actions/Cycle/publishCycle';
import { mecansimTypesEnum } from 'constants/types/mecanism';
import AddTrainingBreakSection from 'containers/AddTrainingBreakSection';
import { BreakForm } from 'view-models/BreakViewModel';
import { formatBreakForSemester } from 'helpers/formatBreak';
import { addBreaks } from 'actions/Break';
import AddCycleSemesterSection from '../AddCycleSemesterSection';

interface IProps {
  endDivRef: RefObject<HTMLDivElement>;
  isCycleOpen: boolean;
  isCycleEdited: boolean;
  toggleCycle?: () => void;
}

export const AddCycleContainer: React.FC<IProps> = ({
  endDivRef,
  isCycleOpen,
  isCycleEdited,
  toggleCycle,
}) => {
  const dispatch = useDispatch();

  const {
    toggleUserBar,
    toggleProgramBar,
    toggleCalendarBar,
    setIsCalendarOpen,
    isBreakOpen,
    toggleSemester,
    setIsModuleOpen,
    toggleModule,
    setIsWithFile,
    isWithFile,
    setIsProgramOpen,
    toggleSessionBar,
    setisSaved,
    setIsModuleSaved,
    isSaved,
    isModuleSaved,
    date,
    selectedDraft,
    toggleBreakBar,
    isUserOpen,
    isSemesterOpen,
    isCalendarOpen,
    isProgramOpen,
    selectSemester,
    setIsBreakOpen,
    isModuleOpen,
    setIsSessionOpen,
    isSessionOpen,
    toggleEditModuleSemester,
  } = useCycleHandler(endDivRef);

  const { newCycle } = useSelector((state: RootState) => state.cycle);
  const { newSemester } = useSelector(({ semester }: RootState) => semester);
  const { newCycleModule, selectedCycleModuleDraft } = useSelector(
    ({ cycleModule }: RootState) => cycleModule,
  );
  useEffect(() => {
    dispatch({ type: mecansimTypesEnum.RESET_ALL });
  }, []);

  // in case it's Edited from the calendar section
  useEffect(() => {
    if (isCycleEdited) {
      toggleEditModuleSemester();
    }
  }, [isCycleEdited]);

  const handlePublishCycle = () => {
    const cycleId = newCycle?.id || selectedDraft?.id;
    dispatch(publishCycle(cycleId));
  };
  const handleSaveCycle = (values: Cycle) => {
    const cycleId = newCycle?.id || selectedDraft?.id;

    dispatch(addCycle(values, cycleId));
  };

  const handleAddSemester = (values: Semester) => {
    const cycleId = newCycle?.id || selectedDraft?.id;
    const newSem = { ...values, cycleId, id: newSemester?.id as number };
    dispatch(addSemesterToCycle(newSem));
    setisSaved(true);
  };

  const handleModule = (values: IModuleValuesForm) => {
    const newModuleToSave = {
      ...values,
      semesterId: newSemester?.id,
      id: newCycleModule?.id,
    };
    dispatch(
      addModuleToSemester({
        module: newModuleToSave,
        semesterId: newSemester?.id,
      }),
    );
    setIsModuleSaved(true);
  };

  const handleUsers = (values: { users: number[] }): void => {
    const cycleId = newCycle?.id || selectedDraft?.id;
    cycleId && dispatch(addUsersToCycle({ users: values.users, cycleId }));
  };
  const handleProgram = (values: ICertifProgram): void => {
    if (isWithFile)
      dispatch(
        addSemesterProgramAndGeneratFile(
          handleCycleModuleValues(
            { ...values, cycleId: newCycle?.id },
            newSemester?.id,
          ),
        ),
      );
    else
      dispatch(
        addSemesterProgram(
          handleCycleModuleValues(
            { ...values, cycleId: newCycle?.id },
            newSemester?.id,
          ),
        ),
      );
    setIsProgramOpen(false);
  };
  const handleCycleModuleSession = (values: ISessionModuleForm) => {
    if (date && newCycleModule?.id) {
      dispatch(
        addSessionToCycleModule({
          ...values,
          cycleModuleId: newCycleModule.id,
          date,
        }),
      );
    }
    setIsSessionOpen(false);
  };

  const handleBreak = (data: { breaks: BreakForm[] }): void => {
    const formattedData = formatBreakForSemester(
      data,
      newCycleModule as IModule,
    );
    dispatch(addBreaks(formattedData));
    setIsBreakOpen(false);
  };
  return (
    <>
      {isCycleOpen && (
        <>
          <AddCycleSection
            setisSaved={setisSaved}
            isSaved={!!newCycle || selectedDraft !== undefined}
            selectSemester={(): void => selectSemester()}
            toggleSemester={(): void => toggleSemester()}
            toggleUserBar={(): void => toggleUserBar()}
            toggleCycle={(): void => toggleCycle?.()}
            onSubmit={handleSaveCycle}
            initialValues={selectedDraft || newCycle}
          />
        </>
      )}
      {isSemesterOpen && (
        <AddCycleSemesterSection
          setIsModuleSaved={setIsModuleSaved}
          toggleSemester={toggleSemester}
          toggleBreakBar={(): void => toggleBreakBar()}
          setisSaved={setisSaved}
          handlePublish={handlePublishCycle}
          toggleCalendarBar={(): void => setIsCalendarOpen(true)}
          toggleModule={toggleModule}
          openModuleSection={(): void => setIsModuleOpen(true)}
          toggleProgramBar={(): void => toggleProgramBar()}
          toggleUserBar={(): void => toggleUserBar()}
          onSubmit={handleAddSemester}
          isSaved={isSaved || selectedDraft !== undefined}
        />
      )}
      {isModuleOpen && (
        <AddCycleModuleSection
          toggleModule={toggleModule}
          isSaved={isModuleSaved || selectedDraft !== undefined}
          toggleCalendarBar={(): void => toggleCalendarBar()}
          onSubmit={handleModule}
          initialValues={selectedCycleModuleDraft}
        />
      )}
      {isCalendarOpen && (
        <AddCycleCalendarSection
          moduleId={newCycleModule?.id}
          toggleCalendarBar={toggleCalendarBar}
          toggleSidebar={(value: Moment | null): void => {
            toggleSessionBar(value as Moment);
          }}
        />
      )}

      {isSessionOpen && (
        <AddCycleSessionSection
          toggleSessionBar={() => setIsSessionOpen(false)}
          date={date}
          cycleModuleId={newCycleModule?.id || selectedDraft?.id}
          onSubmit={handleCycleModuleSession}
        />
      )}
      {isProgramOpen && (
        <AddCycleModuleProgram
          onSubmit={handleProgram}
          setIsWithFile={setIsWithFile}
          initialValues={selectedDraft || newCycleModule}
          toggleProgramBar={toggleProgramBar}
        />
      )}
      {isUserOpen && (
        <AddUserCycleSection
          onSubmit={handleUsers}
          toggleUserBar={toggleUserBar}
          currentCycle={newCycle}
        />
      )}
      {isBreakOpen && (
        <AddTrainingBreakSection
          toggleBreakBar={toggleBreakBar}
          onSubmit={handleBreak}
          cycleModule={newCycleModule || selectedCycleModuleDraft}
        />
      )}
    </>
  );
};
