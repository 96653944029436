import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FetchTrainingInformations } from 'actions';
import { FetchCertificateInformations } from 'actions/Certificate/FetchCertificateInformations';
import { FetchSemesterInformations } from 'actions/Semester/FetchSemesterInformations';
import ViewTrainingDetails from 'containers/ViewTrainingDetails';
import ViewCertificateDetails from 'containers/ViewCertificateDetails';
import ViewSemesterDetails from 'containers/ViewSemesterDetails';
import ReactToPrint from 'react-to-print';
import { IGroupedTrainings } from 'models/IGroupedTrainings';

const PaymentDetails: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    id: string;
    type: IGroupedTrainings['type'];
  }>();
  const [isOneShot, setIsOneShot] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);
  const [isSemester, setIsSemester] = useState(false);
  const payDetailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    switch (params.type) {
      case 'Formation Continue':
        setIsOneShot(true);
        dispatch(FetchTrainingInformations(params.id));
        break;
      case 'Certification':
        setIsCertificate(true);
        dispatch(FetchCertificateInformations(params.id));
        break;
      case 'Cycle':
        setIsSemester(true);
        dispatch(FetchSemesterInformations(params.id));
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Note De Paiement</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Détails des formations , et Fiche de Paie
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {isOneShot && <ViewTrainingDetails refToPrint={payDetailsRef} />}
        {isCertificate && <ViewCertificateDetails refToPrint={payDetailsRef} />}
        {isSemester && <ViewSemesterDetails refToPrint={payDetailsRef} />}
        <div className="col-4 mr-3">
          <div className="card mt-11">
            <div className="card-body">
              <ReactToPrint
                trigger={() => (
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-soft-primary btn-block p-2"
                      type="button"
                    >
                      Génerer Note de Paie ↓
                      <i className="fa fa-print" />
                    </button>
                  </div>
                )}
                content={() => payDetailsRef.current}
              />
              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentDetails;
