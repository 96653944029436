import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { CycleEnum } from 'constants/types/cycle';
import { errorHandler } from 'helpers/errorHandler';
export const getCycleSemesters = (id?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response =
      id &&
      (await api.get(
        `cycles/${id}/semester?filter={"include" : ["cycleModule"]}`,
      ));

    dispatch({
      type: CycleEnum.FETCH_CYCLE_SEMESTERS,
      payload: response ? response.data : [],
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CycleEnum.FETCH_CYCLE_SEMESTERS_FAILURE, error });
  }
  stopLoading()(dispatch);
};
