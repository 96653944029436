import Candidate from 'models/Candidate';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { addExamNote } from 'actions/ExamNote';
import { formatNoteExam } from 'helpers/formatNoteExamToAdd';
import columns from './columns';

export interface CandidateWithNote extends Candidate {
  note?: number;
  examNoteId?: number;
  isPresent: boolean;
}
interface Props {
  data: CandidateWithNote[];
  examId: number;
}

const AllCandidatsExamListTable: React.FC<Props> = ({ data, examId }) => {
  const [keyword, setKeyword] = useState('');
  const [sortedCandidats, setSortedCandidats] = useState<CandidateWithNote[]>(
    [],
  );
  const [formattedData, setFormattedData] = useState<CandidateWithNote[]>([]);

  useEffect(() => {
    setFormattedData(
      data.map(can => ({
        ...can,
        examNoteId: can.examNoteId,
        isPresent: can.isPresent,
      })),
    );
  }, [data]);

  const dispatch = useDispatch();
  const handleChangeNote = useCallback((note: number, id: number) => {
    const changeCandidateNote = (candidates: CandidateWithNote[]) =>
      candidates.map(candidate =>
        candidate.id === id ? { ...candidate, note } : candidate,
      );
    setSortedCandidats(changeCandidateNote);
    setFormattedData(changeCandidateNote);
  }, []);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedCandidats(formattedData);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['firstName'],
    };
    const fuse = new Fuse(formattedData, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCandidats(results);
  }, [keyword, data, formattedData]);

  const TabHeader = useMemo(
    () => (
      <div className="text-center mt-2 row w-100 align-items-start">
        <input
          className="form-control mb-3 mr-3 col-4"
          placeholder="Recherche.."
          value={keyword}
          onChange={event => setKeyword(event.target.value)}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
        <button type="submit" className="btn btn-primary ">
          Enregistrer
        </button>
      </div>
    ),
    [keyword],
  );
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      const notesExamlist = formatNoteExam(formattedData, examId);
      dispatch(addExamNote(notesExamlist));
    },
    [formattedData, examId, dispatch],
  );

  const datatableData = useMemo(() => {
    return columns(String(examId), handleChangeNote);
  }, [examId, handleChangeNote]);

  return (
    <form onSubmit={handleSubmit}>
      <DataTable
        data={sortedCandidats}
        columns={datatableData}
        subHeader
        noHeader
        keyField="timestamps"
        persistTableHead
        subHeaderAlign="center"
        expandOnRowClicked
        highlightOnHover
        subHeaderComponent={TabHeader}
        noDataComponent="Aucun élément"
        pagination
      />
    </form>
  );
};

export default AllCandidatsExamListTable;
