import moment from 'moment';

const getDaysOfMonth = (month?: string, year?: string) => {
  const isMonth = month || (moment().month() + 1).toString();
  const isYear =
    year ||
    moment()
      .year()
      .toString();

  const date = `${isYear}/${isMonth}`;
  const currentMonthDates = new Array(moment().daysInMonth())
    .fill(null)
    .map((x, i) => ({
      fullDate: moment(date)
        .startOf('month')
        .add(i, 'days')
        .format('YYYY-MM-DD'),
      date: moment(date)
        .startOf('month')
        .add(i, 'days')
        .format('DD'),
      day: moment(date)
        .startOf('month')
        .add(i, 'days')
        .day(),
      moment: moment(date)
        .startOf('month')
        .add(i, 'days'),
    }));

  return currentMonthDates;
};
export default getDaysOfMonth;
