import React from 'react';

interface LegendProps {
  className?: string;
  data: { label?: string | number; color: string }[];
}

const Legend = ({ className, data }: LegendProps) => (
  <ul className={`list-inline text-center ${className || ''}`}>
    {data.map(el => (
      <li
        key={el.label}
        className="list-inline-item d-inline-flex align-items-center"
      >
        <span
          className="legend-indicator"
          style={{
            backgroundColor: el.color,
          }}
        />
        {el.label}
      </li>
    ))}
  </ul>
);

export default Legend;
