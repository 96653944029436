import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const getSemesterModules = (id: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(`Semesters/${id}/cycleModule`);

    dispatch({
      type: ModuleCycleEnum.FETCH_SEMESTER_MODULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleCycleEnum.FETCH_SEMESTER_MODULE_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
