export enum TrainingEnum {
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  ADD_TRAINING_SUCCESS = 'ADD_TRAINING_SUCCESS',
  ADD_TRAINING_FAILURE = 'ADD_TRAINING_FAILURE',
  FETCH_UNFINISHED_TRAINING_SUCCESS = 'FETCH_UNFINISHED_TRAINING_SUCCESS',
  FETCH_UNFINISHED_TRAINING_FAILURE = 'FETCH_UNFINISHED_TRAINING_FAILURE',
  FETCH_PROGRAM_DATA_SUCCESS = 'FETCH_PROGRAM_DATA_SUCCESS',
  FETCH_PROGRAM_DATA_FAILURE = 'FETCH_PROGRAM_DATA_FAILURE',
  RESET_TRAINING = 'RESET_TRAINING',
  SET_SELECTED_TRAINING_SUCCESS = 'SET_SELECTED_TRAINING_SUCCESS',
  SELECT_TRAINING_SUCCESS = 'SELECT_TRAINING_SUCCESS',
  UNSELECT_TRAINING_SUCCESS = 'UNSELECT_TRAINING_SUCCESS',
  GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS',
  GET_TRAINING_FAILURE = 'GET_TRAINING_FAILURE',
  PUBLISH_TRAINING_SUCCESS = 'PUBLISH_TRAINING_SUCCESS',
  PUBLISH_TRAINING_FAILURE = 'PUBLISH_TRAINING_FAILURE',
  FETCH_FINISHED_TRAINING_SUCCESS = 'FETCH_FINISHED_TRAINING_SUCCESS',
  FETCH_FINISHED_TRAINING_FAILURE = 'FETCH_FINISHED_TRAINING_FAILURE',
  FETCH_USER_TRAININGS_SUCCESS = 'FETCH_USER_TRAININGS_SUCCESS',
  FETCH_ALL_TRAINING_SUCCESS = 'FETCH_ALL_TRAINING_SUCCESS',
  FETCH_ALL_TRAINING_FAILURE = 'FETCH_ALL_TRAINING_FAILURE',
  UPDATE_TRAINING_REGULATION_SUCCESS = 'UPDATE_TRAINING_REGULATION_SUCCESS',
  UPDATE_TRAINING_REGULATION_FAILURE = 'UPDATE_TRAINING_REGULATION_FAILURE',
  FETCH_TRAINING_DETAILS_SUCCESS = 'FETCH_TRAINING_DETAILS_SUCCESS',
  FETCH_TRAINING_DETAILS_FAILURE = 'FETCH_TRAINING_DETAILS_FAILURE',
  FETCH_ACCOUNTING_NOTES_SUCCESS = 'FETCH_ACCOUNTING_NOTES_SUCCESS',
  FETCH_ACCOUNTING_NOTES_FAILURE = 'FETCH_ACCOUNTING_NOTES_FAILURE',
}
