import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { SessionForm } from 'view-models/SessionViewModel';

interface Props {
  filtredDataClassrooms: ISelectFieldOption[];
  formattedDataClassroomsNum: ISelectFieldOption[];
  handleClassroom: (value: ISelectFieldOption) => void;
  selectedClassroom: ISelectFieldOption | undefined;
}

const UpdateSessionSection: React.FC<Props &
  InjectedFormProps<SessionForm, Props>> = ({
  handleSubmit,
  filtredDataClassrooms,
  formattedDataClassroomsNum,
  handleClassroom,
  selectedClassroom,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <SelectField
        options={filtredDataClassrooms}
        name="classroom"
        getSelectedValue={handleClassroom}
        isSearchable
        placeholder="Choisir par type"
        label="Salle"
        validate={[Validators.required]}
      />
      <SelectField
        options={formattedDataClassroomsNum}
        name="classroomId"
        isSearchable
        placeholder="Choisir par type"
        label="Numero de salle"
        validate={[Validators.required]}
        isDisabled={selectedClassroom === undefined}
      />

      <div className="d-flex justify-content-center">
        <button type="submit" className="btn btn-sm-wide btn-primary">
          Changer
        </button>
      </div>
    </form>
  );
};
export default reduxForm<SessionForm, Props>({
  form: 'UpdateSessionForm',
  enableReinitialize: true,
})(UpdateSessionSection);
