import * as React from 'react';

interface Props {
  data: {
    name: string;
    time: string;
    room: string;
    courseNumber: number;
    isExam?: boolean;
  };
  day?: string;
  id?: number;
  deleteSession?: (id: number) => void;
  editClassroom?: (id: number, day: string, time: string) => void;
}

const CalendarCard: React.FC<Props> = ({
  data,
  day,
  id,
  deleteSession,
  editClassroom,
}) => {
  const { name, time, room, courseNumber, isExam } = data;
  const background = isExam ? '#979797' : '#d3d3d3';
  
  return (
    <div className="mt-1 mb-2 position-relative">
      <div
        className="p-1 shadow rounded text-center mx-auto"
        style={{ backgroundColor: `${background}` }}
      >
        {day && (
          <div className="row">
            <div className="col-5">
              <h3 className="h6 pl-3 text-left mx-auto mb-1 text-primary">
                Date:
              </h3>
            </div>
            <div className="col-7">
              <h3 className="h6 text-left mx-auto mb-1">{day}</h3>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-5">
            <h3 className="h6 pl-3 text-left mx-auto mb-1 text-primary">
              {isExam ? 'Surveillant:' : 'Fromateur:'}
            </h3>
          </div>
          <div className="col-7">
            <h3 className="h6 text-left mx-auto mb-1">{name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3 className="h6 pl-3 text-left mx-auto  mb-1 text-primary">
              Heure:
            </h3>
          </div>
          <div className="col-7">
            <h3 className="h6 text-left mx-auto mb-1">{time}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3 className="h6 pl-3 text-left mx-auto  mb-1 text-primary">
              N° Séances:
            </h3>
          </div>
          <div className="col-7">
            <h3 className="h6 text-left mx-auto mb-1">{courseNumber}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3 className="h6 pl-3 text-left mx-auto  mb-1 text-primary">
              Salle:
            </h3>
          </div>
          <div className="col-7">
            <h3 className="h6 text-left mx-auto mb-1">{room}</h3>
          </div>
        </div>
      </div>
      {id && deleteSession && (
        <div
          className="position-absolute bottom-0 right-0"
          style={{ cursor: 'pointer' }}
        >
          <button
            type="button"
            className="btn btn-icon transition-3d-hover"
            onClick={(): void => deleteSession(id)}
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      )}
      {id && editClassroom && day && (
        <div
          className="position-absolute top-0 right-0"
          style={{ cursor: 'pointer' }}
        >
          <button
            type="button"
            className="btn btn-icon transition-3d-hover"
            onClick={(): void => editClassroom(id, day, time)}
          >
            <i className="fas fa-cog" />
          </button>
        </div>
      )}
    </div>
  );
};

export default CalendarCard;
