import { generateCondidatesFile, getCandidates } from 'actions';
import Fuse from 'fuse.js';
import Candidate from 'models/Candidate';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { IFormattedSessionDetails } from 'view-models/SessionViewModel';

export const useCandidate = (cycleType: string) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentCandidates, setcurrentCandidates] = useState<Candidate[]>([]);
  const [keyword, setKeyword] = useState('');
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [sortedCandidates, setSortedCandidates] = useState<Candidate[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandidates());
  }, []);

  const downloadCondiadatesFile = (
    formation: IFormattedSessionDetails,
    theme: string,
    cycle: boolean,
  ) => {
    dispatch(
      generateCondidatesFile(currentCandidates, formation, theme, cycle),
    );
  };

  const { finishedTraining } = useSelector(
    (state: RootState) => state.training,
  );
  const { finishedExam } = useSelector(
    (state: RootState) => state.exam,
  );
  const { moduleWithSession } = useSelector(
    (state: RootState) => state.cycleModule,
  );
  const certifsModulesWithSessions = useSelector(
    (state: RootState) => state.modules.moduleWithSession,
  );
  useEffect(() => {
    if (cycleType === 'One Shot') {
      setcurrentCandidates(finishedTraining?.candidate || []);
    } else if (cycleType === 'Exam') {
      setcurrentCandidates(finishedExam?.candidate || []);
    }else if (cycleType === 'Cycle') {
      setcurrentCandidates(moduleWithSession?.semester?.cycle?.candidate || []);
    } else if (cycleType === 'Certificate') {
      setcurrentCandidates(
        certifsModulesWithSessions?.certificate?.candidate || [],
      );
    }
  }, [
    cycleType,
    finishedTraining,
    moduleWithSession,
    certifsModulesWithSessions,finishedExam
  ]);

  const { candidateList } = useSelector((state: RootState) => state.candidates);

  // const currentCandidates = finishedTraining?.candidate || [];
  const candidatesToAdd = useMemo(
    () =>
      candidateList.filter(item => {
        return !currentCandidates?.some(el => {
          return el.id === item.id;
        });
      }),
    [currentCandidates, candidateList],
  );

  useEffect(() => {
    if (currentCandidates) setKeyword('');
    switch (selectedIndex) {
      case 0:
        setCandidates(candidatesToAdd);
        break;
      case 1:
        setCandidates(currentCandidates);
        break;
      default:
        break;
    }
  }, [selectedIndex, candidateList, candidatesToAdd]);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedCandidates(candidates);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'firstName',
        'lastName',
        'CIN',
        'phoneNumber',
        'job.title',
        'bank.name',
      ],
    };
    const fuse = new Fuse(candidates, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCandidates(results);
  }, [keyword, candidates]);

  return {
    selectedIndex,
    downloadCondiadatesFile,
    setSelectedIndex,
    keyword,
    setKeyword,
    sortedCandidates,
    currentCandidates
  };
};
