/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import Validators from 'helpers/formValidator';
import { useDispatch } from 'react-redux';
import { getBreakTypes } from 'actions/BreakType';
import { BreakTypeForm } from 'view-models/BreakTypeViewModel';
import InputField from 'components/InputField';

const ModifyBreakTypeForm: React.FC<InjectedFormProps<BreakTypeForm>> = ({
  handleSubmit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBreakTypes());
  }, []);

  return (
    <form onSubmit={handleSubmit} className="col-12 p-0">
      <div className="col-6 ">
        <InputField
          value="Pause Café"
          name="title"
          label="Titre"
          validate={[Validators.required]}
        />
        <InputField
          name="provider"
          label="Fournisseur"
          validate={[Validators.required]}
        />
        <InputField
          name="unitCost"
          label="Coût unitaire"
          validate={[Validators.required, Validators.integer]}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-outline-primary py-2">
          Sauvegarder
        </button>
      </div>
    </form>
  );
};
export default reduxForm<BreakTypeForm>({
  form: 'ModifyBreakTypeForm',
  enableReinitialize: true,
})(ModifyBreakTypeForm);
