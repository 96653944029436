import api from 'api';
import { CycleEnum } from 'constants/types/cycle';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const publishCycle = (cycleId?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    const response = await api.patch('/cycles', {
      id: cycleId,
      isFinished: true,
    });
    dispatch({
      type: CycleEnum.PUBLISH_CYCLE_SUCCESS,
      payload: response.data,
    });
    toast('Semester publié', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CycleEnum.PUBLISH_CYCLE_FAILURE, error });
  }
};
