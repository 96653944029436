import { FetchTrainerSessions } from 'actions';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'reducers';
import TrainerSessionTable from 'components/TrainerSessionTable';
import ReactToPrint from 'react-to-print';

const TrainerHistory: React.FC = () => {
  const { TrainerFilteredSessions } = useSelector(
    (state: RootState) => state.trainer,
  );

  const dispatch = useDispatch();
  const params = useParams<{
    id: string;
  }>();

  const trainerHistoryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(FetchTrainerSessions(params.id));
  }, []);

  return (
    <>
      <div className="bg-primary" ref={trainerHistoryRef}>
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">
                  Historique et Paiement Formateur
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        {TrainerFilteredSessions && (
          <TrainerSessionTable
            trainer={TrainerFilteredSessions?.trainerName}
            sessions={TrainerFilteredSessions?.sessions}
            refToPrint={trainerHistoryRef}
          />
        )}
        <ReactToPrint
          trigger={() => (
            <div
              style={{ maxWidth: '25cm' }}
              className="col-12 d-flex justify-content-center mt-2"
            >
              <button
                className="btn btn-soft-primary btn-block p-2"
                type="button"
              >
                Imprimer Note de Paie ↓
                <i className="fa fa-print" />
              </button>
            </div>
          )}
          content={() => trainerHistoryRef.current}
        />
      </div>
    </>
  );
};

export default TrainerHistory;
