import { resetProgram } from 'actions';
import { ISemesterCard } from 'components/SemesterCard';
import { ModuleCycleEnum } from 'constants/types/CycleModule';
import { IThunkAction } from 'constants/types/IThunkAction';
import { SemesterEnum } from 'constants/types/semester';

export const selectSemesterCard = (
  semester: ISemesterCard,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch(resetProgram())
    dispatch({
      type: SemesterEnum.ADD_SEMESTER_SUCCESS,
      payload: semester.semester,
    });
    dispatch({
      type: ModuleCycleEnum.FETCH_SEMESTER_MODULE_SUCCESS,
      payload: semester.semester.cycleModule,
    });
  } catch (error) {
    dispatch({ type: SemesterEnum.ADD_SEMESTER_FAILURE, error });
  }
};
