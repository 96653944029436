export enum CycleEnum {
  FETCH_CYCLE_SEMESTERS = 'FETCH_CYCLE_SEMESTERS',
  FETCH_CYCLE_SEMESTERS_FAILURE = 'FETCH_CYCLE_SEMESTERS_FAILURE',
  FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS',
  FETCH_ALL_CYCLES_SUCCESS = ' FETCH_ALL_CYCLES_SUCCESS',
  FETCH_ALL_CYCLES_FAILURE = 'FETCH_ALL_CYCLES_FAILURE',
  FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE',
  FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE',
  ADD_CYCLE_SUCCESS = 'ADD_CYCLE_SUCCESS',
  ADD_CYCLE_FAILURE = 'ADD_CYCLE_FAILURE',
  FETCH_UNFINISHED_CYCLE_SUCCESS = 'FETCH_UNFINISHED_CYCLE_SUCCESS',
  FETCH_UNFINISHED_CYCLE_FAILURE = 'FETCH_UNFINISHED_CYCLE_FAILURE',
  FETCH_MODULE_DATA_SUCCESS = 'FETCH_MODULE_DATA_SUCCESS',
  FETCH_MODULE_DATA_FAILURE = 'FETCH_MODULE_DATA_FAILURE',
  RESET_CYCLE = 'RESET_CYCLE',
  RESET_CYCLE_SEMESTER = 'RESET_CYCLE_SEMESTER',
  SET_SELECTED_CYCLE_SUCCESS = 'SET_SELECTED_CYCLE_SUCCESS',
  SELECT_CYCLE_SUCCESS = 'SELECT_CYCLE_SUCCESS',
  UNSELECT_CYCLE_SUCCESS = 'UNSELECT_CYCLE_SUCCESS',
  GET_CYCLE_SUCCESS = 'GET_CYCLE_SUCCESS',
  GET_CYCLE_FAILURE = 'GET_CYCLE_FAILURE',
  PUBLISH_CYCLE_SUCCESS = 'PUBLISH_CYCLE_SUCCESS',
  PUBLISH_CYCLE_FAILURE = 'PUBLISH_CYCLE_FAILURE',
  FETCH_FINISHED_CYCLE_SUCCESS = 'FETCH_FINISHED_CYCLE_SUCCESS',
  FETCH_FINISHED_CYCLE_FAILURE = 'FETCH_FINISHED_CYCLE_FAILURE',
  FETCH_USER_CYCLES_SUCCESS = 'FETCH_USER_CYCLES_SUCCESS',
  FETCH_USER_CYCLES_FAIL = 'FETCH_USER_CYCLES_FAIL',
}
