import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import Fuse from 'fuse.js';
import BreakTypeViewModel from 'view-models/BreakTypeViewModel';
interface Props {
  breakTypes: BreakTypeViewModel[];
  handleEdit: (id: number) => void;
}

const BreakTypesTable: React.FC<Props> = ({ breakTypes, handleEdit }) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedBreakTypes, setSortedBreakTypes] = React.useState<
    BreakTypeViewModel[]
  >([]);
  useEffect(() => {
    if (keyword.length === 0) {
      setSortedBreakTypes(breakTypes);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['title', 'provider', 'unitCost'],
    };
    const fuse = new Fuse(breakTypes, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedBreakTypes(results);
  }, [keyword, breakTypes]);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );

  const columns = () => {
    const data = [
      {
        name: 'Titre',
        selector: 'title',
        center: true,
        sortable: true,
      },
      {
        name: 'Fournisseur',
        selector: 'provider',
        center: true,
        sortable: true,
      },
      {
        name: 'Coût unitaire',
        selector: 'unitCost',
        center: true,
        sortable: true,
      },
      {
        name: 'Modifier',
        center: true,
        maxWidth: '8vw',
        cell: (row: BreakTypeViewModel) => (
          <button
            type="button"
            className="btn btn-block btn-primary btn-icon d-flex 
                align-items-center justify-content-center"
            onClick={(): void => handleEdit(row?.id)}
          >
            <i className="fa fa-edit" />
          </button>
        ),
      },
    ];
    return data;
  };

  return (
    <DataTable
      data={sortedBreakTypes}
      columns={columns()}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default BreakTypesTable;
