import InputField from 'components/InputField';
import * as React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm, InjectedFormProps } from 'redux-form';

export interface ILoginForm {
  username: string;
  password: string;
}

const Login: React.FC<InjectedFormProps<ILoginForm>> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="logincontainer">
        <InputField
          name="username"
          label="Nom d'utilisateur"
          placeholder="Nom d'utilisateur"
          validate={[Validators.required]}
        />
        <InputField
          type="password"
          name="password"
          label="Mot De passe"
          placeholder="Mot de passe"
          validate={[Validators.required]}
        />
        <button
          type="submit"
          className="btn btn-wide btn-primary btn-btn-text-indigo cnxbtn"
        >
          Se Connecter
        </button>
      </div>
    </form>
  );
};

export default reduxForm<ILoginForm>({ form: 'loginform' })(Login);
