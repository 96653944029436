/* eslint-disable max-lines */
import * as React from 'react';
import { Moment } from 'moment';
import {
  InjectedFormProps,
  reduxForm,
  change,
  reset as RFReset,
} from 'redux-form';
import SelectField from 'components/SelectField';
import ISelectFieldOption from 'models/ISelectFieldOption';
import { SessionForm } from 'view-models/SessionViewModel';
import Validators from 'helpers/formValidator';
import { useDispatch } from 'react-redux';
import { useSession } from 'hooks/useSession';
import TrainingSidebar from 'components/TrainingSidebar';
interface IProps {
  date: Moment | null;
  trainingId?: number;
  toggleSessionBar?: () => void;
}
const AddTrainingSessionSection: React.FC<IProps &
  InjectedFormProps<SessionForm, IProps>> = ({
  date,
  trainingId,
  handleSubmit,
  reset,
  toggleSessionBar,
}) => {
  const {
    formattedDataEndTime,
    formattedDataStartTime,
    formattedDataClassroomsNum,
    filtredDataClassrooms,
    courseNumber,
    formattedDataTrainers,
    setselectedClassroom,
    setselectedTrainer,
    setstartTime,
    setendTime,
    setselectedCourseNumber,
    selectedTrainer,
    endTime,
    selectedClassroom,
    startTime,
  } = useSession(date, trainingId);
  const dispatch = useDispatch();
  const handleTrainer = (value: ISelectFieldOption) => {
    setselectedTrainer(value);
    dispatch(change('TrainerSectionForm', 'from', undefined));
  };
  const handleCourseNumber = (value: ISelectFieldOption) => {
    setselectedCourseNumber(value);
  };
  const handleClassroom = (value: ISelectFieldOption): void => {
    setselectedClassroom(value);
    dispatch(change('TrainerSectionForm', 'classroomId', undefined));
  };
  const handleStartTime = (value: ISelectFieldOption): void => {
    setstartTime(value as ISelectFieldOption<Moment>);
    dispatch(change('TrainerSectionForm', 'to', undefined));
  };
  const handleEndTime = (value: ISelectFieldOption): void => {
    setendTime(value as ISelectFieldOption<Moment>);
    dispatch(change('TrainerSectionForm', 'classroom', undefined));
  };
  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <TrainingSidebar
        header={`Ajouter séances, le ${date?.format('L')}`}
        className=" p-4"
        onClose={() => {
          toggleSessionBar?.();
        }}
      >
        <div
          className="d-flex flex-column"
          style={{ justifyContent: 'space-evenly', flex: 1 }}
        >
          <SelectField
            options={formattedDataTrainers}
            name="trainerId"
            getSelectedValue={handleTrainer}
            isSearchable
            placeholder="Nom et prénom"
            label="Formateur"
            validate={[Validators.required]}
          />
          <SelectField
            options={courseNumber}
            name="courseNumber"
            getSelectedValue={handleCourseNumber}
            placeholder="Nombre de Séances"
            label="Nombre de Séances"
            validate={[Validators.required]}
          />
          <SelectField
            options={formattedDataStartTime}
            name="from"
            isSearchable
            getSelectedValue={handleStartTime}
            placeholder="Commence à"
            label="De"
            isDisabled={selectedTrainer === undefined}
            validate={[Validators.required]}
          />
          <SelectField
            options={formattedDataEndTime}
            name="to"
            getSelectedValue={handleEndTime}
            isSearchable
            placeholder="jusqu'à"
            label="A"
            validate={[Validators.required]}
            isDisabled={startTime === undefined}
          />
          <SelectField
            options={filtredDataClassrooms}
            name="classroom"
            getSelectedValue={handleClassroom}
            isSearchable
            placeholder="Choisir par type"
            label="Salle"
            validate={[Validators.required]}
            isDisabled={endTime === undefined}
          />
          <SelectField
            options={formattedDataClassroomsNum}
            name="classroomId"
            isSearchable
            placeholder="Choisir par type"
            label="Numero de salle"
            validate={[Validators.required]}
            isDisabled={selectedClassroom === undefined}
          />
          <div className="row align-items-center">
            <div className="col-6 d-flex justify-content-center">
              <button type="submit" className="btn btn-sm-wide btn-primary">
                Ajouter
              </button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <button
                type="button"
                onClick={reset}
                className="btn btn-sm-wide btn-soft-primary"
              >
                Réinitialiser
              </button>
            </div>
          </div>
        </div>
      </TrainingSidebar>
    </form>
  );
};

export default reduxForm<SessionForm, IProps>({
  form: 'TrainerSectionForm',
  onSubmitSuccess: (_, dispatch) => dispatch(RFReset('TrainerSectionForm')),
})(AddTrainingSessionSection);
