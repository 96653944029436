import React, { useEffect, useState } from 'react';
import DatePickerComponent from 'components/DatePickerComponent';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBreakPerWeek,
  editBreakPerWeek,
  getBreakById,
  getBreaksPerWeek,
} from 'actions/Break';
import BreaksPerWeekTable from 'components/BreaksPerWeekTable';
import { RootState } from 'reducers';
import Modal from 'components/Modal';
import ModifyBreakForm from 'containers/ModifyBreakForm';
import { BreakForm } from 'view-models/BreakViewModel';
import { CSVLink } from 'react-csv';
import formatToCsv from 'helpers/formatToCsv';

const BreaksPerWeek: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const handleDateChange = (newDate: Moment | null): void => {
    setSelectedDate(newDate);
  };
  const { BreakListPerWeek, currentBreak } = useSelector(
    (state: RootState) => state.breaks,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const rangDateObject = {
    startWeek: selectedDate?.startOf('week').format('YYYY-MM-DD'),
    endWeek: selectedDate?.endOf('week').format('YYYY-MM-DD'),
  };
  const formattedBreaks = BreakListPerWeek.map(oneBreak => ({
    ...oneBreak,
    breakTypeTitle: oneBreak.breakType.title,
    hour: moment(oneBreak.dateTime).format('HH:mm'),
    date: moment(oneBreak.dateTime).format('YYYY-MM-DD'),
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedDate) return;
    dispatch(getBreaksPerWeek(rangDateObject));
  }, [selectedDate]);
  const handleDeleteBreak = (id?: number) => {
    dispatch(deleteBreakPerWeek(rangDateObject, id));
  };
  const handleEditBreak = (id?: number) => {
    dispatch(getBreakById(id));
    setModalOpen(true);
  };
  const handleSumbitEdit = (values: BreakForm) => {
    dispatch(editBreakPerWeek(rangDateObject, values, currentBreak?.id));
    setModalOpen(false);
  };

  const breakTypeId = currentBreak?.breakTypeId;
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="d-flex justify-content-between">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Pauses par semaine</h1>
              </div>
            </div>
            <CSVLink
              className="btn btn-soft-light"
              filename={`pauses de la semaine-${selectedDate?.format(
                'YYYY-MM-DD',
              )}.csv`}
              data={formatToCsv(formattedBreaks, [
                {
                  label: 'Titre',
                  value: 'title',
                },
                {
                  label: 'Type de formation',
                  value: 'trainingType',
                },
                {
                  label: 'Nombre de personne inscrit',
                  value: 'totalCandidates',
                },
                {
                  label: 'Type de pause',
                  value: 'breakTypeTitle',
                },
                {
                  label: 'Heure',
                  value: 'hour',
                },
                {
                  label: 'Date',
                  value: 'date',
                },
              ])}
            >
              Exporter en tant que CSV
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <h5 className="card-header-title">
                {`Liste des pauses café pour la semaine ${selectedDate?.format(
                  'DD/MM/YYYY',
                )}`}
              </h5>
              <div className="col-auto">
                <div
                  className="d-none d-lg-block"
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '5px',
                  }}
                >
                  <DatePickerComponent
                    setDate={handleDateChange}
                    value={selectedDate}
                    step={7}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <BreaksPerWeekTable
              handleDelete={handleDeleteBreak}
              handleEdit={handleEditBreak}
              breaksPerWeek={BreakListPerWeek}
            />
          </div>
          <Modal
            title="Modifier type de pause"
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
          >
            <ModifyBreakForm
              initialValues={{ breakTypeId }}
              onSubmit={handleSumbitEdit}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default BreaksPerWeek;
