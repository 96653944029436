import api from 'api';
import { ExamEnum } from 'constants/types/Exam';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const addExamNote = (
  data: { examId: number; candidateId: number; note?: number; id?: number }[],
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    const response = await api.post(`/ExamNotes/many`, data);

    dispatch({
      type: ExamEnum.UPDATE_EXAM_NOTES,payload: response.data
    
    });

    toast('Note Sauvegardé', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
};
