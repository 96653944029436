export enum UserEnum {
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE',
  ADD_USERS_TO_TRAINING_SUCCESS = 'ADD_USERS_TO_TRAINING_SUCCESS',
  ADD_USERS_TO_TRAINING_FAILURE = 'ADD_USERS_TO_TRAINING_FAILURE',
  ADD_USERS_TO_CERTIFICATE_SUCCESS = 'ADD_USERS_TO_CERTIFICATE_SUCCESS',
  ADD_USERS_TO_CERTIFICATE_FAILURE = 'ADD_USERS_TO_CERTIFICATE_FAILURE',
  DELETE_USER_FROM_TRAINING_SUCCESS = 'DELETE_USER_FROM_TRAINING_SUCCESS',
  DELETE_USER_FROM_TRAINING_FAILURE = 'DELETE_USER_FROM_TRAINING_FAILURE',
  DELETE_USER_FROM_CERTIFICATE_SUCCESS = 'DELETE_USER_FROM_CERTIFICATE_SUCCESS',
  DELETE_USER_FROM_CERTIFICATE_FAILURE = 'DELETE_USER_FROM_CERTIFICATE_FAILURE',
  DELETE_USER_FROM_CYCLE_SUCCESS = 'DELETE_USER_FROM_CYCLE_SUCCESS',
  DELETE_USER_FROM_CYCLE_FAILURE = 'DELETE_USER_FROM_CYCLE_FAILURE',
  FIND_USER_SUCCESS = 'FIND_USER_SUCCESS',
  FIND_USER_FAILURE = 'FIND_USER_FAILURE',
  ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS',
  ADD_USERS_FAILURE = 'ADD_USERS_FAILURE',
  SET_SELECTED_USER_SUCCESS = 'SET_SELECTED_USER_SUCCESS',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  ADD_USERS_TO_CYCLE_SUCCESS = 'ADD_USERS_TO_CYCLE_SUCCESS',
  ADD_USERS_TO_CYCLE_FAILURE = 'ADD_USERS_TO_CYCLE_FAILURET',
  FETCH_USERS_TRAINING_SUCCESS = 'FETCH_USERS_TRAINING_SUCCESS',
  FETCH_USERS_TRAINING_FAILURE = 'FETCH_USERS_TRAINING_FAILURE',
  FETCH_USERS_EXAMS_SUCCESS = 'FETCH_USERS_EXAMS_SUCCESS',
  FETCH_USERS_EXAMS_FAILURE = 'FETCH_USERS_EXAMS_FAILURE',
  ADD_USERS_TO_EXAM_SUCCESS = 'ADD_USERS_TO_EXAM_SUCCESS',
  ADD_USERS_TO_EXAM_FAILURE = 'ADD_USERS_TO_EXAM_FAILURE',
  DELETE_USER_FROM_EXAMS_SUCCESS = 'DELETE_USER_FROM_EXAMS_SUCCESS',
  DELETE_USER_FROM_EXAMS_FAILURE = 'DELETE_USER_FROM_EXAMS_FAILURE',
  RESET_USERS_EXAM= "RESET_USERS_EXAM"
}
