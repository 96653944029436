export enum SessionEnum {
  RESET_NEW_SESSION = 'RESET_NEW_SESSION',
  FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS',
  FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE',
  ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS',
  ADD_SESSION_FAILURE = 'ADD_SESSION_FAILURE',
  UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS',
  UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE',
  DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE',
  DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS',
  FETCH_CYCLE_MODULE_SESSION_FAILURE = 'FETCH_CYCLE_MODULE_SESSION_FAILURE',
  FETCH_CYCLE_MODULE_SESSION_SUCCESS = 'FETCH_CYCLE_MODULE_SESSION_SUCCESS',
  RESET_SESSION = 'RESET_SESSION',
  RESET_SESSION_MODULE = 'RESET_SESSION_MODULE',
  GET_REPLACEMENT_CLASSROOMS = 'GET_REPLACEMENT_CLASSROOMS',
}
