/* eslint-disable max-lines */
import * as React from 'react';
import { getUnfinishedTrainings } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Training from 'models/Training';
import { useEffect, useState } from 'react';
import TrainingSidebar from 'components/TrainingSidebar';
import { getUnfinishedCertificates } from 'actions/Certificate/getUnfinishedCertif';
import TrainingDraftCard from 'components/TrainingDraftCard';
import { getUnfinishedCycles } from 'actions/Cycle/getUnfinishedCycle';
import { draftEnum } from 'constants/enum/draftEnum';
import Cycle from 'models/Cycle';
import Certificate from 'models/Certificate';
import moment from 'moment';
import { getUnfinishedExam } from 'actions/Exam/getUnfinishedExam';
import Exam from 'models/Exam';

interface IProps {
  toggleDraft?: (
    draft: Training | Cycle | Certificate | Exam,
    trainingType: draftEnum,
  ) => void;
}

const AddTrainingDraftSection: React.FC<IProps> = ({ toggleDraft }) => {
  const dispatch = useDispatch();
  const [selectedProduct, setselectedProduct] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<
    'OneShot' | 'Certificate' | 'Cycle' | 'Exam'
  >();

  const toggleProductMenu = (): void => {
    setselectedProduct(!selectedProduct);
  };

  const toggleOneShotMenu = (): void => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu('OneShot');
  };
  const toggleCertificateMenu = () => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu('Certificate');
  };

  const toggleCycleMenu = () => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu('Cycle');
  };
  const toggleExamenMenu = () => {
    setselectedProduct(!selectedProduct);
    setSelectedMenu('Exam');
  };

  useEffect(() => {
    dispatch(getUnfinishedTrainings());
    dispatch(getUnfinishedCertificates());
    dispatch(getUnfinishedCycles());
    dispatch(getUnfinishedExam());
  }, []);
  // one shot draft
  const { unfinishedTrainingsList } = useSelector(
    (state: RootState) => state.training,
  );
  const { unfinishedCertificateList } = useSelector(
    (state: RootState) => state.certificate,
  );
  const { unfinishedCycleList } = useSelector(
    (state: RootState) => state.cycle,
  );
  const { unfinishedExamList } = useSelector((state: RootState) => state.exam);

  const { oneshot, certificate, cycle, exam } = draftEnum;

  const renderContent = () => {
    switch (selectedMenu) {
      case 'OneShot':
        return (
          <>
            {unfinishedTrainingsList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index}
                onToggleDraft={(): void => {
                  toggleDraft?.(el, oneshot);
                }}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );
      case 'Certificate':
        return (
          <>
            {/* certificate list  */}
            {unfinishedCertificateList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 7889}
                onToggleDraft={(): void => toggleDraft?.(el, certificate)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );
      case 'Cycle':
        return (
          <>
            {/* Cycle  list  */}
            {unfinishedCycleList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 84894}
                onToggleDraft={(): void => toggleDraft?.(el, cycle)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );
      case 'Exam':
        return (
          <>
            {unfinishedExamList?.map((el, index) => (
              <TrainingDraftCard
                key={el.id}
                index={index + 84894}
                onToggleDraft={(): void => toggleDraft?.(el, exam)}
                title={el.title}
                createdAt={moment(el.createdAt).format('L')}
              />
            ))}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <TrainingSidebar header="Formations non publiées" className="p-4">
      <div
        className="d-flex flex-column"
        style={{ justifyContent: 'space-evenly', marginTop: '25px', flex: 0.5 }}
      >
        {selectedProduct ? (
          <>
            {renderContent()}
            <button
              type="button"
              className="btn btn-soft-primary btn-sm-wide"
              onClick={toggleProductMenu}
            >
              Revenir au Menu
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-soft-primary btn-sm-wide"
              onClick={toggleOneShotMenu}
            >
              Formation continue
            </button>
            <button
              type="button"
              onClick={toggleCertificateMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Certification
            </button>
            <button
              type="button"
              onClick={toggleCycleMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Cycle
            </button>
            <button
              type="button"
              onClick={toggleExamenMenu}
              className="btn btn-soft-primary btn-sm-wide"
            >
              Examen
            </button>
          </>
        )}
      </div>
    </TrainingSidebar>
  );
};

export default AddTrainingDraftSection;
