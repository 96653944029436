import { getCandidatsExam } from 'actions';
import { getExamById } from 'actions/Exam/getExam';
import AllCandidatsExamListTable from 'components/AllCandidatsExamListTable';
import formatToCsv from 'helpers/formatToCsv';
import Attendance from 'models/Attendence';
import Candidate from 'models/Candidate';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'reducers';

type AttendanceWithCandidate = Attendance & {
  candidate: Candidate;
};

const CandidatsExamen: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    id: string;
  }>();

  const { examNotes, examAttendance, finishedExam } = useSelector(
    (state: RootState) => state.exam,
  );

  useEffect(() => {
    dispatch(getCandidatsExam(params.id));
    dispatch(getExamById(params.id));
  }, [params.id]);

  const formattedData = examAttendance
    ?.filter((el): el is AttendanceWithCandidate => !!el.candidate)
    .map(element => {
      const target = examNotes?.find(
        examNote => examNote.candidateId === element.candidateId,
      );

      return {
        ...element.candidate,
        examNoteId: target?.id,
        note: target?.note,
        isPresent: element.isPresent,
      };
    });

  const formattedExamResultsToCSV = formattedData?.map(el => ({
    Nom: el.firstName,
    Prénom: el.lastName,
    Présence: el.isPresent ? 'Présent' : 'absent',
    Note: el.note,
  }));

  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">
                  Candidats de l&apos;examen&nbsp;
                  {finishedExam?.title}
                </h1>
              </div>
            </div>
            {formattedExamResultsToCSV && (
              <CSVLink
                className="btn btn-soft-light"
                filename={`Examen ${finishedExam?.title} Résutat.csv`}
                data={formatToCsv(formattedExamResultsToCSV, [
                  {
                    label: 'Nom',
                    value: 'Nom',
                  },
                  {
                    label: 'Prénom',
                    value: 'Prénom',
                  },
                  {
                    label: 'Présence',
                    value: 'Présence',
                  },
                  {
                    label: 'Note',
                    value: 'Note',
                  },
                ])}
              >
                Exporter en tant que CSV
              </CSVLink>
            )}
          </div>
        </div>
      </div>
      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Liste des Candidats</h5>
          </div>
          <div className="card-body">
            {formattedData && (
              <AllCandidatsExamListTable
                data={formattedData}
                examId={Number(params.id)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatsExamen;
