import 'react-dates/initialize';
import React, { useState, useEffect } from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import moment, { Moment } from 'moment';

interface IDatePickerComponentProps {
  setDate: (date: Moment | null) => void;
  name?: string;
  value: Moment | null;
  withoutPrev?: boolean;
  step?: number;
}

const DatePickerComponent: React.FC<IDatePickerComponentProps> = ({
  setDate,
  name = 'date-picker',
  value,
  withoutPrev,
  step,
}) => {
  const [active, setActive] = useState(false);
  const [stateDate, setStateDate] = useState(
    (value || moment()) as SingleDatePickerShape['date'],
  );

  const setActivity = (v: boolean) => (): void => {
    setActive(v);
  };

  const prevDay = (): void => {
    const newDate = JSON.parse(
      JSON.stringify(value ? value.subtract(step, 'd') : moment()),
    ) as SingleDatePickerShape['date'];
    setDate(moment(newDate));
  };

  const nextDay = (): void => {
    const newDate = JSON.parse(
      JSON.stringify(value ? value.add(step, 'd') : moment()),
    ) as SingleDatePickerShape['date'];
    setDate(moment(newDate));
  };

  useEffect(() => {
    setStateDate(value as SingleDatePickerShape['date']);
  }, [value]);

  return (
    <div className="d-flex justify-content-around">
      <button
        disabled={
          withoutPrev && stateDate?.format('L') === moment().format('L')
        }
        type="button"
        className=" p-1 btn btn-link"
        onClick={prevDay}
      >
        <i className="fas fa-angle-left fa-md mr-2" />
        Précédent
      </button>
      <div className=" p-1">
        <SingleDatePicker
          id={name}
          date={stateDate}
          onDateChange={setDate}
          focused={active}
          onFocusChange={setActivity(!active)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          displayFormat="DD/MM/YYYY"
          customCloseIcon={<div>DONE</div>}
          isOutsideRange={day =>
            withoutPrev ? day.isBefore(moment().subtract(1, 'day')) : false}
        />
      </div>
      <button type="button" className="p-1 btn btn-link" onClick={nextDay}>
        Suivant
        <i className="fas fa-angle-right fa-md ml-2" />
      </button>
    </div>
  );
};

export default DatePickerComponent;
