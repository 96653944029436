import { resetProgram } from 'actions';
import { getCategories, getThemes } from 'actions/Training';
import api from 'api';
import { CertificateEnum } from 'constants/types/Certificate';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';
import Certificate from 'models/Certificate';
import { toast } from 'react-toastify';

export const addCertificate = (
  certificate: Certificate,
  selectedDraftId?: number,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    if (!selectedDraftId) {
      const response = await api.post('/certificates', certificate);
      dispatch({
        type: CertificateEnum.ADD_CERTIFICATE_SUCCESS,
        payload: response.data,
      });
      toast('Certification Sauvegardée', { className: 'toast-success' });
    } else {
      const response = await api.patch(
        `/certificates/${selectedDraftId}`,
        certificate,
      );
      dispatch({
        type: CertificateEnum.ADD_CERTIFICATE_SUCCESS,
        payload: response.data,
      });
      toast('Certification Sauvegardée', { className: 'toast-success' });
    }
    dispatch(getThemes());
    dispatch(getCategories());
    dispatch(resetProgram())
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CertificateEnum.ADD_CERTIFICATE_FAILURE, error });
  }
};
