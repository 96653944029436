/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import messages from 'helpers/errorMessages';
import moment from 'moment';

/*  changed val type to any  */
const size = (val: any | any[], type = 'num') => {
  // if an array or string get the length, else return the value.
  if (type === 'string' || type === undefined || type === 'array') {
    return val.length;
  }

  return parseFloat(val);
};

const sizeText = (type: string | undefined) => {
  if (type === 'string' || type === undefined) {
    return ' caractères';
  }
  if (type === 'array') {
    return 'éléments';
  }

  return '';
};

const testRegex = (
  value: {
    toString: () => {
      (): any;
      new (): any;
      match: { (arg0: any): null; new (): any };
    };
  },
  regex: RegExp,
) => {
  return value && value.toString().match(regex) !== null;
};
const toSentence = (arr: string) => {
  return (
    arr.slice(0, -2).concat(', ') +
    (arr.slice(0, -2).length ? ', ' : '') +
    /*  arg1 looked like it was missing ) */
    arr.slice(0, -2).concat(arr.length > 2 ? ', or ' : ' or ')
  );
};

const Validators = {
  accepted: (val: boolean) => (val === true ? undefined : messages.accepted),
  after: (params: { format: (arg0: string) => string }[]) => (val: {
    isAfter: (arg0: any, arg1: string) => any;
  }) =>
    moment.isMoment(val) && val.isAfter(params[0], 'day')
      ? undefined
      : messages.after.replace(':date', params[0].format('MM/DD/YYYY')),

  after_or_equal: (params: { format: (arg0: string) => string }[]) => (val: {
    isSameOrAfter: (arg0: any, arg1: string) => any;
  }) =>
    moment.isMoment(val) && val.isSameOrAfter(params[0], 'day')
      ? undefined
      : messages.after.replace(':date', params[0].format('MM/DD/YYYY')),
  alpha: (val: any) =>
    testRegex(val, /^[A-Z]*$/i) ? undefined : messages.alpha,
  alpha_space: (val: any) =>
    testRegex(val, /^[A-Z\s]*$/i) ? undefined : messages.alpha_space,
  alpha_num: (val: any) =>
    testRegex(val, /^[A-Z0-9]*$/i) ? undefined : messages.alpha_num,
  alpha_num_space: (val: any) =>
    testRegex(val, /^[A-Z0-9\s]*$/i) ? undefined : messages.alpha_num_space,
  alpha_num_dash: (val: any) =>
    testRegex(val, /^[A-Z0-9_-]*$/i) ? undefined : messages.alpha_num_dash,
  alpha_num_dash_space: (val: any) =>
    testRegex(val, /^[A-Z0-9_-\s]*$/i)
      ? undefined
      : messages.alpha_num_dash_space,
  array: (val: any) => (Array.isArray(val) ? undefined : messages.array),

  before: (params: { format: (arg0: string) => string }[]) => (val: {
    isBefore: (arg0: any, arg1: string) => any;
  }) =>
    moment.isMoment(val) && val.isBefore(params[0], 'day')
      ? undefined
      : messages.before.replace(':date', params[0].format('MM/DD/YYYY')),

  before_or_equal: (params: { format: (arg0: string) => string }[]) => (val: {
    isSameOrBefore: (arg0: any, arg1: string) => any;
  }) =>
    moment.isMoment(val) && val.isSameOrBefore(params[0], 'day')
      ? undefined
      : messages.before.replace(':date', params[0].format('MM/DD/YYYY')),

  between: (params: string[]) => (val: any) =>
    size(val) >= parseFloat(params[0]) && size(val) <= parseFloat(params[1])
      ? undefined
      : messages.between.replace(':min', params[0]).replace(':max', params[1]),
  boolean: (val: boolean) =>
    val === false || val === true ? undefined : messages.boolean,
  card_exp: (val: any) =>
    testRegex(val, /^(([0]?[1-9]{1})|([1]{1}[0-2]{1}))\s?\/\s?(\d{2}|\d{4})$/)
      ? undefined
      : messages.card_exp,
  card_num: (val: any) =>
    testRegex(val, /^\d{4}\s?\d{4,6}\s?\d{4,5}\s?\d{0,8}$/)
      ? undefined
      : messages.card_num,
  date: (val: any) => (moment.isMoment(val) ? undefined : messages.date),

  email: (val: any) =>
    testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
      ? undefined
      : messages.email,
  in: (params: string) => (val: any) =>
    params.indexOf(val) > -1
      ? undefined
      : messages.in.replace(':values', toSentence(params)),
  integer: (val: any) =>
    testRegex(val, /^\d*$/) ? undefined : messages.integer,
  max: (params: any[]) => (val: any) =>
    size(val, params[1]) <= parseFloat(params[0])
      ? undefined
      : messages.max
          .replace(':max', params[0])
          .replace(':type', sizeText(params[1])),
  min8: (val: any) =>
    size(val, 'string') >= parseFloat('8')
      ? undefined
      : messages.min.replace(':min', '8').replace(':type', sizeText('string')),
  numeric: (val: any) =>
    !val || testRegex(val, /^(\d+.?\d*)?$/) ? undefined : messages.numeric,
  phone: (val: any) =>
    testRegex(
      val,
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    )
      ? undefined
      : messages.phone,
  required: (val: string | any[]) => {
    if (val instanceof Array)
      return val.length !== 0 ? undefined : messages.required;

    return val ? undefined : messages.required;
  },
  string: (val: any) =>
    typeof val === typeof 'string' ? undefined : messages.string,
  passwordConfirm: (val: any, allVals: { password: any }) =>
    val === allVals.password ? undefined : messages.passwordConfirm,
  url: (val: any) =>
    testRegex(val, /^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?$/i)
      ? undefined
      : messages.url,
};

export default Validators;
