import { DeleteUser, getUsers, setSelectedUser } from 'actions/User';
import Fuse from 'fuse.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';
import UserViewModel from 'view-models/UserViewModel';
import columns from './columns';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [sortedUsers, setSortedUsers] = useState<UserViewModel[]>([]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);
  const { UserList } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (keyword.length === 0) {
      setSortedUsers(UserList);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['firstName', 'lastName', 'username'],
    };
    const fuse = new Fuse(UserList, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedUsers(results);
  }, [keyword, UserList]);

  const handleDelete = (user: UserViewModel) => {
    dispatch(DeleteUser(user.id));
  };

  const selectUser = (user: UserViewModel) => {
    dispatch(setSelectedUser(user));
  };

  const cols = columns(handleDelete, selectUser);

  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  return (
    <>
      <div className="bg-primary">
        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h1 text-white">Utilisateurs</h1>
              </div>
              <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                <li className="breadcrumb-item text-white">
                  Modifier, ajouter ou supprimer un utilisateur
                </li>
              </ol>
            </div>
            <div className="col-auto">
              <div className="d-none d-lg-block">
                <Link
                  to="/user?operation=add"
                  type="button"
                  className="btn btn btn-soft-light"
                >
                  Ajouter utilisateur
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-1 space-top-lg-0 mt-lg-n10">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Votre équipe</h5>
          </div>
          <div className="card-body">
            <DataTable
              data={sortedUsers}
              columns={cols}
              subHeader
              noHeader
              persistTableHead
              subHeaderAlign="center"
              expandOnRowClicked
              highlightOnHover
              subHeaderComponent={TabHeader()}
              noDataComponent="Aucun élément"
              pagination
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
