/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTraining } from 'actions';
import { RootState } from 'reducers';
import { reset } from 'redux-form';
import formatProgramDays from 'helpers/formatProgramDays';
import Training from 'models/Training';
import { useTrainingHandler } from './useTrainingHandler';

export const useOneShotHandler = (
  endDivRef: React.RefObject<HTMLDivElement>,
) => {
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [isDraftOpen, setisDraftOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [isProgramOpen, setIsProgramOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isBreakOpen, setIsBreakOpen] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [isWithFile, setIsWithFile] = useState(false);
  const [isPublishedTrainingsOpen, setIsPublishedTrainingsOpen] = useState(
    false,
  );
  const [date, setDate] = useState<Moment | null>(moment());
  const dispatch = useDispatch();
  const { selectedDraft } = useSelector((state: RootState) => state.training);
  const setselectedDraft = (training?: Training) => {
    dispatch(setSelectedTraining(training));
  };
  // one shot
  const { isOneShotOpen, setIsOneShotOpen } = useTrainingHandler(endDivRef);

  useEffect(() => {
    if (isOneShotOpen) {
      setisSaved(false);
      setisDraftOpen(false);
      setIsPublishedTrainingsOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setIsProgramOpen(false);
    setIsCalendarOpen(false);
    setIsUserOpen(false);
    setIsSessionOpen(false);
    setIsBreakOpen(false);
  }, [isOneShotOpen]);

  useEffect(() => {
    if (!isDraftOpen) return;
    setIsOneShotOpen(false);
    setIsPublishedTrainingsOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isDraftOpen]);

  useEffect(() => {
    if (!isPublishedTrainingsOpen) return;
    setIsOneShotOpen(false);
    setisDraftOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isPublishedTrainingsOpen]);

  useEffect(() => {
    if (isCalendarOpen) {
      setIsProgramOpen(false);
      setIsBreakOpen(false);
      setIsUserOpen(false);
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setIsSessionOpen(false);
  }, [isCalendarOpen]);

  useEffect(() => {
    if (!isBreakOpen) return;
    setIsProgramOpen(false);
    setIsUserOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isBreakOpen]);

  useEffect(() => {
    if (!isProgramOpen) {
      dispatch(reset('addProgramForm'));
      return;
    }
    setIsBreakOpen(false);
    setIsUserOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isProgramOpen]);

  useEffect(() => {
    if (!isUserOpen) return;
    setIsProgramOpen(false);
    setIsBreakOpen(false);
    setIsCalendarOpen(false);
    endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isUserOpen]);

  useEffect(() => {
    if (isSessionOpen)
      endDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isSessionOpen]);

  const { newTraining } = useSelector((state: RootState) => state.training);

  const toggleDraft = (): void => {
    setisDraftOpen(!isDraftOpen);
  };

  const togglePublishedTrainings = () =>
    setIsPublishedTrainingsOpen(prev => !prev);

  const toggleCalendarBar = (): void => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const toggleModule = (): void => {
    setIsModuleOpen(!isModuleOpen);
  };

  const toggleBreakBar = (): void => {
    setIsBreakOpen(!isBreakOpen);
  };

  const { Program } = useSelector((state: RootState) => state.program);
  let programInitialValues = {};
  if (Program)
    programInitialValues = {
      ...Program,
      programDays: formatProgramDays(Program?.programDays),
    };

  const toggleProgramBar = (): void => {
    setIsProgramOpen(!isProgramOpen);
  };

  const toggleSessionBar = (value: Moment | null): void => {
    setIsSessionOpen(true);
    setDate(moment(value?.toDate().setHours(0, 0, 0, 0)));
  };

  const toggleUserBar = (): void => {
    setIsUserOpen(!isUserOpen);
  };

  return {
    toggleUserBar,
    toggleProgramBar,
    toggleSessionBar,
    toggleBreakBar,
    toggleCalendarBar,
    toggleModule,
    toggleDraft,
    newTraining,
    setisSaved,
    isSaved,
    date,
    selectedDraft,
    setselectedDraft,
    setIsSessionOpen,
    isBreakOpen,
    isUserOpen,
    setIsBreakOpen,
    setIsOneShotOpen,
    setIsModuleOpen,
    isDraftOpen,
    isOneShotOpen,
    isModuleOpen,
    isCalendarOpen,
    isSessionOpen,
    isProgramOpen,
    setIsProgramOpen,
    programInitialValues,
    isWithFile,
    setIsWithFile,
    togglePublishedTrainings,
    isPublishedTrainingsOpen,
  };
};
