/* eslint-disable @typescript-eslint/no-explicit-any */

const buildFormData = (
  formData: FormData,
  data: { [key: string]: any },
): void => {
  if (!data) {
    return;
  }
  Object.keys(data).forEach(element => {
    if (data[element] instanceof File || typeof data[element] === 'string') {
      formData.append(element, data[element]);
    } else if (
      data[element] instanceof Array ||
      data[element] instanceof FileList
    ) {
      (data[element] instanceof Array
        ? data[element]
        : Array.from(data[element])
      ).forEach((el: any) => {
        if (el instanceof File || typeof el === 'string') {
          formData.append(element, el);
        } else formData.append(element, JSON.stringify(el));
      });
    } else formData.append(element, JSON.stringify(data[element]));
  });
};

export default buildFormData;
