import { Moment } from 'moment';

export const numberOfDivs = (
  calendar: { date: string; day: number; moment: Moment }[],
  first?: boolean,
) => {
  let selectedDay = 0;
  selectedDay =
    calendar.length &&
    (first ? calendar[0].day : calendar[calendar.length - 1].day);

  const array = [];

  const divs = first
    ? 7 - (7 - (calendar ? selectedDay : 0))
    : 6 - (calendar ? selectedDay : 0);
  for (let index = 1; index <= divs; index++) {
    array.push(index);
  }

  return array;
};
