import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { startLoading, stopLoading } from 'actions';
import { CertificateEnum } from 'constants/types/Certificate';
import { errorHandler } from 'helpers/errorHandler';
export const getAllCertificatesWithModules = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `certificates?filter={"include" : ["module"]}`,
    );
    dispatch({
      type: CertificateEnum.FETCH_ALL_CERTIFICATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CertificateEnum.FETCH_ALL_CERTIFICATES_FAILURE, error });
  }
  stopLoading()(dispatch);
};
