import React from 'react';

interface IProps {
  onClose?: () => void;
}
export const CloseButton: React.FC<IProps> = ({ onClose }) => {
  return (
    <div className="sidebar-close-button-container">
      <button type="button" onClick={onClose} className="sidebar-close-button">
        X
      </button>
    </div>
  );
};
