/* eslint-disable react/no-array-index-key */
import { CandidatesStatData } from 'models/Stats';
import React from 'react';
import { DataDisplay } from './CandidatesPerTrainingType';
import StatCard from './StatCard';

export type TopTrainingProps = {
  title: string;
  trainings: CandidatesStatData[];
  exportCSVButton: React.ReactNode;
};

const TopTraining = ({
  title,
  trainings,
  exportCSVButton,
}: TopTrainingProps) => {
  return (
    <StatCard {...{ title, exportCSVButton }}>
      {trainings.map((el, i) => (
        <DataDisplay
          className={i > 0 ? 'mt-2' : ''}
          key={`${el.title}-${i}`}
          title={el.title}
          value={el.candidates}
        />
      ))}
    </StatCard>
  );
};

export default TopTraining;
