import { IThunkAction } from 'constants/types/IThunkAction';
import api from 'api';
import { BreakEnum } from 'constants/types/Break';
import { startLoading, stopLoading } from 'actions';
import { BreakForm, BreakToAdd } from 'view-models/BreakViewModel';
import SearchBreaksParams from 'models/SearchBreaksParams';
import { errorHandler } from 'helpers/errorHandler';

export const getBreaks = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get('breaks?filter={"include" : ["breakType"]}');
    dispatch({
      type: BreakEnum.FETCH_BREAK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: BreakEnum.FETCH_BREAK_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const addBreaks = (breaks: BreakToAdd[]): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    const response = await api.post('/breaks/many', breaks);
    dispatch({
      type: BreakEnum.ADD_BREAK_SUCCESS,
      payload: response.data,
    });
    getBreaks()(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: BreakEnum.ADD_BREAK_FAILURE, error });
    errorHandler(error);
  }
};

export const DeleteBreak = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.delete(`/Breaks/${id}`);
    dispatch({
      type: BreakEnum.DELETE_BREAK_SUCCESS,
    });
    getBreaks()(dispatch, getState, null);
  } catch (error) {
    dispatch({ type: BreakEnum.DELETE_BREAK_FAILURE, error });
    errorHandler(error);
  }
};

export const getBreaksPerWeek = (
  searchParams: SearchBreaksParams,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `Breaks/listed-breaks-per-week/${searchParams.startWeek}/${searchParams.endWeek}`,
    );
    dispatch({
      type: BreakEnum.FETCH_SEARCHED_BREAKS_PER_WEEK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: BreakEnum.FETCH_SEARCHED_BREAKS_PER_WEEK_FAILURE, error });
    errorHandler(error);
  }
  stopLoading()(dispatch);
};

export const deleteBreakPerWeek = (
  searchParams: SearchBreaksParams,
  id?: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.delete(`Breaks/${id}`);
    dispatch({
      type: BreakEnum.DELETE_BREAK_SUCCESS,
    });
    getBreaksPerWeek(searchParams)(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BreakEnum.ADD_BREAK_FAILURE, error });
  }
};

export const editBreakPerWeek = (
  searchParams: SearchBreaksParams,
  breaks: BreakForm,
  id?: number,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    startLoading()(dispatch);
    await api.patch(`Breaks/${id}`, breaks);
    dispatch({
      type: BreakEnum.UPDATE_BREAK_SUCCESS,
    });
    getBreaksPerWeek(searchParams)(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: BreakEnum.UPDATE_BREAK_FAILURE,
      error,
    });
  }
};

export const getBreakById = (id?: number): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      `Breaks/${id}?filter={"include" : ["breakType"]}`,
    );
    dispatch({
      type: BreakEnum.FETCH_BREAK_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: BreakEnum.FETCH_BREAK_BY_ID_FAILURE,
      error,
    });
  }
};
