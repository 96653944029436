import * as React from 'react';
import Validators from 'helpers/formValidator';
import InputField from 'components/InputField';
import { change, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import RichField from 'components/RichField';

interface IProps {
  day: string;
  uniqKey?: number;
  moduleTitle?: string;
  duration: string;
  seance?: number;
  isCertif?: boolean;
  content?: string;
  methods?: string;
}
const RenderProgramDay: React.FC<IProps> = ({
  isCertif,
  uniqKey,
  moduleTitle,
  seance,
  day,
  duration,
  content,
  methods,
}) => {
  const dispatch = useDispatch();
  const selector = isCertif
    ? formValueSelector('addCertifProgramForm')
    : formValueSelector('addProgramForm');
  const value = useSelector((state: RootState) =>
    selector(
      state,
      `programDays.${isCertif ? uniqKey : day}.theoreticalDuration`,
    ),
  );

  React.useEffect(() => {
    if (value > duration)
      dispatch(
        change(
          isCertif ? 'addCertifProgramForm' : 'addProgramForm',
          `programDays.${isCertif ? uniqKey : day}.theoreticalDuration`,
          duration.toString(),
        ),
      );
    isCertif &&
      dispatch(
        change(
          'addCertifProgramForm',
          `programDays.${uniqKey}.days`,
          `${seance} séances/ ${duration} heures/ ${day} jours`,
        ),
      );
    isCertif &&
      dispatch(
        change(
          'addCertifProgramForm',
          `programDays.${uniqKey}.module`,
          moduleTitle,
        ),
      );
  }, [value]);
  React.useEffect(() => {
    if (value && duration)
      dispatch(
        change(
          isCertif ? 'addCertifProgramForm' : 'addProgramForm',
          `programDays.${isCertif ? uniqKey : day}.practicalDuration`,
          (+duration - value).toString(),
        ),
      );
  }, [value]);

  return (
    <div className="row my-4" key={isCertif ? uniqKey : day}>
      <div className="col-sm">
        {isCertif ? (
          <InputField
            name={`programDays.${isCertif ? uniqKey : day}.days`}
            label="Jour"
            validate={[Validators.required]}
            disabled
          />
        ) : (
          <>
            <div className="form-label">
              <span className="d-flex justify-content-between align-items-center">
                Jour
              </span>
            </div>
            <span className="form-control">{isCertif ? uniqKey : day}</span>
          </>
        )}
      </div>

      {isCertif && (
        <div className="col-sm">
          <InputField
            name={`programDays.${isCertif ? uniqKey : day}.module`}
            label="module"
            validate={[Validators.required]}
            disabled
          />
        </div>
      )}

      <div className="col-sm">
        <RichField
          pickedContent={content}
          name={`programDays.${isCertif ? uniqKey : day}.content`}
          label="Contenus"
          validate={[Validators.required]}
        />
      </div>
      <div className="col-sm">
        <RichField
          pickedContent={methods}
          name={`programDays.${isCertif ? uniqKey : day}.methods`}
          label="Méthodes"
          validate={[Validators.required]}
        />
      </div>
      <div className="col-sm">
        <InputField
          name={`programDays.${isCertif ? uniqKey : day}.theoreticalDuration`}
          label="durée théorique"
          type="number"
          placeholder={duration?.toString()}
          validate={[Validators.required]}
          min={0}
          step={0.25}
        />
      </div>

      <div className="col-sm">
        <InputField
          name={`programDays.${isCertif ? uniqKey : day}.practicalDuration`}
          label="durée pratique"
          type="number"
          disabled
          validate={[Validators.required]}
        />
      </div>
    </div>
  );
};

export default RenderProgramDay;
