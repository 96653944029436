/* eslint-disable max-len */
import SessionViewModel from 'view-models/SessionViewModel';
import moment, { Moment } from 'moment';

const formatData = (
  list: SessionViewModel[],
  date: moment.Moment | null,
): {
  name: string;
  time: string;
  room: string;
  courseNumber: number;
  isExam: boolean;
}[] => {
  const filtred = list.filter(
    el => date?.format('L') === moment(el.date).format('L'),
  );

  const sorted = filtred.sort((a, b) => {
    if (moment(a.from).format('HH:mm') > moment(b.from).format('HH:mm'))
      return 1;
    if (moment(b.from).format('HH:mm') > moment(a.from).format('HH:mm'))
      return -1;
    return 0;
  });

  const formatted = sorted.map(el => ({
    name: el.examId ? el.exam.defaultUser.map(superivor => `${superivor.firstName} ${superivor.lastName}`).toString()   : `${el.trainer.firstName} ${el.trainer.lastName}`,
    time: `de ${moment(el.from).format('h:mm A')} à ${moment(el.to).format(
      'h:mm A',
    )}`,
    room: `${el.classroom.type} ${
      el.classroom.name !== null ? el.classroom.name : ''
    } ${el.classroom.number}`,
    courseNumber: el.courseNumber,
    isExam: !!el.examId,
  }));

  return formatted 
};

export default formatData;
