import * as React from 'react';
import Candidate from 'models/Candidate';
import ConfirmationPopUp from 'components/ConfirmationPopUp';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js';
import { useEffect } from 'react';

interface Props {
  candidates: Candidate[];
  handleDelete: (id: number) => void;
  HandleUpdate: (candidate: Candidate) => void;
}

const CandTable: React.FC<Props> = ({
  candidates,
  handleDelete,
  HandleUpdate,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const [sortedCandidates, setSortedCandidates] = React.useState<Candidate[]>(
    [],
  );
  useEffect(() => {
    if (keyword.length === 0) {
      setSortedCandidates(candidates);
      return;
    }
    const options = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'lastName',
        'firstName',
        'phoneNumber',
        'email',
        'CIN',
        'bank.name',
        'job.title',
      ],
    };
    const fuse = new Fuse(candidates, options);
    const results = fuse.search(keyword).map(el => el.item);
    setSortedCandidates(results);
  }, [keyword, candidates]);
  const TabHeader = () => (
    <div className="text-center mt-2">
      <input
        className="form-control mb-3"
        placeholder="Recherche.."
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
      />
    </div>
  );
  const columns = () => {
    const data = [
      {
        name: 'Nom',
        selector: 'lastName',
        sortable: true,
      },
      {
        name: 'Prénom',
        selector: 'firstName',
        sortable: true,
      },
      {
        name: 'Téléphone',
        selector: 'phoneNumber',
        sortable: true,
      },
      {
        name: 'E-mail',
        sortable: true,
        selector: 'email',
      },
      {
        name: 'CIN',
        selector: 'CIN',
        sortable: true,
      },
      {
        center: true,
        name: 'Etablissement',
        sortable: true,
        cell: (candidat: Candidate) => candidat?.bank?.name,
      },
      {
        center: true,
        name: 'Poste',
        sortable: true,
        cell: (candidat: Candidate) => candidat?.job?.title,
      },
      {
        center: true,
        name: 'Modifier',
        maxWidth: '8vw',
        cell: (candidat: Candidate) => (
          <Link
            to="/UpdateCandidate"
            type="button"
            className="btn btn-block btn-primary btn-icon d-flex 
            align-items-center justify-content-center"
            onClick={(): void => HandleUpdate(candidat)}
          >
            <i className="fa fa-edit" />
          </Link>
        ),
      },
      {
        center: true,
        name: 'Supprimer',
        maxWidth: '8vw',
        cell: (candidat: Candidate) => (
          <ConfirmationPopUp
            handleDelete={(): void => handleDelete(candidat.id)}
          />
        ),
      },
    ];
    return data;
  };
  return (
    <DataTable
      data={sortedCandidates}
      columns={columns()}
      subHeader
      noHeader
      persistTableHead
      subHeaderAlign="center"
      expandOnRowClicked
      highlightOnHover
      subHeaderComponent={TabHeader()}
      noDataComponent="Aucun élément"
      pagination
    />
  );
};
export default CandTable;
