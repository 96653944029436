// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { WrappedFieldProps } from 'redux-form';
import htmlToDraft from 'html-to-draftjs';

export interface IProps {
  label?: string;
  placeholder?: string;
  pickedContent?: string;
}

const WysiwygEditor: React.FC<IProps & WrappedFieldProps> = ({
  input: { onChange, name, value: initialValue },
  label,
  placeholder,
  pickedContent,
}) => {
  const [value, setValue] = useState(EditorState.createEmpty());

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(initialValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    const editorState = EditorState.createWithContent(contentState);
    setValue(editorState);
  }, []);

  const onEditorStateChange = (input: EditorState) => {
    setValue(input);
    onChange(draftToHtml(convertToRaw(input.getCurrentContent())));
  };

  useEffect(() => {
    if (!pickedContent) return;

    const { contentBlocks, entityMap } = convertFromHTML(pickedContent);
    setValue(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap),
      ),
    );
    onChange(
      draftToHtml(
        convertToRaw(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlocks, entityMap),
          ).getCurrentContent(),
        ),
      ),
    );
  }, [pickedContent]);

  return (
    <div>
      <label className="form-label" htmlFor={name}>
        <span className="d-flex justify-content-between align-items-center">
          {label}
        </span>
      </label>
      <Editor
        placeholder={placeholder}
        editorState={value}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor form-control"
        toolbarClassName="justify-content-center"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'textAlign', 'list'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['ordered'],
          },
          textAlign: {
            options: ['center'],
          },
        }}
      />
    </div>
  );
};

export default WysiwygEditor;
