import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { ModuleEnum } from 'constants/types/Module';
import { IThunkAction } from 'constants/types/IThunkAction';
import { errorHandler } from 'helpers/errorHandler';

export const updateModule = (id?: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  const certificationId = getState().certificate.newCertificate?.id;
  try {
    startLoading()(dispatch);
    const response = await api.get(`/Modules/details/${certificationId}/${id}`);
    dispatch({
      type: ModuleEnum.FETCH_MODULES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({
      type: ModuleEnum.FETCH_MODULES_FAILURE,
      error,
    });
  }
  stopLoading()(dispatch);
};
