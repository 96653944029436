/* eslint-disable max-lines */
import { startLoading, stopLoading } from 'actions';
import api from 'api';
import { SessionEnum } from 'constants/types/Session';
import { IThunkAction } from 'constants/types/IThunkAction';
import {
  ISessionModuleForm,
  SessionCycleModuleToAdd,
  SessionToAdd,
} from 'view-models/SessionViewModel';
import Classroom from 'models/Classroom';
import { errorHandler } from 'helpers/errorHandler';
import { IReduxAction } from 'constants/types/IReduxAction';

export const getSessions = (): IThunkAction => async (
  dispatch,
): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      // eslint-disable-next-line max-len
      'sessions?filter={"include":["classroom", "trainer", {"training": ["theme","category","break"]},{"exam":["defaultUser"]} ,{"cycleModule" : [{"semester" : ["cycle" , "theme" , "category"]}]},{"module" : [{"certificate" : ["theme" , "category"]}]}]}',
    );
    dispatch({
      type: SessionEnum.FETCH_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.FETCH_SESSION_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const getSessionsForClassroomCalendar = (
  onSuccess?: () => void,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      // eslint-disable-next-line max-len
      'sessions?filter={"include":["classroom", "trainer", {"training": ["theme","category","candidate"]},{"exam":["defaultUser"]} , {"cycleModule" : [{"semester" : [{"cycle" : "candidate"} , "theme" , "category"]}]},{"module" : [{"certificate" : ["theme" , "category", "candidate"]}]}]}',
    );
    dispatch({
      type: SessionEnum.FETCH_SESSION_SUCCESS,
      payload: response.data,
    });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.FETCH_SESSION_FAILURE, error });
  }
  stopLoading()(dispatch);
};

export const addSession = (
  sessions: SessionToAdd | ISessionModuleForm,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const response = await api.post('/sessions/', sessions);
    dispatch({
      type: SessionEnum.ADD_SESSION_SUCCESS,
      payload: response.data,
    });
    getSessions()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.ADD_SESSION_FAILURE, error });
  }
};

export const UpdateSession = (id: number, data: object): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.patch(`/Sessions/${id}`, data);
    dispatch({
      type: SessionEnum.UPDATE_SESSION_SUCCESS,
    });
    getSessions()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.UPDATE_SESSION_FAILURE, error });
  }
};

export const DeleteSession = (id: number): IThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  try {
    await api.delete(`/Sessions/${id}`);
    dispatch({
      type: SessionEnum.DELETE_SESSION_SUCCESS,
    });
    getSessions()(dispatch, getState, null);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.DELETE_SESSION_FAILURE, error });
  }
};
export const getCycleSemesterModuleSessions = (
  cycleModuleId: number | undefined,
): IThunkAction => async (dispatch): Promise<void> => {
  try {
    startLoading()(dispatch);
    const response = await api.get(
      // eslint-disable-next-line max-len
      `sessions/cycleModule/${cycleModuleId}`,
    );
    dispatch({
      type: SessionEnum.FETCH_CYCLE_MODULE_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.FETCH_CYCLE_MODULE_SESSION_FAILURE, error });
  }
  stopLoading()(dispatch);
};
export const addSessionToCycleModule = (
  sessions: SessionCycleModuleToAdd,
): IThunkAction => async (dispatch, getState): Promise<void> => {
  try {
    const response = await api.post('/sessions/', sessions);
    dispatch({
      type: SessionEnum.ADD_SESSION_SUCCESS,
      payload: response.data,
    });
    getCycleSemesterModuleSessions(sessions?.cycleModuleId)(
      dispatch,
      getState,
      null,
    );
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SessionEnum.ADD_SESSION_FAILURE, error });
  }
};

export const getReplacementClassrooms = (
  sessionId: number,
): IThunkAction => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await api.get<Classroom[]>(
      `sessions/${sessionId}/get-replacement-classrooms`,
    );
    dispatch({
      type: SessionEnum.GET_REPLACEMENT_CLASSROOMS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const resetReplacementClassrooms: IReduxAction = {
  type: SessionEnum.GET_REPLACEMENT_CLASSROOMS,
  payload: [],
};

export const updateSessionClassroom = (
  sessionId: number,
  classroomId: number,
  onSuccess?: () => void,
): IThunkAction => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`/sessions/${sessionId}`, {
      classroomId,
    });
    dispatch(getSessionsForClassroomCalendar(onSuccess));
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const setSessionPayment = (
  sessionId: number,
  isPaid: boolean,
  onSuccess?: () => void,
): IThunkAction => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`/sessions/${sessionId}`, {
      isPaid,
    });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
