/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { useCertificate } from 'hooks/useCertificate';
import AutoCompleteField from 'components/AutoCompleteFiled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import TrainingSidebar from 'components/TrainingSidebar';
import Certificate from 'models/Certificate';
import ModuleCard from 'components/ModuleCard';
import { getAllCertificatesWithModules } from 'actions/Certificate/getAllCertificatesWithModules';
import Modal from 'components/Modal';
import CertificationTable from 'components/CertificationTable';
import IImportedCertificate from 'models/IImportedCertificate';
import { importCertificate } from 'actions/Certificate/importCertificate';
import IModule from 'models/IModule';
import { deleteModule } from 'actions/Module/deleteModule';
import { getFinishedModules } from 'actions/Module/getFinishedModule';
import BreakCard from 'components/BreakCard';
import { DeleteBreak } from 'actions/Break';

interface Props {
  toggleModule?: () => void;
  setisSaved: (isSaved: boolean) => void;
  openModuleSection?: () => void;
  toggleCalendarBar?: () => void;
  toggleBreakBar?: () => void;
  toggleProgramBar?: () => void;
  toggleUserBar?: () => void;
  handlePublish: () => void;
  toggleCertificate?: () => void;
  isSaved: boolean;
}
const AddTrainingCertifSection: React.FC<Props &
  InjectedFormProps<Certificate, Props>> = ({
  toggleCertificate,
  toggleModule,
  toggleProgramBar,
  openModuleSection,
  toggleUserBar,
  handleSubmit,
  handlePublish,
  isSaved,
  setisSaved,
  initialValues,
  toggleCalendarBar,
  toggleBreakBar,
}) => {
  const dispatch = useDispatch();
  const {
    formattedModules,
    themeList,
    categoryList,
    formattedBreaks,
  } = useCertificate(initialValues);
  const [generateFile, setGenerateFile] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCertif, setSelectedCertif] = useState<IImportedCertificate>();
  const { loading } = useSelector((state: RootState) => state.loading);
  const { certificates } = useSelector(
    (state: RootState) => state.certificates,
  );

  const handleDeleteModule = (value: number): void => {
    dispatch(deleteModule(value));
  };

  useEffect(() => {
    dispatch(getFinishedModules());
    dispatch(getAllCertificatesWithModules());
  }, []);
  const handleDeleteBreak = (value: number): void => {
    dispatch(DeleteBreak(value));
  };
  useEffect(() => {
    dispatch(change('addCertificateForm', 'title', selectedCertif?.title));
  }, [selectedCertif]);

  const importCertif = (id?: number) => {
    const findedCertif = certificates?.find(
      certificate => certificate.id === id,
    );
    delete findedCertif?.id;
    delete findedCertif?.createdAt;
    delete findedCertif?.updatedAt;
    if (findedCertif) {
      const filtredModules: Partial<IModule>[] = findedCertif?.module.map(
        module => ({
          title: module.title,
          content: module.content,
          methods: module.methods,
        }),
      );
      setSelectedCertif(findedCertif);
      dispatch(importCertificate({ ...findedCertif, module: filtredModules }));
      setModalOpen(false);
      setisSaved(true);
    }
  };

  const unhandledModules = formattedModules?.find(
    e => e.duration === null || e.duration === '0' || !e.duration,
  );

  useEffect(() => {
    if (unhandledModules || !formattedModules?.length) setGenerateFile(false);
    else setGenerateFile(true);
  }, [formattedModules, unhandledModules]);

  // test formatted modules

  return (
    <form onSubmit={handleSubmit} className="col-4 p-0">
      <Modal
        title="Ancient Certification"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        {certificates?.length && (
          <CertificationTable
            themeList={themeList}
            categoryList={categoryList}
            handleImport={importCertif}
            certifications={certificates}
          />
        )}
      </Modal>
      <TrainingSidebar
        header="Certification"
        className="p-4"
        onClose={() => toggleCertificate?.()}
      >
        {!loading && (
          <>
            <InputField
              name="title"
              label="titre de la certification"
              validate={[Validators.required]}
            />
            <AutoCompleteField
              importedValue={selectedCertif?.themeId}
              className="orange badge-outline-primary"
              label="Thème"
              name="themeId"
              validate={[Validators.required]}
              suggestions={themeList}
            />
            <AutoCompleteField
              className="orange badge-outline-primary"
              importedValue={selectedCertif?.categoryId}
              label="Categorie"
              name="categoryId"
              suggestions={categoryList}
            />
            <button
              type="submit"
              className="btn btn-sm-wide btn-soft-primary mb-2"
            >
              SAUVEGARDER
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleModule}
              disabled={!isSaved}
            >
              Créer les Modules
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={() => setModalOpen(true)}
            >
              Importer Certification
              <i className="fas fa-angle-right fa-sm ml-2 mb-2" />
            </button>
            {formattedModules?.map(module => {
              return (
                <div key={module.id}>
                  <ModuleCard
                    isCertif
                    certificateId={module?.certificateId}
                    toggleCalendarBar={toggleCalendarBar}
                    toggleModule={openModuleSection}
                    title={module.title}
                    content={module.content}
                    methods={module.methods}
                    duration={module.duration}
                    days={module.days}
                    courseNumber={module.courseNumber}
                    id={module.id}
                    deleteModule={handleDeleteModule}
                  />
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleBreakBar}
              disabled={!isSaved}
            >
              Ajouter les Pauses café
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            {formattedBreaks.map(item => (
              <div key={item.id}>
                <BreakCard data={item} deleteBreak={handleDeleteBreak} />
              </div>
            ))}
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleProgramBar}
              disabled={!generateFile}
            >
              Générer la fiche programme du certification
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-soft-primary mb-2"
              onClick={toggleUserBar}
              disabled={!isSaved}
            >
              Ajouter des organisateurs
              <i className="fas fa-angle-right fa-sm ml-2" />
            </button>
            <button
              type="button"
              className="btn btn-sm-wide btn-primary mb-2"
              onClick={handlePublish}
              disabled={!isSaved}
            >
              PUBLIER
            </button>
          </>
        )}
      </TrainingSidebar>
    </form>
  );
};
export default reduxForm<Certificate, Props>({
  form: 'addCertificateForm',
  enableReinitialize: true,
})(AddTrainingCertifSection);
